<template>
  <v-container>
    <v-row class="py-0 my-0" justify="center" align="center">
      <v-col cols="12" md="6" class="py-0 my-0">
        <v-container fill-height fluid v-if="loading">
          <v-row class="py-0 my-0" justify="center" align="center">
            <v-col cols="12" class="py-0 my-0" justify="center" align="center">
              <v-progress-circular :size="50" indeterminate> </v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row class="py-0 my-0" justify="center" align="center">
            <v-col cols="12" class="py-0 my-0 px-4 mb-5">
              <router-link
                class="subtitle-1 grey-navy--text font-weight-bold text-decoration-none"
                to="/rewards/points"
              >
                <v-icon small class="pb-1"> keyboard_backspace </v-icon> Back
              </router-link>
            </v-col>
          </v-row>
          <v-card elevation="2" style="border-radius: 25px;">
            <v-container v-if="!submitted">
              <v-row class="py-0 my-0" justify="center" align="center">
                <v-col cols="12" class="py-0 my-0 px-4">
                  <v-card-title class="navy--text mb-1">
                    <span class="header">
                      Get points
                    </span>
                  </v-card-title>
                  <v-card-subtitle class="pt-2">
                    <j-alert class="std-text"
                      >Get your 5 out of 7 and you'll be awarded your points on Monday 11:00 SAST
                      <p class="mb-0">
                        <a href="/article/5-out-of-7" class="font-weight-bold std-text">Learn more →</a>
                      </p>
                    </j-alert>

                    <span class="p-text">
                      You can get points for your involvement in the {{ $appName }} community and redeem your points for epic
                      rewards!
                    </span>
                  </v-card-subtitle>
                  <j-btn tertiary large class="ml-3 mb-5" to="/article/how-to-use-rewards-points">
                    How to Earn Points
                  </j-btn>
                </v-col>
              </v-row>
              <v-row class="py-0 my-0" justify="center" align="center">
                <v-col cols="12" class="py-0 my-0 px-4">
                  <v-card-title class="mb-1">
                    <span class="heading-3 mid-grey--text">Want to request your points? </span>
                  </v-card-title>
                  <v-card-subtitle class="pt-2">
                    <span class="p-text">
                      Fill out the form to submit your points request. We'll review it ASAP. Please provide additional
                      information if needed.
                    </span>
                  </v-card-subtitle>
                </v-col>
              </v-row>
              <v-row v-if="submitError" class="py-0 my-0" justify="center" align="center">
                <v-col cols="12" class="py-0 my-0">
                  <v-alert type="error" dismissible>{{ submitError }}</v-alert>
                </v-col>
              </v-row>
              <v-card-text class="pt-0 mt-0">
                <validation-observer ref="earnPointsObs">
                  <v-form>
                    <v-row class="py-0 my-0" justify="center" align="center">
                      <v-col cols="12" class="py-0 my-0">
                        <v-alert v-if="alertInfoLabel" type="info" dismissible>{{ alertInfoLabel }}</v-alert>
                        <ValidationProvider mode="lazy" name="Category" rules="required" v-slot="{ errors, valid }">
                          <v-select
                            v-model="formData.category"
                            :items="categories"
                            @change="changeOption"
                            label="What you did"
                            :error-messages="errors"
                            :success="valid"
                          ></v-select>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                      <v-col cols="12" class="py-0 my-0">
                        <v-menu
                          ref="menu"
                          v-model="datePickerModal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              mode="lazy"
                              name="Earn Date"
                              rules="required"
                              v-slot="{ errors, valid }"
                            >
                              <v-text-field
                                name="Date"
                                v-model="formData.date"
                                label="When you did it"
                                prepend-icon="mdi-calendar"
                                readonly
                                :error-messages="errors"
                                :success="valid"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="formData.date"
                            :max="minStartDate"
                            @change="saveDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row v-if="extraInformationField.show" class="py-0 my-0" justify="center" align="center">
                      <v-col cols="12" class="py-0 my-0">
                        <ValidationProvider name="Extra Information" :rules="extraFieldRules" v-slot="{ errors }">
                          <v-textarea
                            v-model="formData.extraInformation"
                            :placeholder="extraInformationField.label"
                            rows="2"
                            :error-messages="errors"
                          ></v-textarea>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                      <v-col cols="12" class="py-0 my-0">
                        <ValidationProvider name="Description" rules="max:200" v-slot="{ errors }">
                          <v-textarea
                            v-model="formData.description"
                            placeholder="Please provide any additional information you think is needed. If you're requesting points for sharing on Facebook or Instagram, please include a link in your post here"
                            rows="3"
                            :error-messages="errors"
                          ></v-textarea>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                      <v-col cols="12" class="py-0 my-0">
                        <h4 class="pb-1 navy--text">Upload a screenshot as proof</h4>
                        <image-uploader
                          :preview="false"
                          :quality="0.9"
                          style="max-width: 100%"
                          outputFormat="verbose"
                          accept=".jpg,.jpeg,.png"
                          @input="setImage"
                        >
                        </image-uploader>
                        <v-alert v-if="noImage" class="mt-5" type="error">Please upload a screenshot.</v-alert>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                      <v-col cols="12" class="py-0 my-0 mt-5" justify="center" align="center">
                        <j-btn primary large @click="submit" :disabled="submitting" :loading="submitting">
                          Send Request for Points
                        </j-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </validation-observer>
              </v-card-text>
            </v-container>
            <v-container v-else>
              <v-card-title>Rewards points request submitted</v-card-title>
              <v-card-subtitle>
                Your request to earn rewards points has been submitted for review. Once reviewed and the material
                provided is deemed sufficient, your request will be approved and your points will be ready to
                use!</v-card-subtitle
              >
              <v-row class="py-0 my-0" justify="center" align="center">
                <v-col cols="12" class="py-0 my-0 mt-5" justify="center" align="center">
                  <j-btn secondary large to="/rewards/points">
                    Back To Points
                  </j-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";
import { REWARD_CATEGORIES_QUERY } from "@/graphql/queries/rewards";
import { EARN_REWARD_MUTATION } from "@/graphql/mutations/rewards";
import { blobToFile, dataURLtoBlob } from "@/lib/file";

export default {
  name: "EarnPoints",
  components: {
    ImageUploader,
  },
  data() {
    return {
      loading: true,
      submitted: false,
      submitting: false,
      submitError: null,
      datePickerModal: false,
      categories: [],
      noImage: false,
      alertInfoLabel: null,
      extraInformationField: {
        show: false,
        label: "",
        mandatory: false,
      },
      formData: {
        category: "",
        points: 0,
        date: "",
        description: "",
        screenshot: null,
        extraInformation: "",
      },
    };
  },
  computed: {
    minStartDate() {
      return this.$moment()
        .toISOString()
        .substr(0, 10);
    },
    extraFieldRules() {
      let rules = ["max:200"];
      if (this.extraInformationField.mandatory) {
        rules.push("required");
      }
      return rules.join("|");
    },
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    setImage: function(output) {
      this.formData.screenshot = output.dataUrl;
      this.noImage = false;
      this.submitting = false;
    },
    changeOption() {
      let category = this.rewardEventCategories.find((element) => element.id === this.formData.category);
      this.extraInformationField.show = category.showExtraField;
      this.extraInformationField.label = category.extraFieldLabel;
      this.extraInformationField.mandatory = category.extraFieldMandatory;
      this.alertInfoLabel = category.alertInfoLabel;
    },
    submit() {
      this.submitting = true;
      this.submitError = null;
      this.noImage = false;
      this.$refs.earnPointsObs.validate().then((valid) => {
        if (valid) {
          if (!this.formData.screenshot) {
            this.noImage = true;
            this.submitting = false;
            return false;
          }

          let filedata = null;
          if (this.formData.screenshot) {
            let blob = dataURLtoBlob(this.formData.screenshot);
            filedata = blobToFile(blob);
          }

          this.$apollo
            .mutate({
              mutation: EARN_REWARD_MUTATION,
              variables: {
                input: {
                  categoryId: this.formData.category,
                  dateEarned: this.formData.date,
                  description: this.formData.description,
                  screenshot: filedata,
                  extraInformation: this.formData.extraInformation,
                },
              },
            })
            .then((response) => {
              this.submitError = response.data.earnPointsMutation.error;
              if (response.data.earnPointsMutation.success) {
                this.submitted = true;
              }
            });
        } else {
          this.submitting = false;
        }
      });
    },
  },
  apollo: {
    rewardEventCategories: {
      query: REWARD_CATEGORIES_QUERY,
      variables() {
        return {
          categoryType: "EARN",
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          this.loading = false;
          this.categories = response.data.rewardEventCategories.map((item) => {
            return {
              text: item.title,
              value: item.id,
            };
          });
        }
      },
    },
  },
};
</script>
