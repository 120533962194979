<template>
  <v-container class="py-0 my-0 workout-form">
    <validation-observer ref="obs" v-slot="{ valid, errors, pristine, validate }">
      <div v-if="customWorkoutProps.selectedWorkoutType && !shared">
        <div class="p-text mt-5">
          <selfie-alert
            :image="activity.image"
            :loading="imageLoading"
            :prize-text="activePrizeText"
            @clickUploader="clickUploader"
            @removeSelfie="removeSelfie"
            @input="selectImg($event, valid)"
          />
        </div>
      </div>
      <div v-if="!$apollo.loading && !shared" class="pb-4">
        <j-alert
          v-if="!saving && activePrizeText && submitted"
          type="warning"
          :icon="false"
          rounded="xl"
          class="text-center"
        >
          <h3 class="mb-2">You're getting 1 entry ☝️</h3>
          <span>Get a <strong>bonus entry</strong> by sharing your photo to a Group or Chat</span>
        </j-alert>
        <share-options
          v-if="customWorkoutProps.selectedWorkoutType && activity.image"
          :image="activity.image"
          :date="activity.startDate"
          :chatData="chatData"
          :with-groups="true"
        />
        <j-checkbox v-model="showAdditionalStats" label="Log Additional Stats" hide-details class="ma-4 mb-6" />
        <v-row v-if="showAdditionalStats">
          <v-col cols="12" class="my-0">
            <j-select
              v-model="customWorkoutProps.selectedWorkoutType"
              label="Activity Type"
              dense
              :items="categoryActivities"
              @change="warningMessage = false"
              :error-messages="warningMessage ? 'Activity Type missing' : ''"
            />

            <j-text-field
              v-if="otherCategoryIds.indexOf(customWorkoutProps.selectedWorkoutType) !== -1"
              v-model="customWorkoutProps.customWorkoutType"
              prepend-icon="sports"
              :rules="activityNameRules"
              label="Type of Activity (max 20 characters)"
              dense
              class="type-of-activity-select pt-0 my-2"
            />
          </v-col>
          <v-col cols="12" class="mb-2">
            <j-input-mask
              v-model="activity.seconds"
              :value="activity.seconds"
              :mask="['##:##:##']"
              masked
              placeholder="Duration"
              suffix="HH:MM:SS or MM:SS"
            />
          </v-col>

          <v-col v-if="isFieldVisible().distance()" cols="12">
            <j-text-field
              v-model="activity.distance"
              label="Distance"
              dense
              persistent-hint
              hint="KM"
              type="number"
              @wheel="$event.target.blur()"
            />
          </v-col>
          <v-col v-if="isFieldVisible().run()" cols="12">
            <j-text-field
              v-model="activity.averagePace"
              label="Average Pace"
              dense
              persistent-hint
              hint="MIN/KM"
              type="text"
              :rules="[runningRule]"
            />
          </v-col>
          <v-col v-if="isFieldVisible().effort()" cols="12">
            <j-text-field
              v-model="activity.calories"
              label="Calories Burned"
              dense
              type="number"
              @wheel="$event.target.blur()"
              persistent-hint
              suffix="Calories"
            />
          </v-col>
          <v-col v-if="isFieldVisible().effort()" cols="12">
            <j-text-field
              v-model="activity.heartRate"
              label="Average Heart Rate"
              dense
              type="number"
              @wheel="$event.target.blur()"
              persistent-hint
              hint="BPM"
            />
          </v-col>

          <v-col v-if="isFieldVisible().effort()" cols="12" class="my-0">
            <v-card-text class="pa-0">
              <j-select
                v-model="activity.effort"
                label="Effort You Exerted"
                dense
                @change="updateEffortText"
                :items="ratingLabelsForm"
              />
            </v-card-text>
          </v-col>

          <v-col v-if="isFieldVisible().effort()" cols="12" class="my-0 py-0 text-right pa-0 ma-0">
            <span class="pa-0 ma-0">{{ ratingLabel }}</span>
          </v-col>

          <v-col cols="12">
            <j-textarea label="Note To Self" v-model="activity.comment" dense rows="2" auto-grow />
          </v-col>
          <v-col cols="12" class="my-0">
            <div class="p-text mb-1">Completed on</div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <j-text-field
                  v-model="event_date"
                  v-bind="attrs"
                  v-on="on"
                  prepend-inner-icon="mdi-calendar"
                  class="pt-0 mt-0"
                  readonly
                />
              </template>
              <v-date-picker v-model="date" no-title scrollable @input="menu = false" />
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <j-alert v-if="!activePrizeText && submitted" type="success">
              {{ submitMessage }}
            </j-alert>
            <j-alert v-if="warningMessage" type="warning">
              Please select an Activity Type to save
            </j-alert>
            <j-alert v-if="errors && errors.message && errors.message.length > 0" type="error" class="mb-3">
              {{ errors.message[0] }}
            </j-alert>
            <div v-if="mode == 'update' && !submitted">
              Your session is saved. If needed, make changes and tap UPDATE.
            </div>
            <j-btn
              secondary
              wide
              large
              class="mt-1"
              :loading="saving"
              @click="
                pristine ? validate() : null;
                save(valid);
              "
            >
              {{ mode == "new" ? "Save" : "Update" }}
              <span v-if="chatData.isSharing"> AND SEND</span>
            </j-btn>
          </v-col>
        </v-row>
      </div>
      <div v-if="!$apollo.loading && shared">
        <successful-entry-alerts v-if="activePrizeText" :active-prize-text="activePrizeText" />
        <j-alert v-else type="success">Successfully shared!</j-alert>
      </div>
    </validation-observer>
  </v-container>
</template>

<script>
import { maskedTimeToSeconds } from "@/lib/string";
import ActivityFormBase from "@/mixins/ActivityFormBase";
import SelfieAlert from "./SelfieAlert";
import SuccessfulEntryAlerts from "@/components/tracker/activity-form/SuccessfulEntryAlerts.vue";

export default {
  name: "CustomActivityForm",
  mixins: [ActivityFormBase],
  components: {
    SelfieAlert,
    SuccessfulEntryAlerts,
  },
  data() {
    return {
      customActivity: true,
      categoryName: "EXERCISE",
      categoryActivities: [],
      activityNameRules: [(v) => (v && v.length <= 20) || "Must be less than 20 characters"],
      activityCategories: null,
      activityNameError: false,
      warningMessage: false,
      showAdditionalStats: true,
    };
  },
  methods: {
    getActivityName() {
      let name = "Other";
      if (!this.activityTypes) return name;
      this.activityTypes.forEach((item) => {
        if (item.id == this.customWorkoutProps.selectedWorkoutType) {
          name = item.name;
        }
      });
      return name;
    },
    deriveWorkoutType() {
      let workoutType = this.customWorkoutProps.selectedWorkoutType;

      if (this.otherCategoryIds.indexOf(this.customWorkoutProps.selectedWorkoutType) !== -1) {
        workoutType = this.customWorkoutProps.customWorkoutType;
      }
      return workoutType;
    },
    async save(valid, close = false) {
      this.submitted = false;
      if (valid) {
        this.warningMessage = false;

        if (!this.customWorkoutProps.selectedWorkoutType) {
          this.warningMessage = true;
        } else {
          this.saving = true;

          let userActivityInput = {
            isCustom: true,
            activityType: this.deriveWorkoutType(),
            distance: this.activity.distance || null,
            averagePace: this.activity.averagePace || null,
            calories: this.activity.calories || null,
            seconds: maskedTimeToSeconds(this.activity.seconds),
            heartRate: this.activity.heartRate || null,
            startDate: this.date,
            effort: this.activity.effort || null,
            comment: this.activity.comment || null,
            image: this.image || null,
            imageChanged: this.imageChanged || false,
          };

          if (this.customWorkoutProps.selectedWorkoutType.toLowerCase() == "other") {
            this.categoryActivities.selectedWorkoutType;
          }

          if (this.activity.rating) {
            userActivityInput["rating"] = this.activity.rating;
          }
          await this.upsertUserActivity(userActivityInput, close);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.type-of-activity-select .v-messages__wrapper {
  margin-top: 13px !important;
  margin-right: 22px;
}
.workout-form {
  .v-text-field__details {
    position: absolute;
    right: 0;
    margin: 22px;
  }

  .row .col {
    padding-bottom: 0;
  }
}
.feeling {
  width: 100%;
  margin-top: 8px;
}
.workout-status {
  padding: 0px;
  margin: 0px;
  color: #fff;
}
</style>
