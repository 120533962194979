<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="5" align="left" class="white px-8 py-12">
        <v-skeleton-loader v-if="$apollo.loading" type="card" />
        <div v-else>
          <recommendation-journey join-club @block-selected="blockId = $event"/>

          <div class="text-center mt-8">
            <j-btn large color="exercise" @click="next()">Begin my recommended program</j-btn>
            <p class="p-text font-italic mt-1">You can change your programs at any time</p>
            <j-btn small narrow tertiary class="mt-4" @click="next(true)">Choose my own program instead</j-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecommendationJourney from "@/components/shared/blocks/RecommendationJourney";

export default {
  name: "Recommendation",
  data() {
    return {
      blockId: null,
    };
  },
  components: {
    RecommendationJourney,
  },
  methods: {
    next(own = false) {
      localStorage.removeItem("requiredSupport");

      if (own) {
        return this.$router.push(`/today`).catch(() => {});
      } else {
        // user needs to buy club.
        // set the block Id for the purposes of redirecting.
        sessionStorage.setItem("workoutBlockId", this.blockId);

        if (this.$store.getters.role == "ONE_ON_ONE" || this.$store.getters.role == "CLUB") {
          // if we are one on one or club i.e we have paid already, then we can go straight to the program and open the modal
          return this.$router.push(`/program/${this.blockId}?confirm=1`);
        }
        if (this.requiredSupport) {
          return this.$router.push("/checkout/1-on-1");
        }
        return this.$router.push("/checkout/jeff-club");
      }
    },
  },
};
</script>
