<template>
  <v-container>
    <div v-if="$apollo.loading && !workoutBlock">
      <v-row justify="center">
        <v-col cols="12" md="6"><v-skeleton-loader type="card-avatar" /> </v-col>
      </v-row>
    </div>
    <div v-else>
      <j-card-banner-container
        :header="header"
        :title="workoutBlock.title"
        :subtitle="withCopy"
        :image="workoutBlock.image"
        :textGradient="workoutBlock.type === 'NUTRITION' ? ['green', 'yellow'] : ['pink', 'yellow']"
        use-header-title-in-link
        :image-chip="isPremium(workoutBlock) ? { color: 'navy', text: 'CHALLENGE' } : null"
        :show-back-link="false"
      >
        <div class="px-md-8 pt-4">
          <j-subsection-row title="Overview" :show-see-all="false">
            <div v-if="youtubeId" class="pb-4">
              <video-block :youtubeId="youtubeId" :show-video-cast-message="false" />
            </div>

            <p
              class="text-left p-text"
              v-if="workoutBlock.description"
              v-html="formatDescription(workoutBlock.description)"
            ></p>

            <j-card class="mb-2">
              <v-skeleton-loader v-if="loading || $apollo.loading" type="card" />
              <block-header
                v-else
                :avg-rating="workoutBlock.avgRating"
                :total-reviews="workoutBlock.totalReviews"
                :workout-block="workoutBlock"
                @doProgram="$router.push(`/checkout/jeff-club`)"
                @showRatings="showRatings"
              />
            </j-card>
          </j-subsection-row>
        </div>
      </j-card-banner-container>
    </div>

    <!-- All Ratings dialog -->
    <v-dialog
      v-if="workoutBlock"
      v-model="openAllRatingDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
      scrollable
    >
      <rating-list
        :reviews="reviews"
        :hasNextPage="hasNextPage"
        @closed="closeRatingsDialog"
        @showMore="showRatings"
        :user-can-rate="workoutBlock.userCanRate"
      >
      </rating-list>
    </v-dialog>
  </v-container>
</template>

<script>
import { WORKOUT_BLOCK_QUERY, WORKOUT_BLOCK_RATING_QUERY } from "@/graphql/queries/workoutblock";
import { mapGetters, mapActions } from "vuex";
import { getYouTubeId } from "@/lib/string";
import { nl2br } from "@/lib/string";
import BlockMixin from "@/mixins/BlockMixin";
import PremiumBlockMixin from "@/mixins/PremiumBlockMixin";
import BlockHeader from "@/components/workoutblocks/cards/BlockHeader";
import VideoBlock from "@/components/events/VideoBlock";
import RatingList from "@/components/rating/RatingList";
import { extractNodes } from "@/lib/array";

export default {
  name: "WorkoutBlockPublic",
  mixins: [BlockMixin, PremiumBlockMixin],
  components: {
    BlockHeader,
    VideoBlock,
    RatingList,
  },
  data() {
    return {
      loading: false,
      workoutBlock: null,
      openAllRatingDialog: false,
      reviews: [],
      ratingsCursor: null,
      hasNextPage: false,
    };
  },
  props: {
    blockId: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["restDays"]),
    header() {
      if (this.workoutBlock.type === "COURSE") {
        return "Course";
      }
      if (this.workoutBlock.type === "NUTRITION") {
        return "Nutrition Program";
      }
      return "Program";
    },
    // the workout_SUBSCRIPTION_block for the user for this specific block. i.e gives us start date / end date.
    youtubeId() {
      return getYouTubeId(this.workoutBlock?.introYoutubeVideo);
    },
  },
  methods: {
    ...mapActions(["setRestDays"]),
    extractNodes(nodes) {
      return extractNodes(nodes);
    },
    formatDescription(string) {
      return nl2br(string);
    },
    async showRatings() {
      const result = await this.$apollo.query({
        query: WORKOUT_BLOCK_RATING_QUERY,
        variables: {
          first: 3,
          orderBy: "-createdAt",
          after: this.ratingsCursor,
          workoutBlockId: this.blockId,
        },
        fetchPolicy: "no-cache",
      });

      this.ratingsCursor = result.data.workoutBlock.ratingSet.pageInfo.endCursor;
      this.hasNextPage = result.data.workoutBlock.ratingSet.pageInfo.hasNextPage;
      this.reviews.push(...extractNodes(result.data.workoutBlock.ratingSet.edges));

      this.openAllRatingDialog = true;
    },
    closeRatingsDialog() {
      this.openAllRatingDialog = false;
      this.ratingsCursor = null;
      this.reviews = [];
      this.$apollo.queries.workoutBlock.refetch();
    },
  },
  apollo: {
    workoutBlock: {
      query: WORKOUT_BLOCK_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          workoutBlockId: this.blockId,
          withProductVariant: true,
          groupType: "PRIMARY",
        };
      },
    },
  },
  created() {
    if (this.$store.getters.user) {
      return this.$router.push(`/program/${this.$route.params.blockId}`);
    }
  },
};
</script>
