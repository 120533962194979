<template>
  <v-container>
    <j-banner-container
      header="Community Events"
      :textGradient="['blue', 'navy']"
      show-share-link
      use-header-in-link
    >
      <v-row>
        <v-col class="pa-0">
          <v-tabs
            v-model="activeTab"
            centered
            show-arrows
            background-color="grey"
            color="secondary"
            active-class="selected-tab-active"
            slider-size="0"
            class="selected-tabs-active"
          >
            <v-tab key="upcoming" @click="showUpcomingEvents()">
              Upcoming
            </v-tab>
            <v-tab key="attending" @click="showAttendingEvents()">
              Attending
            </v-tab>
            <v-tab key="hosting" @click="showHostedEvents()">
              Hosting
            </v-tab>
            <v-tabs-items v-model="activeTab" class="py-2">
              <v-tab-item key="upcoming">
                <v-row>
                  <v-col cols="12" class="pa-0 ma-0">
                    <p align="center" class="pt-3 mt-3">
                      <j-btn tertiary narrow @click="showFilterMenu = !showFilterMenu">
                        {{ showFilterMenu ? "Hide Filters" : "Show Filters" }}
                        <v-icon class="mb-1">mdi-filter-variant</v-icon>
                      </j-btn>
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="showFilterMenu" align="center" justify="center">
                  <v-col cols="12">
                    <j-card class="pa-5 ma-3 dashboard-card text-align-left">
                      <v-form>
                        <v-select
                          v-model="filters.type"
                          class="mt-0"
                          :items="formOptions.type"
                          label="Meetup Type"
                          required
                        ></v-select>
                        <gmap-autocomplete v-if="filters.type !== 'virtual'" @place_changed="setGmapLocation">
                          <template v-slot:input="slotProps">
                            <j-text-field
                              v-model="gmapLocation"
                              name="location"
                              placeholder="Your Location"
                              ref="input"
                              v-on:listeners="slotProps.listeners"
                              v-on:attrs="slotProps.attrs"
                            >
                            </j-text-field>
                          </template>
                        </gmap-autocomplete>
                        <v-select
                          v-if="filters.type !== 'virtual' && userLocation"
                          v-model="filters.maxDistance"
                          class="mt-0"
                          :items="formOptions.maxDistance"
                          label="Proximity"
                          required
                        ></v-select>
                        <j-btn tertiary @click="applyFilters()">
                          Apply Filters
                        </j-btn>
                      </v-form>
                    </j-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mb-0 mt-n2 pb-0">
                    <h3 class="text-center grey-navy--text text-uppercase">
                      Upcoming Events in your Area
                    </h3>
                    <p align="center" class="pt-3 pb-0 mb-0">
                      <v-icon class="mb-1">sort</v-icon> Soonest one shown first
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" align="center" justify="center" class="px-10">
                    <v-progress-circular v-if="loading" :indeterminate="true" color="blue" />
                    <div v-if="meetups.length > 0">
                      <meeting-card
                        v-for="meetup in meetups"
                        :ref="'upcomingCard-' + meetup.id"
                        :meetup="meetup"
                        :key="meetup.id"
                        v-on:attend="setAttendance"
                        v-on:cancel="cancelDialogOpen"
                      ></meeting-card>
                    </div>
                    <div v-if="!loading && meetups.length === 0">
                      <j-card class="pa-0 mb-3 text-left">
                        <v-card-text>No events matching your conditions. Please try change your filters.</v-card-text>
                      </j-card>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="attending">
                <v-row>
                  <v-col cols="12">
                    <h3 class="text-center grey-navy--text mt-3 text-uppercase">
                      Events you are attending
                    </h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" align="center" justify="center" class="px-10">
                    <v-progress-circular v-if="loading" :indeterminate="true" color="blue" />
                    <div v-if="meetups.length > 0">
                      <meeting-card
                        v-for="meetup in meetups"
                        :meetup="meetup"
                        :key="meetup.id"
                        v-on:attend="setAttendance"
                        v-on:cancel="cancelDialogOpen"
                      ></meeting-card>
                    </div>
                    <div v-if="!loading && meetups.length === 0">
                      <j-card class="pa-0 mb-3 card-radius dashboard-card text-align-left">
                        <v-card-text>You aren't currently attending any events.</v-card-text>
                      </j-card>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="hosting">
                <v-row align="center" justify="center">
                  <v-col cols="12" md="7" align="center" justify="center" class="mt-4">
                    <j-btn secondary large to="/social/community-fitness/create">
                      Create a new event
                    </j-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3 class="text-center grey-navy--text text-uppercase">
                      Events you are Hosting
                    </h3>
                  </v-col>
                </v-row>
                <v-row v-if="activeTab === 2">
                  <v-col cols="12" align="center" justify="center" class="px-10">
                    <v-progress-circular v-if="loading" :indeterminate="true" color="blue" />
                    <div v-if="meetups.length > 0">
                      <meeting-admin-card
                        v-for="meetup in meetups"
                        :meetup="meetup"
                        :key="'admin-' + meetup.id"
                        v-on:cancel="cancelDialogOpen"
                      >
                      </meeting-admin-card>
                    </div>
                    <div v-if="!loading && meetups.length === 0">
                      <j-card class="pa-0 mb-3 card-radius dashboard-card text-align-left">
                        <v-card-text>You aren't hosting any upcoming events.</v-card-text>
                      </j-card>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-col>
      </v-row>
    </j-banner-container>

    <v-dialog v-model="modals.cancelMeetupDialog" width="500">
      <j-card>
        <v-card-title class="headline grey lighten-2">
          Cancel Event
        </v-card-title>
        <v-card-text>
          <p>Are you sure you want to cancel this event?</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <j-btn narrow primary @click="cancelMeetup()">
            Yes
          </j-btn>
          <j-btn narrow secondary @click="modals.cancelMeetupDialog = false">
            No
          </j-btn>
        </v-card-actions>
      </j-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { MEETUPS_QUERY } from "@/graphql/queries/communityFitness";
import { ATTEND_MEETUP_MUTATION, CANCEL_MEETUP_MUTATION } from "@/graphql/mutations/communityFitness";
import { haversineDistance } from "@/lib/geospatial";
import MeetingCard from "@/components/community-fitness/MeetingCard";
import MeetingAdminCard from "@/components/community-fitness/MeetingAdminCard";

export default {
  name: "CommunityFitness",
  components: {
    MeetingCard,
    MeetingAdminCard,
  },
  data() {
    return {
      meetups: [],
      meetup: null,
      showFilterMenu: false,
      activeTab: "upcoming",
      loading: true,
      userLocation: null,
      gmapLocation: "",
      modals: {
        attendDialog: false,
        cancelMeetupDialog: false,
      },
      filters: {
        status: "approved",
        type: "",
        maxDistance: null,
        locationContains: "",
        eventDate_Gte: this.getEventDate(),
        originLatitude: null,
        originLongitude: null,
        host_Id: null,
        orderBy: "eventDate",
        attending: null,
      },
      formOptions: {
        type: [
          {
            text: "Any",
            value: "",
          },
          {
            text: "In Person",
            value: "physical",
          },
          {
            text: "Virtual",
            value: "virtual",
          },
        ],
        maxDistance: [
          {
            text: "Anywhere",
            value: null,
          },
          {
            text: "Closer than 5km",
            value: 5,
          },
          {
            text: "Closer than 10km",
            value: 10,
          },
          {
            text: "Closer than 100km",
            value: 100,
          },
        ],
      },
    };
  },
  async mounted() {
    await this.setLocation();
    if (this.userLocation) {
      this.filters.originLatitude = this.userLocation.coords.latitude;
      this.filters.originLongitude = this.userLocation.coords.longitude;
      this.$apollo.queries.meetups.refetch(this.filters);
    }
  },
  methods: {
    async setLocation() {
      this.userLocation = await this.getLocation();
    },
    async getLocation() {
      return new Promise((resolve) => {
        if (!("geolocation" in navigator)) {
          resolve(null);
        }
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          () => {
            resolve(null);
          }
        );
      });
    },
    setGmapLocation(location) {
      this.gmapLocation = location.formatted_address;
      this.userLocation = {
        coords: {
          latitude: location.geometry.location.lat(),
          longitude: location.geometry.location.lng(),
        },
      };
      this.filters.originLatitude = this.userLocation.coords.latitude;
      this.filters.originLongitude = this.userLocation.coords.longitude;
    },
    getEventDate() {
      let date = new Date();
      date.setHours(date.getHours() - 12);
      return date;
    },
    resetFilters() {
      this.filters.status = "";
      this.filters.type = "";
      this.filters.maxDistance = null;
      this.filters.locationContains = "";
      this.filters.eventDate_Gte = this.getEventDate();
      this.filters.originLatitude = null;
      this.filters.originLongitude = null;
      this.filters.host_Id = null;
      this.filters.attending = null;
    },
    applyFilters() {
      this.loading = true;
      if (this.filters.type === "virtual") {
        this.filters.locationContains = "";
        this.filters.maxDistance = null;
      }
      this.$apollo.queries.meetups.refetch(this.filters);
    },
    setAttendance(meetup, attending) {
      this.meetup = meetup;
      this.attendMeetup(attending);
    },
    attendDialogOpen(meetup) {
      this.meetup = meetup;
      this.modals.attendDialog = true;
    },
    attendMeetup(attending = true) {
      if (!this.meetup) return;

      this.modals.attendDialog = false;

      this.$apollo
        .mutate({
          mutation: ATTEND_MEETUP_MUTATION,
          variables: {
            meetupId: atob(this.meetup.id).split(":")[1],
            attending: attending,
          },
        })
        .then((response) => {
          if (response.data && response.data.attendMeetup.success) {
            let message = attending ? "You are now attending: " : "You are no longer attending: ";
            this.$toasted.success(message + this.meetup.title, {
              icon: "mdi-email-send-outline",
              duration: 2000,
              position: "bottom-center",
            });
            this.applyFilters();
          } else if (response.data && !response.data.attendMeetup.success) {
            this.$toasted.error(response.data.attendMeetup.error, {
              icon: "warn",
              duration: 2000,
              position: "bottom-center",
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    cancelDialogOpen(meetup) {
      this.meetup = meetup;
      this.modals.cancelMeetupDialog = true;
    },
    cancelMeetup() {
      if (!this.meetup) return;

      this.modals.cancelMeetupDialog = false;

      this.$apollo
        .mutate({
          mutation: CANCEL_MEETUP_MUTATION,
          variables: {
            meetupId: atob(this.meetup.id).split(":")[1],
          },
        })
        .then((response) => {
          if (response.data && response.data.cancelMeetup.success) {
            this.$toasted.success("Meetup cancelled", {
              icon: "arm-flex",
              duration: 2000,
              position: "bottom-center",
            });
            this.applyFilters();
          } else if (response.data && !response.data.attendMeetup.success) {
            this.$toasted.error(response.data.cancelMeetup.error, {
              icon: "warn",
              duration: 2000,
              position: "bottom-center",
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    showHostedEvents() {
      this.resetFilters();
      this.loading = true;
      this.meetups = [];
      this.filters.eventDate_Gte = this.getEventDate();
      this.filters.host_Id = btoa("UserNode:" + this.$store.getters.user.id);
      this.$apollo.queries.meetups.refetch(this.filters);
    },
    showAttendingEvents() {
      this.resetFilters();
      this.loading = true;
      this.meetups = [];
      this.filters.eventDate_Gte = this.getEventDate();
      this.filters.attending = true;
      this.$apollo.queries.meetups.refetch(this.filters);
    },
    showUpcomingEvents() {
      this.resetFilters();
      this.loading = true;
      this.filters.eventDate_Gte = this.getEventDate();
      this.filters.status = "approved";
      if (this.userLocation) {
        this.filters.originLatitude = this.userLocation.coords.latitude;
        this.filters.originLongitude = this.userLocation.coords.longitude;
      }
      this.$apollo.queries.meetups.refetch(this.filters);
    },
  },
  apollo: {
    meetups: {
      query: MEETUPS_QUERY,
      fetchPolicy: "network-only",
      variables() {
        return this.filters;
      },
      result(response) {
        if (response.data) {
          this.loading = false;
        }
      },
      update(data) {
        let nodes = data.meetups.edges;
        let output = [];
        nodes.forEach((node) => {
          let data = node.node;
          let distance = null;
          if (this.userLocation) {
            distance = haversineDistance(
              this.userLocation.coords.latitude,
              this.userLocation.coords.longitude,
              data.latitude,
              data.longitude
            ).toFixed(2);
          }
          data.distance = distance;
          output.push(data);
        });
        return output;
      },
    },
  },
};
</script>
