<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col v-if="$apollo.loading" cols="12" md="6" xl="5" class="background-grey-3 fill-height mb-10">
        <v-skeleton-loader type="card" />
      </v-col>
      <v-col v-else cols="12" md="6" xl="5" class="background-grey-3 fill-height mb-10">
        <j-card rounded="lg" class="d-flex flex-column text-center pa-5 pb-0">
          <span class="text-h5 navy--text">You can get your</span>
          <span class="text-h4 pt-1 pb-2 pink--text font-italic font-weight-bold">eating and exercise plan</span>
          <span class="text-h7 navy--text">Take the 2-minute quiz</span>

          <div class="mt-4">
            <span class="font-italic grey-navy--text">"{{ defaultQuote }}"</span>
            <v-img
              v-if="dealOwner && dealOwner.image"
              :src="dealOwner.image"
              :lazy-src="dealOwner.image"
              max-width="400"
              class="mx-auto mt-2"
            />
            <v-img
              v-else
              :src="require('@/assets/images/start/start_2.png')"
              max-width="350"
              contain
              class="mx-auto mt-2"
            />
          </div>
        </j-card>

        <div class="text-center pt-8">
          <div class="text-h5 navy--text font-weight-bold">What is your goal?</div>
          <div class="text-h6 grey-navy--text">Choose one or multiple</div>

          <div v-for="tag in tags" :key="tag.id">
            <j-card rounded="lg" :class="{ 'pink-border': tag.selected }" class="mt-4">
              <j-checkbox color="exercise"  v-model="tag.selected" hide-details @change="setGoals" class="px-3 py-4 mt-0">
                <div slot="label" class="std-text navy--text ml-1">{{ tag.title }}</div>
              </j-checkbox>
            </j-card>
          </div>
          <j-alert v-if="showAlert" class="mt-4"> Please select a goal to continue </j-alert>
          <j-btn @click="submit" color="exercise" wide class="d-flex mx-auto mt-5">Next</j-btn>
          <div class="mt-3 grey-navy--text">
            Already have a {{ $appName }} profile?
            <router-link class="grey-navy--text font-weight-bold text-decoration-none" to="/login">
              Log in
            </router-link>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TAGS_QUERY } from "@/graphql/queries/tag";
import { DEAL_OWNER_QUERY } from "@/graphql/queries/start";
import Cookies from "js-cookie";

export default {
  name: "Start",
  data() {
    return {
      showAlert: false,
    };
  },
  created() {
    const redirectProductCode = this.$route.query.code;
    if (redirectProductCode) {
      Cookies.set("redirectProductCode", redirectProductCode, { expires: 1 });
    }
  },
  methods: {
    setGoals() {
      this.showAlert = false;
      let goals = this.tags.filter((tag) => tag.selected).map((tag) => tag.code);
      this.$store.dispatch("updateGoals", goals);
    },
    submit() {
      if (this.$store.getters.dealGoals?.length == 0) {
        this.showAlert = true;
      } else {
        this.showAlert = false;
        this.$router.push("/start/chosen");
      }
    },
  },
  computed: {
    defaultQuote() {
      return this.$store.getters.settings.get("FUNNEL")?.get("default-quote") ?? "Ready, set, go!";
    },
  },
  apollo: {
    tags: {
      query: TAGS_QUERY,
      variables() {
        return {
          category: "PRIMARY",
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.tags.edges.map((tag) => {
          return { ...tag.node, selected: this.$store.getters.dealGoals?.includes(tag.node.code) };
        });
      },
    },
    dealOwner: {
      query: DEAL_OWNER_QUERY,
      variables() {
        return {
          code: this.$attrs.dealOwner,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        this.$store.dispatch(
          "updateOwner",
          data.dealOwner ? { id: data.dealOwner?.id, code: data.dealOwner?.code } : null
        );
        return data.dealOwner;
      },
      skip() {
        return !this.$attrs.dealOwner;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.j-card.pink-border {
  border: 2px solid var(--v-pink-base);
}
</style>
