<template>
  <j-card class="pa-3">
    <v-toolbar flat>
      <v-spacer />
      <v-toolbar-title class="secondary--text pl-1">
        <span class="heading-2 ml-n1">Log reps</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="planet-grey" class="float-right" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div>
        <span class="secondary--text font-weight-bold">TYPE</span>
        <v-select v-model="form.type" :items="typeList" :loading="loadingTypeList"></v-select>
      </div>
      <div>
        <span class="secondary--text font-weight-bold">REPS</span>
        <j-text-field
          v-model="form.reps"
          type="number"
          @wheel="$event.target.blur()"
        ></j-text-field>
      </div>
      <div>
        <span class="secondary--text font-weight-bold">DATE OF COMPLETION</span>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <j-text-field v-model="rep_date" v-bind="attrs" v-on="on" class="pt-0 mt-0" readonly></j-text-field>
          </template>
          <v-date-picker v-model="form.date" no-title scrollable @input="menu = false" />
        </v-menu>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <j-btn secondary large :disabled="invalid" @click="submit()">
        Save
      </j-btn>
      <v-spacer />
    </v-card-actions>
  </j-card>
</template>

<script>
import { REP_TYPES_QUERY } from "@/graphql/queries/communityStats";
import { UPSERT_USER_REP_LOG } from "@/graphql/mutations/communityStats";
import { toLocalString } from "@/lib/timezone";

export default {
  name: "RepsLogging",
  props: {
    rep: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      form: {
        id: null,
        type: null,
        reps: 0,
        date: toLocalString(new Date(), false),
      },
      menu: null,
      loadingTypeList: true,
    };
  },
  created() {
    if (this.rep) {
      this.form = {
        id: atob(this.rep.id).split(":")[1],
        reps: this.rep.repetitions,
        date: toLocalString(new Date(this.rep.loggedDate)),
      };

      let foundType = this.typeList.filter((type) => type.text == this.rep.repType.title);
      if (foundType.length > 0) {
        this.form.type = foundType[0].value;
      }
    }
  },
  computed: {
    rep_date: {
      get() {
        let date = this.$moment(this.form.date, "YYYY-MM-DD");
        let today = this.$moment();
        let dayDelta = today.diff(date, "days", true);
        let dateString = this.$moment(this.form.date, "YYYY-MM-DD").format("ddd, D MMMM YYYY");
        if (dayDelta >= 0) {
          if (dayDelta > 1 && dayDelta < 2) {
            dateString = "Yesterday: " + dateString;
          } else if (dayDelta > 0 && dayDelta < 1) {
            dateString = "Today: " + dateString;
          } else dateString = Math.floor(dayDelta.toString()) + " days ago: " + dateString;
        }
        return dateString;
      },
      set(newValue) {
        this.date = newValue;
      },
    },
    typeList() {
      if (this.repTypes) {
        return this.repTypes.map((type) => {
          return {
            text: type.title,
            value: type,
          };
        });
      }

      return [];
    },
    invalid() {
      return this.form.type == null || this.form.reps <= 0 || this.form.date == null;
    },
  },
  methods: {
    close() {
      this.resetForm();
      this.$emit("close");
    },
    resetForm() {
      this.form = {
        id: null,
        reps: 0,
        date: toLocalString(new Date(), false),
      };

      if (this.typeList.length > 0) {
        this.form.type = this.typeList[0].value;
      }
    },
    submit() {
      let input = {
        id: this.form.id,
        repetitions: this.form.reps,
        repType: this.form.type.id,
        date: this.$moment(this.form.date).format("YYYY-MM-DD"),
      };

      this.$apollo
        .mutate({
          mutation: UPSERT_USER_REP_LOG,
          variables: {
            input: input,
          },
          refetchQueries: ["communityRepLogQuery", "userRepLogQuery"],
        })
        .then((response) => {
          if (response.data.upsertRepLog.success) {
            this.close();
          } else {
            this.$toasted.success("Could not save rep log", {
              icon: "alert",
              duration: 2000,
              position: "bottom-center",
            });
          }
        });
    },
  },
  apollo: {
    repTypes: {
      query: REP_TYPES_QUERY,
      result(response) {
        if (response.data && !response.loading) {
          this.loadingTypeList = false;

          if (this.form.type == null && response.data.repTypes.length > 0) {
            this.form.type = response.data.repTypes[0];
          }
        }
      },
    },
  },
};
</script>
