<template>
  <div>
    <j-banner-container
      header="Accountability"
      show-share-link
      use-header-in-link
    >
      <v-row class="mt-1 mb-n2">
        <v-col class="text-right py-0">
          <div class="d-flex justify-end">
            <j-btn tertiary small narrow to="/checkin/assessments" class="px-1 my-2 mr-2 ml-14">
              <strong>Check-ins</strong>
              <v-icon small class="ml-1">mdi-tray-full</v-icon>
            </j-btn>
            <j-btn tertiary small narrow to="/accountability/dots" class="px-1 mt-2">
              <strong>Dots</strong>
              <v-icon small class="ml-1">mdi-location-enter</v-icon>
            </j-btn>
            <j-btn tertiary small narrow to="/social/community-stats/reps" class="px-1 ml-2 mt-2">
              <strong>Reps</strong>
              <v-icon small class="ml-1">mdi-chart-box-outline</v-icon>
            </j-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-1 mb-n2">
        <v-col class="text-right pt-0 pb-1">
          <div class="d-flex justify-end">
            <rest-day-card class="mx-2 mt-2" :is-rest-day="true" />
            <j-btn secondary small narrow @click="logCustomWorkoutDialog = true" class="mt-2">
              <span>Log activity</span>
              <v-icon small class="ml-1">mdi-plus-circle-outline</v-icon>
            </j-btn>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <calendar-dots />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <exercise-tracker />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mb-6">
        <v-col>
          <nutrition-tracker />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <habit-tracker />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <sleep-tracker />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <stress-tracker />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <hydration-tracker />
        </v-col>
      </v-row>
    </j-banner-container>
    <v-dialog v-model="logCustomWorkoutDialog" max-width="600px">
      <j-card v-if="logCustomWorkoutDialog" rounded="lg">
        <v-toolbar class="mb-6">
          <v-toolbar-title>
            <span>Log Activity</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="logCustomWorkoutDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pl-2 pr-2">
          <custom-activity-form :fullForm="true" />
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CalendarDots from "@/components/shared/blocks/CalendarDots";
import StressTracker from "@/components/tracker/StressTracker";
import NutritionTracker from "@/components/tracker/NutritionTracker";
import HydrationTracker from "@/components/tracker/HydrationTracker";
import SleepTracker from "@/components/tracker/SleepTracker";
import ExerciseTracker from "@/components/tracker/ExerciseTracker";
import HabitTracker from "@/components/tracker/habit/HabitTracker";
import CustomActivityForm from "@/components/tracker/activity-form/CustomActivityForm";
import RestDayCard from "@/components/tracker/RestDayCard";

export default {
  name: "Tracker",
  components: {
    CalendarDots,
    StressTracker,
    NutritionTracker,
    HydrationTracker,
    SleepTracker,
    ExerciseTracker,
    HabitTracker,
    CustomActivityForm,
    RestDayCard,
  },
  data() {
    return {
      loading: true,
      logCustomWorkoutDialog: false,
    };
  },
  mounted() {
    window.analytics.page("Tracker");
  },
  computed: {
    ...mapGetters(["selectedDate", "role"]),
    inActive() {
      return this.role == "INACTIVE" || this.$store.getters.inActive;
    },
  },
  methods: {
    formattedDate() {
      return this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD");
    },
  },
};
</script>
