<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <j-alert v-if="description" type="success">
          {{ description }}
          <br /><br />
          <j-btn to="/today" wide max-width="310" color="green"
            >Continue <v-icon size="19">mdi-arrow-right</v-icon></j-btn
          >
        </j-alert>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
import { PAYMENT_QUERY } from "@/graphql/queries/store";

export default {
  name: "CheckoutSuccess",
  props: {
    paymentId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    description() {
      if (this.payment?.order?.productVariants?.edges?.length > 0) {
        return this.payment.order.productVariants.edges[0].node.product.successMessage;
      }
      return null;
    },
  },
  apollo: {
    payment: {
      query: PAYMENT_QUERY,
      variables() {
        return {
          paymentId: this.paymentId,
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>
