<template>
  <div>
    <j-card :class="{ 'selected-border': isSelected }">
      <div class="d-flex justify-left pa-2 align-center">
        <j-checkbox @click="chooseCoach" v-model="checkboxSelected" />
        <v-avatar :size="getSize" class="pointer" @click="chooseCoach"
          ><v-img :src="coachProfile.profilePicture" :aspect-ratio="9 / 9"
        /></v-avatar>

        <div class="p-text text-left mx-4">
          <div class="std-text font-weight-bold navy--text pointer" @click="chooseCoach">
            {{ coachProfile.user.firstName }}
          </div>
          <div class="pt-1 p-text pointer" @click="chooseCoach">{{ coachProfile.biography }}</div>

          <div class="d-flex">
            <j-btn @click="showCoachModal = true" class="mt-4 mb-2" tertiary small narrow>Profile</j-btn>
          </div>
        </div>
      </div>
    </j-card>

    <v-dialog
      v-if="showCoachModal"
      v-model="showCoachModal"
      max-width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <j-card rounded="lg" class="px-2">
        <v-toolbar class="px-2" flat>
          <v-toolbar-title class="blue--text heading-2 font-weight-bold">Profile</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="showCoachModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="d-flex">
            <v-avatar size="80"
              ><v-img :src="coachProfile.profilePicture" :aspect-ratio="9 / 9" class="pointer" />
            </v-avatar>
            <div class="ml-4">
              <div class="std-text font-weight-bold navy--text">{{ coachProfile.user.firstName }}</div>
              <div>{{ coachProfile.biography }}</div>
            </div>
          </div>
          <div class="d-flex justify-center">
            <j-btn @click="chooseCoach" class="mt-4" primary>Continue with {{ coachProfile.user.firstName }}</j-btn>
          </div>
          <video-block
            v-if="coachProfile.youtubeIntroVideo"
            :youtube-id="getYouTubeId(coachProfile.youtubeIntroVideo)"
            class="mt-6 mb-4"
            :show-video-cast-message="false"
          />
          <v-card-text v-if="coachProfile.coachIntroduction" v-html="coachProfile.coachIntroduction" />
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import VideoBlock from "@/components/events/VideoBlock";
import { getYouTubeId } from "@/lib/string";

export default {
  name: "ChooseCoachListingCard",
  components: {
    VideoBlock,
  },
  data() {
    return {
      showCoachModal: false,
      checkboxSelected: this.isSelected,
    };
  },
  props: {
    coachProfile: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getSize() {
      return this.$vuetify.breakpoint.mdAndUp ? 120 : 75;
    },
  },
  watch: {
    isSelected: function (value) {
      this.checkboxSelected = value;
    },
  },
  methods: {
    chooseCoach() {
      this.$emit("choose", this.coachProfile.user.id);
      this.showCoachModal = false;
    },
    getYouTubeId(url) {
      return getYouTubeId(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.j-card.selected-border {
  border: 2px solid var(--v-exercise-base);
}
</style>
