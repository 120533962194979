var render = function render(){var _vm=this,_c=_vm._self._c;return _c('j-banner-container',{attrs:{"header":"Shopping List"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{staticClass:"selected-tabs-active",attrs:{"slider-size":"4","height":"40"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{style:(_vm.activeStyle())}),_c('v-tab',{key:"this-week"},[_c('span',{style:(_vm.activeStyle(0))},[_vm._v("This Week")])]),_c('v-tab',{key:"next-week"},[_c('span',{style:(_vm.activeStyle(1))},[_vm._v("Next Week")])]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.requiresRefresh)?[_c('j-alert',{staticClass:"mt-3",attrs:{"type":"warning"}},[_vm._v(" You've recently changed your menu. Would you like to refresh your shopping list?"),_c('br'),_c('j-btn',{staticClass:"mt-2",attrs:{"tertiary":"","narrow":""},on:{"click":_vm.refreshShoppingList}},[_vm._v("Refresh")])],1)]:_vm._e(),_c('v-tab-item',{key:"this-week",staticClass:"mt-5"},[(_vm.state.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar,list-item-avatar,list-item-avatar"}}):_c('div',[(!_vm.state.error && _vm.shoppingList)?_c('shopping-list-view',{attrs:{"shoppingList":_vm.shoppingList,"shoppingListId":parseInt(_vm.shoppingListId),"start-date":_vm.startDate}}):_vm._e(),(
                  _vm.state.error &&
                    (!_vm.upcomingNutritionBlock ||
                      (_vm.upcomingNutritionBlock && _vm.decodeId(_vm.upcomingNutritionBlock.id) == _vm.blockId))
                )?_c('j-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.humanErrorMessage)+" ")]):_vm._e(),(_vm.state.error && _vm.upcomingNutritionBlock && _vm.decodeId(_vm.upcomingNutritionBlock.id) != _vm.blockId)?_c('j-dotted-btn',{attrs:{"wide":"","color":"green"},on:{"click":function($event){return _vm.navigateNutritionBlock(_vm.upcomingNutritionBlock.id)}}},[_vm._v("View next program")]):_vm._e()],1)],1),_c('v-tab-item',{key:"next-week",staticClass:"mt-5"},[(_vm.state.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar,list-item-avatar,list-item-avatar"}}):_c('div',[(!_vm.state.error && _vm.shoppingList)?_c('shopping-list-view',{attrs:{"shoppingList":_vm.shoppingList,"shoppingListId":parseInt(_vm.shoppingListId),"start-date":_vm.startDate}}):_vm._e(),(
                  _vm.state.error &&
                    (!_vm.upcomingNutritionBlock ||
                      (_vm.upcomingNutritionBlock && _vm.decodeId(_vm.upcomingNutritionBlock.id) == _vm.blockId))
                )?_c('j-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.humanErrorMessage)+" ")]):_vm._e(),(_vm.state.error && _vm.upcomingNutritionBlock && _vm.decodeId(_vm.upcomingNutritionBlock.id) != _vm.blockId)?_c('j-dotted-btn',{attrs:{"wide":"","color":"green"},on:{"click":function($event){return _vm.navigateNutritionBlock(_vm.upcomingNutritionBlock.id)}}},[_vm._v("View next program")]):_vm._e()],1)],1)],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }