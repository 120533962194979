<template>
  <j-banner-container
    header="See All Check-ins"
    md="5"
    class="my-2"
  >
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div v-if="!$apollo.loading">
          <v-row v-if="noAssessment">
            <v-col cols="12" align="center" justify="center">
              <span class="p-text">
                Here is where you'll find your check-ins.
                <span v-if="!oneOnOneClient">Add a check-in below to start your journey!</span>
                <span v-else>Your check-ins will show here after your next check-in with your Coach.</span>
              </span>
            </v-col>
          </v-row>
          <v-row class="mt-n2">
            <v-col cols="12" align="center" justify="center">
              <span class="p-text">
                Your check-ins are <strong class="mindset--text text--darken-1">private</strong> to you
                <span v-if="oneOnOneClient"> and your 1-on-1 Coach,</span>
                <span> and are <a class="text-decoration-none darker-grey--text" href="/celebration">never</a> shared without your permission.</span>
              </span>
            </v-col>
          </v-row>
        </div>
        <check-in-alert v-if="oneOnOneClient" />
        <div class="d-flex justify-end">
          <j-btn v-if="!oneOnOneClient" class="my-4" navy to="/checkins/new">
            Add a check-in
          </j-btn>
        </div>

        <div v-if="$apollo.loading">
          <v-skeleton-loader v-for="index in 3" :key="index" type="card" />
        </div>
        <div v-else class="mt-4">
          <check-in-block
            v-for="measurement in userCheckIns"
            :key="measurement.node.id"
            :measurement="measurement.node"
            class="mb-6"
          />
        </div>
        <p v-if="!noAssessment" align="center" class="pt-3 grey-navy--text">
          <v-icon class="mb-2 mr-1">arrow_upward</v-icon>From 1 January 2021 onwards
        </p>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { USER_CHECK_INS_QUERY } from "@/graphql/queries/checkins";
import { mapGetters } from "vuex";
import CheckInAlert from "@/components/checkin/CheckInAlert";
import CheckInBlock from "@/components/checkin/Block";

export default {
  name: "CheckInList",
  components: {
    CheckInAlert,
    CheckInBlock,
  },
  data() {
    return {
      noAssessment: false,
      list: [],
    };
  },
  filters: {
    formatChange(value) {
      return value.toString()[0] == "-" ? `- ${value * -1}` : `+ ${value}`;
    },
  },
  created() {
    if (this.deleted) {
      this.deleteAlert = true;
    }
    if (this.updated) {
      this.updateAlert = true;
    }
    if (this.added) {
      this.addAlert = true;
    }
  },
  computed: {
    ...mapGetters(["checkinRequired", "checkinId"]),
    oneOnOneClient() {
      return this.$store.getters.role == "ONE_ON_ONE";
    },
  },
  apollo: {
    userCheckIns: {
      query: USER_CHECK_INS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          orderBy: "-assessmentDate,-id",
        };
      },
      update: (data) => data.userCheckIns.edges,
    },
  },
};
</script>
