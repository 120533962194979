<template>
  <j-banner-container
    header="Add new check-in"
    class="check-in"
    :secondary-btn="{
      href: '/resources/Check-in-Guide.pdf',
      text: 'Guide',
      icon: 'mdi-file-document-outline',
      target: '_blank',
    }"
  >
    <ValidationObserver ref="obs">
      <j-card class="ma-3 py-4 px-6">
        <v-skeleton-loader v-if="$apollo.loading" class="text-left" type="list-item-three-line, article, card, card" />
        <v-form v-else>
          <div class="text-left mb-4">
            <div class="d-flex justify-space-between">
              <h3 class="dark-grey--text">Review</h3>
              <v-btn text class="grey-navy--text" @click="showDialog = true">
                <strong>Edit</strong>
              </v-btn>
            </div>
            <div class="d-flex flex-column">
              <span class="grey-navy--text">
                <span class="font-weight-medium navy--text">Primary goals:</span> {{ primaryGoals | goalList }}
              </span>
              <span class="grey-navy--text">
                <span class="font-weight-medium navy--text">Goal weight:</span> {{ goalWeight || "Unknown" }}
                <span v-if="goalWeight">kg</span>
              </span>
              <span class="grey-navy--text">
                <span class="font-weight-medium navy--text">Fitness level:</span>
                {{ fitnessLevel || "Unknown" | titleize }}
              </span>
            </div>
          </div>
          <div class="text-left mb-4">
            <h3 class="dark-grey--text">Reflect</h3>

            <div class="d-flex flex-column mb-2">
              <span class="font-weight-medium navy--text">How happy are you with your progress?</span>
            </div>
            <div>
              <v-icon
                v-for="(rating, index) in 5"
                :key="`${index}`"
                class="mr-2"
                @click="
                  selectedRating = rating;
                  ratingError = false;
                "
                :color="getRatingColor(rating)"
                size="35"
              >
                {{ rating > selectedRating ? "mdi-heart-outline" : "mdi-heart" }}
              </v-icon>
            </div>

            <ValidationProvider v-if="selectedRating" name="Comment" rules="required|max:500" v-slot="{ errors }">
              <j-textarea
                v-model="userCheckIn.comments"
                hide-details="auto"
                :rows="1"
                auto-grow
                label="Describe how you feel"
                clearable
                dense
                :error-messages="errors"
                :class="textBoxBottomClass(errors)"
                class="mt-3 mb-6"
                @input="ratingError = false"
              />
            </ValidationProvider>
            <j-alert v-if="ratingError" type="error" class="my-4">Please describe your progress</j-alert>

            <div class="d-flex flex-column mb-2">
              <span class="font-weight-medium navy--text">How happy are you with your nutrition?</span>
            </div>
            <div>
              <v-icon
                v-for="(nutritionRating, index) in 5"
                :key="`${index}`"
                class="mr-2"
                @click="selectedNutritionRating = nutritionRating"
                :color="getNutritionRatingColor(nutritionRating)"
                size="35"
              >
                {{ nutritionRating > selectedNutritionRating ? "mdi-food-apple-outline" : "mdi-food-apple" }}
              </v-icon>
            </div>

            <ValidationProvider
              v-if="selectedNutritionRating"
              name="Nutrition Comment"
              rules="max:500"
              v-slot="{ errors }"
            >
              <j-textarea
                v-model="userCheckIn.nutritionComments"
                hide-details="auto"
                :rows="1"
                auto-grow
                label="Reflect on your nutrition"
                clearable
                dense
                :error-messages="errors"
                :class="textBoxBottomClass(errors)"
                class="mt-3 mb-6"
              />
            </ValidationProvider>
          </div>
          <div class="text-left mb-4">
            <h3 class="dark-grey--text">Capture</h3>

            <form-block title="Weight" ref="weightBlock">
              <ValidationObserver ref="weightObs">
                <ValidationProvider name="weight" rules="validDecimal|max:6" v-slot="{ errors }" class="flex">
                  <j-text-field
                    v-model="userCheckIn.weight"
                    hide-details="auto"
                    background-color="white"
                    :error-messages="errors"
                    label="Weight (kg)"
                    type="number"
                    @wheel="$event.target.blur()"
                    clearable
                    :class="textBoxBottomClass(errors)"
                  />
                </ValidationProvider>
              </ValidationObserver>
            </form-block>

            <form-block title="Measurements" ref="measurementsBlock">
              <ValidationObserver ref="measurementsObs">
                <!-- <div class="d-flex flex-wrap justify-space-between mb-4 mt-n2">
                  <j-btn tertiary href="https://youtu.be/EIaMdrTGYGo" target="_blank" class="mt-2">
                    How to video
                    <v-icon color="navy">mdi-play-circle-outline</v-icon>
                  </j-btn>
                </div> -->

                <ValidationProvider name="Bust/Chest" rules="validDecimal" v-slot="{ errors }">
                  <j-text-field
                    v-model="userCheckIn.chest"
                    label="Bust/Chest (cm)"
                    type="number"
                    @wheel="$event.target.blur()"
                    hide-details="auto"
                    :error-messages="errors"
                    clearable
                    :class="textBoxBottomClass(errors)"
                    background-color="white"
                  />
                </ValidationProvider>
                <ValidationProvider name="Abdomen" rules="validDecimal" v-slot="{ errors }">
                  <j-text-field
                    v-model="userCheckIn.abdomen"
                    hide-details="auto"
                    :error-messages="errors"
                    label="Abdomen (cm)"
                    type="number"
                    @wheel="$event.target.blur()"
                    clearable
                    :class="textBoxBottomClass(errors)"
                    background-color="white"
                  />
                </ValidationProvider>
                <ValidationProvider name="Hip" rules="validDecimal" v-slot="{ errors }">
                  <j-text-field
                    v-model="userCheckIn.hip"
                    label="Hip (cm)"
                    type="number"
                    @wheel="$event.target.blur()"
                    hide-details="auto"
                    :error-messages="errors"
                    clearable
                    :class="textBoxBottomClass(errors)"
                    background-color="white"
                  />
                </ValidationProvider>
                <ValidationProvider name="Quad" rules="validDecimal" v-slot="{ errors }">
                  <j-text-field
                    v-model="userCheckIn.quad"
                    label="Quad (cm)"
                    type="number"
                    @wheel="$event.target.blur()"
                    hide-details="auto"
                    :error-messages="errors"
                    clearable
                    :class="textBoxBottomClass(errors)"
                    background-color="white"
                  />
                </ValidationProvider>
              </ValidationObserver>
            </form-block>

            <form-block title="Photos">
              <p class="font-weight-medium mb-8">
                Add photos to see body shape change, which can be more insightful than measurements
              </p>
              <v-row>
                <v-col cols="8" class="pl-0">
                  <v-row class="ml-3 ml-md-6">
                    <check-in-upload label="Front" @imageChanged="selectImgFront($event)"> </check-in-upload>
                  </v-row>
                </v-col>
                <v-col>
                  <img
                    v-if="userCheckIn.imgFront"
                    :alt="userCheckIn.imgFront.name"
                    :style="{ maxWidth: '80px', maxHeight: '100px' }"
                    :src="userCheckIn.imgFront.dataUrl || userCheckIn.imgFront"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8" class="pl-0">
                  <v-row class="ml-3 ml-md-6">
                    <check-in-upload label="Back" @imageChanged="selectImgBack($event)"> </check-in-upload>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <img
                    v-if="userCheckIn.imgBack"
                    :alt="userCheckIn.imgBack.name"
                    :style="{ maxWidth: '80px', maxHeight: '100px' }"
                    :src="userCheckIn.imgBack.dataUrl || userCheckIn.imgBack"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8" class="pl-0">
                  <v-row class="ml-3 ml-md-6">
                    <check-in-upload label="Side" @imageChanged="selectImgSide($event)"> </check-in-upload>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <img
                    v-if="userCheckIn.imgSide"
                    :alt="userCheckIn.imgSide.name"
                    :style="{ maxWidth: '80px', maxHeight: '100px' }"
                    :src="userCheckIn.imgSide.dataUrl || userCheckIn.imgSide"
                  />
                </v-col>
              </v-row>
            </form-block>
            <form-block title="Fitness" ref="fitnessBlock">
              <ValidationObserver ref="fitnessObs">
                <!-- <div class="d-flex flex-wrap justify-space-between mb-4 mt-n2">
                  <j-btn tertiary href="https://www.youtube.com/watch?v=j24HK3eHPL8" target="_blank" class="mt-2">
                    1 mile
                    <v-icon color="navy">mdi-play-circle-outline</v-icon>
                  </j-btn>
                  <j-btn tertiary href="https://www.youtube.com/watch?v=Mqxe5ZI4PnQ" target="_blank" class="mt-2">
                    Dynamic/static
                    <v-icon color="navy">mdi-play-circle-outline</v-icon>
                  </j-btn>
                </div> -->

                <div class="mb-2 text-uppercase font-weight-medium">1 mile/1.6km</div>
                <div class="d-flex mb-3">
                  <ValidationProvider name="Minutes" rules="max:5" v-slot="{ errors }" class="half-width mr-2">
                    <j-text-field
                      label="M"
                      type="number"
                      v-model="userCheckIn.duration.m"
                      background-color="white"
                      hide-details="auto"
                      min="0"
                      max="10"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <ValidationProvider name="Seconds" rules="max_value:59" v-slot="{ errors }" class="half-width">
                    <j-text-field
                      label="S"
                      type="number"
                      v-model="userCheckIn.duration.s"
                      background-color="white"
                      hide-details="auto"
                      :error-messages="errors"
                      min="0"
                      max="59"
                    />
                  </ValidationProvider>
                </div>
                <ValidationProvider name="Push-ups" rules="max_value:500" v-slot="{ errors }">
                  <j-text-field
                    v-model="userCheckIn.pushups"
                    label="2min push-ups"
                    type="number"
                    @wheel="$event.target.blur()"
                    hide-details="auto"
                    :error-messages="errors"
                    clearable
                    :class="textBoxBottomClass(errors)"
                    background-color="white"
                  />
                </ValidationProvider>
                <ValidationProvider name="Sit-ups" rrules="max_value:500" v-slot="{ errors }">
                  <j-text-field
                    v-model="userCheckIn.situps"
                    label="1min sit-ups"
                    type="number"
                    @wheel="$event.target.blur()"
                    hide-details="auto"
                    :error-messages="errors"
                    clearable
                    :class="textBoxBottomClass(errors)"
                    background-color="white"
                  />
                </ValidationProvider>
                <ValidationProvider name="Burpees/Chirpees" rules="max_value:500" v-slot="{ errors }">
                  <j-text-field
                    v-model="userCheckIn.burpees"
                    label="1min burpee/chirpee"
                    type="number"
                    @wheel="$event.target.blur()"
                    hide-details="auto"
                    :error-messages="errors"
                    clearable
                    :class="textBoxBottomClass(errors)"
                    background-color="white"
                  />
                </ValidationProvider>
                <ValidationProvider name="Elbow plank" rules="max_value:10000" v-slot="{ errors }">
                  <j-text-field
                    v-model="userCheckIn.plank"
                    label="Elbow plank (seconds)"
                    type="number"
                    @wheel="$event.target.blur()"
                    hide-details="auto"
                    :error-messages="errors"
                    clearable
                    :class="textBoxBottomClass(errors)"
                    background-color="white"
                  />
                </ValidationProvider>
                <ValidationProvider name="Squat hold" rules="max_value:10000" v-slot="{ errors }">
                  <j-text-field
                    v-model="userCheckIn.squat"
                    label="Squat hold/wall squat (seconds)"
                    type="number"
                    @wheel="$event.target.blur()"
                    hide-details="auto"
                    :error-messages="errors"
                    clearable
                    :class="textBoxBottomClass(errors)"
                    background-color="white"
                  />
                </ValidationProvider>
              </ValidationObserver>
            </form-block>
          </div>
          <v-card-actions class="justify-space-between">
            <j-btn tertiary to="/checkins">cancel</j-btn>
            <j-btn color="navy" @click="submit" :loading="buttonLoading">Save</j-btn>
          </v-card-actions>
          <j-alert v-if="ratingError" type="error" class="mt-4">Please describe your progress</j-alert>
        </v-form>
      </j-card>
    </ValidationObserver>

    <v-dialog v-model="showDialog" max-width="600" transition="dialog-bottom-transition" class="limited-height">
      <journey-details-dialog
        v-if="showDialog"
        :current-weight="userCheckIn.weight"
        :missing-details-for-checkin="missingUserProfileDetails"
        @close="showDialog = false"
        @update="
          userCheckIn.weight = $event;
          showDialog = false;
        "
      />
    </v-dialog>
    <confirm-nutrition-change-dialog
      :original-plan="changeNutritionPlan.original"
      :new-plan="changeNutritionPlan.new"
      ref="nutritionChangeModal"
    />
  </j-banner-container>
</template>

<script>
import { UPDATE_PROFILE_MUTATION } from "@/graphql/mutations/account";
import { CREATE_CHECK_IN_MUTATION, UPDATE_CHECK_IN_MUTATION } from "@/graphql/mutations/checkin";
import { USER_CHECK_IN_QUERY } from "@/graphql/queries/checkins";
import { UPDATE_SERVING_SIZE_MUTATION } from "@/graphql/mutations/nutrition";
import { dataURLtoBlob, blobToFile } from "@/lib/file";
import JourneyDetailsDialog from "@/components/shared/dialogs/JourneyDetails";
import FormBlock from "@/components/checkin/FormBlock";
import JourneyDetailsMixin from "@/mixins/JourneyDetailsMixin";
import CheckInUpload from "@/components/checkin/CheckInUpload";
import ConfirmNutritionChangeDialog from "@/components/shared/dialogs/ConfirmNutritionChangeDialog";

export default {
  name: "CheckInForm",
  mixins: [JourneyDetailsMixin],
  components: {
    ConfirmNutritionChangeDialog,
    FormBlock,
    JourneyDetailsDialog,
    CheckInUpload,
  },
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      initiallyLoaded: false,
      buttonLoading: false,
      showDialog: false,
      missingUserProfileDetails: false,
      userCheckIn: {
        weight: null,
        imgFront: null,
        imgBack: null,
        imgSide: null,
        imgFrontChanged: false,
        imgBackChanged: false,
        imgSideChanged: false,
        rating: null,
        nutritionRating: null,
        comments: null,
        nutritionComments: null,
        duration: {
          m: null,
          s: null,
        },
        mile: null,
        pushups: null,
        situps: null,
        burpees: null,
        plank: null,
        squat: null,
      },
      selectedRating: null,
      selectedNutritionRating: null,
      ratingError: false,
      changeNutritionPlan: {
        original: "",
        new: "",
      },
    };
  },
  filters: {
    goalList(goals) {
      if (goals?.length == 0) {
        return "Unknown";
      }

      return goals
        ?.map((goal) => {
          let val = goal.replace("-", " ");
          return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
        })
        .join(", ");
    },
  },
  computed: {
    hasCoaching() {
      return this.$store.getters.role == "ONE_ON_ONE";
    },
    gender() {
      return this.user?.userProfile?.gender;
    },
  },
  updated() {
    if (!this.$apollo.loading && !this.initiallyLoaded && !!this.primaryGoals) {
      this.initiallyLoaded = true;

      if (!this.validateUserProfile()) {
        this.missingUserProfileDetails = true;
        this.showDialog = true;
      } else {
        this.missingUserProfileDetails = false;
      }
    }
  },
  methods: {
    getRatingColor(number) {
      return number <= this.selectedRating ? "red" : "grey";
    },
    getNutritionRatingColor(number) {
      return number <= this.selectedNutritionRating ? "green" : "grey";
    },
    textBoxBottomClass(errors) {
      return errors.length == 0 ? "mb-3" : "mb-n1";
    },
    selectImgFront(image) {
      this.imgFrontChanged = true;

      if (image) {
        this.userCheckIn.imgFront = image;
      } else {
        this.userCheckIn.imgFront = "";
      }
    },
    selectImgBack(image) {
      this.imgBackChanged = true;

      if (image) {
        this.userCheckIn.imgBack = image;
      } else {
        this.userCheckIn.imgBack = "";
      }
    },
    selectImgSide(image) {
      this.imgSideChanged = true;

      if (image) {
        this.userCheckIn.imgSide = image;
      } else {
        this.userCheckIn.imgSide = "";
      }
    },
    getImageFile(image) {
      if (image) {
        let blob = dataURLtoBlob(image.dataUrl);
        let file = blobToFile(blob);

        return file;
      }
    },
    getDurationInSeconds() {
      return Math.floor(
        (parseInt(this.userCheckIn.duration?.s) || 0) + (parseInt(this.userCheckIn.duration?.m) || 0) * 60
      );
    },
    submit() {
      this.ratingError = false;

      let hasError = false;
      if (!this.validateUserProfile()) {
        this.missingUserProfileDetails = true;
        this.showDialog = true;
      } else {
        this.missingUserProfileDetails = false;
      }

      this.$refs.obs.validate().then((valid) => {
        if (!this.selectedRating || !this.userCheckIn.comments) {
          this.ratingError = true;
          hasError = true;
        }
        if (hasError || !valid) {
          this.openInvalidPanels();
          return false;
        }
        if (valid) {
          this.save();
        }
      });
    },
    save() {
      this.buttonLoading = true;

      let userCheckInInput = {
        weight: parseFloat(this.userCheckIn.weight),
        abdomen: parseFloat(this.userCheckIn.abdomen),
        chest: parseFloat(this.userCheckIn.chest),
        hip: parseFloat(this.userCheckIn.hip),
        quad: parseFloat(this.userCheckIn.quad),
        oneMile: this.getDurationInSeconds(),
        pushUps: parseInt(this.userCheckIn.pushups),
        sitUps: parseInt(this.userCheckIn.situps),
        burpeeChirpee: parseInt(this.userCheckIn.burpees),
        elbowPlank: parseInt(this.userCheckIn.plank),
        squat: parseInt(this.userCheckIn.squat),
        comments: this.userCheckIn.comments,
        rating: this.selectedRating,
        nutritionRating: this.selectedNutritionRating,
        nutritionComments: this.userCheckIn.nutritionComments,
      };

      if (this.imgFrontChanged) {
        userCheckInInput.imgFront = this.getImageFile(this.userCheckIn.imgFront);
        userCheckInInput.imgFrontChanged = this.imgFrontChanged;
      }
      if (this.imgBackChanged) {
        userCheckInInput.imgBack = this.getImageFile(this.userCheckIn.imgBack);
        userCheckInInput.imgBackChanged = this.imgBackChanged;
      }
      if (this.imgSideChanged) {
        userCheckInInput.imgSide = this.getImageFile(this.userCheckIn.imgSide);
        userCheckInInput.imgSideChanged = this.imgSideChanged;
      }
      if (this.id === "0") {
        userCheckInInput.checkInType = this.$route.params?.type ? this.$route.params?.type.toUpperCase() : "USER";
        this.createUserCheckIn(userCheckInInput);
      } else {
        this.updateUserCheckIn(userCheckInInput);
      }

      this.$apollo.mutate({
        mutation: UPDATE_PROFILE_MUTATION,
        variables: {
          userProfileInput: {
            weight: this.userCheckIn.weight,
          },
        },
        refetchQueries: ["accountQuery"],
      });
    },
    updateUserCheckIn(userCheckInInput) {
      this.$apollo
        .mutate({
          mutation: UPDATE_CHECK_IN_MUTATION,
          variables: {
            userCheckInInput: userCheckInInput,
            id: this.id,
          },
        })
        .then((response) => {
          if (response.data) {
            this.saving = false;
            this.submitted = true;
            this.$router.push({ name: "CheckinList", params: { updated: true } });
          }
        })
        .catch((error) => {
          this.saving = false;
          this.error = true;
          throw error;
        });
    },
    createUserCheckIn(userCheckInInput) {
      this.$apollo
        .mutate({
          mutation: CREATE_CHECK_IN_MUTATION,
          variables: {
            userCheckInInput: userCheckInInput,
          },
          refetchQueries: ["assistantQuery"],
        })
        .then((response) => {
          if (response.data) {
            this.saving = false;
            this.submitted = true;
            let responseData = response.data.createUserCheckIn;
            //this.$router.push({ name: "Journey", params: { skip: true } });
            if (responseData.newServingSize && responseData.newServingSize !== responseData.originalServingSize) {
              // the serving size has been changed, we need to confirm with the client
              this.changeNutritionPlan.new = responseData.newServingSize;
              this.changeNutritionPlan.original = responseData.originalServingSize;

              this.$refs.nutritionChangeModal.open().then((confirmed) => {
                if (confirmed) {
                  this.$apollo
                    .mutate({
                      mutation: UPDATE_SERVING_SIZE_MUTATION,
                    })
                    .then(() => {
                      // we can be eager here and assume the request worked no need to handle it
                      //this.$router.push({ name: "Journey", params: { skip: true } });
                      this.$router.push("/checkins?success=1");
                    });
                } else {
                  // client doesnt want to change nutrition plans, go to journey.
                  //this.$router.push({ name: "Journey", params: { skip: true } });
                  this.$router.push("/checkins?success=1");
                }
              });
            } else {
              //we can jump straight to the journey
              //this.$router.push({ name: "Journey", params: { skip: true } });
              this.$router.push("/checkins?success=1");
            }
          }
        })
        .catch((error) => {
          this.saving = false;
          this.error = true;
          throw error;
        });
    },
    openInvalidPanels() {
      this.$refs.weightObs.validate().then((valid) => {
        if (!valid) {
          this.$refs.weightBlock.expanded = true;
        }
      });
      this.$refs.measurementsObs.validate().then((valid) => {
        if (!valid) {
          this.$refs.measurementsBlock.expanded = true;
        }
      });
      this.$refs.fitnessObs.validate().then((valid) => {
        if (!valid) {
          this.$refs.fitnessBlock.expanded = true;
        }
      });
    },
  },
  apollo: {
    userCheckIn: {
      query: USER_CHECK_IN_QUERY,
      variables() {
        return {
          id: this.id,
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return this.id == "0";
      },
      result(response) {
        if (response.data) {
          let oneMile = response.data?.userCheckIn?.oneMile ?? 0;
          let duration = this.$moment.duration(oneMile, "seconds");
          this.userCheckIn.duration = {
            m: duration.minutes() + duration.hours() * 60,
            s: duration.seconds(),
          };

          this.selectedRating = response.data?.userCheckIn?.rating;
          this.selectedNutritionRating = response.data?.userCheckIn?.nutritionRating;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.check-in .j-text-field .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
<style lang="scss" scoped>
.widget {
  width: 30%;
  aspect-ratio: 1;
}
.half-width {
  width: 50%;
}
</style>
