<template>
  <v-container>
    <v-row class="py-0 my-0" justify="center" align="center">
      <v-col cols="12" md="6" class="py-0 my-0">
        <v-container fill-height fluid v-if="loading">
          <v-row class="py-0 my-0" justify="center" align="center">
            <v-col cols="12" class="py-0 my-0" justify="center" align="center">
              <v-progress-circular :size="50" indeterminate> </v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row class="py-0 my-0" justify="center" align="center">
            <v-col cols="12" class="py-0 my-0 px-4 mb-5">
              <router-link
                class="subtitle-1 grey-navy--text font-weight-bold text-decoration-none"
                to="/rewards/points"
              >
                <v-icon small class="pb-1"> keyboard_backspace </v-icon> Back
              </router-link>
            </v-col>
          </v-row>
          <v-card elevation="2" style="border-radius: 25px">
            <v-container v-if="!submitted">
              <v-container v-if="totalPoints > 0">
                <v-row class="py-0 my-0" justify="center" align="center">
                  <v-col cols="12" class="py-0 my-0">
                    <v-card-title class="navy--text mb-1">
                      <span class="header"> Use points </span>
                    </v-card-title>
                    <v-card-subtitle class="pt-2">
                      <span class="p-text"> Your points can be redeemed for vouchers and discounts. </span>
                    </v-card-subtitle>
                    <j-btn
                      tertiary
                      large
                      to="/article/how-to-use-rewards-points"
                      class="action-button ml-3 mb-5"
                    >
                      How to use points
                    </j-btn>
                  </v-col>
                </v-row>

                <v-row class="py-0 my-0" justify="center" align="center">
                  <v-col cols="12" class="py-0 my-0">
                    <v-card-title class="mb-1">
                      <span class="heading-3 mid-grey--text"> Want to use your points? </span>
                    </v-card-title>
                    <v-card-subtitle class="pt-2">
                      <span class="p-text">
                        Fill out this form to convert your points into vouchers and discounts.
                        <strong>Please note that you can only use points to discount your next payment once per month</strong>.
                      </span>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
                <v-row v-if="submitError" class="py-0 my-0" justify="center" align="center">
                  <v-col cols="12" class="py-0 my-0">
                    <v-alert type="error" dismissible>{{ submitError }}</v-alert>
                  </v-col>
                </v-row>
                <v-card-text class="pt-0 mt-0">
                  <validation-observer ref="redeemPointsObs">
                    <v-form>
                      <v-row v-if="submitError" class="py-0 my-0" justify="center" align="center">
                        <v-col cols="12" class="py-0 my-0">
                          <v-alert type="error" dismissible>{{ submitError }}</v-alert>
                        </v-col>
                      </v-row>
                      <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="12" class="py-0 my-0">
                          <ValidationProvider mode="lazy" name="Action" rules="required" v-slot="{ errors, valid }">
                            <v-select
                              v-model="formData.category"
                              :items="categories"
                              label="How do you want to use your points?"
                              :error-messages="errors"
                              :success="valid"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="12" class="py-0 my-0">
                          <ValidationProvider mode="lazy" name="Points" :rules="pointsRules" v-slot="{ errors, valid }">
                            <v-text-field
                              v-model="formData.points"
                              label="How many points would you like to use?"
                              :error-messages="errors"
                              :success="valid"
                              persistent-hint
                              autocomplete="off"
                              :hint="pointHint"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="12" class="py-0 my-0">
                          <ValidationProvider name="Description" rules="max:200" v-slot="{ errors }">
                            <v-textarea
                              v-model="formData.description"
                              placeholder="Enter a short description if you like."
                              rows="3"
                              :error-messages="errors"
                            ></v-textarea>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="12" class="py-0 my-0 mt-5" justify="center" align="center">
                          <j-btn large @click="submit" :disabled="submitting"> Use Points </j-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </validation-observer>
                </v-card-text>
              </v-container>
              <v-container v-else>
                <v-card-subtitle><j-alert>You don't currently have any points to use.</j-alert></v-card-subtitle>
              </v-container>
            </v-container>
            <v-container v-else>
              <v-row class="py-0 my-0" justify="center" align="center">
                <v-col cols="12" class="py-0 my-0">
                  <v-card-subtitle class="pt-2 text-center mt-6">
                    <span class="heading-2">Your points have been redeemed</span>
                  </v-card-subtitle>
                </v-col>
              </v-row>
              <v-row v-if="rewardEvent.discount" class="py-0 my-0" justify="center" align="center">
                <v-col cols="12" class="py-0 my-0">
                  <v-alert v-if="rewardEvent.discount.code" type="success"
                    >You have redeemed your {{ Math.abs(rewardEvent.points) }} points for a R{{
                      pointsToRandValue(rewardEvent.points)
                    }}
                    voucher. Your voucher code is: {{ rewardEvent.discount.code }}</v-alert
                  >
                  <v-alert v-else type="success"
                    >A discount for R{{ pointsToRandValue(rewardEvent.points) }} has been applied to your upcoming
                    payment</v-alert
                  >
                </v-col>
              </v-row>
              <v-row class="py-0 my-0" justify="center" align="center">
                <v-col cols="12" class="py-0 my-0 mt-5" justify="center" align="center">
                  <j-btn large to="/rewards/points"> Back To Points </j-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { REWARD_CATEGORIES_QUERY, REWARDS_QUERY } from "@/graphql/queries/rewards";
import { REDEEM_REWARD_MUTATION } from "@/graphql/mutations/rewards";
import RewardsMixin from "@/mixins/RewardsMixin";

export default {
  name: "UsePoints",
  mixins: [RewardsMixin],
  data() {
    return {
      totalPoints: 0,
      loaded: {
        categories: false,
        points: false,
      },
      submitted: false,
      submitting: false,
      submitError: null,
      categories: [],
      rewardEvent: null,
      formData: {
        category: "",
        points: 0,
        description: "",
      },
    };
  },
  computed: {
    loading() {
      return !(this.loaded.categories && this.loaded.points);
    },
    pointsRules() {
      let rules = ["required", "min_value:100"];
      rules.push("max_value:" + this.totalPoints);
      return rules.join("|");
    },
    pointHint() {
      return `You have ${this.totalPoints} point${this.totalPoints !== 1 ? "s" : ""} available`;
    },
  },
  methods: {
    async submit() {
      this.submitting = true;
      this.submitError = null;
      const valid = await this.$refs.redeemPointsObs.validate();

      if (valid) {
        const { data } = await this.$apollo.mutate({
          mutation: REDEEM_REWARD_MUTATION,
          variables: {
            input: {
              categoryId: this.formData.category,
              description: this.formData.description,
              points: this.formData.points,
            },
          },
        });

        if (data.redeemPointsMutation.success) {
          this.submitted = true;
          this.rewardEvent = data.redeemPointsMutation.event;
          
        } else {
          this.submitError = data.redeemPointsMutation.error;
        }
      } else {
        this.submitting = false;
      }
    },
  },
  apollo: {
    rewardEventCategories: {
      query: REWARD_CATEGORIES_QUERY,
      variables() {
        return {
          categoryType: "REDEEM",
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          this.loaded.categories = true;
          this.categories = response.data.rewardEventCategories.map((item) => {
            return {
              text: item.title,
              value: item.id,
            };
          });
        }
      },
    },
    rewardEvents: {
      query: REWARDS_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data && !response.loading) {
          this.loaded.points = true;
          response.data.rewardEvents
            .filter((item) => item.approvalStatus === "APPROVED")
            .forEach((item) => {
              this.totalPoints += item.points;
            });
        }
      },
    },
  },
};
</script>
