<template>
  <v-container>
    <j-banner-container header="Contact Us">
      <v-progress-circular indeterminate color="primary" v-if="loading" class="my-4" />

      <v-card-title class="navy--text">What can we help you with?</v-card-title>
      <div class="mx-5">
        <j-radio-group v-model="selection" class="mt-0">
          <j-radio label="Manage my subscription" value="subscription" />
          <j-radio label="Claim rewards points" value="rewards" />
          <!-- <j-radio label="Reset my password" value="password" /> -->
          <j-radio label="I have my own question or request" value="custom" />
        </j-radio-group>

        <j-alert v-if="selection == 'subscription'" :icon="false"
          >We made it possible to manage your own subscription anytime.
          <a class="font-weight-bold block" href="/account/billing">Manage my own subscription →</a></j-alert
        >
        <j-alert v-else-if="selection == 'rewards'" :icon="false"
          >We made it possible to manage your own rewards points anytime.
          <a class="font-weight-bold block" href="/rewards/points">Manage my own rewards points →</a></j-alert
        >
        <!-- <j-alert v-else-if="selection == 'password'" :icon="false"
          >We made it possible to reset your own password anytime.
          <a class="font-weight-bold block" href="/account/reset-password">Reset my own password →</a></j-alert
        > -->
        <div v-show="selection == 'custom'">
          <div v-if="supportChat">
            <j-btn primary :to="`/chats/${supportChat.id}`"
              >Go to my Support Chat<v-icon class="ml-1 mt-n1">mdi-chat</v-icon></j-btn
            >
          </div>

          <div v-else>
            <v-card-title class="grey-navy--text pl-0 pt-0">Let's chat! Tell us more</v-card-title>
            <div v-once id="hubspotForm" class="pa-3"></div>
          </div>
        </div>
      </div>
    </j-banner-container>
  </v-container>
</template>

<script>
import { SIMPLE_ME_QUERY } from "@/graphql/queries/account";
export default {
  name: "Help",
  data() {
    return {
      loading: true,
      loadedHubspot: false,
      loadedJquery: false,
      selection: null,
    };
  },
  mounted() {
    const hubspotScript = document.createElement("script");
    hubspotScript.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(hubspotScript);

    hubspotScript.addEventListener("load", () => {
      this.loadedHubspot = true;
      if (this.loadedHubspot && this.loadedJquery) this.createForm();
    });

    const jqueryScript = document.createElement("script");
    jqueryScript.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
    document.body.appendChild(jqueryScript);

    jqueryScript.addEventListener("load", () => {
      this.loadedJquery = true;
      if (this.loadedHubspot && this.loadedJquery) this.createForm();
    });
  },
  methods: {
    createForm() {
      this.loading = false;
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "6239229",
          formId: "0f8ec258-7335-4ade-bbf0-60c2e7e7db08",
          target: "#hubspotForm",
          onFormReady: function($form) {
            let user = JSON.parse(localStorage.getItem("user"));
            if (user.firstName) {
              $form
                .find('input[name="firstname"]')
                .val(user.firstName)
                .change();
            }
            if (user.lastName) {
              $form
                .find('input[name="lastname"]')
                .val(user.lastName)
                .change();
            }
            if (user.phone) {
              $form
                .find('input[name="phone"]')
                .val(user.phone)
                .change();
            }
            $form
              .find('input[name="email"]')
              .val(user.email)
              .change();
          },
        });
      }
    },
  },
  apollo: {
    supportChat: {
      query: SIMPLE_ME_QUERY,
      fetchPolicy: "no-cache",
      variables() {
        return {
          first: 1,
        };
      },
      update(data) {
        return data.me?.userProfile?.supportChat;
      },
    },
  },
};
</script>
