import { USER_PROFILE_CREATION, UPSERT_DEAL_MUTATION, CREATE_SUBSCRIPTION_MUTATION } from "@/graphql/mutations/account";
import { REGISTER_MUTATION } from "@/graphql/mutations/authentication";
import { USER_TAGS_MUTATION } from "@/graphql/mutations/tag";
import { countryListAlpha2 } from "@/lib/countries";
import VueJwtDecode from "vue-jwt-decode";
import Cookies from "js-cookie";

export default {
  name: "RegistrationForm",
  props: {
    includePassword: {
      type: Boolean,
      default: true,
    },
    requiresEmployeeCode: {
      type: Boolean,
      default: false,
    },
    submitButton: {
      type: Object,
      default: () => {
        return {
          text: "Get access now",
          color: "secondary",
          textColor: "white",
        };
      },
    },
  },
  data() {
    return {
      userId: null,
      employeeCode: null,
      username: "",
      firstName: "",
      lastName: "",
      password: "",
      suburb: "",
      phone: null,
      idNumber: null,
      passportNumber: null,
      errors: null,
      showPassword: false,
      buttonLoading: false,
      terms: false,
      totallyRealFields: {
        name: "",
        surname: "",
      },
      countries: [...countryListAlpha2],
      country: "ZA",
      gymBranch: null,
    };
  },
  methods: {
    displayError(message) {
      // this is a django built in error message and unlikely to change.
      if (message == "A user with that username already exists.") {
        if (this.$route.query.code) {
          sessionStorage.setItem("redirectPath", `/checkout/${this.$route.query.code}`);
        }
        return `You already have an account with us. Please <a href="/login">login</a>`;
      }
      return message;
    },
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.buttonLoading = true;
          this.handleUserCreation();
        }
      });
    },
    handleUserCreation() {
      const username = this.username.toLowerCase();
      let password = this.includePassword ? this.password : Math.random().toString(36).substr(2, 8);

      this.$apollo
        .mutate({
          mutation: REGISTER_MUTATION,
          variables: {
            email: username,
            username: username,
            password1: password,
            password2: password,
          },
        })
        .then((result) => {
          let register = result.data.register;
          this.errors = null;
          if (register.success === true) {
            const accessToken = register.token;
            const tokenPayload = VueJwtDecode.decode(accessToken);
            const refreshToken = tokenPayload.exp;

            this.$store.dispatch("setAuthToken", { accessToken, refreshToken }).then(() => {
              let storedUserData = {};

              if (this.$store.getters.deal) {
                let deal = this.$store.getters.deal;
                storedUserData = {
                  dateOfBirth: deal.birthday,
                  weight: deal.currentWeight,
                  desiredWeight: deal.goalWeight,
                  highestEducation: deal.education,
                  fitnessLevel: deal.fitnessLevel?.toUpperCase(),
                  height: deal.height,
                  biggestObstacle: deal.obstacle,
                  otherObstacleText: deal.obstacleText,
                  otherJoiningInfo: deal.otherText,
                  gender: deal.sex?.toUpperCase(),
                };

                if (deal.goals?.length > 0) {
                  this.handleUserGoals(deal.goals);
                }
              }

              this.handleProfileCreation(storedUserData);
            });
          } else {
            this.errors = register.errors;
            this.buttonLoading = false;
          }
        });
    },
    async handleProfileCreation(storedUserData) {
      const result = await this.$apollo.mutate({
        mutation: USER_PROFILE_CREATION,
        variables: {
          userProfileInput: {
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
            country: this.country,
            suburb: this.suburb,
            gymBranch: this.gymBranch,
            idNumber: this.idNumber,
            passportNumber: this.passportNumber,
            ...storedUserData,
          },
        },
      });
      this.userId = result.data.userProfileCreation.userId;

      /**
       * Careful! defaultProductId must be null in main.js for JEFF, otherwise users get access to the default product without paying!
       */
      if (this.$defaultProductId && !this.requiresPayment) {
        await this.$apollo.mutate({
          mutation: CREATE_SUBSCRIPTION_MUTATION,
          variables: {
            input: {
              productId: this.$defaultProductId,
              referralCode: sessionStorage.getItem("referral_code"),
            },
          },
        });
      }

      this.handleDealCreation();
      this.redirectToNext();
    },
    handleUserGoals(goals) {
      this.$apollo.mutate({
        mutation: USER_TAGS_MUTATION,
        variables: {
          tags: goals,
          category: "PRIMARY",
        },
      });
    },
    handleDealCreation() {
      const dealOwnerId = this.$route.query.doid ?? this.$store.getters.dealOwner?.id ?? null;
      const data = {
        dealOwnerId: dealOwnerId,
        userId: this.userId,
        dealTypeCode: "CHECKOUT",
        stageId: 1,
      };
      const utm_campaign = Cookies.get("utm_campaign");
      const utm_medium = Cookies.get("utm_medium");
      const utm_source = Cookies.get("utm_source");

      if (utm_campaign) {
        data["attributionCampaign"] = utm_campaign;
        Cookies.remove("utm_campaign");
      }

      if (utm_medium) {
        data["attributionMedium"] = utm_medium;
        Cookies.remove("utm_medium");
      }

      if (utm_source) {
        data["attributionSource"] = utm_source;
        Cookies.remove("utm_source");
      }

      this.$apollo
        .mutate({
          mutation: UPSERT_DEAL_MUTATION,
          variables: {
            data: data,
          },
        })
        .then(() => {
          this.$store.dispatch("clearDeal");
        });
    },
    async redirectToNext() {
      let userRole = "INACTIVE";

      let user = {
        id: this.userId,
        name: this.firstName + " " + this.lastName,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.username,
      };

      this.$store.dispatch("updateRole", { role: userRole });
      this.$store.dispatch("updateUser", user);

      // let path = sessionStorage.getItem("redirectPath") || "";

      // if (path !== "") {
      //   sessionStorage.removeItem("redirectPath");
      //   return this.$router.push(`${path}`);
      // }

      // tell the checkin wizard that the user has registered so that get kicked out of said wizard earlier.
      sessionStorage.setItem("registerCompleted", 1);
      
      const userId = this.$store.getters.user.id;
      if (userId) {
        this.$OneSignal.setExternalUserId(userId);
      }

      if (this.$store.getters.coldCheckout && this.$route.query.code && this.requiresPayment) {
        return this.$router.push(`/checkout/${this.$route.query.code}`);
      }

      // we have a product variant ID on register. We need to checkout.
      if (this.$route.query.pvi && this.$route.query.code) {
        return this.$router.push(`/checkout/${this.$route.query.code}?pvi=${this.$route.query.pvi}`);
      }
      // otherwise redirect to goals on register
      return this.$router.push(`/checkin/goals`);
    },
  },
  computed: {
    requiresPayment() {
      // super brittle
      return this.$route.query.code?.includes("1-on-1");
    },
  },
  created() {
    if (this.$route.query.code) {
      this.$store.dispatch("updateColdCheckout", true);
    } else {
      this.$store.dispatch("updateColdCheckout", false);
    }
  },
};
