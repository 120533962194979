<template>
  <j-banner-container header="Personal &amp; Account" md="5">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card>
          <ValidationObserver ref="obs">
            <v-card-text v-if="me.userProfile" class="pt-2 pb-0">
              <v-row class="my-3 mb-6">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Handle: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <span v-if="!edit">
                    {{ me.userProfile.handle }}
                  </span>
                  <ValidationProvider
                    v-else
                    ref="handleValidationProvider"
                    rules="alpha_num"
                    name="Handle"
                    v-slot="{ errors, valid }"
                  >
                    <j-text-field
                      :error-messages="errors"
                      :success="valid"
                      dense
                      ref="handle"
                      v-model="data.handle"
                      placeholder=""
                      class="pt-0 mt-0"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="my-3 mb-6">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Email: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  {{ me.email }}
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> First name: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <span v-if="!edit">
                    {{ me.firstName }}
                  </span>
                  <ValidationProvider v-else rules="required" name="First name" v-slot="{ errors, valid }">
                    <j-text-field
                      :error-messages="errors"
                      :success="valid"
                      dense
                      ref="firstName"
                      v-model="data.firstName"
                      placeholder=""
                      class="pt-0 mt-0"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Last name: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <span v-if="!edit">
                    {{ me.lastName }}
                  </span>
                  <ValidationProvider v-else rules="required" name="Last name" v-slot="{ errors, valid }">
                    <j-text-field
                      :error-messages="errors"
                      :success="valid"
                      dense
                      ref="lastName"
                      v-model="data.lastName"
                      placeholder=""
                      class="pt-0 mt-0"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Personal Bio: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <span v-if="!edit">
                    {{ me.userProfile.biography }}
                  </span>
                  <j-text-field
                    v-else
                    dense
                    ref="biography"
                    v-model="data.biography"
                    placeholder="Add a personal Bio"
                    class="pt-0 mt-0"
                  />
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Sex: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <span v-if="!edit">
                    {{ me.userProfile.gender | titleize }}
                  </span>
                  <j-select v-else v-model="data.gender" dense :items="genderOptions" class="pt-0 mt-0" />
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Date of birth: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <span v-if="!edit">
                    {{ me.userProfile.dateOfBirth }}
                  </span>
                  <v-menu
                    v-else
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider mode="lazy" name="DOB" rules="required" v-slot="{ errors, valid }">
                        <j-text-field
                          v-model="data.date_of_birth"
                          readonly
                          dense
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                          :success="valid"
                        />
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="data.date_of_birth"
                      :max="$moment().subtract(15, 'years').format('YYYY-MM-DD')"
                      min="1920-01-01"
                      @change="save_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Phone: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <span v-if="!edit">
                    {{ formattedPhone }}
                  </span>
                  <ValidationProvider v-else rules="required" name="Phone" v-slot="{ errors }">
                    <vue-tel-input
                      mode="international"
                      :default-country="data.country"
                      v-model="data.phone"
                      v-on:country-changed="countryChanged"
                    ></vue-tel-input>
                    <span v-if="errors.length > 0" class="error--text">The Phone field is required</span>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Suburb: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <span v-if="!edit">
                    {{ me.userProfile.suburb }}
                  </span>
                  <ValidationProvider v-else rules="required" name="Suburb" v-slot="{ errors, valid }">
                    <j-text-field
                      :error-messages="errors"
                      :success="valid"
                      dense
                      ref="suburb"
                      v-model="data.suburb"
                      placeholder=""
                      class="pt-0 mt-0"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Dietary preference: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <j-radio-group class="mt-n1" v-model="selectedPrimaryNutritionPreferences" row :disabled="!edit">
                    <j-radio
                      v-for="primaryNutritionPreference in primaryNutritionPreferences"
                      :key="primaryNutritionPreference.id"
                      :label="primaryNutritionPreference.title"
                      :value="primaryNutritionPreference.id"
                    />
                  </j-radio-group>
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Privacy preference: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <j-radio-group class="mt-n1" v-model="data.showInSearches" row :disabled="!edit">
                    <j-radio label="Show my profile photo and name to others" :value="true" />
                    <j-radio label="Hide me from showing up in searches" :value="false" />
                  </j-radio-group>
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="4" class="font-weight-bold py-0 text-left"> Unread Chats Emails: </v-col>
                <v-col cols="12" md="8" class="py-0 text-left">
                  <j-radio-group class="mt-n1" v-model="data.receiveChatNotifications" row :disabled="!edit">
                    <j-radio label="On" :value="true" />
                    <j-radio label="Off" :value="false" />
                  </j-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
            <j-alert v-if="showErrorAlert" type="warning" icon="mdi-arrow-up" class="mt-4"
              >Please enter more information</j-alert
            >
            <v-card-actions>
              <j-btn tertiary v-if="edit" :disabled="saving" @click="edit = false; showErrorAlert = false;"> Cancel </j-btn>
              <v-spacer />
              <j-btn tertiary v-if="!edit" @click="edit = true"> Edit </j-btn>
              <j-btn primary v-if="edit" :loading="saving" @click="update()"> Update </j-btn>
            </v-card-actions>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { ACCOUNT_QUERY, HANDLE_AVAILABLE_QUERY } from "@/graphql/queries/account";
import { UPDATE_PROFILE_MUTATION } from "@/graphql/mutations/account";
import { formatPhone } from "@/lib/string";
import { userData, genderOptions, dietaryOptions } from "@/lib/user";
import NutritionPreferencesMixin from "@/mixins/nutrition/NutritionPreferencesMixin";

export default {
  name: "PersonalInformation",
  mixins: [NutritionPreferencesMixin],

  data() {
    return {
      error: false,
      errorMessage: "",
      loading: true,
      me: {},
      menu: false,
      errors: null,
      saving: false,
      saved: false,
      edit: false,
      genderOptions: genderOptions,
      dietaryOptions: dietaryOptions,
      data: { ...userData },
      showErrorAlert: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    backLink() {
      if (this.prevRoute != null) {
        return this.prevRoute.fullPath;
      } else {
        return "/account";
      }
    },
    formattedPhone() {
      return formatPhone(this.me.userProfile.phone);
    },
  },
  methods: {
    validate_form() {
      this.$refs.obs.validate();
    },
    countryChanged(country) {
      this.data.country = country.iso2;
    },
    save_date(date) {
      this.$refs.menu.save(date);
    },
    async update() {
      this.showErrorAlert = false;
      let valid = await this.$refs.obs.validate();

      if (!valid) {
        this.showErrorAlert = true;
        return;
      }

      //check that the handle is available
      if (this.data.originalHandle !== this.data.handle) {
        let result = await this.$apollo.query({
          query: HANDLE_AVAILABLE_QUERY,
          variables: {
            handle: this.data.handle,
          },
        });
        if (!result.data.handleAvailable) {
          this.$refs.handleValidationProvider.applyResult({
            errors: ["This handle is not available"],
            valid: false,
            failedRules: {},
          });
          return;
        }
      }

      this.saving = true;
      let userData = {
        dateOfBirth: this.data.date_of_birth,
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        gender: this.data.gender,
        phone: this.data.phone,
        dietaryRequirement: this.data.dietaryRequirement,
        suburb: this.data.suburb,
        country: this.data.country,
        showInSearches: this.data.showInSearches,
        receiveChatNotifications: this.data.receiveChatNotifications,
        biography: this.data.biography,
        handle: this.data.handle,
      };

      await this.$apollo.mutate({
        mutation: UPDATE_PROFILE_MUTATION,
        variables: {
          userProfileInput: userData,
        },
        refetchQueries: ["accountQuery"],
      });

      if (this.selectedPrimaryNutritionPreferences) {
        await this.updateNutritionPreferences();
      }

      this.saving = false;
      this.edit = false;

      if (this.prevRoute && this.prevRoute.fullPath && this.prevRoute.fullPath == "/plan/challenges") {
        this.$router.push("/plan/challenges");
      }
    },
  },
  apollo: {
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data && !response.loading) {
          this.loading = false;
          let userProfile = response.data.me.userProfile;
          let user = response.data.me;
          // overwrite the ID with the non hashed version
          user.id = userProfile.user.id;
          this.data.is_discovery_member = userProfile.isDiscoveryMember;
          this.data.firstName = user.firstName;
          this.data.lastName = user.lastName;
          this.data.gender = userProfile.gender;
          this.data.phone = userProfile.phone;
          this.data.dietaryRequirement = userProfile.dietaryRequirement;
          this.data.date_of_birth = userProfile.dateOfBirth;
          this.data.suburb = userProfile.suburb;
          this.data.country = userProfile.country.code ? userProfile.country.code : "ZA";
          this.data.showInSearches = userProfile.showInSearches;
          this.data.receiveChatNotifications = userProfile.receiveChatNotifications;
          this.data.handle = userProfile.handle;
          this.data.originalHandle = userProfile.handle;
          this.saved = userProfile.isDiscoveryMember;

          this.$store.dispatch("updateUser", user);
        }
      },
    },
  },
};
</script>
