<template>
  <v-card style="position: relative">
    <v-btn icon @click="$emit('close')" style="position: absolute; top: 0; right: 0;">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div color="white" class="d-flex justify-center pb-2 pt-4">
      <j-btn color="secondary" narrow @click="$emit('selectImage', { type: 'before', croppedImage: croppedImage })">
        "Before" image
      </j-btn>
      <j-btn
        color="exercise" 
        narrow
        @click="$emit('selectImage', { type: 'after', croppedImage: croppedImage })"
        class="ml-2"
      >
        "After" image
      </j-btn>
    </div>

    <p class="text-center pt-1 mb-0">Drag rectangle to change size</p>

    <div class="ma-4 pa-1 mt-0">
      <cropper
        class="cropper mt-2 mb-2"
        :src="image"
        :stencil-props="{
          aspectRatio: 1 / 2,
          movable: true,
          resizable: true,
          resizeImage: true,
        }"
        @change="change"
      />
    </div>
  </v-card>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";
import { Cropper } from "vue-advanced-cropper";
export default {
  name: "CropImageCard",
  components: {
    Cropper,
  },
  data() {
    return {
      croppedImage: null,
    };
  },
  props: {
    image: {
      type: String,
      requried: true,
    },
  },
  methods: {
    change({ canvas }) {
      this.croppedImage = canvas.toDataURL();
    },
  },
};
</script>
