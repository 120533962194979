<template>
  <div>
    <div v-show="false">
      <secure-redirect-form ref="redirectForm" :redirect-form="redirectForm" />
    </div>
    <v-row align="center" justify="center">
      <v-col cols="12" md="12">
        <j-banner-container header="Billing Information" md="5" class="my-2">
          <j-alert v-if="undoCancellationAlert" type="success" class="ma-4" dismissible
            >You have successfully undone your cancellation.
            <span v-if="undoCancellationDiscountPercent"
              >Please note that your next payment will be discounted by {{ undoCancellationDiscountPercent }}%.</span
            >
          </j-alert>

          <upsell v-if="subscription && !subscription.endDate" class="mx-3 mt-3" @upsell="upsell" />
          <div class="px-4 px-md-8 py-3 p-text text-left" v-if="subscription">
            <v-row no-gutters>
              <v-col cols="12" md="6" class="font-weight-bold">Current subscription</v-col>
              <v-col cols="12" md="6"
                >{{ subscription.product.name }}
                <span v-if="currentSubscriptionVariant">({{ currentSubscriptionVariant.title }})</span></v-col
              >
            </v-row>

            <v-row no-gutters v-if="nextBillingDate && !subscription.endDate">
              <v-col cols="12" md="6" class="font-weight-bold mt-4">Next payment</v-col>
              <v-col cols="12" md="6" class="mt-0 mt-md-4"> {{ nextBillingDate }}</v-col>
            </v-row>

            <v-row no-gutters v-if="nextPaymentAmount && !subscription.endDate">
              <v-col cols="12" md="6" class="font-weight-bold mt-4">Next payment amount</v-col>
              <v-col cols="12" md="6" class="mt-0 mt-md-4">R {{ nextPaymentAmount }}</v-col>
            </v-row>

            <v-row no-gutters v-if="card">
              <v-col cols="12" md="6" class="font-weight-bold mt-4">Current card</v-col>
              <v-col cols="12" md="6" class="mt-0 mt-md-4"> •••• •••• •••• {{ card.lastDigits }} </v-col>
            </v-row>
          </div>
          <v-card-text>
            <j-alert v-if="subscription && subscription.endDate">
              Your current subscription ends on {{ subscription.endDate | moment("D MMMM YYYY") }}
              <span v-if="subscription.endDate && subscription.transitionProductVariant">
                and will transition to {{ subscription.transitionProductVariant.product.title }} ({{
                  subscription.transitionProductVariant.title
                }}) on this date.
              </span>
              <br /><br />
              <j-btn type="secondary" @click="undoTransition()">Undo this</j-btn>
              <br />
              <j-btn class="mt-4" type="secondary" @click="manageSubscription()">Change product</j-btn>
            </j-alert>

            <h2 class="navy--text mb-4">What would you like to do?</h2>

            <ul class="p-text text-left pointer">
              <li @click="showUpdateCard()" class="text-decoration-underline navy--text font-weight-bold mb-2">
                Update your credit card
              </li>
              <li
                v-if="hasPeachOrder"
                @click="manageSubscription()"
                class="text-decoration-underline navy--text font-weight-bold mb-2"
              >
                Manage your subscription
              </li>
              <li
                v-else
                @click="cancelDialog = true"
                class="text-decoration-underline navy--text font-weight-bold mb-2"
              >
                <span v-if="hasTaskEvents">Request submitted</span>
                <span v-else>Manage subscription</span>
              </li>
              <li @click="$router.push('/rewards/points/use')">
                <div class="text-decoration-underline navy--text font-weight-bold">
                  Use points / discount next payment / create voucher
                </div>
                <small
                  >Please note that you can only use points to discount your next payment
                  <strong>once per month</strong></small
                >
              </li>
            </ul>
          </v-card-text>
        </j-banner-container>
      </v-col>
    </v-row>

    <!--manage card details -->
    <v-row align="center" justify="center" v-if="showCardDetails" class="px-2 mb-2">
      <v-col cols="12" md="6" xl="5">
        <j-alert type="info"
          >Please note that when updating your card details we are required to perform a R1 transaction on your account
          to obtain authorization from your bank.</j-alert
        >
        <j-card class="pt-4">
          <validation-observer ref="observer">
            <card-details :card-details="cardDetails" />
            <v-card-text class="pt-0">
              <v-card-actions class="d-flex flex-wrap">
                <j-btn tertiary @click="close()">
                  <strong>Cancel</strong>
                </j-btn>
                <v-spacer />
                <j-btn class="mt-4 mt-md-0" color="secondary" @click="updateCard" :loading="updatingCard">
                  <strong class="white--text"><span v-if="card">Update</span><span v-else>Add</span> card</strong>
                </j-btn>
              </v-card-actions>
            </v-card-text>
          </validation-observer>
        </j-card>
      </v-col>
    </v-row>

    <!-- transition sub -->
    <v-row align="center" justify="center" v-if="showBillingTransitionPanel">
      <v-col cols="12" md="6">
        <j-card class="pa-4">
          <p class="p-text" v-if="nextBillingDate && !subscription.endDate">
            You are paid up until {{ nextBillingDate }}.
          </p>

          <div v-if="hasPeachOrder">
            <h4 class="mb-2 darker-grey--text">Want to change product?</h4>
            <v-radio-group v-model="changeSubscription">
              <j-radio v-for="option in subscriptionChangeOptions" :key="option.value" :value="option.value">
                <template v-slot:label>
                  <div v-html="option.text"></div>
                </template>
              </j-radio>
            </v-radio-group>
            <j-btn secondary @click="openTransitionProductDialog">Change Product</j-btn>
            <h4 class="mt-6 mb-2 darker-grey--text">
              <span
                v-if="
                  canChangeBillingPeriod ||
                  ($store.getters.role === 'CLUB' && currentSubscriptionVariant.code != 'standard-jeff-club')
                "
                >Want to change billing or cancel your subscription?</span
              >
              <span v-else>Want to cancel your subscription?</span>
            </h4>
            <ul class="p-text">
              <li v-if="canChangeBillingPeriod" class="mb-3">
                <a class="text-decoration-underline" @click="openTransitionProductDialog(true)" color="secondary"
                  >Change to 1, 3, 6, 12 month billing (discounts)</a
                >
              </li>
              <li
                v-if="$store.getters.role === 'CLUB' && currentSubscriptionVariant.code != 'standard-jeff-club'"
                class="mb-3"
              >
                <a
                  class="text-decoration-underline"
                  @click="
                    changeSubscription = 'jeff-club';
                    openTransitionProductDialog();
                  "
                  color="secondary"
                  >Change to monthly billing</a
                >
              </li>
              <!-- <li class="mb-3">
                <a class="text-decoration-underline" @click="confirmCancellation()" color="secondary"
                  >Cancel my subscription completely</a
                >
              </li> -->
            </ul>
          </div>

          <h4 class="mt-6 mb-2 darker-grey--text">Need help?</h4>
          <ul class="p-text">
            <li class="mb-3">
              <router-link to="/help" class="text-decoration-underline">Contact support team</router-link>
            </li>
          </ul>

          <a
            v-if="!hasPeachOrder && offboardingLink"
            :href="offboardingLink"
            target="_blank"
            class="p-text"
            color="secondary "
            >Cancel your subscription</a
          >
        </j-card>
      </v-col>
    </v-row>

    <!-- transition success -->
    <v-row align="center" justify="center" v-if="transitionSuccess">
      <v-col cols="12" md="6">
        <j-alert type="success">Your subscription will be updated on {{ transitionEndDate }}</j-alert>
      </v-col>
    </v-row>

    <!-- transition dialog -->
    <v-dialog v-model="transitionDialog" width="600" transition="dialog-bottom-transition" class="limited-height">
      <transition-subscription-dialog
        v-if="billingProducts"
        :billing-products="billingProducts"
        :selected-product="selectedProduct"
        :end-date="transitionEndDate"
        :current-variant="currentSubscriptionVariant"
        @confirm="transitionSubscription"
        @closed="transitionDialog = false"
      />
    </v-dialog>
    <!-- cancel dialog -->
    <v-dialog v-model="cancelDialog" width="600" transition="dialog-bottom-transition" class="limited-height">
      <cancel-subscription-dialog
        :loading="loading"
        :hasPeachOrder="hasPeachOrder"
        :end-date="nextBillingDate"
        @confirm="cancelSubscription"
        @closed="cancelDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ACCOUNT_QUERY } from "@/graphql/queries/account";
import { BILLING_PRODUCTS_QUERY } from "@/graphql/queries/store";
import {
  TRANSITION_SUBSCRIPTION_MUTATION,
  LINK_DISCOUNT_TO_CURRENT_SUBSCRIPTION,
  REOPEN_SUBSCRIPTION_MUTATION,
} from "@/graphql/mutations/account";
import { UPSERT_SUBSCRIPTION_SURVEY_MUTATION } from "@/graphql/mutations/survey";
import { CREATE_TASK_EVENT_MUTATION } from "@/graphql/mutations/task";
import { CARD_UPDATE_MUTATION_3DS2 } from "@/graphql/mutations/store";
import { TASKS_QUERY } from "@/graphql/queries/task";
import Upsell from "@/components/account/Upsell";
import CardDetails from "@/components/store/blocks/CardDetails";
import TransitionSubscriptionDialog from "@/components/account/dialogs/TransitionSubscriptionDialog";
import CancelSubscriptionDialog from "@/components/account/dialogs/CancelSubscriptionDialog";
import SecureRedirectForm from "@/views/store/SecureRedirectForm";
import { extractNodes } from "@/lib/array";

export default {
  name: "BillingInformation",
  components: {
    Upsell,
    CardDetails,
    TransitionSubscriptionDialog,
    CancelSubscriptionDialog,
    SecureRedirectForm,
  },
  data() {
    return {
      loading: true,
      taskLoading: true,
      showCardDetails: false,
      me: {},
      subscription: null,
      hasPeachOrder: true,
      card: null,
      cardDetails: {
        number: "",
        cvv: "",
        holder: "",
        expiryMonth: null,
        expiryYear: null,
        brand: "",
      },
      showBillingTransitionPanel: false,
      transitionDialog: false,
      transitionSuccess: false,
      undoCancellationAlert: false,
      undoCancellationDiscountPercent: null,
      cancelDialog: false,
      selectedProduct: null,
      transitionTomorrow: false,
      updatingCard: false,
      changeSubscription: null,
      redirect: null,
      redirectForm: {
        url: "",
        parameters: [],
      },
      taskEventData: {
        userId: null,
        title: "Payfast Cancellation",
        description: "Payfast Cancellation",
        taskTypeTitle: "Support",
        files: [],
      },
    };
  },
  created() {
    const redirect = this.$route.query.r;
    if (redirect === "cancel") {
      this.cancelDialog = true;
      return history.pushState({}, null, this.$route.path);
    }
    if (redirect?.includes("jeff")) {
      this.changeSubscription = redirect;
      this.showBillingTransitionPanel = true;
      this.openTransitionProductDialog();
    }

    const undoCancellation = this.$route.query.udc;
    if (undoCancellation) {
      this.undoTransition();
      this.undoCancellationAlert = true;
      this.undoCancellationDiscountPercent = this.$route.query.dcp;
      return history.pushState({}, null, this.$route.path);
    }
  },
  computed: {
    subscriptionChangeOptions() {
      let options = [];
      if (this.currentSubscriptionVariant?.product?.code !== "jeff-club") {
        if (this.$store.getters.role !== "ONE_ON_ONE") {
          options.push({ text: "Change to <strong>JEFF Club</strong> tomorrow", value: "jeff-club_tomorrow" });
        }
        if (this.nextBillingDate) {
          options.push({ text: "Move to the <strong>free version</strong> on " + this.nextBillingDate, value: "jeff-club" });
        }
      }

      if (this.currentSubscriptionVariant?.product?.code !== "1-on-1") {
        options.push({
          text: "Change to <strong>1-on-1 Daily Coaching</strong> tomorrow",
          value: "1-on-1_tomorrow",
        });
        if (this.nextBillingDate) {
          options.push({
            text: "Move to <strong>1-on-1 Daily Coaching</strong> on " + this.nextBillingDate,
            value: "1-on-1",
          });
        }
      }

      if (this.currentSubscriptionVariant?.product?.code !== "1-on-1-grad") {
        if (this.$store.getters.role !== "ONE_ON_ONE") {
          options.push({
            text: "Change to <strong>1-on-1 Weekly Coaching</strong> tomorrow",
            value: "1-on-1-grad_tomorrow",
          });
        }
        if (this.nextBillingDate) {
          options.push({
            text: "Move to <strong>1-on-1 Weekly Coaching</strong> on " + this.nextBillingDate,
            value: "1-on-1-grad",
          });
        }
      }

      return options;
    },
    tomorrow() {
      return this.$moment().add(1, "days").format("YYYY-MM-DD");
    },
    currentSubscriptionVariant() {
      if (this.subscription?.billingOrder?.subscriptionProductVariant) {
        return this.subscription.billingOrder.subscriptionProductVariant;
      }
      return null;
    },
    offboardingLink() {
      if (!this.subscription) {
        return null;
      }
      if (this.subscription.product.type == "JEFF_MEMBER") {
        return "https://www.jeff.fitness/pages/jeff-life-offboarding";
      }
      if (this.subscription.product.type == "CLUB") {
        return "https://jeff-fitness.typeform.com/to/JXhwSgpD";
      }
      return "https://jeff-fitness.typeform.com/to/mt7y6g2g";
    },
    nextBillingDate() {
      if (this.subscription?.billingOrder?.nextBillingDate) {
        return this.$moment(this.subscription.billingOrder.nextBillingDate).format("D MMMM YYYY");
      }
      return "";
    },
    nextPaymentAmount() {
      return this.subscription?.billingOrder?.nextPaymentAmount;
    },
    transitionEndDate() {
      if (this.transitionTomorrow) {
        return this.$moment(this.tomorrow).format("D MMMM YYYY");
      }
      return this.nextBillingDate;
    },
    canChangeBillingPeriod() {
      if (this.$store.getters.role !== "ONE_ON_ONE") {
        return false;
      }

      // convoluted way to check whether a user can switch to other billing periods.
      // if all are hidden except one and the user is on this one, then they can't switch.
      if (!this.currentSubscriptionVariant?.product || !this.billingProducts) {
        return false;
      }
      // get the product the user is on.
      const product = this.billingProducts.edges.find((obj) => {
        return obj.node.code == this.currentSubscriptionVariant.product.code;
      });
      const variants = product.node?.productvariantSet?.edges.filter((variant) => !variant.node.isHidden);

      return !(variants?.length == 1 && this.currentSubscriptionVariant.code == variants[0]?.node.code);
    },
    hasTaskEvents() {
      if (!this.taskEvents) return false;
      return this.taskEvents.length > 0;
    },
  },
  methods: {
    scrollBottom() {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight || document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    },
    showUpdateCard() {
      this.showCardDetails = true;
      this.showBillingTransitionPanel = false;
      this.transitionSuccess = false;
      this.scrollBottom();
    },
    upsell() {
      if (this.hasPeachOrder) {
        this.manageSubscription();
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight || document.documentElement.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
        }, 200);
        return null;
      }
      return this.$router.push(`/checkout/1-on-1`);
    },
    async redirectToSubscriptionSurvey(surveyType) {
      const result = await this.$apollo.mutate({
        mutation: UPSERT_SUBSCRIPTION_SURVEY_MUTATION,
        variables: {
          surveyType: surveyType,
        },
      });
      this.loading = false;
      const subscriptionSurveyId = result.data?.upsertSubscriptionSurveyMutation?.subscriptionSurveyId;
      if (subscriptionSurveyId) {
        this.$router.push(`/survey/${subscriptionSurveyId}?r=${this.redirect}`);
      }
    },
    confirmCancellation() {
      this.redirect = "cancel";
      if (this.$store.getters.role == "CLUB") {
        return this.redirectToSubscriptionSurvey("Club_Cancel");
      }
      if (this.$store.getters.role == "JEFF_MEMBER") {
        return this.redirectToSubscriptionSurvey("Life_Cancel");
      }
      if (this.$store.getters.role == "ONE_ON_ONE") {
        return this.redirectToSubscriptionSurvey("1_on_1_Cancel");
      }
      this.cancelDialog = true;
    },
    manageSubscription() {
      this.showBillingTransitionPanel = true;
      this.transitionSuccess = false;
      this.showCardDetails = false;
      this.scrollBottom();
    },
    openTransitionProductDialog(resetRadioButtons = false, forceTransitionTomorrow = false) {
      if (resetRadioButtons === true) this.changeSubscription = null;

      //only 1-on-1's can change billing cycle.
      let subscription = this.changeSubscription ?? "1-on-1";

      // Club user is transitioning to life. Force survey.
      // if we already have a r query string, they've already done this and we need to fall through.
      if (
        !this.$route.query.r &&
        this.changeSubscription &&
        this.$store.getters.role === "CLUB" &&
        this.changeSubscription.includes("jeff-life")
      ) {
        this.redirect = this.changeSubscription;
        return this.redirectToSubscriptionSurvey("Club_Cancel");
      }

      // jeff-life or jeff-club
      if (
        !this.$route.query.r &&
        this.changeSubscription &&
        this.$store.getters.role === "ONE_ON_ONE" &&
        this.changeSubscription.includes("jeff")
      ) {
        this.redirect = this.changeSubscription;
        return this.redirectToSubscriptionSurvey("1_on_1_Cancel");
      }

      let titles = {
        "jeff-life": "JEFF Life",
        "1-on-1": "1-on-1 Daily Coaching",
        "1-on-1-grad": "1-on-1 Weekly Coaching",
        "jeff-club": "JEFF Club",
      };

      let params = subscription.split("_");
      let transitionTomorrow = params.length > 1 || forceTransitionTomorrow;
      let selectedProduct = {
        code: params[0],
        title: titles[params[0]],
      };

      this.transitionTomorrow = transitionTomorrow;
      this.selectedProduct = selectedProduct;
      this.transitionDialog = true;
      history.pushState({}, null, this.$route.path);
    },
    close() {
      this.cardDetails = {
        number: "",
        cvv: "",
        holder: "",
        expiryMonth: null,
        expiryYear: null,
        brand: "",
      };

      if (this.$refs.observer) this.$refs.observer.reset();
      this.showCardDetails = false;
    },
    async transitionSubscription(data) {
      this.transitionDialog = false;
      this.showBillingTransitionPanel = false;

      const result = await this.$apollo.mutate({
        mutation: TRANSITION_SUBSCRIPTION_MUTATION,
        variables: {
          productVariantCode: data.variantCode,
          endDate: this.transitionTomorrow ? this.tomorrow : null,
          recurringDiscountCode: data.recurringDiscountCode,
          transitionCoachId: data.transitionCoachId,
        },
      });

      if (data.discountCode) {
        await this.$apollo.mutate({
          mutation: LINK_DISCOUNT_TO_CURRENT_SUBSCRIPTION,
          variables: {
            discountCode: data.discountCode,
            productVariantCode: data.variantCode,
          },
        });
      }

      await this.$apollo.queries.me.refetch();

      this.transitionSuccess = result.data.transitionSubscriptionMutation.success;
    },
    async cancelSubscription() {
      this.cancelDialog = false;
      this.showBillingTransitionPanel = false;

      if (this.hasPeachOrder) {
        const result = await this.$apollo.mutate({
          // no variant, so we transition with no variant which is a cancel.
          mutation: TRANSITION_SUBSCRIPTION_MUTATION,
        });
        await this.$apollo.queries.me.refetch();
        this.transitionSuccess = result.data.transitionSubscriptionMutation.success;
      } else {
        await this.createTask();
      }
    },
    async undoTransition() {
      await this.$apollo.mutate({
        mutation: REOPEN_SUBSCRIPTION_MUTATION,
      });
      await this.$apollo.queries.me.refetch();
      this.transitionSuccess = false;
    },
    async updateCard() {
      let formValid = await this.$refs.observer.validate();
      if (!formValid) return;

      let brands = {
        visa: "VISA",
        mastercard: "MASTER",
      };

      let card = {
        holder: this.cardDetails.holder,
        cvv: this.cardDetails.cvv,
        number: this.cardDetails.number.replace(/\s/g, ""),
        brand: brands[this.cardDetails.brand] || "PRIVATE_LABEL",
        expiry: ["20" + this.cardDetails.expiryYear, this.cardDetails.expiryMonth, "01"].join("-"),
      };

      this.updatingCard = true;
      let tokenizationResult = await this.$apollo.mutate({
        mutation: CARD_UPDATE_MUTATION_3DS2,
        variables: {
          input: {
            card: card,
            isDefaultCard: true,
            meta: {
              "customer.browser.language": navigator.language,
              "customer.browser.screenHeight": screen.height,
              "customer.browser.screenWidth": screen.width,
              "customer.browser.timezone": new Date().getTimezoneOffset(),
              "customer.browser.userAgent": navigator.userAgent,
              "customer.browser.javaEnabled": false,
              "customer.browser.javascriptEnabled": true,
              "customer.browser.screenColorDepth": screen.colorDepth,
              "customer.browser.challengeWindow": "05",
            },
          },
        },
        refetchQueries: ["accountQuery"],
      });
      if (tokenizationResult.data.tokenizeCardMutation3ds2.success) {
        if (tokenizationResult.data.tokenizeCardMutation3ds2.redirect) {
          // we need to activate 3dsecure
          this.redirectForm = JSON.parse(tokenizationResult.data.tokenizeCardMutation3ds2.redirect);
          this.$refs.redirectForm.submit();
        }
      }
    },
    async createTask() {
      this.taskEventData.userId = this.$store.getters.user ? this.$store.getters.user.id : null;
      this.loading = true;

      const { data } = await this.$apollo.mutate({
        mutation: CREATE_TASK_EVENT_MUTATION,
        variables: {
          data: this.taskEventData,
        },
        refetchQueries: ["taskEvents"],
      });

      if (data.createTaskEvent.created) {
        this.$toasted.success("Request submitted.", {
          duration: 2000,
          position: "bottom-center",
        });
      } else {
        this.$toasted.error(data.createTaskEvent.error, {
          duration: 2000,
          position: "bottom-center",
        });
      }
      this.loading = false;
    },
  },
  apollo: {
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        this.hasPeachOrder = false;

        if (response.data) {
          this.loading = false;
        }
        this.subscription = response.data.subscription;
        if (this.subscription && this.subscription.billingOrder) {
          this.hasPeachOrder = true;
        }
        if (response.data.tokenizedCard) {
          this.card = response.data.tokenizedCard;
        }
        if (this.$route.query.coaching && this.hasPeachOrder) {
          this.showBillingTransitionPanel = true;
          //force this peach order to transition tomorrow
          this.openTransitionProductDialog(true, true);
        }
      },
    },
    billingProducts: {
      query: BILLING_PRODUCTS_QUERY,
      variables() {
        return {
          isRecurring: true,
          withPackages: false,
          status: "ACTIVE",
        };
      },
      fetchPolicy: "no-cache",
    },
    taskEvents: {
      query: TASKS_QUERY,
      variables() {
        return {
          userId: this.$store.getters.user ? btoa(`UserNode: ${this.$store.getters.user.id}`) : null,
          taskTypeTitle: "Cancellation Request",
        };
      },
      update(data) {
        this.taskLoading = false;
        return extractNodes(data.taskEvents.edges);
      },
    },
  },
};
</script>
