<template>
  <div class="fill-height">
    <transition v-if="chat" name="fade">
      <div v-show="showStickyHeader" class="sticky-header">
        <simple-chat-header :chat="chat" show-back classes="justify-center sticky-header-content" />
      </div>
    </transition>

    <div v-if="!$apollo.loading && !chat" class="d-flex py-6 justify-space-around">
      <j-alert type="error">You do not have permission to view this chat</j-alert>
    </div>

    <j-banner-container
      v-if="chat"
      :header="chat.title"
      :header-image="chat.image"
      class="fill-height"
    >
      <div class="flex-fill d-flex flex-column">
        <div>
          <j-btn class="my-6" v-if="hasPreviousPage" small tertiary narrow @click="loadPrevious">
            Previous Messages
          </j-btn>
          <j-btn
            class="my-6 ml-2"
            tertiary
            narrow
            @click="
              scrollBottomEnabled = true;
              scrollBottom();
            "
            small
          >
            <v-icon>mdi-arrow-down-bold-circle-outline</v-icon>
          </j-btn>
        </div>
        <div class="flex-fill">
          <div v-for="(message, index) in messages" :key="message.id">
            <div v-if="showDayLabel(message, index)" cols="12">
              <v-chip class="my-2">
                <span class="sm-text">{{ getDayLabel(message) }}</span>
              </v-chip>
            </div>
            <div class="d-flex mx-4 my-2">
              <chat-message-view
                :message="message"
                @delete="deleteMessage($event)"
                @reply="replyToMessage($event)"
                @choose-reaction="chooseReaction"
                :class="sentBySelf(message) ? 'ml-auto' : 'mr-auto'"
                :hide-options="chat.type === 'ASSISTANT'"
              />
            </div>
          </div>
          <div v-if="waitingForAssistantResponse" class="d-flex justify-center align-center">
            <div class="sm-text mr-2 font-italic">{{ virtualCoachName }} is typing</div>
            <div class="loader"></div>
          </div>
        </div>

        <div v-if="loading" class="mt-2 px-4">
          <upload-progress :upload-percent-completed="uploadPercentCompleted" />
        </div>

        <div
          v-if="scheduledMessages.length > 0"
          class="d-flex justify-end mr-4"
          @click="showScheduledMessagesForChat = !showScheduledMessagesForChat"
        >
          <v-chip color="mid-grey" class="white--text pointer mw-200">
            See <span class="font-weight-bold mx-1">{{ scheduledMessages.length }}</span> scheduled message<span
              v-if="scheduledMessages.length != 1"
              >s</span
            ></v-chip
          >
        </div>
        <div v-if="showScheduledMessagesForChat">
          <div class="flex-fill">
            <div v-for="message in scheduledMessages" :key="message.id">
              <div class="d-flex mx-4 my-2">
                <chat-message-view
                  :message="message"
                  @delete="deleteMessage($event)"
                  @reply="replyToMessage($event)"
                  :class="sentBySelf(message) ? 'ml-auto' : 'mr-auto'"
                />
              </div>
            </div>
          </div>
        </div>

        <div :class="replyMessage ? 'is-replying' : ''">
          <message-reply-card
            v-if="replyMessage"
            :parent="replyMessage"
            dismissible
            @dismiss="replyMessage = null"
            class="mx-2 mt-6 mb-2"
          />
          <j-alert
            v-if="chat.type === 'COACH' && inActiveStaff"
            type="warning"
            icon="mdi-lightbulb-on-outline"
            class="mx-4"
          >
            <span
              >{{ inActiveStaff }} {{ inActiveStaff.includes(",") ? "are" : "is" }} no longer working at {{ $appName }}.
              Please reach out on your support chat!
            </span>
          </j-alert>

          <j-alert v-else-if="isOutOfOffice" type="warning" icon="mdi-lightbulb-on-outline" class="mx-4">
            <span v-html="outOfOfficeMessage"></span>
          </j-alert>

          <div class="d-flex px-2 pt-4">
            <j-emoji-textarea
              v-model="text"
              auto-grow
              dense
              rows="1"
              :counter="showMessageCounter"
              :rules="[textRule]"
              placeholder="Message"
              ref="textRef"
              class="flex-grow-1"
              @input="scrollBottomEnabled = false"
              @keydown.enter.exact.prevent="submit"
              :disabled="waitingForAssistantResponse"
            />
            <j-btn v-if="text || hasAttachments" tertiary class="ml-2" narrow :disabled="loading" @click="submit">
              <v-icon size="25">mdi-send</v-icon>
            </j-btn>
          </div>
        </div>

        <j-alert v-if="chat.type === 'ASSISTANT'" type="info" class="mx-2 p-text"
          >{{ virtualCoachName }} is your Virtual Coach 🙌. Please write fully formed questions about nutrition or exercise. The more information
          you provide, the better the answers will be 😊.</j-alert
        >

        <div v-if="chat.type !== 'ASSISTANT'">
          <v-row v-if="$store.getters.user.isStaff" class="mt-n5">
            <v-col cols="12" class="text-left mt-n6">
              <j-checkbox
                v-model="sendEmailNotification"
                label="Send chat members an email notification of this message"
                hide-details
              />
            </v-col>
            <v-col cols="12" class="text-left mt-n8 mb-4">
              <j-checkbox v-model="scheduleMessage" label="Schedule this message for a future date" hide-details />
            </v-col>
            <v-col v-if="scheduleMessage" cols="12" md="8" class="text-left mt-n8 mb-4">
              <date-time-picker @changed="scheduleMessageDateTime = $event" class="mt-1" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pt-0 pb-0 mb-0">
              <div class="d-flex">
                <v-avatar v-if="!isAudioRecording" color="rgb(0, 0, 0, 0.1)" size="40">
                  <v-icon @click="$refs.multipleImageUploader.clickUploader()">mdi-camera-plus</v-icon>
                </v-avatar>
                <audio-recorder
                  class="ml-2"
                  @sendAudio="saveAudioFile($event)"
                  @startRecording="startAudioRecording()"
                  @stopRecording="stopAudioRecording()"
                />
                <video-recorder v-if="!isAudioRecording" class="ml-2" @sendVideo="saveVideoFile($event)" />
                <file-uploader
                  v-if="!isAudioRecording"
                  class="ml-2"
                  @sendFile="saveFile($event)"
                  @error="showError($event)"
                />
                <v-avatar class="ml-2" color="rgb(0, 0, 0, 0.1)" size="40">
                  <v-icon @click="showGiphy = !showGiphy"> mdi-sticker-emoji</v-icon>
                </v-avatar>
              </div>
            </v-col>
            <v-col v-if="errorMessage">
              <j-alert>{{ errorMessage }}</j-alert>
            </v-col>
          </v-row>
        </div>

        <v-row v-show="hasAttachments" class="py-0 my-0">
          <v-col cols="12" class="mx-0 px-0" align="center" justify="center">
            <multiple-image-uploader ref="multipleImageUploader" @setImage="saveImageFiles($event)" />
          </v-col>
        </v-row>
        <v-row v-if="pdfFile" class="px-0 mx-0">
          <v-card tile class="rounded ma-2 d-flex relative">
            <v-btn icon color="navy" class="clear-photo-btn">
              <v-icon small @click="pdfFile = null">mdi-close</v-icon>
            </v-btn>
            <v-card-text> <v-icon color="red" x-large>mdi-file-pdf-box</v-icon></v-card-text>
          </v-card>
        </v-row>

        <!-- only show preview if loading it via a paperclip attachment -->
        <v-row v-if="!loading && videoFile" class="px-0 mx-0">
          <v-card tile class="rounded ma-2 d-flex relative">
            <v-btn icon color="navy" class="clear-photo-btn">
              <v-icon small @click="videoFile = null">mdi-close</v-icon>
            </v-btn>
            <v-card-text> <v-icon color="green" x-large>mdi-video</v-icon></v-card-text>
          </v-card>
        </v-row>

        <v-row v-if="!loading && audioFile" class="px-0 mx-0">
          <v-card tile class="rounded ma-2 d-flex relative">
            <v-btn icon color="navy" class="clear-photo-btn">
              <v-icon small @click="audioFile = null">mdi-close</v-icon>
            </v-btn>
            <v-card-text> <v-icon color="green" x-large>mdi-microphone</v-icon></v-card-text>
          </v-card>
        </v-row>
      </div>
      <giphy v-if="!loading && showGiphy" @giphy-selected="updateGif" />
    </j-banner-container>

    <!-- new chat dialog -->
    <v-dialog
      v-model="newChatDialog"
      persistent
      max-width="500"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <new-chat :chat="chat" @closed="newChatDialog = false" />
    </v-dialog>

    <!-- Emoji reaction picker -->
    <v-dialog
      v-model="emojiPicker.open"
      max-width="500"
      transition="dialog-bottom-transition"
      class="limited-height"
      scrollable
    >
      <v-card class="pa-5 emoji-v-card">
        <v-card-text class="emoji-v-card-text">
          <simple-emoji-picker @chooseEmoji="insert" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { decodeId } from "@/lib/string";
import { mapGetters } from "vuex";
import { extractNodes } from "@/lib/array";
import { debounce } from "@/lib/helpers";
import { MESSAGES_FOR_CHAT, CHAT } from "@/graphql/queries/chat";
import MultipleImageUploader from "@/components/shared/MultipleImageUploader";
import { blobToDataURL } from "@/lib/file";
import {
  CREATE_MESSAGE_ATTACHMENTS_MUTATION,
  UPDATE_USER_CHAT_LAST_VIEWED_TIMESTAMP_MUTATION,
} from "@/graphql/mutations/chat";
import AudioRecorder from "@/components/chat/AudioRecorder";
import VideoRecorder from "@/components/chat/VideoRecorder";
import FileUploader from "@/components/chat/FileUploader";
import ChatMessageView from "@/components/chat/ChatMessageView";
import SimpleChatHeader from "@/components/chat/SimpleChatHeader";
import NewChat from "@/components/chat/dialogs/NewChat";
import MessageReplyCard from "@/components/chat/MessageReplyCard";
import Giphy from "@/components/chat/Giphy";
import CloudinaryMixin from "@/mixins/CloudinaryMixin";
import ChatMixin from "@/mixins/ChatMixin";
import UploadProgress from "@/components/shared/blocks/UploadProgress";
import DateTimePicker from "@/components/shared/DateTimePicker";
import SimpleEmojiPicker from "@/components/chat/plugins/SimpleEmojiPicker";
import { nl2br } from "@/lib/string";

export default {
  name: "ChatView",
  mixins: [CloudinaryMixin, ChatMixin],
  components: {
    MultipleImageUploader,
    AudioRecorder,
    VideoRecorder,
    ChatMessageView,
    FileUploader,
    SimpleChatHeader,
    NewChat,
    MessageReplyCard,
    Giphy,
    UploadProgress,
    DateTimePicker,
    SimpleEmojiPicker,
  },
  data() {
    return {
      loading: false,
      socket: null,
      messages: [],
      text: null,
      textRule: (v) => v == null || v.length <= 500 || "Max 500 characters",
      before: "",
      startCursor: "",
      hasPreviousPage: true,
      imageUploadFailure: false,
      imageFiles: null,
      audioFile: null,
      videoFile: null,
      pdfFile: null,
      gif: null,
      audioTime: null,
      videoTime: null,
      lastTimeStampInterval: null,
      errorMessage: null,
      forceSocketRefresh: true,
      scrollBottomEnabled: true,
      // we want to hide other controls if audio recording
      isAudioRecording: false,
      scrollY: 0,
      showStickyHeader: false,
      newChatDialog: false,
      replyMessage: null,
      sendEmailNotification: false,
      showGiphy: false,
      scheduleMessage: false,
      scheduleMessageDateTime: null,
      scheduledMessages: [],
      showScheduledMessagesForChat: false,
      waitingForAssistantResponse: false,
      emojiPicker: {
        search: "",
        open: false,
        message: null,
      },
      inActiveStaff: null,
    };
  },
  props: {
    chatId: {
      type: [String, Number],
      required: true,
    },
  },
  updated() {
    this.scrollBottom();
  },
  async created() {
    await this.createSocket();
    this.onOpen();
    this.getMessages();
    this.onClose();
    this.onError();

    // listen to scroll event and store in variable. Every x ms, we add it to the querystring.
    window.addEventListener("scroll", () => {
      this.scrollY = scrollY;
      debounce(this.handleScroll, 100);
    });

    // update on page load before creating poll
    await this.$apollo.mutate({
      mutation: UPDATE_USER_CHAT_LAST_VIEWED_TIMESTAMP_MUTATION,
      variables: {
        chatId: this.chatId,
      },
      refetchQueries: ["notificationQuery"],
    });

    //create a poll to update the last viewed timestamp
    this.lastTimeStampInterval = setInterval(() => {
      if (document.hidden) return;
      this.$apollo.mutate({
        mutation: UPDATE_USER_CHAT_LAST_VIEWED_TIMESTAMP_MUTATION,
        variables: {
          chatId: this.chatId,
        },
        refetchQueries: ["notificationQuery"],
      });
    }, 1000 * 60);
  },
  beforeRouteLeave(to, from, next) {
    if (this.lastTimeStampInterval) clearTimeout(this.lastTimeStampInterval);
    if (this.socket) {
      this.forceSocketRefresh = false;
      this.socket.onclose = function () {};
      this.socket.close();
    }
    next();
  },
  computed: {
    ...mapGetters(["user"]),
    virtualCoachName() {
      return this.$store.getters.settings.get("CHAT")?.get("virtual-assistant-first-name") ?? "Virtual Coach";
    },
    isOutOfOffice() {
      if (decodeId(this.user.userProfile?.coachingChat?.id) !== decodeId(this.chat.id)) return;
      if (!this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod) return;

      const startDate = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod?.startDate;
      const endDate = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod?.endDate;

      return (
        this.$moment(startDate).isSameOrBefore(this.$moment()) && this.$moment(endDate).isSameOrAfter(this.$moment())
      );
    },
    outOfOfficeMessage() {
      const coach = this.$store.getters.user?.userProfile?.coach?.firstName;
      const startDate = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod?.startDate;
      const endDate = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod?.endDate;
      const outOfOfficeMessage = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod.outOfOffice;
      const defaultMessage = `${coach} is on leave from ${this.$moment(startDate).format("D MMM")} to ${this.$moment(
        endDate
      ).format("D MMM")}`;

      return nl2br(outOfOfficeMessage) || defaultMessage;
    },
    uploadedVideoURLasDataObject() {
      return URL.createObjectURL(new Blob([this.videoFile], { type: "video/mp4" }));
    },
    uploadedAudioURLasDataObject() {
      return URL.createObjectURL(new Blob([this.audioFile], { type: "audio/wav" }));
    },
    showMessageCounter() {
      return this.text?.length > 500;
    },
    hasAttachments() {
      return this.imageFiles || this.audioFile || this.videoFile || this.pdfFile || this.gif;
    },
  },
  methods: {
    handleScroll() {
      this.scrollBottomEnabled = false;
      this.showStickyHeader = this.scrollY > 200;
    },
    startAudioRecording() {
      this.isAudioRecording = true;
    },
    stopAudioRecording() {
      this.isAudioRecording = false;
    },
    async createSocket() {
      let endPoint = process.env.VUE_APP_API_ENDPOINT;
      endPoint = endPoint.replace("https://", "wss://");
      endPoint = endPoint.replace("http://", "ws://");
      this.socket = await new WebSocket(`${endPoint}/ws/chat/${this.chatId}`);
    },
    scrollBottom(behaviour = "auto") {
      if (this.scrollBottomEnabled) {
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight || document.documentElement.scrollHeight,
            behavior: behaviour,
          });
        }, 200);
      }
    },
    // get messages via websocket
    getMessages() {
      this.socket.onmessage = (event) => {
        this.scheduleMessage = false;

        const data = JSON.parse(event.data);
        if (data.action_type === "CREATE") {
          // we may get a incoming websocket message that is scheduled by a user. Only show if the logged in user is the owner
          if (this.isScheduled(data) && data.user?.id == this.$store.getters.user.id) {
            return this.scheduledMessages.push(data);
          } else if (this.isScheduled(data)) {
            return;
          }

          // previously scheduled message, that is now coming in via websockets and needs removal from scheduledMessages Array
          this.scheduledMessages = this.scheduledMessages.filter((msg) => decodeId(msg.id) != decodeId(data.id));

          this.messages.push(data);
          if (data.user?.id == this.$store.getters.user.id) {
            // incoming message is one that I sent
            new Audio(require("@/assets/audio/pop.mp3")).play();

            if (this.chat.type === "ASSISTANT") this.waitingForAssistantResponse = true;
          } else {
            new Audio(require("@/assets/audio/ping.mp3")).play();
            if (data.user?.isAssistant) {
              this.waitingForAssistantResponse = false;
            }
          }
        }
        if (data.action_type === "DELETE") {
          if (this.isScheduled(data)) {
            this.scheduledMessages = this.scheduledMessages.filter((msg) => decodeId(msg.id) != decodeId(data.id));
          } else {
            this.messages = this.messages.filter((msg) => decodeId(msg.id) != decodeId(data.id));
          }
        }

        if (data.action_type === "ADD_REACTION") {
          let msgIndex = this.messages.findIndex((element) => decodeId(element.id) == decodeId(data.id));
          if (msgIndex < 0) return;

          if (!this.messages[msgIndex].messagereactionSet) this.$set(this.messages[msgIndex], "messagereactionSet", []);

          let existingIndex = this.messages[msgIndex].messagereactionSet.findIndex(
            (element) => decodeId(element.id) == decodeId(data.reaction.reaction.id)
          );
          if (existingIndex > -1) {
            this.messages[msgIndex].messagereactionSet.splice(existingIndex, 1, {
              id: data.reaction.reaction.id,
              reaction: data.reaction.reaction.reaction,
              user: {
                id: data.reaction.user.id,
                firstName: data.reaction.user.firstName,
                lastName: data.reaction.user.lastName,
              },
            });
          } else {
            this.messages[msgIndex].messagereactionSet.push({
              id: data.reaction.reaction.id,
              reaction: data.reaction.reaction.reaction,
              user: {
                id: data.reaction.user.id,
                firstName: data.reaction.user.firstName,
                lastName: data.reaction.user.lastName,
              },
            });
          }
        }
        this.$apollo.mutate({
          mutation: UPDATE_USER_CHAT_LAST_VIEWED_TIMESTAMP_MUTATION,
          variables: {
            chatId: this.chatId,
          },
          refetchQueries: ["notificationQuery"],
        });
      };
    },
    onClose() {
      let $vm = this;
      this.socket.onclose = function (e) {
        console.error("socketClosed", e);
        // this happens when the server restarts. On close, it's broken and we need to fix with one of the methods below.
        //$vm.socketError = true; --> is a bit shit
        //$vm.$forceUpdate(); --> does not work
        //window.location.reload(); //--> works but is a pain whe the server not running. keeps reloading page.
        //$vm.createSocket() --> does not work.
        if ($vm.forceSocketRefresh) {
          debounce(window.location.reload(), 2500);
        }
      };
    },
    onOpen() {
      //let $vm = this;
      this.socket.onopen = function (e) {
        console.log("socketOpen", e);
        //$vm.socketError = false;
      };
    },
    onError() {
      //let $vm = this;
      this.socket.onerror = function (e) {
        console.error("onerror", e);
        //$vm.socketError = false;
      };
    },
    showError(error) {
      this.errorMessage = error;
    },
    async submit() {
      if (!this.text && !this.hasAttachments) {
        return false;
      }

      this.errorMessage = null;
      // we only trigger loading if there are attachments. Otherwise it's just too heavy. totally uncessary
      if (this.hasAttachments) {
        this.loading = true;
      }

      this.imageUploadFailure = false;
      let attachmentIds = [];
      this.uploadPercentCompleted = 0;

      // handle images //////////////////////////////////////
      if (this.imageFiles?.length > 0) {
        let imageUploadResult = await this.uploadImages();
        if (imageUploadResult === false) {
          this.imageUploadFailure = true;
          return;
        }
        attachmentIds = imageUploadResult;
      }

      // handle audio + video File //////////////////////////////////////
      if (this.audioFile) {
        let audioFileResult = await this.uploadAudioFile();
        if (audioFileResult) {
          attachmentIds = [...attachmentIds, ...audioFileResult];
        }
      }

      if (this.videoFile) {
        let videoFileResult = await this.uploadVideoFile();
        if (videoFileResult) {
          attachmentIds = [...attachmentIds, ...videoFileResult];
        }
      }

      if (this.pdfFile) {
        let pdfFileResult = await this.uploadPdfFile();
        if (pdfFileResult) {
          attachmentIds = [...attachmentIds, ...pdfFileResult];
        }
      }

      if (this.gif) {
        let gifResult = await this.uploadGif();
        if (gifResult) {
          attachmentIds = [...attachmentIds, ...gifResult];
        }
      }

      ///////////////////////////////////////////////////////////
      this.$refs.multipleImageUploader.files = [];
      this.imageFiles = null;
      this.audioFile = null;
      this.videoFile = null;
      this.pdfFile = null;
      this.gif = null;
      this.showGiphy = false;

      if (this.socket.readyState !== this.socket.OPEN) {
        await this.createSocket();
      }

      let data = {
        text: this.text,
        attachment_ids: attachmentIds,
        action_type: "CREATE", // CREATE or DELETE
        access_token: this.$store.getters.accessToken,
        send_email_notification: this.sendEmailNotification,
        scheduled_date: this.scheduleMessageDateTime,
      };

      if (this.replyMessage) {
        data["parent_id"] = this.replyMessage.id;
        this.replyMessage = null;
      }

      /**
       * CREATE VIA SOCKET
       */
      this.socket.send(JSON.stringify(data));

      setTimeout(() => {
        this.loading = false;
      }, 500);
      this.text = null;
      this.scheduleMessageDateTime = null;
      this.sendEmailNotification = false;
      this.scrollBottomEnabled = true;
      this.scrollBottom("smooth");
    },
    saveImageFiles(files) {
      this.errorMessage = null;
      this.imageFiles = files;
    },
    saveAudioFile(data) {
      this.errorMessage = null;
      this.audioTime = data.time;
      this.audioFile = data.audioFile;
      // submit straight away. Don't make the user click twice. Also bypasses playback not working on iOS
      this.submit();
    },
    saveVideoFile(data) {
      this.errorMessage = null;
      this.videoTime = data.time;
      this.videoFile = data.videoFile;
      // submit straight away. Don't make the user click twice. Also bypasses playback not working on iOS
      this.submit();
    },
    saveFile(data) {
      this.errorMessage = null;
      if (data.length == 0) {
        return;
      }
      // grab the first element.
      data = data[0];
      if (data.file.type.includes("video") || /.mp4|.mov|.wmv|.webm/.test(data.file.name.toLowerCase())) {
        this.videoFile = data.file;
      }
      if (data.file.type.includes("audio") || /.wav|.mp3/.test(data.file.name.toLowerCase())) {
        this.audioFile = data.file;
      }
      if (data.file.type.includes("pdf") || /.pdf/.test(data.file.name.toLowerCase())) {
        this.pdfFile = data.file;
      }
      if (data.file.type.includes("image") || /.jpg|.jpeg|.png/.test(data.file.name.toLowerCase())) {
        let file = data.file;
        this.imageFiles = [file];
        const imageUploader = this.$refs.multipleImageUploader;
        blobToDataURL(data.file, function (e) {
          file.dataUrl = e;
          imageUploader.files = [file];
        });
      }
      this.scrollBottom();
    },
    async uploadImages() {
      return this.uploadAttachments(this.imageFiles, "IMAGE");
    },
    async uploadAudioFile() {
      return this.uploadAttachments([this.audioFile], "AUDIO", this.audioTime);
    },
    async uploadVideoFile() {
      return this.uploadAttachments([this.videoFile], "VIDEO", this.videoTime);
    },
    async uploadPdfFile() {
      return this.uploadAttachments([this.pdfFile], "PDF");
    },
    async uploadGif() {
      return this.uploadAttachments([this.gif], "GIF");
    },
    async uploadAttachments(attachments, type, time = null) {
      let remoteFiles = [];
      // first we upload client side to Cloudinary.
      if (type === "VIDEO" || type === "AUDIO") {
        for (const file of attachments) {
          const result = await this.uploadFileToCloudinary(file);
          remoteFiles.push(result.public_id);
        }
        attachments = [];
      }

      if (type === "GIF") {
        remoteFiles = attachments;
      }

      const uploadResult = await this.$apollo.mutate({
        mutation: CREATE_MESSAGE_ATTACHMENTS_MUTATION,
        variables: {
          data: {
            remoteFiles: remoteFiles,
            files: attachments,
            type: type,
            timeInSeconds: time,
          },
        },
        context: {
          fetchOptions: {
            onUploadProgress: (progressEvent) => {
              this.uploadPercentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            },
          },
        },
      });
      if (uploadResult.data.createMessageAttachmentsMutation.success) {
        return uploadResult.data.createMessageAttachmentsMutation.attachmentIds;
      }
    },
    loadPrevious() {
      this.scrollBottomEnabled = false;
      this.before = this.startCursor;
    },
    sentBySelf(message) {
      return message.user?.id == this.$store.getters.user.id;
    },
    showDayLabel(message, index) {
      if (this.messages[index - 1] === undefined) return true;
      let momentObj = this.$moment(message.sentDate);
      let momentObjPrev = this.$moment(this.messages[index - 1].sentDate);
      return !momentObj.isSame(momentObjPrev, "day");
    },
    getDayLabel(message) {
      let momentObj = this.$moment(message.sentDate);
      let formattedDate = momentObj.format("D MMM 'YY");
      let copy = momentObj.isSame(this.$moment(), "day") ? "Today" : formattedDate.toString();
      return copy;
    },
    updateGif(data) {
      this.gif = data;
    },
    async deleteMessage(message) {
      if (this.socket.readyState !== this.socket.OPEN) {
        await this.createSocket();
      }

      this.socket.send(
        JSON.stringify({
          message_id: message.id,
          action_type: "DELETE", // CREATE or DELETE
          access_token: this.$store.getters.accessToken,
          scheduled_date: message.scheduledDate,
        })
      );
    },
    replyToMessage(message) {
      this.scrollBottomEnabled = true;
      this.replyMessage = message;
      this.$refs.textRef.focus();
      this.scrollBottom();
    },
    chooseReaction(message) {
      this.emojiPicker.open = true;
      this.emojiPicker.message = message;
    },
    insert(emoji) {
      this.emojiPicker.open = false;
      this.socket.send(
        JSON.stringify({
          message_id: this.emojiPicker.message.id,
          action_type: "ADD_REACTION",
          access_token: this.$store.getters.accessToken,
          reaction: emoji,
        })
      );
    },
  },
  apollo: {
    chat: {
      query: CHAT,
      fetchPolicy: "network-only",
      variables() {
        return {
          chatId: this.chatId,
          withUserChats: false,
        };
      },
      result(response) {
        if (response.data) {
          // if we dont' have a lastViewedTimestamp, we assume it's a new Chat
          const chat = response.data?.chat;
          // only popup the settings dialog if accontablity AND the user is a member of this chat. i.e staff members may access this chat =
          // i.e no userChat record. in which case, do not popup.
          if (chat && chat?.type === "ACCOUNTABILITY" && chat.userChat) {
            this.newChatDialog = !chat.userChat.lastViewedTimestamp;
          }
        }
      },
    },
    messagesForChat: {
      query: MESSAGES_FOR_CHAT,
      fetchPolicy: "no-cache",
      variables() {
        return {
          chatId: this.chatId,
          sent: true,
          orderBy: "sentDate",
          before: this.before,
          last: 10,
        };
      },
      result(response) {
        if (response.data) {
          this.startCursor = response.data.messagesForChat.pageInfo.startCursor;
          this.hasPreviousPage = response.data.messagesForChat.pageInfo.hasPreviousPage;

          if (response.data.messagesForChat.edges.length > 0) {
            this.messages = [...extractNodes(response.data.messagesForChat.edges), ...this.messages];

            this.inActiveStaff = this.messages
              .filter((x) => x.user?.isStaff && !x.user?.isActive)
              .filter((value, index, self) => self.map((x) => x.user?.id).indexOf(value.user?.id) == index)
              .map((x) => x.user.firstName)
              .join(", ");
          }
        }
      },
    },
    scheduledMessages: {
      query: MESSAGES_FOR_CHAT,
      fetchPolicy: "no-cache",
      variables() {
        return {
          chatId: this.chatId,
          userId: window.btoa(`UserNode: ${this.$store.getters.user.id}`),
          scheduledDate_Gte: this.$moment(),
          orderBy: "scheduledDate",
        };
      },
      update: (data) => extractNodes(data.messagesForChat.edges),
      result(response) {
        if (response.data?.messagesForChat?.edges?.length > 0) {
          this.scheduledMessages = extractNodes(response.data.messagesForChat.edges);
        }
      },
    },
  },
};
</script>

<style scoped>
.image-attachment {
  width: 50px;
  height: 50px;
  background-size: contain;
}

.sticky-header {
  position: fixed;
  top: 0;
  text-align: center;
  width: 100%;
  z-index: 999;
}
.sticky-header-content {
  background-color: white;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.is-replying {
  background-color: #f7f7f9;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.mw-200 {
  max-width: 200px;
}

.emoji-v-card {
  display: flex !important;
  flex-direction: column;
  height: 400px;
}
.emoji-v-card-text {
  flex-grow: 1;
  overflow: auto;
}

.loader {
  width: 40px; /* control the size */
  aspect-ratio: 8/5;
  --_g: no-repeat radial-gradient(#000 68%, #0000 71%);
  -webkit-mask: var(--_g), var(--_g), var(--_g);
  -webkit-mask-size: 25% 40%;
  background: lightgrey;
  animation: load 2s infinite;
}

@keyframes load {
  0% {
    -webkit-mask-position: 0% 0%, 50% 0%, 100% 0%;
  }
  16.67% {
    -webkit-mask-position: 0% 100%, 50% 0%, 100% 0%;
  }
  33.33% {
    -webkit-mask-position: 0% 100%, 50% 100%, 100% 0%;
  }
  50% {
    -webkit-mask-position: 0% 100%, 50% 100%, 100% 100%;
    background: darkgray;
  }
  66.67% {
    -webkit-mask-position: 0% 0%, 50% 100%, 100% 100%;
  }
  83.33% {
    -webkit-mask-position: 0% 0%, 50% 0%, 100% 100%;
  }
  100% {
    -webkit-mask-position: 0% 0%, 50% 0%, 100% 0%;
  }
}
</style>
