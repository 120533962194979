<template>
    <j-card-banner-container
      header="Event"
      :title="event.name"
      :subtitle="subtitle"
      :caption="event.durationMinutes ? `${event.durationMinutes} minutes` : ''"
      :icon="icon"
      :gradient="['grey-background', 'yellow-background']"
      :textGradient="['pink', 'yellow']"
      :loading="loading"
      show-share-link
      use-header-title-in-link
    >
      <template v-if="!loading" v-slot:card-content>
        <div class="px-4" v-html="event.description" />
        <v-card-text v-if="showEvent" align="center" justify="center" class="pt-0">
          <template v-if="isLive || !upcoming">
            <!-- <v-chip v-if="isLive" class="ma-2 mb-4" color="error" text-color="white" label>
              <h1 class="pb-1 pr-1">&#8226;</h1>
              LIVE NOW
            </v-chip>
            <j-btn
              v-if="(isLive && event.youtubeId) || (!upcoming && event.url)"
              color="facebook-blue"
              class="d-block my-2"
              large
              @click="handleNavigation()"
            >
              <strong>Watch + comment <v-icon class="pb-1">mdi-facebook</v-icon></strong>
            </j-btn> -->
            <!-- no videoStarttime-->

            <j-btn
              v-if="event.pdf"
              color="error"
              align="center"
              large
              rounded
              target="_blank"
              :href="event.pdf"
              class="mt-4"
            >
              <strong>Download PDF</strong>
            </j-btn>

            <div v-if="event.audioFile && (isLive || !upcoming)">
              <vue-plyr
                :emit="['play', 'ended', 'pause']"
                :options="{ controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay'] }"
              >
                <audio :src="event.audioFile" type="audio/mp3"></audio>
              </vue-plyr>
              <v-btn
                color="tertiary"
                class="my-2"
                large
                rounded
                :loading="downloading"
                min-width="200px"
                @click="download(event.audioFile, event.name)"
              >
                <strong>Download</strong>
                <template v-slot:loader>
                  <strong class="mr-4">Downloading </strong>
                  <v-progress-circular :size="15" indeterminate></v-progress-circular>
                </template>
              </v-btn>
            </div>

            <j-card class="watch-in-app" v-if="!isLive && !upcoming && !viewInApp && event.videoStartTime">
              <div class="p-text">WATCH VIDEO IN THE WEB APP</div>
              <j-btn secondary class="my-2" large @click="showVideo(false)">
                <strong> Video Start</strong><v-icon class="ml-1" small>mdi-play</v-icon>
              </j-btn>

              <j-btn secondary class="my-2 d-block" large @click="showVideo(true)">
                <strong>Session Start </strong><v-icon class="ml-1" small>mdi-fast-forward</v-icon>
              </j-btn>
            </j-card>

            <div
              v-if="
                viewInApp || (((isLive && event.youtubeId) || (!upcoming && event.youtubeId)) && !event.videoStartTime)
              "
              class="mt-4"
            >
              <video-block
                :eventProperties="segmentEventProperties"
                :videoStartTime="playerVars.start"
                :youtubeId="event.youtubeId"
                :event-start-time="event.videoStartTime"
                @ended="ended()"
                @playing="playing()"
                @paused="paused()"
              >
              </video-block>
            </div>
          </template>
          <template v-else>
            <!-- This workout is upcoming and not currently live -->
            <div v-if="upcoming && event.registrationLink">
              <v-sheet class="pa-6 registration-info">
                <j-btn primary :href="event.registrationLink" target="_blank">Register Now</j-btn>
              </v-sheet>
            </div>
            <div v-if="upcoming">
              <h4>{{ scheduledText }}</h4>
              <h1 class="mt-2">{{ scheduledStartTime }}</h1>
            </div>
            <v-sheet class="pa-3 pt-6" v-if="upcoming && !isLive">
              <!-- <div class="text-left descriptors">
                <div v-if="!event.audioFile">
                  <v-icon>mdi-video</v-icon>Get access to watch live in Facebook and in here
                </div>
                <div v-else><v-icon>mdi-microphone</v-icon>Get access to listen live in the app</div>
                <div>
                  <v-icon>mdi-bell</v-icon>Get alerts in our
                  <a class="font-weight-bold green--text" href="/notifications">WhatsApp notification groups</a>
                </div>
                <div><v-icon>mdi-refresh</v-icon>Refresh the page if the video isn’t showing</div>
              </div>
              <j-btn tertiary class="mt-2 secondary--text center-element" @click="updateComponent()">
                REFRESH PAGE
              </j-btn> -->
            </v-sheet>
          </template>
          <div v-if="event.content && event.content.file" class="col-8">
            <j-btn
              color="nutrition"
              class="my-3"
              large
              dark
              @click="viewRecipe(event.content.name, event.content.file)"
            >
              <strong>View recipe</strong>
            </j-btn>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <j-alert type="warning">
            Sorry, you need to
            <router-link to="/shop" class="warning--text font-weight-bold">upgrade your membership</router-link> to view
            this event.
          </j-alert>
        </v-card-text>

        <v-expansion-panels v-model="panel">
          <activity-exercise-panel
            v-for="section in activitySections"
            :ref="`panel_${section.id}`"
            :key="section.section"
            :section="section"
            :completed-exercises="completedSectionExercises"
            @complete="completeExercise"
            @next-section="nextSection"
          />
        </v-expansion-panels>
      </template>

      <v-row align="center" justify="center">
        <v-col class="mx-4">
          <j-card class="pt-3 mb-0 j-elevated-1">
            <v-card-text class="pb-0 mb-0 log-nutrition-heading">
              <template v-if="userActivityId != '0'">
                <p v-if="firstName.trim() !== ''" class="mb-2">You logged your dot 🎉</p>
                <p v-else class="mb-2">You logged your dot! 🎉</p>
                <br />
              </template>
            </v-card-text>
            <v-card-text>
              <j-card @click="formVisible = !formVisible" class="pa-0 mb-3" rounded="pill" v-if="event.eventType">
                <v-toolbar flat class="pa-0 ma-0">
                  <span class="font-weight-bold secondary--text"> Log my Dot </span>
                  <v-spacer />
                  <span class="pt-0 pr-1 p-text">{{ workoutType }}</span>
                  <v-icon v-if="userActivityId !== '0'" :color="event.eventType.category.toLowerCase()">
                    fiber_manual_record
                  </v-icon>
                  <v-icon v-else :color="event.eventType.category.toLowerCase()">radio_button_unchecked</v-icon>
                </v-toolbar>
              </j-card>
              <v-card-text>
                <p class="sm-text">Log dots to track activity and earn badges</p>
              </v-card-text>
              <j-card flat v-if="formVisible">
                <contracted-activity-form
                  :userActivityId="userActivityId"
                  :eventProperties="eventProperties"
                  workoutType="event"
                  @activityLogged="activityLogged"
                  :workoutId="$route.params.id"
                />
              </j-card>
            </v-card-text>
          </j-card>
        </v-col>
      </v-row>

      <post-container
        v-if="event"
        ref="postContainer"
        class="mt-6 px-4"
        :event-id="event.id"
        :featured-user-ids="event.instructor ? [event.instructor.id] : null"
        highlight-colour="#FD89A2"
        hide-post-group-name
        hide-post-link
      />
    </j-card-banner-container>
</template>

<script>
import { SCHEDULED_EVENT_QUERY } from "@/graphql/queries/events";
import { DiscoveryVideoTracking } from "@/lib/discovery_video_tracking";
import { convertSATimeToLocalTime } from "@/lib/timezone";
import ContractedActivityForm from "@/components/tracker/activity-form/ContractedActivityForm";
import VideoBlock from "@/components/events/VideoBlock";
//import moment from "moment";
import ActivityTrackingMixin from "@/mixins/ActivityTrackingMixin";
import UserMixin from "@/mixins/UserMixin";
import FileDownloadMixin from "@/mixins/FileDownloadMixin";
import PostContainer from "@/components/groups/posts/PostContainer";
import ActivityExercisePanel from "@/components/workoutblocks/cards/ActivityExercisePanel";
import ExerciseMixin from "@/mixins/ExerciseMixin";

export default {
  name: "ScheduledEvent",
  mixins: [UserMixin, ActivityTrackingMixin, FileDownloadMixin, ExerciseMixin],
  components: {
    ContractedActivityForm,
    VideoBlock,
    PostContainer,
    ActivityExercisePanel,
  },
  props: ["routedFrom"],
  data() {
    return {
      event: {},
      formVisible: false,
      saving: false,
      loading: true,
      clickedLoad: false,
      height: "500px",
      userActivityId: "0",
      viewInApp: false,
      vimeoOptions: {
        portrait: false,
        byline: false,
        title: false,
        playsinline: true,
      },
      instructorId: "",
      prevRoute: null,
      playerVars: {
        start: 0,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        autoplay: 0,
        enablecastapi: 1,
      },
      // in gym data. Not putting into ExerciseMixin since it could be a prop!
      activitySections: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  methods: {
    updateComponent() {
      this.$router.go();
    },
    handleNavigation() {
      window.analytics.track("Clicked Facebook Link", {
        event_name: this.event.name,
        event_id: this.event.id,
        instructor_id: this.instructorId,
        video_type: this.isLive ? "live" : "past",
        scheduled_date: this.event.scheduledDate,
        destination_url: this.event.url,
        session: this.event.session ? this.event.session.name : "",
      });
      if (this.event.facebookPermalinkId && navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        var url = "https://m.facebook.com/groups/JEFFMembers?view=permalink&id=" + this.event.facebookPermalinkId;
        window.open(url, "_blank");
      } else {
        window.open(this.event.url, "_blank");
      }
    },
    viewRecipe(name, url) {
      window.analytics.track("Content Resource Viewed", {
        name: name,
        type: "Recipe",
      });
      window.open(url, "_blank");
    },
    fbLiveVideoUpdated() {
      if (!this.event.url) return false;

      let placeholderUrls = [
        "https://www.facebook.com/groups/jeffmembers",
        "https://www.facebook.com/groups/jefftogether",
      ];
      let event_url = this.event.url.toLowerCase().trim().replace(/\/$/, "");
      return !placeholderUrls.includes(event_url);
    },
    activityLogged(activityId) {
      this.userActivityId = activityId;
    },
    showVideo(jumpToStart = false) {
      this.viewInApp = true;

      if (jumpToStart && this.event.videoStartTime > 0) {
        this.playerVars["start"] = this.event.videoStartTime;
      }
      window.analytics.track("Clicked View In App", {
        event_name: this.event.name,
        instructor_id: this.instructorId,
        event_id: this.event.id,
        video_type: this.isLive ? "live" : "past",
        scheduled_date: this.event.scheduledDate,
        session: this.event.session ? this.event.session.name : "",
      });
    },
  },
  computed: {
    showEvent() {
      return !this.inActive || this.event.openAccess;
    },
    isLive() {
      let now = this.$moment().utc();
      let eventDate = this.$moment(this.event.scheduledDate).utc();
      return now >= eventDate;
      //calculate duration & end date of event
      // let startTime = moment(this.event.startTime, "HH:mm:ss");
      // let endTime = moment(this.event.endTime, "HH:mm:ss");
      // let duration = moment.duration(endTime.diff(startTime));
      //let passedDate = eventDate.clone().add(duration);

      /**
       * If the embed url is set to: https://www.facebook.com/groups/jefftogether
       * Then the video is NOT live and the OPS team needs to first add the video
       */
      
      // if (this.event.audioFile) {
      //   return now > eventDate && now < passedDate;
      // }

      // if (!this.fbLiveVideoUpdated()) {
      //   return false;
      // } else if (this.fbLiveVideoUpdated() && this.event.vimeoVideoId) {
      //   return false;
      // }
      // // If the vimeo video has not been added but the FB live video has then this video is live
      // else if (this.fbLiveVideoUpdated() && !this.event.vimeoVideoId) {
      //   return now <= passedDate;
      // }

      // return now > eventDate && now < passedDate;
    },
    upcoming() {
      let now = this.$moment().utc();
      let eventDate = this.$moment(this.event.scheduledDate).utc();

      if (this.event.audioFile) {
        return now < eventDate;
      }

      if (this.event.vimeoVideoId) {
        return false;
      }

      if (!this.event.url) {
        return true;
      }

      if (eventDate < now && this.event.url.trim() === "https://www.facebook.com/groups/JEFFMembers") {
        return true;
      }

      return now < eventDate;
    },
    scheduledText() {
      return "Launching on";
      // let startTime = this.$moment(this.event.startTime, "HH:mm:ss");
      // let scheduledTime = this.$moment(this.event.scheduledDate);
      // if (startTime < scheduledTime.add(-5, "minutes")) {
      //   return "Launching sometime between:";
      // } else {
      //   return "Launching at:";
      // }
    },
    scheduledStartTime() {
      return this.$moment(this.event.scheduledDate).format("ddd, D MMM HH:mm");
      // 
      // let startTime = this.$moment(this.event.startTime, "HH:mm:ss");
      // let scheduledTime = this.$moment(this.event.scheduledDate);
      // if (startTime < scheduledTime.add(5, "minutes")) {
      //   return `${startTime.format("HH:mm")} - ${scheduledTime.format("HH:mm")}`;
      // } else {
      //   return `${startTime.format("HH:mm")}`;
      // }
    },
    eventProperties() {
      return {
        workout_id: this.event.id,
        name: this.event.name,
        type: this.event.eventType.name,
        timeRequired: this.event.timeRequired,
        category: this.event.eventType.category.toLowerCase(),
        custom: false,
      };
    },
    segmentEventProperties() {
      return {
        name: this.event.name,
        instructor_id: this.instructorId,
        event_id: this.event.id,
        video_type: this.isLive ? "live" : "past",
        workout_type: "event",
        session: this.event.session ? this.event.session.name : "",
      };
    },
    locationSrc() {
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyAf4MduSjYF8Gl1115UOWGf1gBKYBoW5PQ&q=${this.event.location}&zoom=10`;
    },
    firstName() {
      return this.$store.getters.user.firstName;
    },
    workoutType() {
      let category = this.event.eventType?.category.toLowerCase();
      if (category == "nutrition") return "Nutrition";
      else return this.event.eventType?.name;
    },
    subtitle() {
      return `with ${this.event.instructor?.firstName}`;
    },
    icon() {
      return this.event.instructor?.userProfile?.profilePicture;
    },
  },
  apollo: {
    event: {
      query: SCHEDULED_EVENT_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.loading = false;
          this.videoTracking = new DiscoveryVideoTracking(this.$apollo, this.event.id);
          this.userActivityId = response.data.event.useractivitySet.edges[0]
            ? response.data.event.useractivitySet.edges[0].node.id
            : "0";
          this.instructorId = this.event.instructor ? this.event.instructor.id : "";
          window.analytics.page("Event", {
            title: response.data.event.name,
          });

          this.activitySections = response.data.event.activitysectionSet;
        }
      },
      update(data) {
        data.event.startTime = convertSATimeToLocalTime(data.event.startTime);
        data.event.endTime = convertSATimeToLocalTime(data.event.endTime);
        return data.event;
      },
    },
  },
};
</script>

<style scoped>
.registration-info {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 36px;
}

.log-nutrition-heading {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.watch-in-app {
  margin-top: 20px;
  background-color: #fafafa !important;
  padding: 10px 0;
}
.descriptors > div {
  padding-bottom: 7px;
}
.descriptors > div > i {
  padding-right: 4px;
  padding-bottom: 4px;
}
</style>
