<template>
  <j-main-banner-container header="Friend Requests">
    <j-card rounded="sm" class="ma-4">
      <v-card-text>
        <friends-listing :userId="$store.getters.user.id" status="PENDING" />
      </v-card-text>
    </j-card>
    <j-card rounded="lg" class="my-2 ma-4">
      <v-toolbar flat class="grey-background">
        <v-toolbar-title class="std-text">Recently Added Friends</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <friends-listing :userId="$store.getters.user.id" status="APPROVED" :limit="10" ordered />
      </v-card-text>
    </j-card>
  </j-main-banner-container>
</template>

<script>
import FriendsListing from "@/components/friends/FriendsListing";

export default {
  name: "FriendRequest",
  components: {
    FriendsListing,
  },
  data() {
    return {};
  },
};
</script>
