<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="font-weight-bold std-text pa-3">
      {{ section.title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="section.description" v-html="section.description" class="mt-n4" />

      <div
        v-for="(activitySectionExercise, index) in section.activitysectionexerciseSet"
        :key="activitySectionExercise.id"
      >
        <div>
          <active-exercise-panel
            v-if="currentExerciseSet === index"
            :exercise="activitySectionExercise"
            :completed-exercises="completedExercises"
            @exercise-completed="exerciseCompleted"
            @open-exercise-info-dialog="openExerciseInfoDialog"
          />
          <upcoming-exercise-panel
            v-else
            :exercise="activitySectionExercise"
            :index="index"
            :total-exercises="section.activitysectionexerciseSet.length"
            :completed-exercises="completedExercises"
            @exercise-completed="exerciseCompleted"
            @open-exercise-info-dialog="openExerciseInfoDialog"
          />
        </div>
        <v-divider class="mb-4" />
      </div>
    </v-expansion-panel-content>

    <!-- dialogs -->
    <v-dialog v-model="exerciseInfoDialog" max-width="420px">
      <j-card v-if="selectedExercise" rounded="lg">
        <v-toolbar class="mb-6" color="navy">
          <v-toolbar-title>
            <span class="white--text">{{ selectedExercise.title }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="exerciseInfoDialog = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pl-4 pr-4">
          <v-img v-if="selectedExercise.animatedImage" :src="selectedExercise.animatedImage" class="rounded-lg" />
          <div class="d-flex align-center mt-2">
            <v-avatar v-if="selectedExercise.coach" size="60">
              <img :src="selectedExercise.coach.userProfile.profilePicture" />
            </v-avatar>
            <p v-if="selectedExercise.quickHowTo" class="std-text font-italic ml-2 mt-5">
              "{{ selectedExercise.quickHowTo }}"
            </p>
          </div>
          <div v-if="selectedExercise.howTo" class="checkbox-list py-2 mt-2" v-html="selectedExercise.howTo" />
          
        </v-card-text>
      </j-card>
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import ActiveExercisePanel from "@/components/workoutblocks/cards/ActiveExercisePanel";
import UpcomingExercisePanel from "@/components/workoutblocks/cards/UpcomingExercisePanel";

export default {
  name: "ActivityExercisePanel",
  components: {
    UpcomingExercisePanel,
    ActiveExercisePanel
  },
  data() {
    return {
      exerciseInfoDialog: false,
      selectedExercise: null,
      currentExerciseSet: 0,
      currentTimerIndex: null,
    };
  },
  props: {
    section: {
      required: true,
    },
    completedExercises: {
      required: false,
    },
  },
  methods: {
    timerVisible(index) {
      return this.currentTimerIndex == index;
    },
    timerEnded(index) {
      this.currentTimerIndex = index + 1;
    },
    exerciseHasHowTo(exercise) {
      return exercise.coach && exercise.quickHowTo;
    },
    openExerciseInfoDialog(exercise) {
      if (!this.exerciseHasHowTo(exercise)) {
        return false;
      }
      this.selectedExercise = exercise;
      this.exerciseInfoDialog = true;
    },
    exerciseCompleted(exerciseId, completed, goToNext=false) {
      this.$emit('complete', exerciseId, completed);
      if (goToNext)
        this.nextExercise();
    },
    nextExercise() {
      let incompleteExerciseIndex = this.section.activitysectionexerciseSet.findIndex(element => {
        return !this.completedExercises.includes(element.id);
      });
      if (incompleteExerciseIndex > -1) {
        this.currentExerciseSet = incompleteExerciseIndex;
        return;
      }
      this.currentExerciseSet++;
      this.$emit('next-section');
    },
    jumpToFirstIncompleteExercise() {
      this.currentExerciseSet = null;
      this.nextExercise();
    }
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 10px 16px;
}
.checkbox-list ::v-deep {
  ul {
    list-style-type: "\2713";
    line-height: 180%;
  }
  li {
    padding-left: 10px;
  }
}

.activity-description ::v-deep {
  p {
    margin-bottom: 5px;
  }
}
</style>
