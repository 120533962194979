import { decodeId } from "@/lib/string";

export default {
  state: {
    accessToken: localStorage.getItem("accessToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
    user: localStorage.getItem("user") || null,
    role: localStorage.getItem("role") || null,
    programType: localStorage.getItem("programType") || null,
    timezone: localStorage.getItem("timezone") || null,
    isDiscoveryMember: "false",
    providedIdentification: "false",
    profileImage: localStorage.getItem("profileImage") || null,
    subscription: localStorage.getItem("subscription") || null,
    unreadNotificationCount: 0,
    unreadChatCount: 0,
    unreadRewardNotificationCount: 0,
    activeNutritionBlock: null,
    activeSubscriptionWorkoutBlocks: [],
    upcomingNutritionBlock: null,
    nutritionPreferences: null,
    coach: null,
    goals: [],
  },
  mutations: {
    authSuccess(state, { accessToken, refreshToken, user, userRole, programType, timezone, profileImage }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.user = JSON.stringify(user);
      state.timezone = timezone;
      state.role = userRole;
      state.programType = programType;
      state.profileImage = profileImage;
    },
    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      state.firstLogin = null;
      state.subscription = null;
      state.profileImage = null;
      state.goals = [];
    },
    updateTimezone({ timezone }) {
      localStorage.setItem("timezone", timezone);
    },
    updateRole(state, { role, programType }) {
      state.role = role;
      state.programType = programType;
    },
    updateUser(state, user) {
      state.user = JSON.stringify(user);
    },
    updateCoach(state, coach) {
      state.coach = JSON.stringify(coach);
    },
    updateProfileImage(state, { url }) {
      state.profileImage = url;
      localStorage.setItem("profileImage", url);
    },
    setNewAuthToken(state, { accessToken, refreshToken }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    setSubscription(state, { subscription }) {
      state.subscription = JSON.stringify(subscription);
    },
    mutateUnreadNotificationCount(state, { unreadNotificationCount }) {
      state.unreadNotificationCount = unreadNotificationCount;
    },
    mutateUnreadChatCount(state, { unreadChatCount }) {
      state.unreadChatCount = unreadChatCount;
    },
    mutateActiveNutritionBlock(state, activeNutritionBlock) {
      state.activeNutritionBlock = JSON.stringify(activeNutritionBlock);
    },
    mutateActiveSubscriptionWorkoutBlocks(state, activeSubscriptionWorkoutBlocks) {
      state.activeSubscriptionWorkoutBlocks = JSON.stringify(activeSubscriptionWorkoutBlocks);
    },
    mutateUpcomingNutritionBlock(state, upcomingNutritionBlock) {
      state.upcomingNutritionBlock = JSON.stringify(upcomingNutritionBlock);
    },
    mutateNutritionPreferences(state, nutritionPreferences) {
      state.nutritionPreferences = JSON.stringify(nutritionPreferences);
    },
    mutateUnreadRewardNotificationCount(state, unreadRewardCount) {
      state.unreadRewardNotificationCount = unreadRewardCount;
    },
    mutateGoals(state, { goals }) {
      state.goals = goals;
    },
  },
  actions: {
    login(
      { commit, getters },
      { accessToken, refreshToken, user = getters.user, userRole, programType, timezone = null, profileImage = "" }
    ) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("role", userRole);
      localStorage.setItem("programType", programType);
      localStorage.setItem("profileImage", profileImage.toString());
      if (timezone) localStorage.setItem("timezone", timezone);
      commit("authSuccess", { accessToken, refreshToken, user, userRole, programType, timezone, profileImage });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        localStorage.removeItem("profileImage");
        localStorage.removeItem("role");
        localStorage.removeItem("programType");
        localStorage.removeItem("orderId");
        localStorage.removeItem("checkoutCode");
        localStorage.removeItem("billingDetails");
        localStorage.removeItem("checkoutProduct");
        localStorage.removeItem("checkoutVoucher");
        localStorage.removeItem("paymentRetry");
        localStorage.removeItem("trackedCheckoutOpportunity");
        localStorage.removeItem("subscription");
        sessionStorage.removeItem("redirectPath");
        sessionStorage.removeItem("workoutBlockId");
        sessionStorage.removeItem("registerCompleted");
        sessionStorage.removeItem("selectedCoach");
        sessionStorage.removeItem("appSource");
        localStorage.removeItem("skipTasks");
        localStorage.removeItem("deal");
        commit("logout");
        resolve();
      });
    },
    setAuthToken({ commit }, { accessToken, refreshToken }) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      commit("setNewAuthToken", { accessToken, refreshToken });
    },
    updateTimezone({ commit }, { timezone }) {
      commit("updateTimezone", { timezone });
    },
    updateRole({ commit }, { role, programType }) {
      localStorage.setItem("role", role);
      localStorage.setItem("programType", programType);

      commit("updateRole", { role, programType });
    },
    updateUser({ commit }, user) {
      localStorage.setItem("user", JSON.stringify(user));
      commit("updateUser", user);
    },
    updateCoach({ commit }, coach) {
      commit("updateCoach", coach);
    },
    updateProfileImage({ commit }, { url }) {
      commit("updateProfileImage", { url });
    },
    setSubscription({ commit }, subscription) {
      localStorage.setItem("subscription", JSON.stringify(subscription));
      commit("setSubscription", { subscription });
    },
    updateUnreadNotificationCount({ commit }, unreadNotificationCount) {
      commit("mutateUnreadNotificationCount", { unreadNotificationCount });
    },
    updateUnreadChatCount({ commit }, unreadChatCount) {
      commit("mutateUnreadChatCount", { unreadChatCount });
    },
    updateActiveNutritionBlock({ commit }, activeNutritionBlock) {
      commit("mutateActiveNutritionBlock", activeNutritionBlock);
    },
    updateActiveSubscriptionWorkoutBlocks({ commit }, activeSubscriptionWorkoutBlocks) {
      commit("mutateActiveSubscriptionWorkoutBlocks", activeSubscriptionWorkoutBlocks);
    },
    updateUpcomingNutritionBlock({ commit }, upcomingNutritionBlock) {
      commit("mutateUpcomingNutritionBlock", upcomingNutritionBlock);
    },
    updateNutritionPreferences({ commit }, nutritionPreferences) {
      commit("mutateNutritionPreferences", nutritionPreferences);
    },
    updateUnreadRewardNotificationCount({ commit }, unreadRewardCount) {
      commit("mutateUnreadRewardNotificationCount", unreadRewardCount);
    },
    updateGoals({ commit }, goals) {
      commit("mutateGoals", { goals });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.user,
    user: (state) => {
      let user = JSON.parse(state.user);
      if (!user) {
        return null;
      }
      user.id = decodeId(user.id);
      return user;
    },
    coach: (state) => {
      let coach = JSON.parse(state.coach);
      if (!coach) {
        return null;
      }
      coach.id = decodeId(coach.id);
      return coach;
    },
    profileImage: (state) => state.profileImage,
    accessToken: (state) => state.accessToken,
    refreshToken: (state) => state.refreshToken,
    role: (state) => state.role,
    programType: (state) => state.programType,
    timezone: (state) => state.timezone,
    isDiscoveryMember: (state) => {
      const user = JSON.parse(state.user);
      if (!user?.userProfile) {
        return false;
      }
      // if you are not a clubber, then discovery does not apply to you.
      if (state.role !== "CLUB") {
        return false;
      }
      return user.userProfile.isDiscoveryMember;
    },
    providedIdentification: (state) => {
      const user = JSON.parse(state.user);
      if (!user?.userProfile) {
        return false;
      }
      return !!user.userProfile.passportNumber || !!user.userProfile.idNumber;
    },
    subscription: (state) => JSON.parse(state.subscription),
    unreadNotificationCount: (state) => state.unreadNotificationCount,
    unreadChatCount: (state) => state.unreadChatCount,
    unreadRewardNotificationCount: (state) => state.unreadRewardNotificationCount,
    inActive: (state) => {
      return state.role === "INACTIVE";
    },
    activeNutritionBlock: (state) => JSON.parse(state.activeNutritionBlock),
    upcomingNutritionBlock: (state) => JSON.parse(state.upcomingNutritionBlock),
    activeSubscriptionWorkoutBlocks: (state) => JSON.parse(state.activeSubscriptionWorkoutBlocks),
    // A user is either on a default block (no subscription nutrition block record) or has specifically opted into one. i.e has a
    // subscription nutrtion block record.
    activeSubscriptionNutritionBlock: (state) => {
      const activeNutritionBlock = JSON.parse(state.activeNutritionBlock);
      if (activeNutritionBlock?.subscriptionnutritionblockSet?.edges?.length > 0) {
        // there should only be one. pick the first one.
        return activeNutritionBlock?.subscriptionnutritionblockSet.edges[0].node;
      }
      return null;
    },
    nutritionPreferences: (state) => JSON.parse(state.nutritionPreferences),
    goals: (state) => state.goals,
  },
};
