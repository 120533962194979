<template>
    <j-banner-container
      header="Add phone shortcut"
    >
      <v-row>
        <v-col cols="12">
          <v-container>
            <v-container v-if="!isIOS">
              <!-- THIS BUTTON MIGHT NOT SHOW ON YOUR DEV ENVIRONMENT -->
              <j-btn v-if="installPrompt" @click="install" primary>
                Add to home screen
              </j-btn>
              <div v-if="done" class="green--text font-weight-bold mt-5">Successfully added to home screen</div>
            </v-container>
            <v-container v-else>
              <ol class="text-left">
                <li>Make sure you’re in your phone’s Safari browser</li>
                <li>Tap the ‘Share’ up arrow button at the bottom of your screen</li>
                <li>Scroll down to tap ‘Add to Home Screen’</li>
              </ol>
            </v-container>
            <div class="mt-5 text-left"><strong><a href="https://f.hubspotusercontent20.net/hubfs/6239229/Web%20App/JEFF_Web_app_Shortcut_Guide_V4_01_12_2021.pdf" target="_blank">Read the full guide →</a></strong></div>
          </v-container>
        </v-col>
      </v-row>
    </j-banner-container>
</template>

<script>
export default {
  data() {
    return {
      done: false,
      installPrompt: null
    };
  },
  created() {
    //if this event doesnt fire then make sure to uninstall the app.
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.installPrompt = e;
    });
  },
  computed : {
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }
  },
  methods: {
    install() {
      this.installBtn = "none";
      this.installPrompt.prompt();
      this.installPrompt.userChoice.then(result => {
        if (result.outcome === "accepted") {
          this.done = true;
        } else {
          console.log("Denied do what?");
        }
      });
    }
  }
};

</script>