import { UPSERT_NUTRITION_LOG_MUTATION } from "@/graphql/mutations/nutritionblock";
import { dataURLtoBlob, blobToFile, urlToDataURL } from "@/lib/file";
import { decodeId } from "@/lib/string";
import SendToChatsGroupsMixin from "@/mixins/nutrition/SendToChatsGroupsMixin";
import BadgeToaster from "@/mixins/BadgeToaster";
import { maskedTimeToSeconds } from "@/lib/string";
import { UPSERT_ACTIVITY_MUTATION } from "@/graphql/mutations/tracker";

export default {
  name: "ImageSaveMixin",
  mixins: [BadgeToaster, SendToChatsGroupsMixin],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async enrichDot(id, data, chatData, source = "activity", subscriptionBlockId = null, unitActivityId = null) {
      // close straight away for better UX. Also to prevent weird flashing on the masked input.
      // this.$emit("closed");
      this.loading = true;
      // this has been handled already
      let saveData = { ...data };
      delete saveData.image;

      let badges;
      switch (source) {
        case "activity":
          badges = await this.saveActivity(id, saveData, subscriptionBlockId, unitActivityId);
          break;
        default:
          badges = await this.saveNutrition(saveData);
      }
      if (badges) {
        this.toastBadges(badges);
      }

      if (data.image && chatData.isSharing) {
        await this.sendMessages(chatData, data.image);
        if (source !== "activity") {
          // on nutrition dialog, we can close.
          this.$emit("closed");
        }
        this.shared = true;
      }
      this.loading = false;
      this.$emit("activityLogged");
    },
    async saveActivity(id, saveData, subscriptionBlockId, unitActivityId) {
      delete saveData.unitActivity;

      saveData.seconds = maskedTimeToSeconds(saveData.seconds);

      let result = await this.$apollo.mutate({
        mutation: UPSERT_ACTIVITY_MUTATION,
        variables: {
          typeId: saveData?.activityType ?? null,
          data: {
            id: id,
            subscriptionBlockId: subscriptionBlockId,
            unitActivityId: unitActivityId,
            ...saveData,
          },
        },
        refetchQueries: ["workoutBlockWorkoutLog", "userStatsQuery", "accountQuery"],
      });

      let badges = result.data?.upsertWorkoutLog?.earnedBadges;

      return badges;
    },
    async saveNutrition(saveData) {
      const response = await this.$apollo.mutate({
        mutation: UPSERT_NUTRITION_LOG_MUTATION,
        variables: {
          data: {
            nutritionBlockId: decodeId(this.nutritionBlockId),
            recipeId: this.recipe ? decodeId(this.recipe.id) : null,
            ...saveData,
          },
        },
        refetchQueries: [
          "nutritionBlockLogs",
          "prizeEligibilityQuery",
          "dailyTrackerSummary",
          "nutritionBlockNutritionLogs",
          "recipesForWeek",
          "activitiesQuery",
          "nutritionQuery",
          "assistantQuery",
          "recipesForDay",
        ],
      });
      let badges = response.data?.upsertNutritionBlockLog?.earnedBadges;
      this.$emit("nutritionLogId", response.data?.upsertNutritionBlockLog?.nutritionLogId);

      return badges;
    },
    async sendMessages(chatData, image) {
      // if incoming image is a remote image.
      if (!image) {
        return;
      }

      if (image.startsWith("https://")) {
        const $vm = this;
        const ready = new Promise((resolve) => {
          urlToDataURL(image, async function (dataUrl) {
            const blob = dataURLtoBlob(dataUrl);
            const file = blobToFile(blob);
            await $vm.saveMessageData(chatData, file);
            resolve();
          });
          return;
        });
        await ready;
        return;
      }

      // incoming image is NOT remote image.
      const blob = dataURLtoBlob(image);
      const file = blobToFile(blob);
      await this.saveMessageData(chatData, file);
    },
  },
};
