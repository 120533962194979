<template>
  <j-banner-container
    header="Preferences"
    md="5"
  >
    <personal-preferences ref="preferences" />
    <div class="d-flex justify-center">
      <j-btn wide class="mt-4" @click="$refs.preferences.save()">Save</j-btn>
    </div>
  </j-banner-container>
</template>

<script>
import PersonalPreferences from "@/components/support/PersonalPreferences.vue";

export default {
  name: "Preferences",
  components: {
    PersonalPreferences,
  },
};
</script>
