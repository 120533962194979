<template>
  <j-banner-container header="Register">
    <v-row v-if="loading">
      <v-col cols="12" align="center" justify="center">
        <v-progress-circular :indeterminate="loading" color="blue"></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-alert v-if="virtualEvent == null" type="info">
          Event does not exist
        </v-alert>
        <v-alert v-else-if="isActive === false && virtualEvent !== null" type="info">
          Event not currently active
        </v-alert>
        <div v-else>
          <j-card class="small-centered-card">
            <v-img :src="virtualEvent.thumbnail" />
            <v-card-title>{{ virtualEvent.title }}</v-card-title>
            <v-card-subtitle>{{ virtualEvent.description }}</v-card-subtitle>
            <v-card-text>
              <div v-if="virtualEvent.hasDedication">
                <span class="subtitle-2 font-weight-bold">{{ virtualEvent.dedicationText }}</span>
                <j-text-field class="mt-2" @input="error = ''" v-model="dedication" required :error-messages="error"></j-text-field>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <j-btn
                v-if="link != null && virtualEvent.hasRaceNumber"
                :href="link"
                color="one-on-one"
                dark
                rounded
                target="_blank"
                class="mb-6 mt-n6"
              >
                View Event Number
              </j-btn>
              <j-btn v-else @click="registerForEvent()" color="secondary" :loading="submitting" class="mb-6 mt-n6">
                Register For Event
              </j-btn>
              <v-spacer />
            </v-card-actions>
          </j-card>
        </div>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { VIRTUAL_EVENT_QUERY } from "@/graphql/queries/virtualEvent";
import { REGISTER_MUTATION } from "@/graphql/mutations/virtualEvent/";
export default {
  name: "EventRegistration",
  data() {
    return {
      virtualEvent: null,
      dedication: null,
      loading: true,
      link: null,
      submitting: false,
      error: "",
    };
  },
  computed: {
    isActive() {
      return (
        this.$moment() >= this.$moment(this.virtualEvent.startDate) &&
        this.$moment() <= this.$moment(this.virtualEvent.endDate)
      );
    },
    submitDisabled() {
      return this.virtualEvent.hasDedication && !this.dedication;
    },
  },
  methods: {
    viewRaceNumber() {
      window.open(this.link, "_blank");
    },
    registerForEvent() {
      if (this.virtualEvent.hasDedication && !this.dedication) {
        this.error = "Please complete this field";
        return;
      }

      this.submitting = true;
      let input = {
        eventId: this.$route.params.id,
      };
      if (this.dedication) {
        input["dedication"] = this.dedication;
      }

      this.$apollo
        .mutate({
          mutation: REGISTER_MUTATION,
          variables: {
            registrationInput: input,
          },
        })
        .then((response) => {
          this.submitting = false;
          if (response.data && response.data.eventRegistration.registration.id) {
            this.link = response.data.eventRegistration.registration.link;
            this.$toasted.success("Thank you for registering for " + this.virtualEvent.title, {
              icon: "arm-flex",
              duration: 2000,
              position: "bottom-center",
            });
          } else if (response.data && !response.data.eventRegistration.registration == null) {
            this.$toasted.error("Sorry something went wrong", {
              icon: "warn",
              duration: 2000,
              position: "bottom-center",
            });
          }
        })
        .catch((error) => {
          this.submitting = false;
          throw error;
        });
    },
  },
  apollo: {
    virtualEvent: {
      query: VIRTUAL_EVENT_QUERY,
      fetchPolicy: "network-only",
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          this.$nextTick(() => {
            this.loading = false;
          });
        }
      },
    },
  },
};
</script>

<style scoped>
.small-centered-card {
  max-width: 540px;
  margin: 0 auto;
}
</style>
