<template>
  <j-banner-container header="Tips">
    <v-row>
      <v-col cols="12">
        <j-card class="j-elevated-1 mx-5">
          <v-expansion-panels accordion class="rounded-xl">
            <v-expansion-panel>
              <v-expansion-panel-header class="blue--text">
                <div class="d-flex">
                  <v-icon size="22" color="blue">mdi-cellphone-charging</v-icon>
                  <h2 class="heading-6 ml-2">SHORTCUT</h2>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-left">
                <p>Want to make {{ $appName }} look like an app on your phone’s home screen? Follow these simple steps.</p>
                <j-btn
                  secondary
                  class="mt-2"
                  target="_blank"
                  href="https://help.fitnessfaculty.club/help-centre/quick-start-guide"
                >
                  Read quick guide
                </j-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="blue--text">
                <div class="d-flex">
                  <v-icon size="22" color="blue">mdi-clipboard-check-outline</v-icon>
                  <h2 class="heading-6 ml-2">CHECK-INS</h2>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-left">
                <p>
                  Regularly capture a check-in of your photographs and measurements to see the results of your
                  incredible work.
                </p>
                <j-btn secondary class="mt-2" to="/checkin/assessments">Go to check-ins</j-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="blue--text">
                <div class="d-flex">
                  <v-avatar tile size="22">
                    <img :src="require('@/assets/images/rewards.svg')" max-height="22" max-width="22" />
                  </v-avatar>
                  <h2 class="heading-6 ml-2">REWARDS</h2>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-left">
                <p>
                  Earn rewards for your participation in the community. Use your points to get discounts and vouchers.
                </p>
                <j-btn secondary class="mt-2" to="/rewards/points">Go to rewards</j-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </j-card>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
export default {
  name: "Tips",
};
</script>
