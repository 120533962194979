<template>
  <j-banner-container header="Success" md="5">
    <v-row>
      <v-col cols="12">
        <v-container v-if="$apollo.loading">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-container>
        <v-container v-else>
          <div v-if="linkingResult || linkingError === 'USER_ALREADY_LINKED'" class="text-left">
            <j-alert type="success">
              You're on the {{ this.corporateChallenge.title }}! Your program will start on
              {{ startDate | moment("dddd, DD MMM") }}.
            </j-alert>

            <div v-if="corporateChallenge.successMessage" class="px-2">
              <span v-html="corporateChallenge.successMessage" />
            </div>
            <div v-else class="px-2">
              <p>
                Once your program starts, your activity to complete each day will appear within 'Exercise' on your
                'Home' page.
              </p>
              <p>
                Your next step now is to tap on the 'To Do' button at the top right of your screen to find tips and
                tricks on how to get ready!
              </p>
            </div>
          </div>

          <j-alert type="error" v-else>
            Invalid linking code.
          </j-alert>
        </v-container>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { CORPORATE_CHALLENGE_QUERY } from "@/graphql/queries/challenge";
import { fancy_date } from "@/lib/fancy_date";
import { LINK_CORPORATE_CHALLENGE_MUTATION } from "@/graphql/mutations/account";

export default {
  name: "LinkCorporateChallenge",
  data() {
    return {
      linkingResult: this.$route?.query?.lr,
      linkingError: this.$route?.query?.le,
    };
  },
  computed: {
    startDate() {
      return fancy_date(this.corporateChallenge.startDate, "dddd, D MMMM YYYY", false);
    },
  },
  async mounted() {
    // if a user is logged in, do the linking here. If they are a new sign up, the linking is handled by RegistrationMixin
    if (this.$store.getters.isLoggedIn && this.$route.query.loggedin) {
      const result = await this.$apollo.mutate({
        mutation: LINK_CORPORATE_CHALLENGE_MUTATION,
        variables: {
          registrationCode: this.$route.params.linkingCode,
        },
        refetchQueries: ["accountQuery", "assistantQuery", "notificationQuery"],
      });
      this.linkingResult = result.data?.linkUserToCorporateChallenge?.success;
    }
  },
  apollo: {
    corporateChallenge: {
      query: CORPORATE_CHALLENGE_QUERY,
      variables() {
        return {
          registrationCode: this.$route.params.linkingCode,
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.$route.params.linkingCode;
      },
    },
  },
};
</script>
