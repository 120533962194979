import { gradientStyle } from "@/lib/gradientStyle";

export default {
  name: "BannerContainerMixin",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      required: false,
    },
    headerImage: {
      type: String,
      required: false,
    },
    backLink: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    accentColor: {
      type: String,
      default: "white",
    },
    color: {
      type: String,
      required: false,
    },
    gradient: {
      type: Array,
      default() {
        return ["white", "white"];
      },
    },
    textGradient: {
      type: Array,
      required: false,
    },
    md: {
      type: [String, Number],
      required: false,
      default: 6,
    },
    lg: {
      type: [String, Number],
      required: false,
      default: 6,
    },
    xl: {
      type: [String, Number],
      required: false,
      default: 5,
    },
    slotTextAlign: {
      type: String,
      required: false,
      default: "center",
    },
  },
  computed: {
    textGradientStyle() {
      if (this.textGradient && this.textGradient.length > 0) return gradientStyle(this.textGradient, 90);
      else return gradientStyle(this.gradient, 90);
    },
    backgroundGradientStyle() {
      return gradientStyle(this.gradient, 180);
    },
    slotTextAlignClass() {
      return {
        center: "",
        left: "text-left",
      }[this.slotTextAlign];
    },
  },
};
