<template>
  <v-container class="fill-height pt-0">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" md="6" class="background-grey-3 fill-height">
        <div class="d-flex pb-2">
          <v-chip color="green-background" dense text-color="black" class="font-16 mx-auto">
            <strong>2 of 3</strong>
          </v-chip>
        </div>
        <h1 class="heading-2 navy--text text-center px-5 pt-3">What's your highest level of education?</h1>

        <j-select
          v-model="selectedLevel"
          label="Choose an option"
          :items="educationLevelOptions"
          :item-value="(option) => option.value"
          background-color="white"
          color="navy"
          class="mx-3 mt-3"
          @change="$store.dispatch('updateEducation', selectedLevel)"
        />

        <j-btn to="/start/anything-else" color="exercise"  wide class="d-flex mx-auto">Continue</j-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Education",
  data() {
    return {
      selectedLevel: null,
      educationLevelOptions: [
        { text: "Primary", value: "primary" },
        { text: "High school", value: "high-school" },
        { text: "Tertiary certificate", value: "tertiary-certificate" },
        { text: "Tertiary diploma", value: "tertiary-diploma" },
        { text: "Bachelor's degree", value: "bachelors-degree" },
        { text: "Honour's degree", value: "honours-degree" },
        { text: "Master's degree or higher", value: "masters-higher" },
      ],
    };
  },
  created() {
    this.selectedLevel = this.$store.getters.education;
  },
};
</script>

<style lang="scss" scoped>
.j-card.pink-border {
  border: 2px solid var(--v-pink-base);
}
.foreground {
  z-index: 1;
}
.background {
  z-index: 0;
}
</style>
