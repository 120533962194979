<template>
  <j-banner-container header="Check-ins" md="5">
    <v-skeleton-loader v-if="$apollo.loading" type="card, card" />
    <v-row v-else justify="center">
      <v-col cols="12">
        <v-snackbar v-model="deleteAlert" :timeout="3000" :top="true"> Check-in Deleted </v-snackbar>
        <v-snackbar v-model="updateAlert" color="success" :timeout="3000" :top="true"> Check-in Updated </v-snackbar>
        <v-snackbar v-model="addAlert" color="success" :timeout="3000" :top="true"> New Check-in Added </v-snackbar>

        <div v-if="$route.query.success">
          <j-alert type="success"> You have successfully captured your data 💥 </j-alert>
          <book-consult />
        </div>

        <v-row>
          <v-col cols="12" align="center" justify="center">
            <span class="p-text">
              Your check-ins are <strong class="mindset--text text--darken-1">private</strong> to you and are never
              shared outside of {{ $appName }} without your permission.
            </span>
          </v-col>
        </v-row>
        <check-in-alert v-if="oneOnOneClient" />
        <div v-if="hasUserCheckIns" class="d-flex justify-space-around mx-3">
          <j-btn small narrow class="my-4" tertiary to="/checkins/list">
            See all check-ins
            <v-icon small color="navy"> mdi-tray-full </v-icon>
          </j-btn>
          <j-btn small narrow class="my-4" to="/checkins/new">
            Add a check-in
            <v-icon small color="white"> mdi-plus-circle-outline </v-icon>
          </j-btn>
        </div>

        <v-row v-if="hasUserCheckIns" justify="center">
          <v-col cols="12" sm="9" md="12" lg="9" class="mt-6">
            <h3 class="navy--text">Weight</h3>
            <weight-graph :goalWeight="goalWeight" />
          </v-col>
          <v-col cols="12" sm="9" md="12" lg="9" class="mt-6">
            <h3 class="navy--text">Measurements</h3>
            <measurements-graph :user-check-ins="userCheckIns" />
          </v-col>
          <v-col cols="12" sm="9" md="12" lg="9" class="mt-6">
            <h3 class="navy--text">Fitness</h3>
            <fitness-graph :user-check-ins="userCheckIns" />
          </v-col>
        </v-row>
        <v-row v-else justify="center">
          <v-col cols="12" sm="9" md="12" lg="9">
            <j-dotted-btn wide color="exercise" to="/checkins/new">Add First Check-in</j-dotted-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { USER_CHECK_INS_QUERY } from "@/graphql/queries/checkins";
import { mapGetters } from "vuex";
import WeightGraph from "@/components/checkin/CheckinSummary";
import MeasurementsGraph from "@/components/checkin/blocks/MeasurementsGraph";
import FitnessGraph from "@/components/checkin/blocks/FitnessGraph";
import CheckInAlert from "@/components/checkin/CheckInAlert";

export default {
  name: "CheckinSummary",
  components: {
    CheckInAlert,
    WeightGraph,
    MeasurementsGraph,
    FitnessGraph,
  },
  props: {
    deleted: {
      type: Boolean,
      default: false,
    },
    updated: {
      type: Boolean,
      default: false,
    },
    added: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteAlert: false,
      updateAlert: false,
      addAlert: false,
    };
  },
  created() {
    if (this.deleted) {
      this.deleteAlert = true;
    }
    if (this.updated) {
      this.updateAlert = true;
    }
    if (this.added) {
      this.addAlert = true;
    }
  },
  computed: {
    ...mapGetters(["checkinRequired", "checkinId"]),
    oneOnOneClient() {
      return this.$store.getters.role == "ONE_ON_ONE";
    },
    hasUserCheckIns() {
      return this.userCheckIns.length > 0;
    },
    goalWeight() {
      return this.$store.getters.user.userProfile?.desiredWeight;
    },
  },
  apollo: {
    userCheckIns: {
      query: USER_CHECK_INS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          // UI seems ok with a lot of checkins. But let's still limit it to an arbitray value.
          first: 50,
          assessmentDate_Isnull: false,
          orderBy: "assessmentDate",
        };
      },
      update(data) {
        return data.userCheckIns.edges.map((checkin) => {
          let date = this.$moment(checkin.node.assessmentDate);
          return {
            date: Date.UTC(date.year(), date.month(), date.date()),
            chest: parseFloat(checkin.node.chest),
            hip: parseFloat(checkin.node.hip),
            abdomen: parseFloat(checkin.node.abdomen),
            quad: parseFloat(checkin.node.quad),
            pushUps: parseFloat(checkin.node.pushUps),
            sitUps: parseFloat(checkin.node.sitUps),
            burpeeChirpee: parseFloat(checkin.node.burpeeChirpee),
            elbowPlank: parseFloat(checkin.node.elbowPlank),
            squat: parseFloat(checkin.node.squat),
          };
        });
      },
    },
  },
};
</script>
