<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-skeleton-loader v-if="$apollo.loading" type="image" height="200" />
      <v-col v-else cols="12" md="6" class="background-grey-3 fill-height">
        <v-img
          v-if="recommendedPlan && recommendedPlan.image"
          :src="recommendedPlan.image"
          :lazy-src="recommendedPlan.image"
          max-height="200"
          contain
        />

        <j-card rounded="lg" class="navy--text pa-4">
          <h1 v-if="recommendedPlan && recommendedPlan.title" class="text-center font-weight-medium">
            {{ recommendedPlan.title }}
          </h1>
          <h1 v-else class="text-center font-weight-medium">Your Plan Is Ready</h1>
          <div class="py-1 pt-3"><v-icon color="green" class="mr-2">mdi-check</v-icon>Exercise programs</div>
          <div class="py-1">
            <v-icon color="green" class="mr-2">mdi-check</v-icon>Nutrition plans personalised to your preferences
          </div>
          <div class="py-1"><v-icon color="green" class="mr-2">mdi-check</v-icon>Fresh Classes & Courses</div>
          <div class="py-1 pb-2"><v-icon color="green" class="mr-2">mdi-check</v-icon>Tracking & so much more</div>
        </j-card>
        <j-btn to="/start/pricing" color="exercise"  wide class="d-flex mx-auto mt-5">Continue</j-btn>
        <j-card rounded="lg" elevated="1" class="pa-4 mt-4 font-weight-medium text-center">
          <div class="navy--text text-h6 d-flex flex-wrap justify-center">
            4000+ 5-Star Reviews
            <div class="ml-2"><v-icon v-for="icon of 5" :key="icon" color="yellow">mdi-star</v-icon></div>
          </div>
          <v-avatar class="my-2">
            <v-img
              :src="require('@/assets/images/start/funnel-lauren.png')"
              :lazy-src="require('@/assets/images/start/funnel-lauren.png')"
              contain
            />
          </v-avatar>
          <div class="font-italic grey-navy--text p-text">
            <div>"After just 3 weeks I feel healthier and happier than ever!"</div>
            <div>Lauren</div>
          </div>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { RECOMMENDED_JOURNEY_PLAN_QUERY } from "@/graphql/queries/start";
import { getColor } from "@/lib/vuetifyColorHelper";

export default {
  name: "Plan",
  apollo: {
    recommendedPlan: {
      query: RECOMMENDED_JOURNEY_PLAN_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        let deal = this.$store.getters.deal;
        return {
          goals: deal.goals,
          fitnessLevel: deal.fitnessLevel,
          desiredWeightLoss: deal.currentWeight - deal.goalWeight,
        };
      },
      update(data) {
        setTimeout(() => {
          this.$confetti.start({
            particlesPerFrame: 1.5,
            particles: [{ type: "rect" }],
            defaultColors: [getColor("gold")],
          });
          setTimeout(() => {
            this.$confetti.stop();
          }, 2000);
        }, 500);
        return data.recommendedJourneyPlan;
      },
    },
  },
};
</script>
