<template>
  <div class="d-flex flex-column">
    <j-card class="pa-2 flex-fill mt-4 j-elevated-3" :to="link" @click="$emit('clicked', chat.id)">
      <div class="d-flex align-center text-left ">
        <div class="d-flex flex-column">
          <v-avatar color="background-grey" size="44">
            <img v-if="chatImage" :src="chatImage" />
            <v-icon v-else>mdi-account-group</v-icon>
          </v-avatar>
        </div>
        <div class="d-flex flex-column flex-fill ml-2">
          <h4 class="planet-grey--text font-weight-medium truncate-text">
            {{ chatTitle }}
          </h4>
          <p v-if="descriptionInline && chat.description">{{ stripTags(chat.description) }}</p>
          <div v-if="!infoOnly">
            <div v-if="chat.lastMessage" class="truncate-text" :class="{ 'font-weight-bold': chat.unreadCount > 0 }">
              <span v-if="chat.participantCount > 2 && chat.lastMessage.user" class="p-text"
                >{{ chat.lastMessage.user.firstName }}:
              </span>
              <span v-if="hasAttachmentSet && chat.lastMessage.messageattachmentSet[0].type == 'AUDIO'" class="ml-n1">
                <v-icon class="text-h6"> mdi-microphone </v-icon>
              </span>
              <span v-if="hasAttachmentSet && chat.lastMessage.messageattachmentSet[0].type == 'VIDEO'" class="ml-n1">
                <v-icon class="text-h6"> mdi-video </v-icon>
              </span>
              <span v-if="hasAttachmentSet && chat.lastMessage.messageattachmentSet[0].type == 'IMAGE'" class="ml-n1">
                <v-icon class="text-h6"> mdi-image </v-icon>
              </span>
              <span v-if="chat.lastMessage.text" class="grey-navy--text font-weight-regular truncate-text p-text">
                {{ truncate(chat.lastMessage.text, $vuetify.breakpoint.smAndDown ? 30 : 50) }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="!infoOnly" class="d-flex flex-column align-end">
          <div
            v-if="chat.lastMessage"
            class="mr-3 sm-text"
            :class="{ 'green--text font-weight-bold': chat.unreadCount > 0 }"
          >
            {{ timeAgo }}
          </div>
          <div class="d-flex mr-2">
            <v-chip v-if="chat.unreadCount > 0" color="green-background" class="px-3 ml-auto">
              <span class="green--text justify-center text--darken-2 font-weight-bold">{{ chat.unreadCount }}</span>
            </v-chip>
            <v-icon
              v-if="chat.description && !descriptionInline"
              class="ml-2 mr-1"
              @click.prevent="descriptionDialog = true"
            >
              mdi-information-variant
            </v-icon>
            <div v-if="showSettingsToggle" class="my-auto">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" @click.prevent="on.click">
                    mdi-dots-vertical
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item v-if="isAdmin" class="p-text pointer" @click="editChat()">
                    <v-icon class="pr-2 mt-n1">mdi-pencil</v-icon> Edit
                  </v-list-item>
                  <v-list-item
                    v-if="chat.type !== 'SUPPORT' && chat.type !== 'COACH'"
                    class="p-text pointer"
                    @click="leaveChat()"
                  >
                    <v-icon class="pr-2 mt-n1">mdi-exit-to-app</v-icon> Leave
                  </v-list-item>
                  <v-list-item class="p-text pointer" @click="settingsDialog = true">
                    <v-icon class="pr-2 mt-n1">mdi-cog</v-icon> Settings
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>

      <!-- settings dialog -->
      <v-dialog v-model="settingsDialog" max-width="500" transition="dialog-bottom-transition" class="limited-height">
        <chat-settings :chat="chat" @closed="settingsDialog = false" />
      </v-dialog>
      <!-- description dialog -->
      <v-dialog
        v-model="descriptionDialog"
        max-width="500"
        transition="dialog-bottom-transition"
        class="limited-height"
      >
        <chat-description :chat="chat" @closed="descriptionDialog = false" />
      </v-dialog>
    </j-card>
    <div v-if="!infoOnly && showUserList" class="d-flex align-center user-list">
      <j-card
        class="d-flex mt-n2 ml-16 px-3 py-1 j-elevated-3 cut-off-max-width"
        :class="{ 'no-pointer': !accountabilityChat }"
        rounded="pill"
        @click="membersClick()"
      >
        <div class="d-flex flex-column pr-1 overflow-hidden rounded-l-0 rounded-r-pill cut-off-max-height">
          <div class="d-flex flex-wrap">
            <div v-for="user in chat.users" :key="user.id">
              <user-stats-avatar
                v-if="canAccessChat(chat, getUserProductType(user))"
                :profile-picture="user.userProfile.profilePicture"
                :user="user"
                size="35"
                class="mr-2 ml-1"
              />
            </div>
          </div>
        </div>
        <div class="d-flex flex-column ml-2">
          <j-btn small narrow color="exercise"  elevation="0" class="my-auto">
            MEMBERS
            <v-icon>mdi-chevron-right</v-icon>
          </j-btn>
        </div>
      </j-card>
    </div>
  </div>
</template>

<script>
import { decodeId, stripTags } from "@/lib/string";
import UserStatsAvatar from "@/components/shared/user/UserStatsAvatar";
import ChatSettings from "@/components/chat/dialogs/ChatSettings";
import ChatDescription from "@/components/chat/dialogs/ChatDescription";
import ChatMixin from "@/mixins/ChatMixin";

export default {
  name: "ChatListingCard",
  mixins: [ChatMixin],
  components: {
    UserStatsAvatar,
    ChatSettings,
    ChatDescription,
  },
  data() {
    return {
      settingsDialog: false,
      descriptionDialog: false,
      chatClicked: true,
    };
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    showSettingsToggle: {
      type: Boolean,
      default: false,
    },
    hideUserList: {
      type: Boolean,
      default: false,
    },
    // use last message sender for chat.title and chat.image
    useLastMessageSender: {
      type: Boolean,
      default: false,
    },
    // should the description show inline or as an icon?
    descriptionInline: {
      type: Boolean,
      default: false,
    },
    // show a very bare bones version of this card. Just title and description
    infoOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chatImage() {
      if (!this.useLastMessageSender) {
        return this.chat.image;
      }
      if (this.chat?.lastMessage?.user?.userProfile?.profilePicture) {
        return this.chat.lastMessage.user.userProfile.profilePicture;
      }
      return this.chat.image;
    },
    chatTitle() {
      if (!this.useLastMessageSender) {
        return this.chat.title;
      }
      if (this.chat?.lastMessage?.user?.firstName) {
        return this.chat.lastMessage.user.firstName;
      }
      return this.chat.title;
    },
    showUserList() {
      if (this.hideUserList) {
        return false;
      }
      return this.chat?.users && this.chat?.users?.length > 1 && this.chat?.type === "ACCOUNTABILITY";
    },
    receiveNotifications() {
      return this.chat?.userChat?.receiveNotifications;
    },
    timeAgo() {
      if (!this.chat.lastMessage) {
        return null;
      }
      return this.$moment(this.chat.lastMessage.sentDate).fromNow();
    },
    link() {
      if (!this.infoOnly) {
        return `/chats/${decodeId(this.chat.id)}`;
      }
      return null;
    },
    isAdmin() {
      return this.chat?.creator?.id == this.$store.getters.user.id || this.chat?.userChat?.isAdmin;
    },
    hasAttachmentSet() {
      return this.chat?.lastMessage?.messageattachmentSet?.length > 0 ?? false;
    },
    accountabilityChat() {
      return this.chat.type === "ACCOUNTABILITY";
    },
  },
  methods: {
    stripTags(string) {
      return stripTags(string);
    },
    editChat() {
      this.$emit("editChat", this.chat);
    },
    leaveChat() {
      this.$emit("leaveChat", this.chat);
    },
    membersClick() {
      if (this.accountabilityChat) {
        this.$router.push(`/chats/members/${decodeId(this.chat.id)}`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.icon-wrapper {
  min-width: 102px;
  text-align: right;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: grey;
}
.no-pointer {
  cursor: auto;
}
.cut-off-max-width {
  max-width: calc(100% - (16px * 4));
}
.cut-off-max-height {
  height: 42px;
}
</style>
