<template>
  <v-bottom-navigation v-model="value" bottom app color="yellow" class="elevation-15 app-bar">
    <v-btn to="/today" class="app-bar">
      <span :class="{ 'white--text': !activeStyle(0), 'yellow--text': activeStyle(0) }">Home</span>
      <v-icon :color="!activeStyle(0) ? 'white' : 'yellow'">mdi-white-balance-sunny</v-icon>
    </v-btn>
    <v-btn to="/search" class="app-bar">
      <span :class="{ 'white--text': !activeStyle(1), 'yellow--text': activeStyle(1) }">Programs</span>
      <v-icon :color="!activeStyle(1) ? 'white' : 'yellow'">mdi-magnify</v-icon>
    </v-btn>
    <v-btn :to="classesLink" class="app-bar">
      <span :class="{ 'white--text': !activeStyle(2), 'yellow--text': activeStyle(2) }">Classes</span>
      <v-icon :color="!activeStyle(2) ? 'white' : 'yellow'">mdi-play-speed</v-icon>
    </v-btn>
    <v-btn to="/social" class="app-bar">
      <span :class="{ 'white--text': !activeStyle(3), 'yellow--text': activeStyle(3) }">Community</span>
      <v-icon :color="!activeStyle(3) ? 'white' : 'yellow'">mdi-account-group</v-icon>
      <div v-if="unreadNotificationCount > 0 && value != 3" class="unread-notification">
        <v-avatar color="green-background" size="20"
          ><span class="green--text font-weight-bold">{{ unreadNotificationCount }}</span></v-avatar
        >
      </div>
    </v-btn>
    <v-btn to="/chats" class="app-bar">
      <span :class="{ 'white--text': !activeStyle(4), 'yellow--text': activeStyle(4) }">Chats</span>
      <v-icon :color="!activeStyle(4) ? 'white' : 'yellow'">mdi-chat</v-icon>
      <div v-if="unreadChatCount > 0 && value != 4" class="unread-notification">
        <v-avatar color="green-background" size="20"
          ><span class="green--text font-weight-bold">{{ unreadChatCount }}</span></v-avatar
        >
      </div>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { gradientStyle } from "@/lib/gradientStyle";
import { mapGetters } from "vuex";

export default {
  name: "BottomNav",
  data() {
    return {
      value: null,
      drawer: false,
    };
  },
  computed: {
    ...mapGetters(["unreadNotificationCount", "unreadChatCount", "user"]),
    textGradientStyle() {
      return gradientStyle(["white", "green"], 90);
    },
    classesLink() {
      // for PF, we can just go to /classes
      return "/classes";
      //return this.user?.userProfile?.gymBranch ? "/classes" : "/live";
    },
  },
  methods: {
    activeStyle(btnIndex) {
      return this.value == btnIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.v-bottom-navigation .v-btn--active i::before {
  //background-image: linear-gradient(90deg, rgb(255, 197, 11), rgb(46, 184, 167));
  background-color: yellow;
  background-clip: text;
}

// .bottom-nav-active {
//   color: yellow;
// }

.v-bottom-navigation--fixed {
  position: fixed !important;
}

.v-item-group.v-bottom-navigation .v-btn {
  height: inherit !important;
  //background-color: #fff !important;
}

.v-btn span {
  text-transform: uppercase;
  font-size: 10px;
}

// .v-btn--active .v-btn__content span,
// .v-btn--active .v-btn__content .v-icon {
//   @extend .gradient-text;
//   font-weight: bold;
// }
.unread-notification {
  position: absolute;
  top: 2px;
  right: 0;
}
</style>
