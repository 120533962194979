<template>
  <v-timeline dense align-top class="pt-0 mx-n2">
    <v-timeline-item
      v-for="(block, index) in recommendedWorkoutJourney"
      :key="block.id"
      small
      :fill-dot="index >= selectedIndex"
      :color="color(index)"
      :class="{ 'pb-0': index == recommendedWorkoutJourney.length - 1, disabled: index != selectedIndex }"
    >
      <template v-slot:icon>
        <span class="white--text">{{ index >= selectedIndex ? index - selectedIndex + 1 : "" }}</span>
      </template>
      <!-- <span class="grey-navy--text">{{ block.weeks }}</span> -->
      <span class="grey-navy--text">&nbsp;</span>
      <j-block-header-card
        :title="block.title"
        :avatarPicture="block.leadInstructor.userProfile.profilePicture"
        :secondaryAvatarPicture="secondaryInstructorProfilePic(block)"
        :subTitle="instructorNames(block)"
        accentColor="exercise"
        :showArrow="false"
        :showDone="block.hasCompleted"
        :showCurrent="block.isCurrent"
        :showUpcoming="block.isUpcoming"
        class="background-grey"
        @click="handleSelection(block, index)"
      />
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { RECOMMENDED_WORKOUT_JOURNEY_QUERY } from "@/graphql/queries/workoutblock";

export default {
  name: "RecommendationTimeline",
  data() {
    return {
      selectedIndex: 0,
    };
  },
  methods: {
    secondaryInstructor(block) {
      return block.secondaryInstructor ?? null;
    },
    secondaryInstructorProfilePic(block) {
      return this.secondaryInstructor(block)?.userProfile?.profilePicture;
    },
    instructorNames(block) {
      let secondaryInstructor = this.secondaryInstructor(block)?.firstName;
      return `${block.leadInstructor.firstName}${secondaryInstructor ? " & " + secondaryInstructor : ""}`;
    },
    color(index) {
      if (index == this.selectedIndex) {
        return "pink";
      } else if (index > this.selectedIndex) {
        return "grey-navy";
      }
      return "grey-navy lighten-2";
    },
    handleSelection(block, index) {
      this.$emit("blockSelected", block.id);
      this.selectedIndex = index;
      this.setBlocks(index);
    },
    setBlocks(index, blocks = this.recommendedWorkoutJourney) {
      let inactiveBlocks = blocks.slice(0, index);
      inactiveBlocks.forEach((block) => {
        block.weeks = "";
      });

      let activeBlocks = blocks.slice(index);

      let week = 1;
      activeBlocks.forEach((block) => {
        let weeks = block.duration / 7;
        if (weeks > 1) {
          block.weeks = `Weeks ${week}—${week + (weeks - 1)}`;
        } else {
          block.weeks = `Week ${week}`;
        }

        week += weeks;
      });
      this.$emit("block-selected", activeBlocks[0].id);
    },
  },
  apollo: {
    recommendedWorkoutJourney: {
      query: RECOMMENDED_WORKOUT_JOURNEY_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        this.selectedIndex = 0;
        let blocks = data.recommendedWorkoutJourney;
        blocks.every((block, index) => {
          if (block.hasCompleted || block.isCurrent || block.isUpcoming) {
            this.selectedIndex += 1;

            if (index == blocks.length - 1) {
              this.selectedIndex = 0;
            }
            return true;
          } else {
            return false;
          }
        });

        this.setBlocks(this.selectedIndex, blocks);

        return blocks;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.6;
}
</style>
