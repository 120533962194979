<template>
  <v-row v-if="$apollo.loading" align="center" justify="center" class="my-6">
    <v-col>
      <v-skeleton-loader type="avatar@7" class="d-flex mb-2 justify-center" />
      <v-skeleton-loader type="avatar@7" class="d-flex mb-1 justify-center" />
      <v-skeleton-loader type="avatar@7" class="d-flex mb-1 justify-center" />
      <v-skeleton-loader type="avatar@7" class="d-flex mb-1 justify-center" />
    </v-col>
  </v-row>
  <v-container v-else class="calendar-dots streak-calendar-dots" :class="{ 'mb-4': !readOnly }">
    <v-row align="center" justify="center">
      <v-col class="pb-0 px-0 relative mt-n2">
        <v-icon v-if="!hasOpenPanel" color="secondary" @click="updateWeek(-1)" class="navigate-week left"
          >keyboard_arrow_left</v-icon
        >
        <v-icon v-if="!hasOpenPanel" @click="updateWeek(1)" color="secondary" class="navigate-week right"
          >keyboard_arrow_right</v-icon
        >
        <v-tabs
          v-if="!$apollo.loading"
          :v-model="readOnly ? null : dayTab"
          :centered="true"
          :icons-and-text="true"
          color="secondary"
          slider-size="0"
        >
          <div v-if="weeklyTrackerSummary" class="d-flex flex-column activity-icons">
            <v-icon color="light-blue" size="18" class="py-special">mdi-run</v-icon>
            <v-icon color="green" size="18" class="py-special">
              mdi-silverware-fork-knife
            </v-icon>
            <v-icon
              v-if="weeklyGoal && weeklyGoal.targets.weekly_nutrition > 0"
              color="green"
              size="18"
              class="py-special"
              >mdi-check-decagram</v-icon
            >
            <v-icon v-if="trackingMindset" color="yellow" size="18" class="py-special"> mdi-lightbulb-on </v-icon>
            <v-icon v-if="trackingHydration" color="hydration" size="18" class="py-special"> mdi-water </v-icon>
            <v-icon v-if="trackingSleep" color="sleep" size="18" class="py-special"> mdi-moon-waning-crescent </v-icon>
          </div>
          <v-tab
            v-for="(key, index) in days"
            :key="index"
            :icons-and-text="true"
            class="py-3 mb-0 px-0 day-tab"
            :class="{ active: dayTab == index }"
            align="start"
            @click.native="readOnly ? null : changeDate(index)"
          >
            <calendar-dot-tab
              v-if="eligible && weeklyTrackerSummary"
              :hasOpenPanel="readOnly ? null : hasOpenPanel"
              :active="readOnly ? false : dayTab == index"
              :prizesEnabled="prizesEnabled"
              :index="readOnly ? null : index"
              :keyDay="key.day"
              :keyDate="key.date"
              :workoutImage="eligible.dayBreakdown[index].workoutImage"
              :loggedWorkout="eligible.dayBreakdown[index].loggedWorkout"
              :greenDay="eligible.dayBreakdown[index].greenDay"
              :loggedNutrition="eligible.dayBreakdown[index].loggedNutritionNoPic"
              :logged-focus="nutritiousDay(key.date)"
              :qualified="eligible.qualifiedForPrize"
              :borderColorStyle="borderColorStyle"
              :accentColor="accentColor"
              :trackingMindset="trackingMindset"
              :trackingSleep="trackingSleep"
              :trackingHydration="trackingHydration"
              :sleepLogged="weeklyTrackerSummary && weeklyTrackerSummary[index].sleep"
              :partialSleepLogged="weeklyTrackerSummary && weeklyTrackerSummary[index].partialSleep"
              :hydrationLogged="weeklyTrackerSummary && weeklyTrackerSummary[index].hydration"
              :mindsetLogged="weeklyTrackerSummary && weeklyTrackerSummary[index].mindset"
              :restLogged="weeklyTrackerSummary && weeklyTrackerSummary[index].rest"
              :weekly-goal-set="!!weeklyGoal && weeklyGoal.targets.weekly_nutrition > 0"
              @openWorkout="$router.push('/today/exercise')"
              @openNutrition="$router.push('/today/nutrition')"
              @openMindset="$router.push('/today/mindset')"
            />
          </v-tab>
          <div class="prize-summary-wrapper d-flex flex-column">
            <div
              v-if="prizesEnabled"
              class="prize-summary flex-shrink-1 pointer"
              :class="eligible.qualifiedForPrize ? 'qualified-shadow' : 'elevation-3'"
              @click.stop="showPrizeModal = true"
              :style="prizeSummaryMarginTop"
            >
              <prize-summary-pill :qualifiedForPrize="eligible.qualifiedForPrize" :activePrize="activePrize" />
            </div>
          </div>
        </v-tabs>
      </v-col>
    </v-row>

    <v-dialog
      v-if="showPrizeModal"
      v-model="showPrizeModal"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <prize-details :qualified="eligible.qualifiedForPrize" @closed="showPrizeModal = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import { ACTIVE_PRIZE_QUERY, PRIZE_ELIGIBILITY_QUERY } from "@/graphql/queries/prizeCalendar";
import { WEEKLY_TRACKER_SUMMARY_QUERY } from "@/graphql/queries/tracker";
import { WEEKLY_GOAL_QUERY } from "@/graphql/queries/goal";
import { INBOX_QUERY } from "@/graphql/queries/inbox";
import { mapGetters, mapActions } from "vuex";
import { getDayOfWeekString } from "@/lib/timezone";
import { getMonday } from "@/lib/calendarWeek";
import { formatDate } from "@/lib/fancy_date";
import CalendarDotMixin from "@/mixins/CalendarDotMixin";
import UserMixin from "@/mixins/UserMixin";
import PrizeDetails from "@/components/shared/dialogs/PrizeDetails";
import CalendarDotTab from "@/components/shared/sections/CalendarDotTab";
import PrizeSummaryPill from "@/components/shared/blocks/PrizeSummaryPill";

export default {
  name: "StreakCalendarDots",
  mixins: [CalendarDotMixin, UserMixin],
  components: {
    CalendarDotTab,
    PrizeDetails,
    PrizeSummaryPill,
  },
  props: {
    accentColor: {
      type: String,
      required: false,
      default: "blue",
    },
    hasOpenPanel: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      showPrizeModal: false,
      updateInbox: false,
    };
  },
  filters: {
    fancyDate(val) {
      return formatDate(val, "dddd, D MMM YYYY");
    },
  },
  watch: {
    selectedDate: function (date) {
      this.week = getDayOfWeekString(1, false, this.$moment(date).format("YYYY-MM-DD"), false).substring(0, 10);
      let day = this.$moment(date).day();
      this.dayTab = day - (day == 0 ? -6 : 1);
    },
  },
  computed: {
    ...mapGetters(["selectedDate", "role"]),
    monday() {
      if (this.selectedDate) {
        return this.$moment(getMonday(this.selectedDate.format("YYYY-MM-DD"))).format("YYYY-MM-DD");
      }
      return this.$moment(getMonday(this.$moment().format("YYYY-MM-DD"))).format("YYYY-MM-DD");
    },
    prizeSummaryMarginTop() {
      let prizeCount =
        (this.activePrize.entryCopy != null) +
        (this.activePrize.drawCopy != null) +
        (this.activePrize.rewardsCopy != null);
      switch (prizeCount) {
        case 1:
        case 2:
          return "margin-top: 90px";
        case 3:
          return "margin-top: 79px";
      }
      return "margin-top: 79px";
    },
    prizesEnabled() {
      return this.activePrize && ["ONE_ON_ONE", "CLUB"].includes(this.role);
    },
    trackingMindset() {
      return (
        this.weeklyTrackerSummary?.some((summary) => summary.mindset == true) ||
        this.settingEnabled("stress-tracking") ||
        this.settingEnabled("habit-tracking")
      );
    },
    trackingSleep() {
      return (
        this.weeklyTrackerSummary?.some((summary) => summary.sleep == true) || this.settingEnabled("sleep-tracking")
      );
    },
    trackingHydration() {
      return (
        this.weeklyTrackerSummary?.some((summary) => summary.hydration == true) ||
        this.settingEnabled("hydration-tracking")
      );
    },
  },
  methods: {
    ...mapActions(["setSelectedDate", "setRestDays", "updateUnread"]),
    changeDate(index) {
      this.dayTab = index;
      let newDate = this.$moment(this.week, "YYYY-MM-DD")
        .startOf("week")
        .add(index + 1, "days");
      this.setSelectedDate(newDate);
      this.$emit("dateUpdated", newDate);
    },
    setDay() {
      //if given the selected date; set the day
      var today = new Date();
      var day = today.getDay();
      this.setSelectedDate(this.$moment());

      this.dayTab = day - (day == 0 ? -6 : 1);
    },
    nutritiousDay(date) {
      let formattedDate = this.$moment(date).format("YYYY-MM-DD");
      return this.weeklyGoal?.nutrition_breakdown[formattedDate];
    },
  },
  apollo: {
    activePrize: {
      query: ACTIVE_PRIZE_QUERY,
      variables() {
        return {
          date: this.monday,
        };
      },
      fetchPolicy: "cache-and-network",
    },
    eligible: {
      query: PRIZE_ELIGIBILITY_QUERY,
      variables() {
        let data = {
          date: this.monday,
        };
        if (this.userId) {
          data.userId = this.userId;
        }

        return data;
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.userPrizeWeeklyEligibility?.newInbox) {
          this.updateInbox = true;
        }
        
        return data.userPrizeWeeklyEligibility;
      },
    },
    weeklyGoal: {
      query: WEEKLY_GOAL_QUERY,
      variables() {
        return {
          weekStart: this.monday,
        };
      },
      update(data) {
        return data.weeklyGoals;
      },
    },
    weeklyTrackerSummary: {
      query: WEEKLY_TRACKER_SUMMARY_QUERY,
      variables() {
        let data = {
          summaryDate: this.monday,
        };
        if (this.userId) {
          data.userId = this.userId;
        }

        return data;
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        this.loading = false;
        if (data.weeklyTrackerSummary) {
          let rest = data.weeklyTrackerSummary.map((day) => {
            return {
              node: {
                restDayDate: formatDate(day.day, "YYYY-MM-DD", false),
                didRest: day.rest,
              },
            };
          });
          if (!this.userId) {
            this.setRestDays(rest);
          }
        }
        return data.weeklyTrackerSummary;
      },
    },
    inboxMessages: {
      query: INBOX_QUERY,
      fetchPolicy: "no-cache",
      pollInterval: 15 * 60 * 1000,
      variables() {
        return {
          unreadOnly: true,
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          let unread = response.data.inboxMessages.importantCount + response.data.inboxMessages.freshCount;
          this.updateUnread(unread);
          this.featuredInboxMessage = this.inboxMessages.edges.find((obj) => {
            return obj.node.dismissed === false && obj.node.inboxMessage.showAsBanner;
          });
          this.updateInbox = false;
        }
      },
      skip() {
        return !this.updateInbox;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-dots .v-item-group {
  height: 100%;
}

.streak-calendar-dots .v-tabs {
  .activity-icons {
    margin-top: 78px;

    .py-special {
      padding: 15px 0;
    }
  }

  .v-tab--active.active::before {
    opacity: 0.12;
    border-radius: 25px;
  }

  .v-tab:hover::before {
    border-radius: 25px;
  }

  .prize-summary {
    border: 3px solid var(--v-yellow-base);
    background-color: white;
    min-height: 70px;
    max-height: 100px;
    z-index: 1;

    &.qualified-shadow {
      box-shadow: 0px 3px 3px -2px var(--v-yellow-darken2), 0px 3px 4px 0px var(--v-yellow-darken2),
        0px 1px 8px 0px var(--v-yellow-base);
    }

    &,
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-radius: 100px;
    }

    .icon-wrapper .v-icon {
      padding: 3px 0;
      width: 30px;
    }
  }
}
</style>
