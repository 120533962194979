<template>
  <div class="pb-6">
    <j-card rounded="xl" class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
      <v-list dense>
        <v-list-item-group class="px-4">
          <v-list-item class="px-0" :to="`/p/${handle}`">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-account-voice</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text">Profile</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/rewards/points">
            <v-list-item-icon class="pt-1 mr-2">
              <v-avatar tile size="16">
                <img :src="require('@/assets/images/rewards-navy.svg')" max-height="18" max-width="18" />
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text">Rewards</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <!-- <v-divider />
          <v-list-item class="px-0" to="/shop">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text">Shop</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item> -->
          <v-divider />
          <v-list-item class="px-0" to="/social">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text"> Friends </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <template v-if="user && user.userProfile.friendsCount > 0">
                <span class="p-text counter">{{ user.userProfile.friendsCount }}</span>
              </template>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </j-card>
    <j-card rounded="xl" class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
      <v-list dense>
        <v-list-item-group class="px-4">
          <v-list-item class="px-0" to="/checkin/assessments">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy"> mdi-clipboard-check-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text">Check-ins</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <template v-if="user && user.userProfile.checkInCount > 0">
                <span class="p-text counter">{{ user.userProfile.checkInCount }}</span>
              </template>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <!-- <v-list-item class="px-0" to="/celebrations">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-party-popper</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text"> Celebrations </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider /> -->
          <v-list-item class="px-0" to="/leaderboards">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-chart-bar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text"> Leaderboards </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </j-card>
    <j-card rounded="xl" class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
      <v-list dense>
        <v-list-item-group class="px-4">
          <v-list-item class="px-0" to="/your-programs">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy"> mdi-book-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text"
                >Programs, Menu, Courses
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <!-- <v-divider />
          <v-list-item class="px-0" to="/social/community-fitness/">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text"> mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">Events </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </j-card>

    <j-card rounded="xl" class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
      <v-list dense>
        <v-list-item-group class="px-4">
          <div v-if="leaderboardSettings">
            <v-list-item class="px-0" to="/leaderboard">
              <v-list-item-icon class="mr-2">
                <v-icon class="navy--text">mdi-format-list-bulleted-type</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="navy--text text-uppercase font-weight-bold">Leaderboard</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon class="navy--text">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider />
          </div>
          <v-list-item class="px-0" to="/consistency">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-chart-scatter-plot</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text">Consistency</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/accountability/dots">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-grain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text">Dots</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <template v-if="user && user.userProfile.dotsCount > 0">
                <span class="p-text counter">{{ user.userProfile.dotsCount }}</span>
              </template>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/badges">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-police-badge-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold d-flex navy--text">
                <span class="mr-1 pt-1">Badges</span>
                <div v-for="(item, index) in awardedBadges" :key="index">
                  <v-img :alt="item.node.badge.title" :src="item.node.badge.iconLink" max-width="25px" />
                </div>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <template v-if="user && user.userProfile.earnBadgeCount > 0">
                <span class="p-text counter">{{ user.userProfile.earnBadgeCount }}</span>
              </template>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" :to="`/bookmarks/${encodedUserId}`">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-bookmark-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text"> Bookmarks </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <template v-if="bookmarks && bookmarks.edges.length > 0">
                <span class="p-text counter">{{ bookmarks.edges.length }}</span>
              </template>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </j-card>

    <j-card rounded="xl" class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
      <v-list dense>
        <v-list-item-group class="px-4">
          <v-list-item class="px-0" to="/account/preferences">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-image-auto-adjust</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text">Preferences</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/account/general">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-post-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text"> Personal </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <span v-if="this.$store.getters.role == 'ONE_ON_ONE'">
            <v-divider />
            <v-list-item class="px-0" to="/account/billing">
              <v-list-item-icon class="mr-2">
                <v-icon color="navy">mdi-credit-card-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase font-weight-bold navy--text">
                  Billing
                  <!-- <v-chip v-if="upgradableSubscription" dark label small color="yellow" class="ml-4 px-2">Upgrade</v-chip> -->
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="navy">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </span>

          <!-- <v-list-item v-if="this.$store.getters.role == 'CLUB'" class="px-0" to="/account/discovery">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-share-variant mdi-rotate-270 </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text">
                Vitality
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </j-card>

    <j-card rounded="xl" class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
      <v-list dense>
        <v-list-item-group class="px-4">
          <v-list-item v-if="supportChatId" class="px-0" :to="`/chats/${supportChatId}`">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-chat</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div style="width: 100%">
                <v-list-item-title class="text-uppercase font-weight-bold navy--text"> Support </v-list-item-title>
              </div>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" href="https://www.planetfitness.co.za/">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">mdi-cursor-default-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text"> Website </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" @click="logout">
            <v-list-item-icon class="mr-2">
              <v-icon color="navy">exit_to_app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-bold navy--text"> Logout </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="navy">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </j-card>
  </div>
</template>

<script>
import { AWARDED_BADGES_QUERY } from "@/graphql/queries/badges";
import { USER_NAV_QUERY } from "@/graphql/queries/profile";
import { BOOKMARKS_QUERY } from "@/graphql/queries/bookmark";

export default {
  name: "MenuOptions",
  data() {
    return {
      user: null,
      awardedBadges: null,
      bookmarks: null,
    };
  },
  computed: {
    supportChatId() {
      return this.$store.getters.user?.userProfile?.supportChat?.id;
    },
    handle() {
      if (this.$store.getters.user?.userProfile?.handle) return "@" + this.$store.getters.user?.userProfile?.handle;
      return this.encodedUserId;
    },
    encodedUserId() {
      if (!this.$store.getters.user?.id) {
        return null;
      }

      return btoa(`UserNode: ${this.$store.getters.user.id}`);
    },
    upgradableSubscription() {
      return this.user?.userProfile?.activeSubscription?.product?.type != "ONE_ON_ONE";
    },
    leaderboardSettings() {
      let setting = this.$store.getters.settings.get("LEADERBOARD");
      return setting?.get("leaderboard-tribes-active") === "true" || setting?.get("leaderboard-gyms-active") === "true";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  apollo: {
    user: {
      query: USER_NAV_QUERY,
      variables() {
        return {
          userId: this.encodedUserId,
        };
      },
      skip() {
        return !this.encodedUserId;
      },
    },
    bookmarks: {
      query: BOOKMARKS_QUERY,
      variables() {
        return {
          userId: this.encodedUserId,
          orderBy: "-created_at",
        };
      },
      skip() {
        return !this.encodedUserId;
      },
      fetchPolicy: "cache-and-network",
    },
    awardedBadges: {
      query: AWARDED_BADGES_QUERY,
      variables() {
        return {
          userId: this.encodedUserId,
          first: 4,
        };
      },
      skip() {
        return !this.encodedUserId;
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.awardedBadges.edges) {
          return data.awardedBadges.edges;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  margin-top: 3px;
  font-weight: bold;
}
</style>
