<template>
  <j-subsection-row
    title="Fresh classes"
    color="planet-grey"
    :see-all="{
      text: 'See all',
      link: '/live',
    }"
    class="mt-2 px-0"
  >
    <v-carousel v-model="slide" hide-delimiters :height="null" class="mt-n2">
      <v-carousel-item v-for="currentSlide in totalSlides" :key="currentSlide">
        <div v-if="$apollo.loading" class="d-flex flex-row">
          <v-skeleton-loader v-for="loadingCard in cardsPerSlide" :key="loadingCard" type="card" class="flex-fill mx-1 rounded-xl" />
        </div>
        <div v-else-if="freshClasses" class="d-flex flex-row">
          <j-workout-card
            v-for="fresh in freshClasses.slice(
              freshClassIndex(currentSlide),
              freshClassIndex(currentSlide) + cardsPerSlide
            )"
            :key="fresh.id"
            small
            :imageSrc="imageSrc(fresh)"
            :instructor-profile-pic="profilePicture(fresh)"
            :title="fresh.name"
            :instructor-name="'with ' + fresh.instructor.firstName"
            :card-width="cardSize"
            :overlay-play="manualOverlay(fresh)"
            @navigate="$router.push(`/event/${decodeId(fresh.id)}`);"
            class="ma-2 py-2"
          />
        </div>
      </v-carousel-item>
    </v-carousel>
  </j-subsection-row>
</template>

<script>
import { FRESH_CLASSES } from "@/graphql/queries/home";
import { decodeId } from "@/lib/string";
import JWorkoutCard from "@/layouts/cards/JWorkoutCard";

export default {
  name: "TodayFreshSection",
  components: {
    JWorkoutCard,
  },
  props: {
    skipFreshBlock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slide: 0,
      cardsPerSlide: 2,
    };
  },
  computed: {
    totalSlides() {
      if (this.freshClasses) {
        return Math.ceil(this.freshClasses.length / this.cardsPerSlide);
      }
      return 1;
    },
    cardSize() {
      return `${100 / this.cardsPerSlide - this.cardsPerSlide}%`;
    },
  },
  methods: {
    freshClassIndex(slide) {
      return slide * this.cardsPerSlide - this.cardsPerSlide;
    },
    imageSrc(node) {
      const overlayImage = "l_playnavy_mtlav7";

      if (node.embedUrl && node.embedUrl.includes("youtube")) {
        const youtubeId = node.embedUrl.split("watch?v=")[1];
        return `https://res.cloudinary.com/jeff-fitness/image/youtube/${overlayImage}/w_250/fl_layer_apply/v1/${youtubeId}.jpg`;
      } else if (node.youtubeId) {
        return `https://res.cloudinary.com/jeff-fitness/image/youtube/${overlayImage}/w_250/fl_layer_apply/v1/${node.youtubeId}.jpg`;
      } else if (node.thumbnail) {
        return node.thumbnail;
      }

      return "";
    },
    profilePicture(node) {
      return node.instructor?.userProfile?.profilePicture;
    },
    manualOverlay(node) {
      return !this.imageSrc(node)?.includes("res.cloudinary.com/jeff-fitness");
    },
    decodeId(val) {
      return decodeId(val);
    }
  },
  apollo: {
    freshClasses: {
      query: FRESH_CLASSES,
      variables() {
        return {
          first: 12,
          scheduledDate_Lte: this.$moment().startOf("isoweek"),
          featureForWeek: true,
          skip: this.skipFreshBlock ? 1 : 0,
        };
      },
      update(data) {
        return data.paginatedEvents.edges.map((edge) => edge.node);
      },
    },
  },
};
</script>
