<template>
    <j-banner-container
      header="Community reps"
    >
      <delete-confirm ref="confirmModal" />
      <v-row align="center" justify="center">
        <v-col align="center" justify="center">
          <j-btn color="secondary" class="my-2" @click="addRepLog()">
            <strong class="white--text">Log my reps</strong>
          </j-btn>
        </v-col>
      </v-row>
      <v-row v-if="loadingRepList" align="center" justify="center">
        <v-col cols="12" align="center" justify="center">
          <v-progress-circular :indeterminate="true" color="blue"></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else align="center" justify="center" class="mt-0">
        <v-col cols="12" align="center" justify="center">
          <div v-if="userRepLog.edges.length > 0">
            <j-card v-for="(loggedRep, index) in userRepLog.edges" :key="index" class="j-elevated-1 pa-3 mb-5">
              <v-toolbar flat class="pa-0 ma-0">
                <span class="secondary--text">{{ niceDate(loggedRep.node.loggedDate) }}</span>
              </v-toolbar>
              <v-card-title class="secondary--text py-0">
                {{ loggedRep.node.repType.title }}
              </v-card-title>
              <v-card-text class="text-left pb-0">
                <v-list flat class="py-0">
                  <v-list-item class="px-0">
                    <v-list-item-content class="py-0 my-0">
                      <v-list-item-title> Reps: {{ loggedRep.node.repetitions }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <j-btn tertiary @click="deleteRepLogConfirm(loggedRep.node)">
                  Delete
                </j-btn>
                <j-btn secondary @click="editRepLog(loggedRep.node)">
                  Edit
                </j-btn>
              </v-card-actions>
            </j-card>
          </div>
          <div v-else>
            <j-alert type="success">You don't have any reps logged. Tap 'Log My Reps' to get started!</j-alert>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="showRepModal" max-width="600px">
        <reps-logging v-if="showRepModal" @close="showRepModal = false" :rep="editLog" />
      </v-dialog>
    </j-banner-container>
</template>

<script>
import { LOGGED_REPS_QUERY } from "@/graphql/queries/communityStats";
import { DELETE_USER_REP_LOG } from "@/graphql/mutations/communityStats";
import { fancy_date } from "@/lib/fancy_date";
import DeleteConfirm from "@/components/shared/dialogs/DeleteConfirm";
import RepsLogging from "@/components/community-stats/RepsLogging";

export default {
  name: "CommunityStatsReps",
  components: {
    DeleteConfirm,
    RepsLogging,
  },
  data() {
    return {
      loading: false,
      loadingRepList: true,
      showRepModal: false,
      editLog: null,
    };
  },
  methods: {
    niceDate(date) {
      return fancy_date(date, "ddd, D MMMM YYYY", false);
    },
    deleteRepLogConfirm(rep) {
      this.$refs.confirmModal.open().then((confirmed) => {
        if (confirmed) {
          this.deleteRepLog(rep);
        }
      });
    },
    addRepLog() {
      this.editLog = null;
      this.showRepModal = true;
    },
    editRepLog(rep) {
      this.editLog = rep;
      this.showRepModal = true;
    },
    deleteRepLog(rep) {
      let id = atob(rep.id).split(":")[1];

      this.loading = true;
      this.loadingRepList = true;

      this.$apollo.mutate({
        mutation: DELETE_USER_REP_LOG,
        variables: {
          id: id,
        },
        refetchQueries: ["communityRepLogQuery", "userRepLogQuery"],
      });
    },
  },
  apollo: {
    userRepLog: {
      query: LOGGED_REPS_QUERY,
      result(response) {
        if (response.data && !response.loading) {
          this.loadingRepList = false;
        }
      },
    },
  },
};
</script>
