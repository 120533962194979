<template>
  <span v-if="highlightUserType" class="user-type-highlight" :class="getClass" :style="getStyle">{{
    chipText | capitalize
  }}</span>
</template>

<script>
import { capitalize } from "@/lib/string";

export default {
  name: "UserTypeHighlight",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  filters: {
    capitalize(val) {
      return capitalize(val);
    },
  },
  computed: {
    activeTribe() {
      return !!this.user?.userProfile?.activeTribe;
    },
    chipText() {
      if (this.activeTribe) {
        return this.user?.userProfile?.activeTribe.title;
      }
      return this.user.userProfile.userType;
    },
    highlightUserType() {
      if (this.activeTribe) {
        return true;
      }
      return (
        this.user?.userProfile?.userType === "INSTRUCTOR" ||
        this.user?.userProfile?.userType === "COACH" ||
        this.user?.userProfile?.userType === "AMBASSADOR"
      );
    },
    getClass() {
      if (this.user?.userProfile?.userType === "INSTRUCTOR" || this.user?.userProfile?.userType === "COACH") {
        return "user-type-staff";
      }
      if (this.user?.userProfile?.userType === "AMBASSADOR") {
        return "user-type-member";
      }
      return null;
    },
    getStyle() {
      if (this.activeTribe) {
        return {
          backgroundColor: this.user?.userProfile?.activeTribe?.hexColour,
          color: "white",
        };
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.user-type-highlight {
  padding: 1px 5px;
  font-size: 11px;
  border-radius: 3px;
}

.user-type-staff {
  color: var(--v-exercise-base);
  background-color: var(--v-grey-background-base);
}

.user-type-member {
  color: var(--v-text-grey-base);
  background-color: var(--v-background-grey-base);
}
</style>
