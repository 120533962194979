<template>
  <div>
    <j-alert v-if="$store.getters.role == 'JEFF_MEMBER' || $store.getters.role == 'INACTIVE'" type="warning" icon="mdi-rocket-launch">
      <span v-if="$store.getters.role == 'JEFF_MEMBER'"
        >You're currently on <strong>JEFF Life</strong>
        <br />
      </span>
      Want to access Programs, Courses, Groups and much more Support?
      <br /><br />
      <j-btn class="mt-n3" primary to="/checkout/jeff-club"
        >Upgrade to JEFF Club <v-icon class="ml-1 mt-n1">mdi-account-group</v-icon></j-btn
      >
      <br />
      <j-btn class="mt-2" secondary to="/checkout/1-on-1"
        >Get a 1-on-1 Coach <v-icon class="ml-1 mt-n1">mdi-account-star</v-icon></j-btn
      >
    </j-alert>

    <j-alert v-if="$store.getters.role == 'CLUB'" type="warning" icon="mdi-star-circle-outline">
      You're currently on our <strong>free version</strong>
      <br />
      Want a 1-on-1 Coach for individualised support, expert guidance and accountability?
      <br /><br />
      <j-btn class="mt-n3" primary @click="$emit('upsell')"
        >Get a 1-on-1 Coach <v-icon class="ml-1 mt-n1">mdi-account-star</v-icon></j-btn
      >
    </j-alert>
  </div>
</template>

<script>
export default {
  name: "Upsell",
};
</script>
