<template>
  <v-container>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="title"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="pdf-item" style="margin: 40px; 40px; 0px; 40px">
          <table style="width: 100%; margin-bottom: 10px;">
            <tr>
              <td align="left">
                <h1 class="pdf-heading-1">{{ title }}</h1>
              </td>
              <td align="right" style="width: 105px;">
                <img width="100" src="https://storage.googleapis.com/master_site/planetplay.png" />
              </td>
            </tr>
          </table>

          <slot name="content"></slot>
        </section>
      </section>
    </vue-html2pdf>
  </v-container>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "GeneratePdf",
  components: {
    VueHtml2pdf,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  methods: {
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
