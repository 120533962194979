<template>
  <div>
    <v-app-bar light flat height="80" class="app-bar" align="center" justify="center">
      <v-toolbar-title>
        <a :href="homeLink">
          <img :alt="$appName + ' Logo'" src="@/assets/images/logo.png" width="50" />
        </a>
      </v-toolbar-title>
      <v-spacer />
      <div v-if="showNavPills && showNav" class="d-flex pb-2">
        <div class="d-flex">
          <div class="mr-2">
            <div class="text-center">
              <my-coach />
            </div>
          </div>
          <div class="mr-2">
            <div class="text-center">
              <v-btn icon class="d-flex mx-auto" to="/today/inbox" large>
                <v-badge overlap avatar :value="showUnreadNotification" color="green-background">
                  <template v-slot:badge>
                    <div class="mt-1 green--text">
                      <strong>{{ unread }}</strong>
                    </div>
                  </template>
                  <v-icon size="30" color="white">mdi-email-newsletter</v-icon>
                </v-badge>
              </v-btn>
              <div class="sm-text white--text">INBOX</div>
            </div>
          </div>

          <div>
            <div class="text-center">
              <v-btn icon class="d-flex mx-auto" to="/today/to-do" large>
                <v-badge overlap avatar :value="showAssistant" color="green-background">
                  <template v-slot:badge>
                    <div class="mt-1 green--text">
                      <strong>{{ tasks }}</strong>
                    </div>
                  </template>
                  <v-icon size="30" color="white">mdi-check-circle</v-icon>
                </v-badge>
              </v-btn>
              <div class="sm-text white--text">TO DO</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <v-btn icon class="d-flex mx-auto" @click="drawer = true" large>
              <v-avatar color="blue-background">
                <img v-if="profileImage" :src="profileImage" />
                <span v-if="!profileImage && user" class="white--text">
                  {{ user.firstName ? user.firstName.charAt(0).toUpperCase() : ""
                  }}{{ user.lastName ? user.lastName.charAt(0).toUpperCase() : "" }}</span
                >
              </v-avatar>
            </v-btn>
            <div id="v-step-10" class="sm-text pl-3 pointer white--text" @click="drawer = true">
              PROFILE <v-icon color="white" class="mt-n1 ml-n1 pointer" @click="drawer = true">mdi-menu-down</v-icon>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="isLoggedIn && !showNav">
        <v-btn icon to="/gate" class="gate-button">
          <v-icon size="48" :style="textGradientStyle" class="pa-6 rounded-circle">mdi-home-circle</v-icon>
        </v-btn>
      </div>
      <div v-if="!isLoggedIn && showLoginLink" class="p-text white--text">
        Already have a profile?
        <router-link class="secondary--text font-weight-bold text-decoration-none white--text" to="/login">
          Log in
        </router-link>
      </div>
    </v-app-bar>
    <!-- bug with navigation drawer on iOS. Set height to 100% and not default 100vh https://github.com/vuetifyjs/vuetify/issues/9607-->
    <v-navigation-drawer v-model="drawer" app right temporary width="340" style="height: 100%">
      <v-btn large tile text @click.stop="drawer = !drawer" align="right">
        Close <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <menu-options />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { gradientStyle } from "@/lib/gradientStyle";
import { mapGetters } from "vuex";
import MenuOptions from "@/components/shared/MenuOptions";
import MyCoach from "@/components/coach/MyCoach";

export default {
  name: "TheNavBar",
  components: {
    MenuOptions,
    MyCoach,
  },
  props: {
    showNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      showLoginLink: false,
    };
  },
  computed: {
    ...mapGetters(["unread", "tasks", "unreadRewardNotificationCount"]),
    userId() {
      return window.btoa(`UserNode: ${this.user.id}`);
    },
    showNavPills() {
      if (this.$route?.name?.toLowerCase().includes("checkout") || this.$route?.name == "RequiresApp") {
        return false;
      }
      return this.isLoggedIn && this.user;
    },
    showNavigation() {
      return this.$store.getters.isDiscoveryMember && !this.$store.getters.providedIdentification;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.getters.user;
    },
    profileImage() {
      return this.$store.getters.profileImage;
    },
    fullAccess() {
      return this.$store.getters.role == "ONE_ON_ONE";
    },
    homeLink() {
      let link = "/today";
      if (!this.$store.getters.isLoggedIn) {
        link = "/login";
      } else if (this.fullAccess) {
        link = "/today";
      }
      return link;
    },
    showUnreadNotification() {
      return this.unread > 0;
    },
    showAssistant() {
      return this.tasks > 0;
    },
    textGradientStyle() {
      return gradientStyle(["yellow", "green"], 90);
    },
  },
  watch: {
    $route: "routeChanged",
  },
  methods: {
    toggleLoginLink() {
      this.showLoginLink = this.$route.path.includes("/start");
    },
    routeChanged() {
      this.toggleLoginLink();
    },
  },
  created() {
    this.toggleLoginLink();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.button-text {
  text-transform: uppercase;
  font-size: small;
}

.v-btn.gate-button .v-btn__content .v-icon {
  @extend .gradient-text;
  font-weight: bold;
}

.v-btn.gate-button {
  color: var(--v-grey-background-base);
}
</style>
