<template>
  <div>
    <j-card>
      <v-card-title class="font-weight-bold pb-2">
        <v-spacer />
        <j-btn small narrow class="mr-1" @click="logCustomWorkoutDialog = true">
          Log Activity
          <v-icon small class="ml-1">mdi-plus-circle-outline</v-icon>
        </j-btn>
        <rest-day-card ref="restDayCard" class="mr-1 d-flex">Rest</rest-day-card>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader v-if="$apollo.loading" type="list-item-avatar" />
        <div v-else class="mt-4">
          <div class="d-flex justify-space-between">
            <h4>Programs</h4>
            <v-spacer />
            <v-btn small text to="/your-programs" class="grey-navy--text">
              <strong>See All Programs</strong>
            </v-btn>
          </div>
          <div v-if="activeWorkoutBlocks">
            <div
              v-for="activeWorkoutBlock in activeWorkoutBlocks"
              :key="activeWorkoutBlock.id"
              class="workout-block-section"
            >
              <workout-block-overview
                :workout-block="activeWorkoutBlock.block"
                :subscription-block="activeWorkoutBlock"
                :startDate="activeWorkoutBlock.startDate"
                :end-date="activeWorkoutBlock.endDate"
                :anchor-date="selectedDate.format('YYYY-MM-DD')"
                type="PROGRAM"
                @openRestDay="openRestDay()"
              />
            </div>
            <j-alert v-if="endingBlock" type="warning" icon="mdi-flare" class="alert-line-height">
              <strong>Program finishing end of week</strong>
              <span class="block" :class="colorCheckinText">
                <v-icon v-if="recentCheckIn" color="green" class="ml-n7 mr-1 mb-1" size="25"> mdi-check-circle </v-icon>
                <strong>1. </strong>
                Add a
                <router-link
                  to="/checkins/new"
                  class="font-weight-bold text-decoration-underline"
                  :class="colorCheckinText"
                >
                  check-in
                </router-link>
                to track progress
              </span>
              <span :class="colorUpcomingText">
                <v-icon
                  v-if="upcomingPrograms && upcomingPrograms.length > 0"
                  color="green"
                  class="ml-n7 mr-1 mb-1"
                  size="25"
                >
                  mdi-check-circle
                </v-icon>
                <strong>2. </strong>Choose your next program from
                <router-link
                  to="/journey"
                  class="font-weight-bold text-decoration-underline"
                  :class="colorUpcomingText"
                >
                  your {{ $appName }} Journey</router-link
                >
                or
                <router-link
                  to="/programs"
                  class="font-weight-bold text-decoration-underline"
                  :class="colorUpcomingText"
                >
                  browse all</router-link
                >
              </span>
            </j-alert>
          </div>
          <div v-if="upcomingPrograms && upcomingPrograms.length > 0">
            <h5 class="navy--text">Upcoming Programs</h5>
            <workout-block-overview
              v-for="workoutBlock in upcomingPrograms"
              :key="workoutBlock.id"
              :workout-block="workoutBlock.block"
              :subscription-block="workoutBlock"
              :startDate="workoutBlock.startDate"
              :end-date="workoutBlock.endDate"
              :anchor-date="selectedDate.format('YYYY-MM-DD')"
              type="PROGRAM"
            />
          </div>
          <div v-if="showChooseExercise" align="center">
            <j-dotted-btn wide color="light-blue" to="/programs">Choose Exercise Program</j-dotted-btn>
          </div>
        </div>
        <j-card class="d-flex j-elevated-1 text-left pa-5 mt-4" to="/live">
          <v-icon color="navy" class="mr-2">mdi-play-speed</v-icon>
          <span class="font-weight-bold navy--text">FRESH CLASSES</span>
          <v-icon color="navy" class="ml-auto">mdi-chevron-right</v-icon>
        </j-card>
        <j-card class="d-flex j-elevated-1 text-left pa-5 mt-4" to="/on-demand">
          <v-icon color="navy" class="mr-2">mdi-animation-play</v-icon>
          <span class="font-weight-bold navy--text">ON-DEMAND LIBRARY</span>
          <v-icon color="navy" class="ml-auto">mdi-chevron-right</v-icon>
        </j-card>
      </v-card-text>
      <v-card-actions class="grey pa-0">
        <v-btn
          elevation="0"
          color="grey"
          class="font-weight-bold rounded-b-xl py-5 px-4 grey-navy--text flex-grow-1"
          to="/accountability/dots"
        >
          SEE ALL MY ACTIVITY
          <v-spacer />
          <v-icon color="grey-navy" class="ml-auto">mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </j-card>
    <v-dialog v-model="logCustomWorkoutDialog" max-width="600px">
      <j-card v-if="logCustomWorkoutDialog" rounded="lg">
        <v-toolbar class="mb-6">
          <v-toolbar-title>
            <span>Log My Exercise</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="logCustomWorkoutDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pl-2 pr-2">
          <custom-activity-form :fullForm="true" @close="logCustomWorkoutDialog = false" />
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import { HOME_QUERY, LAST_CHECKIN_QUERY } from "@/graphql/queries/home";
import { mapGetters } from "vuex";
import RestDayCard from "@/components/tracker/RestDayCard";
import WorkoutBlockOverview from "@/components/workoutblocks/cards/WorkoutBlockOverview";
import CustomActivityForm from "@/components/tracker/activity-form/CustomActivityForm";

export default {
  name: "TodayWorkout",
  components: {
    RestDayCard,
    WorkoutBlockOverview,
    CustomActivityForm,
  },
  data() {
    return {
      activeWorkoutBlocks: null,
      upcomingPrograms: [],
      logCustomWorkoutDialog: false,
    };
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    showChooseExercise() {
      return (
        // role is not life/open
        (!this.activeWorkoutBlocks || this.activeWorkoutBlocks?.length == 0) &&
        (!this.upcomingPrograms || this.upcomingPrograms?.length === 0)
      );
    },
    endingBlock() {
      return (
        this.activeWorkoutBlocks.filter((block) => this.$moment(block.endDate).isBefore(this.$moment().add(1, "weeks")))
          .length > 0
      );
    },
    recentCheckIn() {
      if (this.lastCheckin) {
        return this.$moment(this.lastCheckin).isAfter(this.$moment().add(-1, "weeks"));
      }
      return false;
    },
    colorCheckinText() {
      return this.recentCheckIn ? "green--text" : "warning--text";
    },
    colorUpcomingText() {
      return this.upcomingPrograms?.length > 0 ? "green--text" : "warning--text";
    },
  },
  methods: {
    openRestDay() {
      this.$refs.restDayCard.modal.show = true;
    },
  },
  apollo: {
    home: {
      query: HOME_QUERY,
      variables() {
        return {
          withGroups: true,
          withGroupUsers: false,
          withUsers: true,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          let data = response.data.home;
          if (data.subscriptionBlocks) {
            // set the bool that we do have programs or challenges, even if they have ended.
            this.hasPrograms = data.subscriptionBlocks.some(
              (element) => element.block.type === "PROGRAM" || element.block.type === "CHALLENGE"
            );

            this.activeWorkoutBlocks =
              data.subscriptionBlocks.filter(
                (element) =>
                  (element.block.type === "PROGRAM" || element.block.type === "CHALLENGE") &&
                  element.progressPercent < 100 && 
                  (element.endDate == null || this.$moment(element.endDate).isAfter(this.selectedDate))
              ) ?? null;
          }
          if (data.upcomingSubscriptionBlocks) {
            this.upcomingPrograms =
              data.upcomingSubscriptionBlocks.filter(
                (element) => element.block.type === "PROGRAM" || element.block.type === "CHALLENGE"
              ) ?? null;
          }
        }
      },
    },
    lastCheckin: {
      query: LAST_CHECKIN_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data?.userCheckIns?.edges && data?.userCheckIns?.edges.length > 0) {
          return data.userCheckIns?.edges[0].node.assessmentDate;
        }

        return null;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-line-height {
  line-height: 26px;
}
</style>
