<template>
  <div v-if="!$apollo.loading">
    <div
      class="d-flex justify-space-around align-center onboarding-pill elevation-3 pointer"
      @click="$router.push('/today/to-do/onboarding')"
    >
      <div class="mr-2"><span v-if="isComplete">WELL DONE!</span><span v-else>START HERE</span></div>

      <v-progress-linear
        color="nutrition darken-1"
        background-color="white"
        class="onboarding-progress"
        rounded
        :value="progressValue"
        height="12"
      ></v-progress-linear>

      <div class="ml-2">{{ onboardingCompletedCount }} OF {{ onboardingTotalCount }}</div>
    </div>
  </div>
</template>

<script>
import TodoMixin from "@/mixins/TodoMixin";

export default {
  name: "TodoOnboardingSummary",
  mixins: [TodoMixin],
};
</script>

<style lang="scss" scoped>
.onboarding-progress {
  max-width: 125px;
}
.onboarding-pill {
  background-color: #ffc045;
  background-image: linear-gradient(180deg, var(--v-yellow-lighten2), var(--v-yellow-darken2));
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  max-width: 350px;
}
</style>
