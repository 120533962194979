<template>
  <v-skeleton-loader v-if="$apollo.loading" type="avatar" />
  <div v-else id="user-stats-avatar" class="relative mb-2">
    <user-avatar v-bind="$props" v-on="$listeners" />
    <workout-calendar-dot
      :logged="log.workoutImage != null"
      :partiallyLogged="log.loggedWorkout && log.workoutImage == null"
      :isRestDay="log.restLogged"
      :workoutImage="log.workoutImage"
      setWhiteBackground
      size="24"
      color="exercise"
      class="calendar-dot-badge left"
    />
    <nutrition-calendar-dot
      :logged="log.greenDay"
      :nutritionLogged="log.loggedNutrition"
      setWhiteBackground
      size="24"
      color="green"
      class="calendar-dot-badge right font-weight-bold"
    />
  </div>
</template>

<script>
import { PRIZE_DAY_ELIGIBILITY_QUERY } from "@/graphql/queries/prizeCalendar";
import WorkoutCalendarDot from "@/components/shared/sections/WorkoutCalendarDot";
import NutritionCalendarDot from "@/components/shared/sections/NutritionCalendarDot";
import UserAvatar from "./UserAvatar";

export default {
  name: "UserStatsAvatar",
  extends: UserAvatar,
  components: {
    WorkoutCalendarDot,
    NutritionCalendarDot,
    UserAvatar,
  },
  apollo: {
    log: {
      query: PRIZE_DAY_ELIGIBILITY_QUERY,
      variables() {
        return {
          userId: this.user.id,
          date: this.$moment().format("YYYY-MM-DD")
        };
      },
      fetchPolicy: "network-only",
      update(data) {
        return data.userPrizeDayEligibility;
      },
    },
  },
};
</script>

<style lang="scss">
#user-stats-avatar {
  .calendar-dot-badge {
    position: absolute;
    bottom: -6px;

    &.left {
      left: -3px;
    }
    &.right {
      right: -3px;
    }
  }
  .sweaty-selfie {
    left: 4px;
    top: 1px;
  }
  .half-tracking-icon {
    left: 3px;
    bottom: 3px;
  }

  .nutrition-no {
    margin-right: -17px;
    z-index: 1;
    top: -1px;
    left: 5px;
    font-size: 15px;
  }

  .white-background {
    width: 18px;
    height: 18px;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
  }
}
</style>
