import gql from "graphql-tag";

export const UPSERT_CONTACT_REQUEST_MUTATION = gql`
  mutation upsertContactRequest($data: ContactRequestInput!) {
    upsertContactRequest(data: $data) {
      success
      error
    }
  }
`;
