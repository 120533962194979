<template>
  <j-banner-container
    v-if="nutritionBlock"
    header="Week Menu"
    :icon="nutritionBlock.leadInstructor.userProfile.profilePicture"
    :title="nutritionBlock.title"
    :subtitle="'with ' + nutritionBlock.leadInstructor.firstName"
  >
    <v-row>
      <v-col v-if="useActiveNutritionBlock" cols="12" class="pt-4 pb-1 px-4 text-left">
        <j-btn tertiary narrow :to="shoppingListLink" small class="mx-1">
          <span>Shopping List</span>
          <v-icon small class="ml-2">mdi-cart-outline</v-icon>
        </j-btn>
        <j-btn tertiary narrow @click="generateShoppingListPdf" small class="mx-1">
          <span>Download Week</span>
          <v-icon small class="ml-1">mdi-download</v-icon>
        </j-btn>
      </v-col>
      <v-col class="py-0 px-2 white">
        <v-tabs v-if="useActiveNutritionBlock" v-model="tab" slider-size="4" height="40" class="pa-2 pb-0">
          <v-tabs-slider :style="activeStyle()"></v-tabs-slider>
          <v-tab key="current" @click="setTab(0)"> <span :style="activeStyle(0)">This week</span></v-tab>
          <v-tab key="next" @click="setTab(1)"> <span :style="activeStyle(1)">Next week</span></v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" :touchless="true">
          <v-tab-item key="current">
            <div class="pt-6">
              <div v-if="recipesForWeek">
                <j-subsection-row
                  v-for="(day, index) in recipesForWeek"
                  :key="day.id"
                  :title="getDay(index)"
                  :showSeeAll="false"
                  class="mx-0"
                >
                  <recipe-day-listing
                    :blockId="blockId"
                    :date="getDate(index)"
                    :breakfast="breakfast(getDate(index))"
                    :lunch="lunch(getDate(index))"
                    :dinner="dinner(getDate(index))"
                    :primarySnack="primarySnack(getDate(index))"
                    :show-action-buttons="useActiveNutritionBlock"
                  />
                </j-subsection-row>
              </div>
              <j-alert v-if="!$apollo.loading && (!recipesForWeek || recipesForWeek.length == 0)">
                No recipes for this week
              </j-alert>
            </div>
          </v-tab-item>
          <v-tab-item key="next" class="text-left">
            <div v-if="recipesForWeek">
              <j-subsection-row
                v-for="(day, index) in recipesForWeek"
                :key="day.id"
                :title="getDay(index)"
                :showSeeAll="false"
                class="mx-0"
              >
                <recipe-day-listing
                  :blockId="blockId"
                  :date="getDate(index)"
                  :breakfast="breakfast(getDate(index))"
                  :lunch="lunch(getDate(index))"
                  :dinner="dinner(getDate(index))"
                  :primarySnack="primarySnack(getDate(index))"
                />
              </j-subsection-row>
            </div>

            <j-alert
              v-if="
                !$apollo.loading &&
                (!recipesForWeek || recipesForWeek.length == 0) &&
                (!upcomingNutritionBlock || (upcomingNutritionBlock && blockId == decodeId(upcomingNutritionBlock.id)))
              "
              class="mt-6"
              >No recipes for this week</j-alert
            >
            <div
              v-if="
                !$apollo.loading &&
                (!recipesForWeek || recipesForWeek.length == 0) &&
                upcomingNutritionBlock &&
                blockId != decodeId(upcomingNutritionBlock.id)
              "
              class="text-center mt-8"
            >
              <j-dotted-btn wide color="green" @click="navigateNutritionBlock(upcomingNutritionBlock.id)"
                >View next program</j-dotted-btn
              >
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <!-- PDF of plan-->
    <generate-pdf ref="refGeneratePdf" :title="pdfTitle">
      <template v-slot:content>
        <span v-for="(day, index) in recipesForWeek" :key="day.id">
          <h2 class="pdf-heading-2" style="margin-top: 10px">{{ getDay(index) }}</h2>
          <p v-if="breakfast(getDate(index))" class="pdf-copy">
            {{ breakfast(getDate(index)).recipe.title }} <span style="font-size: 11px">(Breakfast)</span>
          </p>
          <p v-if="lunch(getDate(index))" class="pdf-copy">
            {{ lunch(getDate(index)).recipe.title }} <span style="font-size: 11px">(Lunch)</span>
          </p>
          <p v-if="dinner(getDate(index))" class="pdf-copy">
            {{ dinner(getDate(index)).recipe.title }} <span style="font-size: 11px">(Dinner)</span>
          </p>
          <p v-if="primarySnack(getDate(index))" class="pdf-copy">
            {{ primarySnack(getDate(index)).recipe.title }} <span style="font-size: 11px">(Snack)</span>
          </p>
        </span>
      </template>
    </generate-pdf>
  </j-banner-container>
</template>

<script>
import { mapGetters } from "vuex";
import { gradientStyle } from "@/lib/gradientStyle";
import RecipeListMixin from "@/mixins/nutrition/RecipeListMixin";
import GeneratePdf from "@/components/pdf/GeneratePdf";
import { getDayFromNumber } from "@/lib/fancy_date";
import { decodeId } from "@/lib/string";

export default {
  name: "NutritionOverview",
  mixins: [RecipeListMixin],
  components: {
    GeneratePdf,
  },
  props: {
    blockId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      tab: 0,
      week: Map,
    };
  },
  created() {
    if (this.$route.query.tab) {
      this.tab = parseInt(this.$route.query.tab);
    }
  },
  watch: {
    tab(value) {
      this.anchorDate = this.$moment().add(value * 7, "days");
    },
  },
  computed: {
    ...mapGetters(["activeNutritionBlock", "upcomingNutritionBlock"]),
    textGradientStyle() {
      return gradientStyle(["yellow", "green"], 90);
    },
    pdfTitle() {
      const monday = this.$moment(this.anchorDate).startOf("isoweek").format("ddd, D MMM");
      const sunday = this.$moment(this.anchorDate).startOf("isoweek").add(6, "days").format("ddd, D MMM");
      return `${this.nutritionBlock.title} - ${monday} - ${sunday}`;
    },
    shoppingListLink() {
      let link = `/nutrition/shopping-list/${this.blockId}`;
      if (this.tab > 0) {
        link = `${link}?tab=${this.tab}`;
      }
      return link;
    },
  },
  methods: {
    setTab(index) {
      history.pushState({}, null, `${this.$route.path}?tab=${index}`);
    },
    test(data) {
      this.tab = 1 - data;
    },
    getDay(day) {
      if (this.$route.query.sd) {
        return this.$moment(this.$route.query.sd).add(day, "days").format("dddd (D MMM)");
      }
      return getDayFromNumber(day);
    },
    getDate(index) {
      const monday = this.$moment(this.anchorDate).startOf("isoweek");
      return monday.add(index, "days").format("YYYY-MM-DD");
    },
    activeStyle(btnIndex = this.tab) {
      return this.tab == btnIndex ? this.textGradientStyle : "";
    },
    generateShoppingListPdf() {
      this.$refs.refGeneratePdf.generatePdf();
    },
    navigateNutritionBlock(id) {
      this.$router.push(`/nutrition/${decodeId(id)}`);
    },
    decodeId(val) {
      return decodeId(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.v-tab--active span,
.v-tab--active .v-icon {
  @extend .gradient-text;
  font-weight: bold;
}
</style>
