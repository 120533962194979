var render = function render(){var _vm=this,_c=_vm._self._c;return _c('j-subsection-row',{staticClass:"mt-2 px-0",attrs:{"title":"Fresh classes","color":"planet-grey","see-all":{
    text: 'See all',
    link: '/live',
  }}},[_c('v-carousel',{staticClass:"mt-n2",attrs:{"hide-delimiters":"","height":null},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.totalSlides),function(currentSlide){return _c('v-carousel-item',{key:currentSlide},[(_vm.$apollo.loading)?_c('div',{staticClass:"d-flex flex-row"},_vm._l((_vm.cardsPerSlide),function(loadingCard){return _c('v-skeleton-loader',{key:loadingCard,staticClass:"flex-fill mx-1 rounded-xl",attrs:{"type":"card"}})}),1):(_vm.freshClasses)?_c('div',{staticClass:"d-flex flex-row"},_vm._l((_vm.freshClasses.slice(
            _vm.freshClassIndex(currentSlide),
            _vm.freshClassIndex(currentSlide) + _vm.cardsPerSlide
          )),function(fresh){return _c('j-workout-card',{key:fresh.id,staticClass:"ma-2 py-2",attrs:{"small":"","imageSrc":_vm.imageSrc(fresh),"instructor-profile-pic":_vm.profilePicture(fresh),"title":fresh.name,"instructor-name":'with ' + fresh.instructor.firstName,"card-width":_vm.cardSize,"overlay-play":_vm.manualOverlay(fresh)},on:{"navigate":function($event){_vm.$router.push(`/event/${_vm.decodeId(fresh.id)}`);}}})}),1):_vm._e()])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }