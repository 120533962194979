<template>
  <j-main-banner-container header="Badges" md="5">
    <v-row justify="center" class="py-0 my-0">
      <v-col align="right" :cols="available.length > 0 ? 8 : 12" class="py-0 my-0" v-show="!isMobile">
        <v-select v-model="viewMode" :items="viewOptions" label="View"> </v-select>
      </v-col>
      <v-col v-if="available.length > 0" class="py-0 my-0">
        <j-checkbox v-model="showAvailable" label="Show Available" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="py-0 my-0">
      <v-col cols="12" class="py-0 my-0">
        <div v-if="loadingSection.available || loadingSection.earned">
          <v-progress-circular indeterminate class="ma-7" color="secondary"></v-progress-circular>
        </div>
        <div v-else>
          <div v-if="available.length > 0 && showAvailable">
            <h4 class="grey-navy--text font-weight-bold mb-3">Available</h4>
            <badge-card v-for="(badge, idx) in available" :badge="badge" :key="idx" :earned="false"></badge-card>
          </div>
          <div v-if="earned.length > 0">
            <div v-if="viewMode === 'list'">
              <badge-card
                v-for="(badge, idx) in earned"
                :badge="badge.badge"
                :key="idx"
                :earned="true"
                :earned-date="badge.createdAt"
                :coach-comment="badge.coachComment"
                :awarded-by="badge.awardedBy"
              />
            </div>
            <j-card v-if="viewMode === 'grid'" class="j-elevated-1 mb-4">
              <v-card-text class="badge-height">
                <div class="d-flex flex-wrap pointer">
                  <div
                    v-for="(item, index) in earned"
                    :key="index"
                    @click="
                      selectedBadge = item.badge;
                      showBadgeInfo = true;
                    "
                  >
                    <v-img
                      :alt="item.badge.title"
                      class="shrink ma-1"
                      contain
                      min-width="80"
                      :src="item.badge.iconLink"
                      width="80"
                    />
                  </div>
                </div>
              </v-card-text>
            </j-card>
          </div>
          <div v-if="earned.length == 0">
            <h4 class="grey-navy--text font-weight-bold mb-3 mt-4">Your Badges</h4>
            <p>Log a workout to earn your first badge</p>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="showBadgeInfo" width="400">
      <j-card>
        <v-toolbar class="mb-4">
          <v-toolbar-title>Badge Details</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="showBadgeInfo = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <div class="image-block">
                <v-img
                  :alt="selectedBadge.title"
                  class="shrink mx-auto"
                  contain
                  min-width="100"
                  :src="selectedBadge.iconLink"
                  width="100"
                />
              </div>
              <v-card-title class="secondary--text badge-title" align="center" justify="center">{{
                selectedBadge.title
              }}</v-card-title>

              <v-card-text>
                <j-alert type="success">
                  You've earned this badge
                </j-alert>

                <span class="secondary--text font-weight-bold">How to earn the badge</span>
                <v-spacer />
                <span class="p-text">{{ selectedBadge.description }}</span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card-text>
      </j-card>
    </v-dialog>
  </j-main-banner-container>
</template>

<script>
import { fancy_date_from_iso } from "@/lib/fancy_date";
import BadgeCard from "@/components/badges/BadgeCard";
import { BADGES_QUERY, USER_BADGES_QUERY } from "@/graphql/queries/badges";

export default {
  name: "BadgeList",
  components: {
    BadgeCard,
  },
  data() {
    return {
      showBadgeInfo: false,
      selectedBadge: [],
      viewMode: "list",
      available: [],
      earned: [],
      showAvailable: true,
      viewOptions: [
        {
          text: "View As List",
          value: "list",
        },
        {
          text: "View As Grid",
          value: "grid",
        },
      ],
      loadingSection: {
        available: true,
        earned: true,
        availableImage: true,
        earnedImage: true,
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    window.analytics.page("BadgeTracker");

    this.viewMode = this.isMobile ? "grid" : "list";
  },
  methods: {
    earnedOn(date) {
      return fancy_date_from_iso(date, "ddd, D MMMM YYYY", false);
    },
    changeViewMode(type) {
      this.viewMode = type;
    },
  },
  apollo: {
    badges: {
      query: BADGES_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && response.data.badges) {
          this.available = response.data.badges;

          this.available.forEach((badge) => {
            badge.url =
              badge.type == "EVENT"
                ? `/goal/tracker/badges/event/${badge.id}/${JSON.parse(badge.functionValues).eventId}`
                : `/goal/tracker/badges/challenge/${badge.id}`;
          });
          this.loadingSection.available = false;
        }
      },
    },
    userBadges: {
      query: USER_BADGES_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.userBadges) {
          this.earned = data.userBadges.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

          this.loadingSection.earned = false;
        }
      },
    },
  },
};
</script>

<style>
.badge-height {
  min-height: 100px;
}
</style>
