<template>
  <j-banner-container header="My Week" md="5">
    <v-skeleton-loader v-if="$apollo.loading" type="card, card" />
    <div v-else>
      <div class="d-flex flex-shrink-1 justify-center">
        <j-alert v-model="showUpdateAlert" type="success" dismissible class="ma-4 mb-0">Week goal updated</j-alert>

        <j-alert v-model="weeklyGoalSettingSubscribedAlert" type="success" dismissible class="ma-4 mb-0"
          >You have <span v-if="!weeklyGoalSettingSubscribed">unsubscribed from</span
          ><span v-else>subscribed to</span> the weekly goal email</j-alert
        >
      </div>

      <j-card class="ma-5 pa-3 j-elevated-2 exercise-goal">
        <h2 class="exercise--text mb-2">Exercise Goal</h2>
        <div class="font-weight-medium grey-navy--text">Choose your number of days</div>

        <v-btn-toggle
          v-model="exerciseDays"
          @change="upsertExerciseGoal"
          group
          mandatory
          class="my-2 flex-wrap justify-center"
        >
          <v-btn v-for="(number, index) in 6" :key="'exercise-' + index" class="rounded-pill text-body-1">
            {{ index }}
          </v-btn>
        </v-btn-toggle>

        <div v-if="blocks.length > 0" class="my-3 px-10 font-weight-light">
          On the
          <span v-for="(block, index) in blocks" :key="block">
            <span class="font-weight-bold exercise--text">{{ block }}</span>
            <span v-if="blocks.length > 2 && index < blocks.length - 2" class="font-weight-normal">, </span>
            <span v-else-if="index != blocks.length - 1" class="font-weight-normal"> and </span>
          </span>
          <span v-if="blocks.length > 1"> programs</span>
          <span v-else> program</span>
        </div>
      </j-card>
      <j-card class="ma-5 mt-0 pa-3 j-elevated-2 nutrition-goal">
        <h2 class="green--text mb-2">Nutrition Goal</h2>
        <div class="font-weight-medium grey-navy--text">Choose your number of days</div>

        <v-btn-toggle
          v-model="nutritionDays"
          @change="upsertNutritionGoal"
          group
          mandatory
          class="my-2 flex-wrap justify-center"
        >
          <v-btn v-for="(number, index) in 8" :key="'nutrition-' + index" class="rounded-pill text-body-1">
            {{ index }}
          </v-btn>
        </v-btn-toggle>

        <div class="text-left px-10">
          <div class="font-weight-light">Focus:</div>
          <j-select
            v-model="focus"
            :items="focuses"
            :item-text="(focus) => focus.title"
            :item-value="(focus) => focus.id"
            item-color="green"
            class="py-1 green--text font-weight-bold"
            hide-details
            @change="upsertFocus"
            clearable
            dense
          />
        </div>
      </j-card>
      <div class="d-flex justify-center mr-6 mt-n4 mb-6">
        <v-switch
          v-model="weeklyGoalSettingSubscribed"
          label="Subscribe to the weekly goal email"
          color="planet-grey"
          hide-details
          @click="upsertSetting"
        />
      </div>
    </div>

    <j-card v-if="primaryGoal && fitnessLevel" class="ma-5 mt-0 pa-3 j-elevated-2 nutrition-goal">
      <h2 class="navy--text mb-2">Overall Goal</h2>

      <p class="std-text">
        I want to <strong class="font-weight-bold blue--text">{{ primaryGoal }}</strong
        >, at an <strong class="font-weight-bold blue--text">{{ fitnessLevel | titleize }}</strong> fitness level, with
        a weight goal to
        <strong class="font-weight-bold blue--text">{{ goal }} {{ amount | formatAmount }}kg</strong>
      </p>

      <j-btn tertiary small narrow class="mb-4" @click="showDialog = true">Update My Goal</j-btn>
    </j-card>

    <v-dialog v-model="showDialog" max-width="600" transition="dialog-bottom-transition" class="limited-height">
      <journey-details-dialog show-journey-text @close="showDialog = false" @update="showDialog = false" />
    </v-dialog>
  </j-banner-container>
</template>

<script>
import Cookies from "js-cookie";
import { GOAL_BlOCKS_QUERY, FOCUSES_QUERY, WEEKLY_GOAL_QUERY } from "@/graphql/queries/goal";
import { UPSERT_WEEKLY_GOAL } from "@/graphql/mutations/goal";
import { getMonday } from "@/lib/calendarWeek";
import JourneyDetailsDialog from "@/components/shared/dialogs/JourneyDetails";
import JourneyDetailsMixin from "@/mixins/JourneyDetailsMixin";
import { UPSERT_MY_SETTINGS_MUTATION } from "@/graphql/mutations/account";

export default {
  name: "Goal",
  components: { JourneyDetailsDialog },
  mixins: [JourneyDetailsMixin],
  data() {
    return {
      showDialog: false,
      exerciseDays: null,
      nutritionDays: null,
      focus: null,
      showUpdateAlert: false,
      weeklyGoalSettingSubscribed: true,
      weeklyGoalSettingSubscribedAlert: false,
    };
  },
  async mounted() {
    // if query string set for unsubscribe to weekly goal, we force unsubscribe!
    if (this.$route.query.u) {
      this.weeklyGoalSettingSubscribed = false;
      this.upsertSetting();
    } else {
      const goalSetting = this.$store.getters.user?.userSettings?.find((obj) => obj.key == "weekly-goal-unsubscribed");
      this.weeklyGoalSettingSubscribed = !goalSetting?.value;
    }
  },
  computed: {
    blocks() {
      return (
        this.goalBlocks?.subscriptionBlocks
          ?.filter(
            (sb) =>
              (sb.block.type === "PROGRAM" || sb.block.type === "CHALLENGE") &&
              (sb.endDate == null || this.$moment(sb.endDate).isAfter(this.$moment()))
          )
          .map((sb) => sb.block.title) ?? []
      );
    },
    monday() {
      return this.$moment(getMonday(this.$moment().format("YYYY-MM-DD"))).format("YYYY-MM-DD");
    },
  },
  methods: {
    async upsertSetting() {
      // reset query string.
      this.$route.query.u = null;
      history.pushState({}, null, this.$route.path);
      this.$apollo.mutate({
        mutation: UPSERT_MY_SETTINGS_MUTATION,
        variables: {
          settingName: "weekly-goal-unsubscribed",
          value: !this.weeklyGoalSettingSubscribed,
        },
        refetchQueries: ["accountQuery"],
      });
      this.weeklyGoalSettingSubscribedAlert = true;
    },
    getInput() {
      return {
        numberOfExerciseDays: this.exerciseDays,
        numberOfNutritiousDays: this.nutritionDays,
        nutritionFocusId: this.focus,
      };
    },
    upsertExerciseGoal(val) {
      const input = this.getInput();
      (input.numberOfExerciseDays = val), this.upsertGoal(input);
    },
    upsertNutritionGoal(val) {
      const input = this.getInput();
      input.numberOfNutritiousDays = val;

      this.upsertGoal(input);
    },
    upsertFocus(val) {
      const input = this.getInput();
      input.nutritionFocusId = parseInt(val);

      this.upsertGoal(input);
    },
    upsertGoal(input) {
      input.dateSet = this.monday;

      this.$apollo.mutate({
        mutation: UPSERT_WEEKLY_GOAL,
        variables: {
          input: input,
        },
        refetchQueries: ["weeklyGoal", "accountQuery"],
      });

      // when we update the goal, we should also remove this cookie?
      Cookies.remove("weekly_goal_achieved_notification");
      this.showUpdateAlert = true;
    },
  },
  apollo: {
    goalBlocks: {
      query: GOAL_BlOCKS_QUERY,
      update(data) {
        return data.home;
      },
    },
    focuses: {
      query: FOCUSES_QUERY,
      update(data) {
        return data.nutritionFocuses;
      },
    },
    weeklyGoal: {
      query: WEEKLY_GOAL_QUERY,
      variables() {
        return {
          weekStart: this.monday,
        };
      },
      fetchPolicy: "network-only",
      update(data) {
        const goal = data.weeklyGoals;
        this.exerciseDays = goal?.targets?.weekly_exercise ?? 0;
        this.nutritionDays = goal?.targets?.weekly_nutrition ?? 0;

        if (goal?.targets?.focus) {
          this.focus = this.focuses?.find((focus) => parseInt(goal?.targets?.focus.id) == focus.id).id ?? null;
        } else {
          this.focus = null;
        }

        // once the query has run, see if we are passing via query string and update accordingly.
        if (this.$route.query.ed) {
          this.exerciseDays = parseInt(this.$route.query.ed);
          this.upsertExerciseGoal(parseInt(this.$route.query.ed));
          history.pushState({}, null, this.$route.path);
        }
        if (this.$route.query.nd) {
          this.nutritionDays = parseInt(this.$route.query.nd);
          this.upsertNutritionGoal(parseInt(this.$route.query.nd));
          history.pushState({}, null, this.$route.path);
        }
        return goal;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr .exercise-goal .v-btn-toggle > .v-btn.v-btn:not(:first-child),
.v-application--is-ltr .nutrition-goal .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
  border-left-width: 2px;
}

.exercise-goal .v-btn-toggle,
.nutrition-goal .v-btn-toggle {
  .v-btn {
    min-height: 36px;
    max-height: 36px;
    min-width: 36px;
    padding: 0;
  }
}

.exercise-goal .v-btn-toggle {
  .v-btn--active {
    color: var(--v-exercise-base);
    border: var(--v-exercise-base) solid 2px;
    font-weight: bold;
  }
}

.nutrition-goal .v-btn-toggle {
  .v-btn--active {
    color: var(--v-green-base);
    border: var(--v-green-base) solid 2px;
    font-weight: bold;
  }
}
</style>
