<template>
  <j-main-banner-container header="Celebrations">
    <div v-if="$apollo.loading">
      <v-row>
        <v-col cols="12" md="6"><v-skeleton-loader type="card-avatar" /> </v-col>
        <v-col cols="12" md="6"><v-skeleton-loader type="card-avatar" /> </v-col>
      </v-row>
    </div>

    <div v-else>
      <j-dotted-btn
        v-if="celebrations && celebrations.length == 0"
        color="primary"
        to="/celebration"
        class="my-4 mx-2 mx-md-8"
      >
        <span>Create your celebration</span>
        <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
      </j-dotted-btn>

      <template v-else>
        <div class="d-flex justify-center mt-4">
          <j-btn to="/celebration">
            <span>Create Celebration</span>
            <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
          </j-btn>
        </div>

        <div class="d-flex justify-start ml-2 mt-4">
          <j-select v-model="orderBy" :items="orderByItems" label="Sort by" hide-details dense class="mw" />
        </div>

        <v-row>
          <v-col cols="12" md="6" v-for="celebration in celebrations" :key="celebration.id">
            <j-card rounded="lg" class="my-1 j-elevated-2" @click="goToCelebration(celebration.id)">
              <v-img class="pointer" :src="celebration.celebrationImage" :lazy-src="celebration.celebrationImage">
              </v-img>
              <v-chip small color="yellow" class="chip">{{ celebration.createdAt | moment("ddd, D MMM 'YY") }}</v-chip>
            </j-card>
          </v-col>
        </v-row>
      </template>
    </div>
  </j-main-banner-container>
</template>

<script>
import { decodeId } from "@/lib/string";
import { CELEBRATIONS_QUERY } from "@/graphql/queries/celebration";
import { extractNodes } from "@/lib/array";

export default {
  name: "CelebrationList",
  data() {
    return {
      orderBy: "-createdAt",
      orderByItems: [
        {
          text: "Oldest first",
          value: "createdAt",
        },
        {
          text: "Newest first",
          value: "-createdAt",
        },
      ],
    };
  },
  methods: {
    goToCelebration(id) {
      this.$router.push(`/celebration?s=3&id=${decodeId(id)}`);
    },
  },
  apollo: {
    celebrations: {
      query: CELEBRATIONS_QUERY,
      variables() {
        return {
          hasCelebrationImage: true,
          userId: window.btoa(`UserNode: ${this.$store.getters.user.id}`),
          orderBy: this.orderBy,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => extractNodes(data.celebrations.edges),
    },
  },
};
</script>

<style lang="scss" scoped>
.mw {
  max-width: 175px;
}
.chip {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
  font-weight: bold;
}
</style>
