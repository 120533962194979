<template>
  <v-container id="checkout-page">
    <div v-show="false">
      <secure-redirect-form ref="redirectForm" :redirect-form="redirectForm" />
    </div>
    <template v-if="loading || $apollo.loading">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" sm="8" align="center" justify="center">
          <v-progress-circular :indeterminate="true" color="blue" />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row v-if="paymentError.error" align="center" justify="center">
        <v-col cols="12" md="6" sm="8">
          <j-alert type="error">
            An error occurred with your payment, please ensure that your payment details are correct and try again.
            <span v-if="paymentError.errorMsg">(Message: '{{ paymentError.errorMsg }}')</span>
          </j-alert>
        </v-col>
      </v-row>
      <v-row v-if="showProductBlock" align="center" justify="center">
        <v-col cols="12" md="6" sm="8" align="center" justify="center" class="pb-0">
          <product-title-block />
        </v-col>
      </v-row>

      <v-row v-if="restrictAccess" align="center" justify="center">
        <v-col cols="12" md="6" sm="8">
          <j-card>
            <v-card-text class="font-18">
              To change your subscription, please
              <router-link class="font-weight-bold" to="/account/billing"> manage your subscription </router-link>
              in your billing information.
            </v-card-text>
          </j-card>
        </v-col>
      </v-row>

      <v-row v-if="invalidCode" align="center" justify="center">
        <v-col cols="12" md="6" sm="8">
          <j-card>
            <v-card-text class="font-16">
              This product does not exist. Please
              <router-link to="/contact" class="font-weight-bold">contact our support team</router-link>.
            </v-card-text>
          </j-card>
        </v-col>
      </v-row>

      <v-row v-if="isClubAndHasClub" align="center" justify="center">
        <v-col cols="12" md="6" sm="8">
          <j-alert>
            You already have access to JEFF Club.
            <span v-if="role === 'ONE_ON_ONE'">Please speak to your coach if you want to know more.</span>
          </j-alert>
        </v-col>
      </v-row>

      <v-row v-if="pkgError" align="center" justify="center">
        <v-col cols="12" md="6" sm="8">
          <j-alert>
            This product is not available on your subscription.
            <span v-if="role === 'ONE_ON_ONE'">Please speak to your coach if you want to know more.</span>
          </j-alert>
        </v-col>
      </v-row>

      <v-row v-if="showProductBlock && (product.youtubeId || product.shortDescription)" align="center" justify="center">
        <v-col cols="12" md="6" sm="8">
          <j-card class="px-2 pb-3">
            <v-card-text v-if="product.shortDescription" v-html="product.shortDescription" class="pb-0 std-text" />
            <video-block
              v-if="product.youtubeId"
              :youtube-id="product.youtubeId"
              class="px-4 py-1"
              :show-video-cast-message="false"
            />
          </j-card>
        </v-col>
      </v-row>

      <!-- end error alerts. Start main checkout section -->
      <div v-if="!restrictAccess && !invalidCode && !isClubAndHasClub && !pkgError">
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" sm="8">
            <j-card v-if="pkg" class="checkout-card mb-5">
              <club-product-block
                v-if="role != 'CLUB' && role != 'ONE_ON_ONE' && pkg.requiresClub"
                class="px-4"
                :product="product"
                :allow-billing-period-selection="allowJeffLifeBillingPeriodChange"
              />
              <v-card-title class="text-h6 secondary--text pb-0">
                {{ pkg.title }}
              </v-card-title>
              <v-card-text class="py-0 pt-2" v-html="pkg.longDescription" />
              <video-block
                v-if="pkg.youtubeVideo"
                :youtube-id="getYouTubeId(pkg.youtubeVideo)"
                class="px-4 py-1"
                :show-video-cast-message="false"
              />
            </j-card>
            <j-card v-if="!pkg" class="checkout-card">
              <div v-if="coldCheckout" class="d-flex mb-n2">
                <v-chip color="green-background" dense text-color="alert-green" class="font-16 mx-auto mt-4">
                  <strong>Step 2 of 3</strong>
                </v-chip>
              </div>
              <no-overseas-shipping-alert v-if="product.noOverseasShipping && !localUser" />
              <v-card-title class="text-h6 secondary--text pb-0 mt-2">
                <span v-if="allowBillingPeriodSelection">Choose your payment option</span>
                <span v-else>Your payment schedule</span>
              </v-card-title>
              <v-card-text class="py-0 pt-2">
                <product-block
                  :product="product"
                  :product-variant-id="productVariantId"
                  :allow-billing-period-selection="allowBillingPeriodSelection"
                />
                <div v-if="bundleJeffClub">
                  <v-divider class="my-1" />
                  <club-product-block
                    :product="product"
                    :allow-billing-period-selection="allowJeffLifeBillingPeriodChange"
                  />
                </div>
                <!-- v-if false -->
                <div v-if="false && product.code === 'jeff-club'">
                  <j-checkbox
                    v-model="isDiscovery"
                    :label="`I'm a Discovery Vitality member and would like a ${discoveryDiscountPercent}% discount on ${primarySubscriptionName}`"
                    @click.native="updateDiscovery"
                    hide-details
                    class="mt-0"
                  />
                </div>
              </v-card-text>
            </j-card>
          </v-col>
        </v-row>
        <v-row v-if="allowVoucherRedemption" align="center" justify="center">
          <v-col cols="12" md="6" sm="8">
            <j-card class="checkout-card pt-2 pb-0">
              <v-card-text class="pb-0">
                <div class="d-flex">
                  <v-text-field
                    v-model="voucherCode"
                    dense
                    outlined
                    :error-messages="voucher.error"
                    label="Use a voucher or promotion code"
                  />
                  <div class="ml-4">
                    <v-btn v-if="!voucher.applied" class="secondary--text" rounded @click="applyVoucher">
                      <strong>Apply</strong>
                    </v-btn>
                    <v-btn v-else rounded class="secondary--text" @click="clearVoucher">
                      <strong>Remove</strong>
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </j-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" sm="8">
            <j-card class="checkout-card py-3">
              <v-row id="amountSection" align="center" justify="center" class="mx-0">
                <v-col class="pa-0">
                  <div v-if="cartTotal.totalDiscount > 0.0 && !pkg" class="d-flex">
                    <div>
                      <v-card-text class="text--secondary text-body">
                        <span v-if="voucher.applied && !pkg">Voucher applied: {{ voucher.code }}</span>
                        <span v-else>Promotion Discount</span>
                      </v-card-text>
                    </div>
                    <v-spacer />
                    <div class="text-right discount-amounts">
                      <v-card-text class="text--secondary text-body"> ({{ voucherText }}) </v-card-text>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div>
                      <v-card-title class="text-h6 secondary--text"> Payable now </v-card-title>
                    </div>
                    <v-spacer />
                    <div>
                      <v-card-text class="text-h6 secondary--text ">
                        <div v-if="wasPrice" class="mt-n2 sm-text strikethrough">Was {{ wasPrice }}</div>
                        {{ payableNowText }}
                      </v-card-text>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-card-text
                v-if="
                  (cartTotal.onceOffAmount > 0.0 && product.clubVariant && !useTokenizedCard) ||
                  cartTotal.recurringAmount > 0.0
                "
                class="mt-0 pt-1 p-text font-weight-bold"
                v-html="subscriptionBlurb"
              />
              <div v-if="ratherSignUpText" class="px-4 p-text" v-html="ratherSignUpText"></div>
            </j-card>
          </v-col>
        </v-row>

        <v-row v-if="cartTotal.amountAfterDiscounts === 1 && requiresCardDetails" align="center" justify="center">
          <v-col cols="12" md="6" sm="8">
            <v-alert type="success" dismissible>
              You will be charged R1 to confirm your credit card details for your next subscription payment.
            </v-alert>
          </v-col>
        </v-row>

        <validation-observer ref="observer">
          <v-row v-if="requiresCardDetails && !useTokenizedCard" id="card-details" align="center" justify="center">
            <v-col cols="12" md="6" sm="8">
              <j-alert v-if="paymentRetry" type="warning" dismissible>
                Your payment has not been successful. Please try again.
              </j-alert>
              <j-card class="pa-2">
                <no-overseas-shipping-alert v-if="product.noOverseasShipping && !localUser" />
                <v-card-title class="text-h6 secondary--text"> Payment details </v-card-title>
                <v-card-subtitle class="sm-text pb-4">
                  We accept Visa &amp; Mastercard credit cards and most debit cards
                </v-card-subtitle>
                <card-details :card-details="cardDetails" />
                <div class="d-flex align-center">
                  <v-spacer />
                  <v-icon>lock</v-icon>
                  <div class="sm-text py-0 pl-1 pr-4">Secure and Encrypted</div>
                </div>
              </j-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="8" align="center" justify="center">
              <j-alert v-if="cartTotal.amountAfterDiscounts === 1 && requiresCardDetails" type="success" dismissible>
                You will be charged R1 to confirm your credit card details for your next subscription payment.
              </j-alert>
              <j-btn large color="secondary" @click="makePayment" class="px-10">
                <strong>{{ payButtonText }}</strong>
              </j-btn>
              <a
                v-if="useTokenizedCard"
                @click="useTokenizedCard = false"
                class="d-block green--text mt-2 text-decoration-underline"
              >
                Enter my card details again
              </a>
            </v-col>
          </v-row>
        </validation-observer>
      </div>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { ACCOUNT_QUERY } from "@/graphql/queries/account";
import { BILLING_PRODUCTS_QUERY, CHECK_VOUCHER_QUERY, CART_TOTAL } from "@/graphql/queries/store";
import { PRODUCT_ORDER_MUTATION } from "@/graphql/mutations/store";
import { UPDATE_PROFILE_MUTATION } from "@/graphql/mutations/account";
import { VOUCHER_ERRORS } from "@/constants/store/strings.js";
import { decodeId, getYouTubeId } from "@/lib/string";
import ProductTitleBlock from "@/components/store/blocks/ProductTitleBlock.vue";
import ClubProductBlock from "@/components/store/blocks/ClubProductBlock";
import ProductBlock from "@/components/store/blocks/ProductBlock";
import CardDetails from "@/components/store/blocks/CardDetails";
import SecureRedirectForm from "@/views/store/SecureRedirectForm";
import CheckoutMixin from "@/components/store/mixins/CheckoutMixin";
import VideoBlock from "@/components/events/VideoBlock";
import NoOverseasShippingAlert from "@/components/store/NoOverseasShippingAlert";

export default {
  name: "Checkout",
  components: {
    ProductTitleBlock,
    ClubProductBlock,
    ProductBlock,
    CardDetails,
    SecureRedirectForm,
    VideoBlock,
    NoOverseasShippingAlert,
  },
  mixins: [CheckoutMixin],
  data() {
    return {
      loading: true,
      invalidCode: false,
      firstLoad: true,
      currency: "ZAR",
      product: null,
      pkg: null,
      pkgError: false,
      currentSubscription: null,
      // we may pass a product variant ID via GET as `pvi`. If so, then use it.
      productVariantId: null,
      // if true, then it hides the payment details form.
      useTokenizedCard: false,
      packageCode: null,
      cartTotal: {
        baseAmount: 0,
        amountAfterDiscounts: 0,
        onceOffAmount: 0,
        recurringAmount: 0,
        totalDiscount: 0,
      },
      cardDetails: {},
      voucherCode: "",
      voucher: {
        code: "",
        error: null,
        applied: false,
        discounts: [],
        recurringDiscount: false,
      },
      redirectForm: {
        url: "",
        parameters: [],
      },
      paymentError: {
        error: false,
        errorMsg: "",
      },
      peachErrorCodes: {
        "000.100.201": "Incorrect Bank Details",
        "000.100.202": "Account closed",
        "000.100.203": "Insufficient Funds",
        "100.100.303": "Card expired",
        "100.100.600": "CVV Missing",
        "100.380.100": "Transaction Declined",
        "800.100.157": "Invalid Expiry Date"
      },
      isDiscovery: false,
      // watch out. These hard coded here and Product Card and must match up what is set in the DB under RecurringDiscounts
      discoveryDiscountPercent: 25,
      recurringDiscountCode: "jeff-life-vitality",
    };
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.cardDetails = {
        holder: "HA Rode",
        number: "4200000000000091",
        expiryMonth: "12",
        expiryYear: 24,
        cvv: 819,
      };
    }
  },
  updated() {
    if (this.paymentRetry && this.firstLoad) {
      let elementExists = document.getElementById("card-details");
      if (elementExists) {
        this.$vuetify.goTo("#card-details", this.scrollOptions);
        this.firstLoad = false;
      }
    }
  },
  watch: {
    "product.variant": function () {
      this.applyVoucher();
      this.calculateCartTotal();
    },
    "product.clubVariant": function () {
      this.applyVoucher();
      this.calculateCartTotal();
    },
    isDiscovery: function () {
      this.clearVoucher();
      this.applyVoucher();
      this.calculateCartTotal();
    },
  },
  computed: {
    ...mapGetters([
      "checkoutCode",
      "checkoutProduct",
      "checkoutVoucher",
      "paymentRetry",
      "coldCheckout",
      "role",
      "preferredVariant",
    ]),
    wasPrice() {
      return this.product?.variant?.wasPrice;
    },
    allowVoucherRedemption() {
      return (
        this.product?.allowVoucherRedemption ||
        this.$store.getters.user?.userProfile?.userType === "AMBASSADOR" ||
        this.role === "ONE_ON_ONE"
      );
    },
    showProductBlock() {
      return !this.pkg;
    },
    isClubAndHasClub() {
      if (this.pkg) return false;
      return this.product.code == "jeff-club" && (this.role == "ONE_ON_ONE" || this.role === "CLUB");
    },
    ratherSignUpText() {
      if (this.pkg) return "";

      if (this.product.code === "1-on-1") {
        return 'Would you rather <a class="green--text" href="/checkout/jeff-club">just sign up for Club</a> without 1-on-1 personal coaching?';
      }
      if (this.product.code === "jeff-club") {
        return 'Do you want to <a class="green--text" href="/checkout/1-on-1">add 1-on-1 personal coaching</a> while joining Club?';
      }
      return null;
    },
    primarySubscriptionName() {
      let name = this.product.title;

      if (this.product.requiresActiveSubscription) name = "JEFF Club";

      return name;
    },
    scrollOptions() {
      return {
        duration: 400,
        offset: 0,
        easing: "easeInOutCubic",
      };
    },
    discountAmount() {
      if (this.cartTotal.baseAmount > this.cartTotal.amountAfterDiscounts) {
        return this.cartTotal.baseAmount - this.cartTotal.amountAfterDiscounts;
      }
      return null;
    },
    currencySymbol() {
      let symbols = {
        ZAR: "R",
        USD: "$",
        GBP: "£",
      };
      return symbols[this.currency];
    },
    subscriptionBlurb() {
      if (this.pkg) {
        let programDuration = this.pkg.workoutBlock.duration;
        let programStartDate = this.$moment(this.pkg.startDate);

        let copy = `<p>Your program will start on ${programStartDate.format("DD MMMM YYYY")}.</p>`;

        let programEndDate = programStartDate.add(programDuration + 3, "days");

        let endDate = programEndDate.format("DD MMMM YYYY");

        if (this.currentSubscription?.product && this.pkg.transitionOnEnd) {
          copy += `This is a ONCE-OFF payment. Once your package is completed on ${endDate}, your current subscription (${this.currentSubscription.product.name})
          will continue and you will be billed accordingly.`;
          return copy;
        }

        if (this.pkg.transitionOnEnd) {
          copy += `This is a ONCE-OFF payment. This subscription ends automatically once the package is completed on ${endDate}.`;
        }
        return copy;
      }

      let product = this.product.clubVariant || this.product.variant;
      let interval_type = product.billingPeriod;
      let interval = null;

      switch (interval_type) {
        case "MONTHLY":
          interval = { count: 1, period: "M" };
          break;

        case "QUARTERLY":
          interval = { count: 1, period: "Q" };
          break;

        case "ANNUALLY":
          interval = { count: 1, period: "y" };
          break;

        case "BIANNUAL":
          interval = { count: 6, period: "M" };
          break;
      }

      if (interval != null) {
        let next_billing_date = this.$moment();
        const day = next_billing_date.format("D");
        next_billing_date = next_billing_date.add(interval.count, interval.period);

        if (day > 28) {
          next_billing_date = next_billing_date.set("date", 28);
        }

        let text;
        if (this.product.code === "1-on-1") {
          text = `Important to note, this is a recurring subscription. Your next debit order will be based on the day that you first consult with your coach.`;
        } else {
          text = `Important to note, this is a recurring subscription. Your next debit order date will be the ${next_billing_date.format(
            "D MMMM YYYY"
          )}
        at ${this.currencySymbol} ${this.cartTotal.recurringAmount} ${this.paymentPlanText(product.billingPeriod)}.`;
        }

        return text;
      }
      return "";
    },
    allowJeffLifeBillingPeriodChange() {
      return this.product.code === "challenge";
    },
    bundleJeffClub() {
      if (this.product.requiresActiveSubscription && this.role !== "ONE_ON_ONE" && this.role !== "CLUB") return true;
      return false;
    },
    requiresCardDetails() {
      if (this.cartTotal.amountAfterDiscounts > 1.0) return true;

      if (this.bundleJeffClub) return true;

      let product = this.product.clubVariant || this.product.variant;
      if (product && product.billingPeriod !== "ONCE_OFF" && product.billingPeriod !== null) return true;

      return false;
    },
    restrictAccess() {
      if (
        this.$route.params.code == "jeff-life" &&
        (this.$store.getters.role == "JEFF_MEMBER" || this.$store.getters.role == "ONE_ON_ONE")
      ) {
        return true;
      }
      if (this.$route.params.code == "1-on-1" && this.$store.getters.role == "ONE_ON_ONE") {
        return true;
      }
      if (this.$route.params.code == "1-on-1-grad" && this.$store.getters.role == "ONE_ON_ONE") {
        return true;
      }
      return false;
    },
    allowBillingPeriodSelection() {
      // if we have passed a productVariantId via the query string.
      if (this.productVariantId) {
        return false;
      }
      const productVariantSet = this.product?.productvariantSet?.filter((variant) => !variant.isHidden);
      return !(this.product && productVariantSet?.length == 1);
    },
    payButtonText() {
      if (!this.requiresCardDetails) {
        return `Complete Purchase`;
      }
      let amount = this.$options.filters.currency(this.cartTotal.amountAfterDiscounts);
      return `Pay ${this.currencySymbol}${amount} Now`;
    },
    payableNowText() {
      if (!this.requiresCardDetails) {
        return `${this.currencySymbol}0.00`;
      }
      let amount = this.$options.filters.currency(this.cartTotal.amountAfterDiscounts);
      return `${this.currencySymbol}${amount}`;
    },
    voucherText() {
      let amount = this.$options.filters.currency(this.cartTotal.baseAmount);
      if (!this.requiresCardDetails) {
        return `${this.currencySymbol}${amount}`;
      }
      let discountAmount = this.$options.filters.currency(this.cartTotal.totalDiscount);
      return `${this.currencySymbol}${discountAmount}`;
    },
    localUser() {
      return this.$store.getters.timezone?.includes("Africa/Johannesburg");
    },
  },
  methods: {
    getProductVariants() {
      let products = [];
      if (this.product.variant && this.product.variant.id) {
        products.push(decodeId(this.product.variant.id));
      }

      if (this.product.clubVariant && this.product.clubVariant.id && this.bundleJeffClub) {
        // this product requires jeff club bundled with it
        products.push(decodeId(this.product.clubVariant.id));
      }
      // ensure unique id
      return [...new Set(products)];
    },
    makePayment() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          this.paymentError.error = false;

          let card = null;
          if (!this.useTokenizedCard && this.requiresCardDetails) {
            let brands = {
              visa: "VISA",
              mastercard: "MASTER",
            };

            card = {
              holder: this.cardDetails.holder,
              cvv: this.cardDetails.cvv,
              number: this.cardDetails.number.replace(/\s/g, ""),
              brand: brands[this.cardDetails.brand] || "PRIVATE_LABEL",
              expiry: ["20" + this.cardDetails.expiryYear, this.cardDetails.expiryMonth, "01"].join("-"),
            };
          }

          let products = this.getProductVariants();

          let voucherCode = this.voucher.recurringDiscount ? "" : this.voucherCode;
          let recurringDiscountCode = this.isDiscovery
            ? "jeff-life-vitality"
            : this.voucher.recurringDiscount
            ? this.voucherCode
            : "";

          let data = {
            products: products,
            voucher: voucherCode,
            processPayment: true,
            card: card,
            currency: this.currency.toUpperCase(),
            recurringDiscountCode: recurringDiscountCode,
            packageCode: this.pkg?.code,
            meta: {
              "customer.browser.language": navigator.language,
              "customer.browser.screenHeight": screen.height,
              "customer.browser.screenWidth": screen.width,
              "customer.browser.timezone": new Date().getTimezoneOffset(),
              "customer.browser.userAgent": navigator.userAgent,
              "customer.browser.javaEnabled": false,
              "customer.browser.javascriptEnabled": true,
              "customer.browser.screenColorDepth": screen.colorDepth,
              "customer.browser.challengeWindow": "05",
            },
          };
          this.$apollo
            .mutate({
              mutation: PRODUCT_ORDER_MUTATION,
              variables: {
                input: data,
              },
            })
            .then((response) => {
              sessionStorage.removeItem("referral_code");

              if (response.data && !response.loading) {
                if (response.data.productOrderMutation?.gatewayResult?.workoutBlocks?.length > 0) {
                  // API will return an array with block ids. [1,2,3]
                  // There should only be one main block that the user purchased, this is the main thing we want to return
                  // this may break if you purchase more than one block at the same time. But can you?? For now, let's just pick the first one.
                  const workoutBlockId = response.data.productOrderMutation.gatewayResult.workoutBlocks[0];
                  sessionStorage.setItem("workoutBlockId", workoutBlockId);
                }

                if (response.data.productOrderMutation.success) {
                  // set loading to true so that page doesn't flicker while redirectForm submits
                  this.loading = true;
                  this.$store.dispatch("updateCheckoutCode", this.$route.params.code);
                  this.$store.dispatch("updateCheckoutProduct", this.product);

                  const transactionStatus = response.data.productOrderMutation.gatewayResult.transactionStatus;
                  if (transactionStatus === "SUCCESS") {
                    this.$store.dispatch("clearPreferredVariant");
                    // this payment does not require 3d secure workflow
                    // we can redirect directly to the success page
                    let nextUrl =
                      "/checkout/payment/result?id=" +
                      response.data.productOrderMutation.gatewayResult.gatewayReference;
                    if (response.data.productOrderMutation.gatewayResult.voucher)
                      nextUrl += "&voucher=" + response.data.productOrderMutation.gatewayResult.voucher;

                    this.$router.push(nextUrl);
                  } else if (transactionStatus === "3D_SECURE_VERIFICATION") {
                    this.redirectForm = JSON.parse(response.data.productOrderMutation.gatewayResult.redirect);
                    this.$refs.redirectForm.submit();
                  } else {
                    const url = window.location.href;
                    window.location.href = `${url}&error=1`;
                  }
                } else {
                  this.loading = false;
                  this.paymentError.error = true;
                  let error = response.data.productOrderMutation.error;
                  if (Object.prototype.hasOwnProperty.call(this.peachErrorCodes, error)) {
                    error = this.peachErrorCodes[error];
                  }
                  this.paymentError.errorMsg = error;
                }
              }
            })
            .catch((error) => {
              throw error;
            });
        }
      });
    },
    applyVoucher() {
      if (this.voucherCode === "") return;
      this.voucher.code = this.voucherCode;

      this.voucher.error = null;
      this.voucher.applied = false;
      let products = this.getProductVariants();
      if (products === null || products.length == 0) return;
      this.$apollo
        .query({
          query: CHECK_VOUCHER_QUERY,
          fetchPolicy: "no-cache",
          variables: {
            code: this.voucher.code,
            variantIds: products,
          },
        })

        .then((response) => {
          if (!response.loading) {
            let data = response.data;
            if (data.voucherDiscounts.error) {
              let error = data.voucherDiscounts.error;

              this.voucher.error = VOUCHER_ERRORS[error];
              return;
            }

            let applicableDiscounts = data.voucherDiscounts.discounts.filter((element) => element.error == null);

            let supercededDiscounts = data.voucherDiscounts.discounts.filter(
              (element) => element.error == "PROMO_HIGHER"
            );

            if (supercededDiscounts.length > 0) {
              this.voucher.error = VOUCHER_ERRORS["PROMO_HIGHER"];
              return;
            }

            if (applicableDiscounts.length === 0) {
              this.voucher.error = VOUCHER_ERRORS["NO_APPLICABLE_PRODUCTS"];
              return;
            }

            this.voucher.applied = true;
            this.voucher.discounts = applicableDiscounts;
            this.voucher.recurringDiscount = applicableDiscounts.some((element) => element.isRecurring === true);

            if (this.isDiscovery && this.voucher.recurringDiscount) {
              //we cannot use a recurring voucher in conjunction with jeff-life.
              this.clearVoucher();
              this.voucher.error = VOUCHER_ERRORS["NO_APPLICABLE_PRODUCTS"];
            }

            this.calculateCartTotal();
            this.$store.dispatch("updateCheckoutVoucher", this.voucher);
          }
        });
    },
    clearVoucher() {
      this.voucher.applied = false;
      this.voucher.code = "";
      this.voucherCode = "";
      this.voucher.error = null;
      this.voucher.discounts = null;
      this.voucher.recurringDiscount = false;
      this.calculateCartTotal();
    },
    updateDiscovery() {
      let userData = {
        isDiscoveryMember: this.isDiscovery,
      };

      this.$apollo
        .mutate({
          mutation: UPDATE_PROFILE_MUTATION,
          variables: {
            userProfileInput: userData,
          },
        })
        .then(() => {});
    },
    calculateCartTotal() {
      const productVariants = this.getProductVariants();
      let voucherCode = this.voucher.recurringDiscount ? "" : this.voucherCode;
      let recurringDiscountCode = this.isDiscovery
        ? "jeff-life-vitality"
        : this.voucher.recurringDiscount
        ? this.voucherCode
        : "";
      this.$apollo
        .query({
          query: CART_TOTAL,
          skip() {
            return productVariants === null || productVariants.length === 0;
          },
          variables: {
            productVariantIds: productVariants,
            voucherCode: voucherCode,
            currency: this.currency,
            recurringDiscountCode: recurringDiscountCode,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          if (response.data.cartTotal) {
            this.cartTotal = response.data.cartTotal;
            this.cartTotal.totalDiscount = this.cartTotal.baseAmount - this.cartTotal.amountAfterDiscounts;
            this.raiseCheckoutEvent();
          }
        });
    },
    raiseCheckoutEvent() {
      const products = this.getProductVariants().map((e) => ({
        product_id: e,
        sku: e,
      }));

      const opportunity = this.opportunity(this.product.code);
      const order = {
        checkout_id: this.$store.getters.orderId,
        order_id: this.$store.getters.orderId,
        total: this.cartTotal.amountAfterDiscounts,
        discount: this.cartTotal.totalDiscount,
        currency: this.currency,
        products: products,
        opportunity: opportunity,
      };
      // doing this in Vuex, since on return from peach,
      // we come back to this page and do not want to raise this `Checkout Started` again for this particular opportunity
      if (this.$store.getters.trackedCheckoutOpportunity == opportunity) {
        window.analytics.track("Order Updated", order);
      } else {
        window.analytics.track("Checkout Started", order);
      }
      this.$store.dispatch("updateTrackedCheckoutOpportunity", opportunity);
    },
    getYouTubeId(url) {
      return getYouTubeId(url);
    },
  },
  apollo: {
    product: {
      query: BILLING_PRODUCTS_QUERY,
      variables() {
        return {
          code: this.$route.params.code,
          withPackages: true,
        };
      },
      fetchPolicy: "network-only",
      result(response) {
        if (response.data && !response.loading) {
          if (response.data.billingProducts.edges.length === 0) {
            this.invalidCode = true;
            this.loading = false;
            return false;
          }

          if (this.packageCode) {
            const pkg = response.data.packages?.filter((element) => element.code === this.packageCode)[0];
            let validPkg = pkg.products?.some((product) => product.type.includes(this.$store.getters.role));
            // if jeff open, you can always buy this package?
            if (this.$store.getters.role == "INACTIVE") {
              validPkg = true;
            }
            if (validPkg) {
              this.pkg = pkg;
            } else {
              this.pkgError = true;
            }

            if (this.pkg?.discount) {
              this.voucherCode = this.pkg.discount.code;
              this.applyVoucher();
            }
          }

          if (this.coldCheckout) {
            let discount = response.data.billingProducts.edges[0].node.firstMonthDiscount;

            // if we already have  discount via pkg, then don't apply again.
            if (!this.pkg?.discount && discount) {
              this.voucherCode = discount?.code;
              this.applyVoucher();
            }
          }

          if (!this.pkg?.discount && this.checkoutVoucher?.code) {
            this.voucher.code = this.checkoutVoucher.code;
            this.voucherCode = this.checkoutVoucher.code;
            this.applyVoucher();
          }

          if (!this.pkg?.discount && sessionStorage.getItem("referral_code")) {
            this.voucherCode = sessionStorage.getItem("referral_code");
            this.applyVoucher();
          }
          this.calculateCartTotal();

          this.$nextTick(() => {
            this.loading = false;
          });
        }
      },
      update(data) {
        if (data.billingProducts.edges.length > 0) {
          let product = JSON.parse(JSON.stringify(data.billingProducts.edges[0].node));
          product.variant = null;
          product.clubVariant = null;

          if (product.productvariantSet.edges)
            product.productvariantSet = product.productvariantSet.edges.map((edge) => edge.node);

          if (this.productVariantId) {
            product.variant =
              product.productvariantSet.find((element) => decodeId(element.id) === decodeId(this.productVariantId)) ??
              null;
          } else if (this.preferredVariant) {
            let variantCode = `${this.preferredVariant}-${this.$route.params.code}`;
            product.variant = product.productvariantSet.find((el) => el.code == variantCode) ?? null;
          } else if (this.checkoutProduct && this.checkoutCode === this.$route.params.code) {
            let storedProduct = this.checkoutProduct;
            product.variant = storedProduct.variant;
            product.clubVariant = storedProduct.clubVariant;
          }

          return product;
        }
        return null;
      },
    },
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data && !response.loading) {
          // let's assume that if there is a billing order, we have a tokenized card. If there is an error, then show card form
          if (response.data?.subscription?.billingOrder && !this.paymentError.error) {
            /**
             * CAREFUL. HARD CODED TO FALSE. LET'S ALWAYS SHOW THE CREDIT CARD FORM
             * USING TOKENZIZED CARD NOT WORKING AFTER SWITCH TO 3DSECURE 2?
             */
            // this.useTokenizedCard = true;
          }
          this.currentSubscription = response.data.subscription;
        }
      },
    },
  },
  created() {
    // this order id is used by segment to uniquely track this order from `Checkout Started` to `Order Complete`
    // this is then also used by the segment web hook receiver to create a deal
    // only create the orderId once on load.
    let orderId = `${this.$store.getters.user.email}-${this.$moment().format("YYYY-MM-DD-HH:mm:ss")}`;
    this.$store.dispatch("updateOrderId", orderId);
    this.productVariantId = this.$route.query.pvi;
    this.packageCode = this.$route.query.package;
    this.paymentError.error = this.$route.query.error;
    this.$store.dispatch("updatePaymentRetry", false);
  },
};
</script>

<!-- Cannot scope a v-html's content, so use an id for the component to keep it safe -->
<style lang="scss">
#checkout-page .product-description.std-text * {
  color: var(--v-grey-navy-base);
  font-size: 16px;
}
</style>

<style lang="scss" scoped>
.row + .row {
  margin-top: 0;
}
.text-body {
  font-size: 1rem;
}

.font-12 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.checkout-card {
  padding: 12px;
  padding-top: 4px;
}

#amountSection div.d-flex div.v-card__text {
  padding-bottom: 0;
}

#amountSection div.d-flex + div.d-flex div.v-card__title,
#amountSection div.d-flex + div.d-flex div.v-card__text {
  padding-top: 0;
}

.discount-amounts div.v-card__text + div.v-card__text {
  padding-top: 0;
}
</style>
