<template>
  <div>
    <v-row v-if="$apollo.loading" justify="center">
      <v-col cols="12" md="5"><v-skeleton-loader type="card" /> </v-col
    ></v-row>

    <j-banner-container
      v-if="!$apollo.loading && todo"
      :header="todo.title"
    >
      <div class="pa-4" align="left">
        <div class="p-text" v-html="todo.description" />
        <j-card v-if="youtubeId" class="pa-4">
          <video-block :youtubeId="youtubeId" :show-video-cast-message="false" />
        </j-card>
        <j-card v-if="todo.loom" class="pa-4 mt-3">
          <div class="loom-div">
            <iframe
              :src="todo.loom"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              class="loom-iframe"
            />
          </div>
        </j-card>

        <div class="d-flex justify-center mt-6">
          <p v-if="todo.computed" class="std-text font-italic">This task will automatically mark as done</p>

          <j-btn v-else tertiary @click="completeTodo()" :loading="loading"
            >Mark as done<v-icon class="ml-1 mt-n1">mdi-check-circle</v-icon></j-btn
          >
        </div>
      </div>
    </j-banner-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TODO_QUERY } from "@/graphql/queries/todo";
import { UPSERT_USER_TODO_MUTATION } from "@/graphql/mutations/todo";
import VideoBlock from "@/components/events/VideoBlock";
import { getYouTubeId } from "@/lib/string";

export default {
  name: "TodoView",
  components: {
    VideoBlock,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    todoId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["outstandingUserTodos"]),
    youtubeId() {
      return getYouTubeId(this.todo?.youtube);
    },
  },
  methods: {
    async completeTodo() {
      this.loading = true;
      await this.$apollo.mutate({
        mutation: UPSERT_USER_TODO_MUTATION,
        variables: {
          data: {
            todoId: this.todo.id,
            completedDate: this.$moment().format("YYYY-MM-DD"),
          },
        },
        refetchQueries: ["assistantQuery"],
      });
      this.loading = false;
      if (this.outstandingUserTodos?.length > 0) {
        return this.$router.push(`/today/to-do/onboarding`);
      }
      return this.$router.push(`/today/to-do`);
    },
  },
  apollo: {
    todo: {
      query: TODO_QUERY,
      variables() {
        return {
          todoId: this.todoId,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.loom-div {
  position: relative;
  padding-bottom: 60.810810810810814%;
  height: 0;

  .loom-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
