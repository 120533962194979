<template>
  <j-main-banner-container md="5">
    <div class="px-4 pb-8">
      <v-img :src="require('@/assets/images/jan2022challenge.png')" />
      <h2 class="navy--text">Bring a friend to do the challenge with you</h2>
      <p class="std-text navy--text mt-4">
        Give your friend access to train with you at your gym for the duration of this 21 Challenge. All we need is
        their name and email address 🎉.
      </p>
      <j-btn class="mt-4" href="https://forms.gle/LVtBkhDdi8mw79he8" target="_blank">Add Friend</j-btn>
    </div>
  </j-main-banner-container>
</template>

<script>
export default {
  name: "InviteFriend",
};
</script>
