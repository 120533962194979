<template>
  <v-container>
    <j-banner-container
      header="All Your Programs"
      md="6"
      slot-text-align="left"
    >
      <div v-if="$apollo.loading">
        <v-row>
          <v-col cols="12"><v-skeleton-loader type="card-avatar,divider,card-avatar" /> </v-col>
        </v-row>
      </div>

      <v-row v-else justify="center" align="center">
        <v-col cols="12">
          <div align="center" class="my-4">
            <j-btn to="/programs" wide max-width="310" color="light-blue"
              >Choose Exercise Program <v-icon size="19">mdi-arrow-right</v-icon></j-btn
            >
          </div>
          <div align="center" class="my-4">
            <j-btn to="/programs?type=NUTRITION" wide max-width="310" color="green"
              >Choose Nutrition Menu<v-icon size="19">mdi-arrow-right</v-icon></j-btn
            >
          </div>
          <div align="center" class="my-4">
            <j-btn to="/programs?type=COURSE" wide max-width="310" color="yellow"
              >Choose course <v-icon size="19">mdi-arrow-right</v-icon></j-btn
            >
          </div>
          <!-- hide for now. -->
          <!-- <div align="center" class="my-4">
            <j-btn to="/journey" small narrow max-width="310" color="navy"
              >My {{ $appName }} Journey <v-icon size="19">mdi-calendar-arrow-right</v-icon></j-btn
            >
          </div> -->
        </v-col>
        <v-col cols="12">
          <div v-if="activeNutritionBlock">
            <j-subsection-row
              v-if="activeNutritionBlock"
              title="Currently active"
              :see-all="{
                text: 'Edit Or Leave',
                link: null,
                action: manageNutritionBlock,
                args: [activeNutritionBlock],
              }"
              class="mb-3"
            >
              <j-block-header-card
                class="pointer"
                :title="activeNutritionBlock.title"
                :avatar-picture="activeNutritionBlock.leadInstructor.userProfile.profilePicture"
                :sub-title="activeNutritionBlock.leadInstructor.firstName"
                :user-profile-list="activeNutritionBlock.userList"
                accent-color="green"
                :start-date="activeNutritionBlock.startDate"
                @click.native="navigateNutritionBlock(activeNutritionBlock.id)"
              />
              <hr class="mt-4" />
            </j-subsection-row>
          </div>
          <div v-if="workoutBlocks.length > 0">
            <j-subsection-row
              v-for="workoutBlock in workoutBlocks"
              :key="workoutBlock.id"
              :title="format_date(workoutBlock)"
              :see-all="{ text: 'Edit Or Leave', link: null, action: manageBlock, args: [workoutBlock] }"
              class="mb-3"
            >
              <workout-block-overview
                class="pointer"
                :workout-block="workoutBlock.block"
                :subscription-block="workoutBlock"
                :show-unit="false"
                :start-date="workoutBlock.startDate"
                type="PROGRAM"
                @click.native="navigateBlock(workoutBlock.block.id)"
              />
              <hr class="mt-4" />
            </j-subsection-row>
          </div>
        </v-col>
      </v-row>
    </j-banner-container>
    <!-- manage block dialog -->
    <v-dialog v-model="openManageBlockDialog" width="600" transition="dialog-bottom-transition" class="limited-height">
      <manage-block-dialog
        @leave="leaveBlockHandler"
        @saved="updateSubscriptionBlockHandler"
        :workout-subscription-block="workoutSubscriptionBlock"
        @closed="openManageBlockDialog = false"
      />
    </v-dialog>

    <!-- manage block dialog -->
    <v-dialog
      v-if="nutritionSubscriptionBlock"
      v-model="openManageNutritionBlockDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <manage-nutrition-block-dialog
        @leave="leaveNutritionBlockHandler"
        @saved="updateSubscriptionNutritionBlockHandler"
        :nutrition-subscription-block="nutritionSubscriptionBlock"
        @closed="openManageNutritionBlockDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { WORKOUT_SUBSCRIPTION_BLOCKS_QUERY } from "@/graphql/queries/workoutblock";
import { fancy_date } from "@/lib/fancy_date";
import WorkoutBlockOverview from "@/components/workoutblocks/cards/WorkoutBlockOverview";
import JBlockHeaderCard from "@/layouts/cards/JBlockHeaderCard";
import ManageBlockDialog from "@/components/workoutblocks/dialogs/ManageBlockDialog";
import BlockMixin from "@/mixins/BlockMixin";
import ManageNutritionBlockDialog from "@/components/nutritionblocks/dialogs/ManageBlockDialog";
import NutritionBlockMixin from "@/mixins/nutrition/NutritionBlockMixin";
import { decodeId } from "@/lib/string";

export default {
  name: "YourPrograms",
  mixins: [BlockMixin, NutritionBlockMixin],
  components: {
    WorkoutBlockOverview,
    ManageBlockDialog,
    JBlockHeaderCard,
    ManageNutritionBlockDialog,
  },
  data() {
    return {
      nutritionSubscriptionBlocks: [],
      workoutSubscriptionBlock: null,
      workoutBlocks: [],
      openManageBlockDialog: false,
      openManageNutritionBlockDialog: false,
      nutritionSubscriptionBlock: null,
    };
  },
  computed: {
    ...mapGetters(["activeNutritionBlock", "upcomingNutritionBlock"]),
  },
  methods: {
    navigateBlock(workoutBlockId) {
      this.$router.push(`/program/${decodeId(workoutBlockId)}`);
    },
    navigateNutritionBlock(nutritionBlockId) {
      this.$router.push(`/nutrition/${decodeId(nutritionBlockId)}`);
    },
    manageBlock(workoutBlock) {
      this.workoutSubscriptionBlock = workoutBlock;
      this.openManageBlockDialog = true;
    },
    manageNutritionBlock(nutritionSubscriptionBlock) {
      this.nutritionSubscriptionBlock = nutritionSubscriptionBlock;
      this.openManageNutritionBlockDialog = true;
    },
    async updateSubscriptionBlockHandler(data) {
      await this.updateSubscriptionBlock(data);
    },
    async leaveBlockHandler() {
      await this.leaveBlock();
      await this.$apollo.queries.workoutSubscriptionBlocks.refetch();
    },
    async leaveNutritionBlockHandler() {
      if (this.nutritionSubscriptionBlock?.subscriptionnutritionblockSet?.edges.length > 0) {
        this.nutritionSubscriptionBlock.subscriptionnutritionblockSet.edges[0].node.id;
        await this.leaveNutritionBlock(
          decodeId(this.nutritionSubscriptionBlock.subscriptionnutritionblockSet.edges[0].node.id)
        );
      }
      this.openManageNutritionBlockDialog = false;
    },
    async updateSubscriptionNutritionBlockHandler(data) {
      await this.updateNutritionSubscriptionBlock(data);
      this.openManageNutritionBlockDialog = false;
    },
    format_date(workoutBlock) {
      if (this.isActive(workoutBlock.startDate, workoutBlock.endDate)) {
        return "Currently active";
      }

      if (workoutBlock.endDate <= this.$moment().format("YYYY-MM-DD")) {
        const endDateString = fancy_date(workoutBlock.endDate, "D MMM", false);
        return "Ended on " + endDateString;
      }

      const datestring = fancy_date(workoutBlock.startDate, "D MMM", false);
      return "Starts on " + datestring;
    },
    isActive(startDate, endDate) {
      const s = this.$moment(startDate, "YYYY-MM-DD");
      const e = this.$moment(endDate, "YYYY-MM-DD");
      const now = this.$moment();
      return now.isBetween(s, e, "days", "[)");
    },
  },
  apollo: {
    workoutSubscriptionBlocks: {
      query: WORKOUT_SUBSCRIPTION_BLOCKS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          orderBy: "-start_date",
        };
      },
      result(response) {
        this.workoutBlocks = [];
        if (response.data && !response.loading) {
          const edges = response.data.workoutSubscriptionBlocks.edges;
          edges.forEach((element) => {
            this.workoutBlocks.push(element.node);
          });
        }
      },
    },
  },
};
</script>

<style scoped></style>
