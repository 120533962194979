<template>
  <v-container>
    <div v-if="$apollo.loading && !group">
      <v-row justify="center">
        <v-col cols="12" md="6"><v-skeleton-loader type="card-avatar" /> </v-col>
      </v-row>
    </div>
    <j-card-banner-container
      v-else
      header="Group"
      :image="groupImage"
      :show-image-upload="isCreator"
      :image-loading="imageLoading"
      :title="group.title"
      :textGradient="['pink', 'yellow']"
      @imageUpload="imageUpload"
      show-share-link
      use-header-title-in-link
    >
      <template v-slot:card-content>
        <j-card-content
          :summaryInfo="summaryInfo"
          :description="group.description"
          :summaryAvatarList="group.users.length > 0"
          accentColor="blue"
          :leftButton="!isCreator ? leftButton : null"
          :leftButtonIcon="!isCreator ? leftButtonIcon : null"
          leftButtonSecondary
          @leftButtonClicked="manageGroup"
          :disable-actions="disableActions"
          class="pb-5"
          :instructor="createdBy"
          :icon="createdByPic"
        >
          <template v-slot:summaryAvatarList>
            <j-avatar-list :user-list="group.users" justify-center accentColor="blue" />
          </template>
        </j-card-content>
      </template>

      <div align="left" class="pa-0 pt-2 pa-md-4" v-if="group.isMember && hasAccess">
        <post-container ref="postContainer" :groupId="groupId" hide-post-group-name show-pinned />
      </div>

      <j-alert class="my-4 mx-6" v-if="group.isMember && !hasAccess">
        Please join <a href="/checkout/jeff-club">JEFF Club</a> to participate in this group.
      </j-alert>
    </j-card-banner-container>

    <!-- leave group dialog -->
    <v-dialog
      v-if="group"
      v-model="leaveGroupDialog"
      width="400"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <leave-group :group="group" @saved="leaveGroup" @closed="leaveGroupDialog = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import { GROUP_QUERY } from "@/graphql/queries/group";
import { UPSERT_GROUP_MUTATION, JOIN_GROUP_MUTATION, LEAVE_GROUP_MUTATION } from "@/graphql/mutations/group";
import { blobToFile, dataURLtoBlob } from "@/lib/file";
import { capitalize } from "@/lib/string";
import PostContainer from "@/components/groups/posts/PostContainer";
import JCardContent from "@/layouts/sections/JCardContent";
import LeaveGroup from "@/components/groups/dialogs/LeaveGroup";

export default {
  name: "Group",
  components: {
    PostContainer,
    JCardContent,
    LeaveGroup,
  },
  data() {
    return {
      group: null,
      imageLoading: false,
      leaveGroupDialog: false,
      disableActions: false,
    };
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
  },
  computed: {
    createdBy() {
      if (!this.group.createdBy) {
        return null;
      }
      return `by ${this.group.createdBy.firstName} ${this.group.createdBy.lastName}`;
    },
    createdByPic() {
      return this.group?.createdBy?.userProfile?.profilePicture;
    },
    hasAccess() {
      const role = this.$store.getters.role;
      return role === "ONE_ON_ONE" || role === "CLUB";
    },
    groupImage() {
      if (this.group && this.group.banner) {
        return this.group.banner;
      }
      return require("@/assets/images/group_banner_default.png");
    },
    isCreator() {
      return this.group.createdBy && this.group.createdBy.id == this.$store.getters.user.id;
    },
    summaryInfo() {
      let summaryInfo = [];

      if (this.group?.memberCount) {
        summaryInfo.push({ title: "Members", value: this.group.memberCount });
      }

      if (this.group?.permission) {
        summaryInfo.push({
          title: capitalize(this.group.permission),
          value: this.group.permission == "PUBLIC" ? "mdi-web" : "mdi-lock-ouutline",
          icon: true,
        });
      }

      return summaryInfo;
    },
    leftButton() {
      return this.group?.isMember ? "Leave" : "Join";
    },
    leftButtonIcon() {
      return this.group?.isMember ? "mdi-logout" : "mdi-account-multiple-plus";
    },
    shareObject() {
      return {
        url: window.location.href,
        title: this.group.title,
        description: this.group.description,
        quote: `Check out this group on ${this.$appName}`,
        hashtags: "jeffclub",
        media: this.groupImage,
      };
    },
    groupBtnBindings() {
      if (this.group.isMember) {
        return {
          tertiary: true,
        };
      }
      return {
        secondary: true,
      };
    },
  },
  methods: {
    navigateManageGroup() {
      if (!this.isCreator) {
        return false;
      }
      this.$router.push("/manage-groups");
    },
    async imageUpload(data) {
      this.imageLoading = true;
      const blob = dataURLtoBlob(data.dataUrl);
      const file = blobToFile(blob);
      const input = {
        id: this.groupId,
        banner: file,
      };
      await this.$apollo.mutate({
        mutation: UPSERT_GROUP_MUTATION,
        variables: {
          data: input,
        },
        refetchQueries: ["groups", "group"],
      });
      this.imageLoading = false;
    },
    reloadGroup() {
      const postContainer = this.$refs.postContainer;
      postContainer.reloadThread();
    },
    async manageGroup() {
      if (this.group.isMember) {
        this.leaveGroupDialog = true;
      } else {
        this.disableActions = true;
        await this.$apollo.mutate({
          mutation: JOIN_GROUP_MUTATION,
          variables: {
            groupId: this.group.id,
          },
        });
        this.disableActions = false;
      }
      this.$apollo.queries.group.refetch();
    },
    async leaveGroup() {
      this.disableActions = true;
      this.leaveGroupDialog = false;
      await this.$apollo.mutate({
        mutation: LEAVE_GROUP_MUTATION,
        variables: {
          groupId: this.group.id,
        },
      });
      this.disableActions = false;
      this.$apollo.queries.group.refetch();
    },
  },
  apollo: {
    group: {
      query: GROUP_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          groupId: this.groupId,
        };
      },
    },
  },
};
</script>
