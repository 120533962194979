<template>
  <div class="mb-3">
    <j-btn tertiary narrow small @click="showModal = true">
      Learn more
      <v-icon color="blue">mdi-play-circle-outline</v-icon>
    </j-btn>

    <v-dialog v-if="showModal" v-model="showModal" max-width="600px">
      <j-card class="pa-2 card-radius">
        <v-btn icon color="planet-grey" class="float-right" @click="showModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="mt-1 pt-0">

          <h2 class="secondary--text mx-auto">Accountability Groups at {{$appName}}</h2>
        </v-card-title>
        <v-card-text class="std-text mt-3">
          <p class="std-text">
            Watch this 1-minute video on why people love {{$appName}} Accountability Groups
          </p>
          <div class="mt-4">
            <video-block youtubeId="MwiUvzBt1HI" :show-video-cast-message="false"  />
          </div>
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import VideoBlock from "@/components/events/VideoBlock";
export default {
  name: "AccountabilityGroupHowTo",
  components: { VideoBlock },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

