<template>
  <j-banner-container
    header="Dots"
  >
    <v-tabs v-model="activeTab" centered>
      <v-tab key="overview"> Summary </v-tab>
      <v-tab key="edit"> View </v-tab>
      <v-tabs-items v-model="activeTab" class="py-2">
        <v-tab-item key="overview">
          <activity-overview />
        </v-tab-item>
        <v-tab-item key="edit">
          <div>
            <v-snackbar v-model="deleteAlert" :timeout="3000" :top="true"> Activity Deleted </v-snackbar>
            <v-row v-if="!inActive" align="center" justify="center">
              <j-btn tertiary class="mt-8" @click="logCustomActivity()"> Log my activity </j-btn>
            </v-row>
            <v-skeleton-loader v-if="loading || $apollo.loading" type="card, card, card" class="mt-8 mx-4" />
            <v-row v-else align="center" justify="center">
              <v-col v-if="activityList && activityList.length < 1" cols="12" md="8">
                <v-alert class="mx-4 mt-6" dense outlined color="exercise"> No custom activities logged. </v-alert>
              </v-col>
              <v-col v-else cols="11">
                <j-card v-for="(item, i) in activityList" :key="`${i}-${item.node.id}`" class="my-3">
                  <v-toolbar flat class="pa-0 ma-0">
                    <span class="secondary--text">{{ activityDate(item.node.startDate) }}</span>
                    <v-spacer />
                    <span class="secondary--text pt-0 pr-1">
                      {{ activityName(item.node) }}
                    </span>
                    <v-icon :color="getNotificationColor(item.node)">fiber_manual_record</v-icon>
                  </v-toolbar>
                  <v-card-title class="secondary--text">
                    {{ activityTitle(item.node) }}
                  </v-card-title>
                  <v-card-text class="text-left ml-1 p-text">
                    <div v-if="item.node.effort">Effort: {{ item.node.effort }}</div>
                    <div v-if="item.node.seconds">Time spent: {{ item.node.seconds | humanizeSeconds }}</div>
                    <div v-if="item.node.calories">Calories burned: {{ item.node.calories }}</div>
                    <div v-if="item.node.heartRate">Average heart rate: {{ item.node.heartRate }} bpm</div>
                    <div v-if="item.node.averagePace">Average pace: {{ item.node.averagePace }} min/km</div>
                    <div v-if="item.node.distance">Distance: {{ item.node.distance }} km</div>
                    <div v-if="item.node.comment && item.node.comment.trim() !== ''">
                      <br />Comments: <br /><span v-html="formatComment(item.node.comment)"></span>
                    </div>
                  </v-card-text>
                  <v-card-actions class="pt-3" style="border-top: 1px solid #dadae2">
                    <v-btn
                      color="background-grey"
                      class="secondary--text pa-2"
                      rounded
                      @click="deleteActivityModal(item.node)"
                    >
                      Delete
                    </v-btn>
                    <v-spacer />
                    <v-btn color="secondary" class="white--text pa-2" rounded @click="edit(item.node)">Edit</v-btn>
                  </v-card-actions>
                </j-card>
              </v-col>
              <v-dialog v-model="editDialog" max-width="600px">
                <j-card v-if="editDialog" rounded="lg">
                  <v-toolbar class="mb-6">
                    <v-toolbar-title>
                      <span>Edit Activity</span>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="editDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text class="pl-2 pr-2">
                    <custom-activity-form
                      v-if="selectedActivity !== {} && selectedActivity.isCustom"
                      :userActivityId="selectedActivity.id"
                      @activityLogged="callbackOnActivityEdit"
                    />
                    <activity-form
                      v-if="selectedActivity !== {} && !selectedActivity.isCustom"
                      :userActivityId="selectedActivity.id"
                      :workoutType="selectedActivity.type"
                      :workoutId="selectedActivity.workoutId"
                      :fullForm="renderFullForm(selectedActivity)"
                      @activityLogged="callbackOnActivityEdit"
                    />
                  </v-card-text>
                </j-card>
              </v-dialog>
              <v-dialog v-model="logCustomWorkoutDialog" max-width="600px">
                <j-card rounded="lg">
                  <v-toolbar class="mb-6">
                    <v-toolbar-title>
                      <span>Log Activity</span>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="logCustomWorkoutDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text class="pl-2 pr-2">
                    <custom-activity-form
                      :fullForm="true"
                      @activityLogged="callbackOnActivityEdit"
                      :key="customActivityModalKey"
                    />
                  </v-card-text>
                </j-card>
              </v-dialog>
              <v-dialog v-model="deleteDialog" width="500">
                <j-card rounded="lg">
                  <v-card-title class="headline grey lighten-2"> Are you sure you want to delete? </v-card-title>
                  <v-card-text></v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="secondary" @click="deleteDialog = false"> Cancel </v-btn>
                    <v-spacer />
                    <v-btn color="background-grey" class="secondary--text pa-2" @click="deleteActivity">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </j-card>
              </v-dialog>
            </v-row>
            <scroll-loader :loader-method="loadmore" :loader-disable="!hasNextPage"></scroll-loader>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <!-- Enahance Dot/Workout Log dialog -->
    <v-dialog
      v-if="logDotDialog"
      v-model="logDotDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <enhance-workout-log
        :activity-id="selectedActivityId"
        :subscription-block-id="selectedSubscriptionBlockId"
        :unit-activity="selectedWorkoutBlockUnitActivity"
        @closed="logDotDialog = false"
        @activityLogged="callbackOnActivityEdit"
        :is-editing="true"
      />
    </v-dialog>
  </j-banner-container>
</template>

<script>
import { USER_ACTIVITIES_PAGINATED_QUERY } from "@/graphql/queries/tracker";
import { DELETE_ACTIVITY_MUTATION } from "@/graphql/mutations/tracker";
import { fancy_date } from "@/lib/fancy_date";
import {nl2br, capitalize, decodeId} from "@/lib/string";
import ActivityForm from "@/components/tracker/activity-form/ActivityForm";
import CustomActivityForm from "@/components/tracker/activity-form/CustomActivityForm";
import EnhanceWorkoutLog from "@/components/workoutblocks/dialogs/EnhanceWorkoutLog";
import ActivityOverview from "@/components/tracker/ActivityOverview";

export default {
  name: "ActivityHistory",
  components: {
    ActivityForm,
    CustomActivityForm,
    EnhanceWorkoutLog,
    ActivityOverview,
  },
  data() {
    return {
      activeTab: "overview",
      pageTitle: "",
      after: "",
      endCursor: "",
      hasNextPage: true,
      activities: [],
      activityList: [],
      loading: true,
      saving: false,
      selectedActivity: {},
      editDialog: false,
      deleteDialog: false,
      logCustomWorkoutDialog: false,
      deleteAlert: false,
      customActivityModalKey: 1, //used to force vueJS to re-render the component
      logDotDialog: false,
      selectedActivityId: null,
      selectedSubscriptionBlockId: null,
      selectedWorkoutBlockUnitActivity: null,
    };
  },
  computed: {
    inActive() {
      return this.$store.getters.role == "INACTIVE" || this.$store.getters.inActive;
    },
  },
  methods: {
    formatComment(string) {
      return nl2br(string);
    },
    loadmore() {
      this.after = this.endCursor;
    },
    activityDate(timestamp) {
      return this.$moment.utc(timestamp).format("ddd, D MMM YYYY");
    },
    getMonday() {
      return this.$moment().startOf("isoweek").format("YYYY-MM-DD");
    },
    getSunday() {
      return this.$moment().endOf("isoweek").format("YYYY-MM-DD");
    },
    logItem(activity) {
      var activityItem = {};
      if (activity.workout) {
        activityItem = activity.workout;
      } else if (activity.action) {
        activityItem = activity.action.workout;
      } else if (activity.event) {
        activityItem = activity.event;
      } else if (activity.guidedRun) {
        activityItem = activity.guidedRun;
      }
      return activityItem;
    },
    renderFullForm(activity) {
      if (activity.isCustom) {
        return true;
      } else {
        if (activity.event) {
          if (activity.event.eventType.category == "EXERCISE") {
            return true;
          }
        } else if (activity.workout || activity.guidedRun) return true;
      }
      return false;
    },
    deleteActivity() {
      this.loading = true;

      this.$apollo.mutate({
        mutation: DELETE_ACTIVITY_MUTATION,
        refetchQueries: ["userDotsQuery", "userStatsQuery"],
        variables: {
          id: this.selectedActivity.id,
        },
      })
      .then((response) => {
        if (response.data && response.data.deleteUserActivity.ok) {
          this.callbackOnActivityEdit();
          window.analytics.track("Activity Deleted");
          this.deleteDialog = false;
          this.deleteAlert = true;
          this.loading = false;
        }
      })
      .catch((error) => {
        this.loading = false;
        this.error = true;
        throw error;
      });

      this.deleteDialog = false;
    },
    deleteActivityModal(activity) {
      this.selectedActivity = activity;
      this.deleteDialog = true;
    },
    edit(activity) {
      var activityToEdit = activity;

      if (activity.unitActivity) {
        this.selectedActivityId = decodeId(activity.id);
        this.selectedSubscriptionBlockId = activity.subscriptionBlock.id;
        this.selectedWorkoutBlockUnitActivity = activity.unitActivity;
        return (this.logDotDialog = true);
      }

      if (!activity.isCustom) {
        var item = this.logItem(activity);
        activityToEdit["workoutId"] = item.id;
        activityToEdit["eventType"] = item.type ? item.type : "";
        activityToEdit["type"] = this.activityType(activity);
      }
      this.selectedActivity = activityToEdit;
      this.editDialog = true;
    },
    activityName(activity) {
      if (activity.workout || activity.action) {
        return "Workout";
      } else if (activity.event) {
        return activity.event.eventType.name;
      } else if (activity.guidedRun) {
        return activity.guidedRun.category == "MEDITATION" ? "Meditation" : "Run";
      } else if (activity.isCustom && activity.type) {
        if (activity.type.name.toLowerCase() == "other") return activity.activityType;
        return activity.type.name;
      }
      if (activity.unitActivity?.type) {
        return capitalize(activity.unitActivity.type);
      }
      return "";
    },
    activityTitle(activity) {
      if (activity.isCustom) {
        if (activity.type) {
          if (activity.type.name.toLowerCase() == "other") {
            return activity.activityType + " | Custom Activity";
          }
          return activity.type.name + " | Custom Activity";
        }
        return "Other | " + activity.activityType.replace("Other: ", "") + " | Custom Activity";
      } else {
        if (activity.event) {
          return activity.event.name;
        } else if (activity.workout || activity.action) {
          return this.logItem(activity).label;
        }

        if (activity.unitActivity?.activity) {
          return activity.unitActivity.activity.title;
        }
        return this.logItem(activity).name;
      }
    },
    activityType(activity) {
      if (activity.workout) {
        return "workout";
      } else if (activity.event) {
        return "event";
      } else if (activity.guidedRun) {
        return "run";
      } else if (activity.isCustom) {
        return activity.activityType;
      }

      return "";
    },
    getNotificationColor(activity) {
      if (activity.event) {
        if (activity.event.eventType) {
          return activity.event.eventType.category.toLowerCase();
        }
      }

      if (
        activity.guidedRun?.category == "MEDITATION" ||
        activity.unitActivity?.type == "MODULE" ||
        activity.unitActivity?.type == "ED"
      ) {
        return "mindset";
      }

      if (activity.workout || activity.action || activity.guidedRun) {
        return "exercise";
      } else if (activity.isCustom && activity.type) {
        return activity.type.category.toLowerCase();
      }

      if (activity.unitActivity?.type == "WORKOUT") {
        return "exercise";
      }
    },
    logCustomActivity() {
      this.customActivityModalKey++;
      this.logCustomWorkoutDialog = true;
    },
    callbackOnActivityEdit() {
      this.logCustomWorkoutDialog = false;
      this.editDialog = false;
      this.activityList = [];
      this.after = "";
      this.endCursor = "";
      this.hasNextPage = "";
      this.$apollo.queries.activities.refetch();
    },
    fancyDate(date, format, daysAgo) {
      return fancy_date(date, format, daysAgo);
    },
  },
  apollo: {
    activities: {
      query: USER_ACTIVITIES_PAGINATED_QUERY,
      variables() {
        return {
          after: this.after,
          // setting `first` to high, because the merged relay pagination is not super stable, (i.e where i merge two relay results into one array)
          // so best to bypass for the majority of people. it does work, but the activity date ordering may be a teeny bit funky
          // most will probably not notice. if set high then dates should more or less line up and things should be fine.
          first: 30,
          orderBy: "-startDate",
        };
      },
      fetchPolicy: "no-cache",
      result(response) {
        if (response.data) {
          this.endCursor = response.data.activities.pageInfo.endCursor;
          this.hasNextPage = response.data.activities.pageInfo.hasNextPage;
          this.activityList = response.data.activities.edges;
          this.loading = false;
        }
      },
      update: (data) => data.activities.edges,
    },
  },
};
</script>

<style>
.notification-icon {
  margin-top: 2px;
  font-size: 13px !important;
}
</style>
