<template>
  <v-checkbox
    large
    on-icon="mdi-check-circle"
    off-icon="mdi-checkbox-blank-circle-outline"
    v-bind="[$props, $attrs]"
    v-on="$listeners"
    class="j-checkbox font-weight-medium secondary--text"
  >
    <template v-slot:label>
      <slot name="label" />
    </template>
    <slot />
  </v-checkbox>
</template>

<script>
import { VCheckbox } from "vuetify/lib";

export default {
  name: "JCheckbox",
  extends: VCheckbox,
  props: {
    color: {
      type: String,
      default: "secondary"
    }
  }
};
</script>

<style lang="scss">
// NOTE, not scoped, so use the j-checkbox wrapper class
// i.v-icon {
//   font-size: 28px;
//   border-radius: 100%;
//   border: 2px var(--v-secondary-base) solid;
// }
.j-checkbox {
  .v-label {
    font-size: 14px;
  }
}
</style>
