<template>
  <v-container>
    <div v-if="pageLoaded" class="text-center ma-12">
      <v-progress-circular :indeterminate="pageLoaded" color="blue" />
    </div>
    <div v-else>
      <v-row align="center" justify="center">
        <v-col class="mb-0 pb-0">
          <h1 class="text-center mt-4 mb-2 grey-navy--text">On-Demand Classes</h1>
          <p class="text-center font-weight-bold">Browse past live classes and activities</p>
        </v-col>
      </v-row>
      <v-row justify="center" class="px-4">
        <v-col cols="6">
          <j-select
            v-model="selectedEventType"
            :items="eventTypes"
            label="Activity Type"
            dense
            hide-details
            @change="updateWorkouts(true)"
          />
        </v-col>
        <v-col cols="6">
          <j-select
            v-model="workoutType"
            :items="workoutTypes"
            dense
            label="Workout Type"
            hide-details
            @change="updateWorkouts(true)"
          />
        </v-col>
        <v-col cols="6">
          <j-select
            v-model="selectedInstructors"
            :items="instructors"
            attach
            chips
            label="Instructors"
            multiple
            clearable
            hide-details
            @change="updateWorkouts(true)"
          />
        </v-col>
        <v-col cols="6">
          <j-text-field v-model="filter" dense label="Search" hide-details @keyup="updateWorkoutsDelay()" />
        </v-col>
      </v-row>
      <v-row v-if="pagination.pages > 1" align="center" justify="center">
        <v-col cols="12" md="9" sm="9" class="pb-0">
          <v-pagination
            v-if="pagination.pages > 1"
            v-model="pagination.currentPage"
            :length="pagination.pages"
            @input="updateWorkouts()"
          ></v-pagination>
        </v-col>
      </v-row>
      <v-row v-if="workouts && workouts.length > 0 && !loadingWorkouts" align="center" justify="center">
        <v-col cols="12" md="9" sm="9" class="pb-0">
          <p align="center" class="pt-3"><v-icon class="mb-1">sort</v-icon> Most recent shown first</p>
          <up-sell-pop-up :showModal="showUpSellModal" v-on:upSellModalClosed="showUpSellModal = false" />
          <v-card
            class="pa-0 mb-3 card-radius dashboard-card"
            v-for="(item, index) in workouts"
            :key="index"
            @click.prevent="handleEventNavigation(item.node)"
          >
            <v-toolbar flat class="pa-0 ma-0">
              <span class="grey-navy--text p-text"
                >{{ item.node.scheduledDate | moment("ddd, D MMMM YYYY | HH:mm") }}
                <v-icon small class="pb-2">history </v-icon>
                Past
              </span>
              <v-spacer />
              <span class="secondary--text pt-0 pr-1 p-text">
                {{ item.node.eventType.name }}
              </span>
              <v-icon
                v-if="
                  (item.node.useractivitySet.edges && item.node.useractivitySet.edges.length < 1) ||
                  item.node.useractivitySet.edges[0].node.status == 'IN_PROGRESS'
                "
                :color="item.node.eventType.category.toString().toLowerCase()"
              >
                radio_button_unchecked
              </v-icon>
              <v-icon v-else :color="item.node.eventType.category.toString().toLowerCase()" style="font-size: 28px"
                >fiber_manual_record
              </v-icon>
              <manage-bookmark
                :objectId="item.node.id"
                objectType="event"
                :bookmarkId="item.node.userBookmark && item.node.userBookmark.id"
                @saved="updateWorkouts(false, false)"
              />
            </v-toolbar>

            <div class="d-flex align-center pa-4">
              <v-img
                max-width="75"
                class="rounded-lg mr-2"
                :src="
                  item.node.thumbnail
                    ? item.node.thumbnail
                    : item.node.userProfile && item.node.userProfile.profilePicture
                "
              ></v-img>
              <div class="text-left">
                <div v-if="inActive">
                  <open-availability :locked="locked(item.node)" />
                </div>
                <span class="p-text navy--text font-weight-bold">{{ item.node.name }}</span>
                <div v-if="item.node.durationMinutes" class="sm-text pt-2">{{ item.node.durationMinutes }} minutes</div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="loadingWorkouts" align="center" justify="center">
        <v-col cols="12" class="pb-0">
          <div class="text-center ma-12" v-if="loadingWorkouts">
            <v-progress-circular :indeterminate="loadingWorkouts" color="blue" />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="9" sm="9" class="pb-0">
          <v-pagination
            v-if="pagination.pages > 1"
            v-model="pagination.currentPage"
            :length="pagination.pages"
            @input="updateWorkouts()"
          ></v-pagination>
        </v-col>
      </v-row>
      <v-row v-if="workouts && workouts.length == 0 && !loadingWorkouts" align="center" justify="center">
        <v-col class="pb-0">
          <div class="text-center ma-12">
            <strong>No Results Found.</strong>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { decodeId } from "@/lib/string";
import { PAGINATED_EVENT_LIST_QUERY, EVENT_TYPES_QUERY } from "@/graphql/queries/events";
import { CLIENTS_QUERY } from "@/graphql/queries/account";
import UpSellPopUp from "@/components/shared/blocks/UpSellPopUp";
import OpenAvailability from "@/components/shared/blocks/OpenAvailability";
import ManageBookmark from "@/components/bookmarks/block/ManageBookmark";
import { ADD_PARAMS_TO_LOCATION } from "@/lib/history";

export default {
  name: "Vault",
  components: {
    UpSellPopUp,
    OpenAvailability,
    ManageBookmark,
  },
  data: () => ({
    pagination: {
      currentPage: 1,
      pages: 0,
      pageSize: 10,
      after: null,
      endCursor: null,
    },
    showUpSellModal: false,
    workouts: {
      objects: [],
    },
    selectedInstructors: [],
    instructors: [],
    selectedEventType: null,
    loadingWorkouts: false,
    workoutType: null,
    filter: "",
    keyUpSearchInterval: null,
    workoutTypes: [
      { text: "Any", value: null },
      { text: "Full Body Cardio", value: "Full Body Cardio" },
      { text: "Cardio and Strength", value: "Cardio and Strength" },
      { text: "Core and Cardio", value: "Core and Cardio" },
      { text: "Boxfit", value: "Boxfit" },
      { text: "Low Impact - High Burn", value: "Low Impact - High Burn" },
    ],
    eventTypes: [],
    loadingSection: {
      eventList: true,
    },
  }),
  mounted() {
    window.analytics.page("Vault");

    this.reloadFilterState();
  },
  computed: {
    pageLoaded() {
      return this.loadingSection.eventList;
    },
    inActive() {
      return this.$store.getters.role == "INACTIVE" || this.$store.getters.inActive;
    },
  },
  methods: {
    handleEventNavigation(event) {
      if (this.locked(event)) {
        this.showUpSellModal = true;
      } else {
        this.$router.push(`/event/${this.getWorkoutId(event.id)}`);
      }
    },
    locked(event) {
      if (!this.inActive) {
        return null;
      }

      return !event.openAccess;
    },
    reloadFilterState() {
      if (this.$route.query.page) this.pagination.currentPage = parseInt(this.$route.query.page);

      if (this.$route.query.instructorIdIn) this.selectedInstructors = this.$route.query.instructorIdIn.split(",");

      if (this.$route.query.workoutType && this.$route.query.workoutType != "")
        this.workoutType = this.$route.query.workoutType;

      if (this.$route.query.filter && this.$route.query.filter != "") this.filter = this.$route.query.filter;

      let resetPage = !this.$route.query.page;

      this.updateWorkouts(resetPage);
    },
    updateWorkoutsDelay() {
      if (this.keyUpSearchInterval) {
        clearTimeout(this.keyUpSearchInterval);
        this.keyUpSearchInterval = null;
      }
      this.keyUpSearchInterval = setTimeout(() => {
        this.updateWorkouts(true);
      }, 1000);
    },
    updateWorkouts(resetPage = false, triggerLoading = true) {
      if (this.selectedInstructors === [] && this.instructorIdIn === "") {
        this.workouts = [];
        return;
      }

      this.loadingWorkouts = triggerLoading;

      if (resetPage) {
        this.pagination.currentPage = 1;
        this.pagination.endCursor = null;
        this.pagination.after = null;
      }

      if (this.pagination.currentPage > 1) {
        let afterString = "arrayconnection:" + (this.pagination.currentPage - 1) * this.pagination.pageSize;
        this.pagination.after = btoa(afterString);
      } else {
        this.pagination.after = null;
      }

      let variables = {
        eventTypeName: this.selectedEventType,
        orderBy: "-scheduled_date",
        pageSize: this.pagination.pageSize,
        endDate: new Date().toISOString(),
        hidden: false,
      };
      if (this.filter) {
        variables.nameContains = this.filter;
      }

      if (this.pagination.after) {
        variables.after = this.pagination.after;
      }

      if (this.selectedInstructors.length > 0) {
        variables.instructorIdIn = this.selectedInstructors;
      }

      if (this.workoutType && this.workoutType.length > 0) {
        variables.nameContains = this.workoutType;
      }

      this.$apollo
        .mutate({
          mutation: PAGINATED_EVENT_LIST_QUERY,
          variables: variables,
        })
        .then((response) => {
          this.loadingWorkouts = false;
          this.workouts = response.data.paginatedEvents.edges;
          this.pagination.pages = 0;
          if (response.data.paginatedEvents.totalCount > 0) {
            this.pagination.pages = Math.ceil(response.data.paginatedEvents.totalCount / this.pagination.pageSize);
          }
          this.pagination.endCursor = response.data.endCursor;
          this.addParamsToLocation({
            page: this.pagination.currentPage,
            workoutType: this.workoutType ? this.workoutType : "",
            instructorIdIn: variables.instructorIdIn ? variables.instructorIdIn : [],
            filter: this.filter,
          });
        });
    },
    getWorkoutId(base64string) {
      let decoded = atob(base64string).split(":");
      if (decoded[1]) return decoded[1];
      return "";
    },
    addParamsToLocation(params) {
      ADD_PARAMS_TO_LOCATION(this.$route, params);
    },
  },
  apollo: {
    eventTypes: {
      query: EVENT_TYPES_QUERY,
      variables() {
        return {
          category: null,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.loading) return;

        if (response.data) {
          let excludedCategories = ["Unspecified", "Dance"];
          let filters = response.data.eventTypes
            .filter((value) => {
              return !excludedCategories.includes(value.name);
            })
            .map((value) => {
              return { text: value.name, value: value.name };
            });
          filters = [{ text: "Any", value: null }, ...filters];
          this.eventTypes = filters;
          this.loadingSection.eventList = false;
        }
      },
    },
    clients: {
      query: CLIENTS_QUERY,
      variables() {
        return {
          isEventInstructor: true,
          orderBy: "user__first_name",
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          this.instructors = response.data.clients.edges.map((item) => {
            return {
              text: `${item.node.user.firstName} ${item.node.user.lastName} (${item.node.user.userProfile.numEventsInstructing})`,
              value: decodeId(item.node.user.id),
            };
          });
        }
      },
    },
  },
};
</script>
