<template>
  <div v-if="product" class="d-inline-block">
    <v-avatar v-if="image" size="120">
      <v-img cover :src="image" />
    </v-avatar>
    <v-card-title class="text-heading font-weight-bold secondary--text pa-2">
      {{ title }}
    </v-card-title>

    <div v-if="subTitle" class="sm-text mt-n2">{{ subTitle }}</div>
    <p v-if="selectedCoach" class="font-weight-bold">
      with Coach {{ selectedCoach }}
      <router-link
        class="text-decoration-none"
        :to="`/start/coaches?redirect=/checkout/join?code=${this.$route.query.code}`"
        ><v-icon size="16" class="ml-1 mt-n1">mdi-pencil</v-icon></router-link
      >
    </p>
  </div>
</template>

<script>
import { BILLING_PRODUCTS_QUERY } from "@/graphql/queries/store";
import { WORKOUT_BLOCKS_QUERY } from "@/graphql/queries/workoutblock";
import { decodeId, toGraphqlID } from "@/lib/string";

export default {
  name: "ProductTitleBlock",
  data() {
    return {
      selectedCoach: null,
    };
  },
  created() {
    let selectedCoach = sessionStorage.getItem("selectedCoach");
    if (selectedCoach && this.$route.query?.code?.includes("1-on-1")) {
      selectedCoach = JSON.parse(selectedCoach);
      this.selectedCoach = selectedCoach.coachName;
    }
  },
  computed: {
    image() {
      if (this.workoutBlock?.image) {
        return this.workoutBlock.image;
      }
      return this.product.image;
    },
    productVariant() {
      const productVariantId = this.$route.query.pvi;
      if (productVariantId && this.product?.productvariantSet?.edges) {
        const productVariant = this.product.productvariantSet.edges.find(
          (element) => decodeId(element.node.id) == decodeId(productVariantId)
        );
        if (productVariant) {
          return productVariant.node;
        }
      }
      return null;
    },
    title() {
      if (this.productVariant) {
        return this.productVariant.title;
      }

      return this.product.title;
    },
    subTitle() {
      if (this.workoutBlock) {
        if (this.$moment().isBefore(this.workoutBlock.startDate)) {
          return `Starts ${this.$moment(this.workoutBlock.startDate, "YYYY-MM-DD").format("ddd, D MMMM")}`;
        }
        return "Always on";
      }
      return null;
    },
  },
  apollo: {
    product: {
      query: BILLING_PRODUCTS_QUERY,
      variables() {
        return {
          code: this.$route.query.code || this.$route.params.code,
          withPackages: false,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.billingProducts.edges.length > 0) {
          let product = data.billingProducts.edges[0].node;
          return product;
        }
        return null;
      },
    },
    workoutBlock: {
      query: WORKOUT_BLOCKS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          productVariant_Id: toGraphqlID("WorkoutBlock", this.productVariant.id),
          withUsers: false,
          orderBy: "-startDate",
        };
      },
      update(data) {
        return data.workoutBlocks?.edges[0]?.node;
      },
      skip() {
        return !this.productVariant;
      },
    },
  },
};
</script>
