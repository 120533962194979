<template>
  <v-select
    :color="color"
    :outlined="outlined"
    append-icon="mdi-chevron-down"
    v-bind="$props"
    v-on="$listeners"
    class="j-select"
  >
    <template v-if="chipColor" v-slot:selection="{ item }">
      <v-chip :color="chipColor" class="white--text">
        <span>{{ item.text }}</span>
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import { VSelect } from "vuetify/lib";

export default {
  name: "JSelect",
  extends: VSelect,
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    chipColor: {
      type: String,
      required: false
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },
};
</script>
