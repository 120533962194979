<template>
  <div v-if="block" class="pointer">
    <j-card rounded="lg" class="mr-3 my-1 j-elevated-2" :to="link">
      <v-img v-if="block.image" :src="block.image" :lazy-src="block.image" :aspect-ratio="10 / 9" />
      <v-chip v-if="block.hasCompleted" small color="nutrition" class="new-chip">DONE</v-chip>
      <v-chip v-else-if="isPremium(block)" small color="navy" class="new-chip">CHALLENGE</v-chip>
      <v-chip v-else-if="showNewChip" small color="yellow" class="new-chip">NEW</v-chip>
      <v-chip v-else-if="block.featured" small color="yellow" class="new-chip">FEATURED</v-chip>
      <v-card-text class="px-2 pt-1 pb-2 text-left">
        <div :class="{ 'd-flex': $vuetify.breakpoint.mdAndUp, 'mt-n4': $vuetify.breakpoint.smAndDown }">
          <div class="d-flex">
            <user-avatar
              v-if="block.leadInstructor"
              :profile-picture="block.leadInstructor.userProfile.profilePicture"
              :user="block.leadInstructor"
              size="40"
            />

            <div v-if="block.secondaryInstructor" class="ml-n1">
              <user-avatar
                :profile-picture="block.secondaryInstructor.userProfile.profilePicture"
                :user="block.secondaryInstructor"
                size="40"
              />
            </div>
          </div>
          <div class="ml-2">
            <div class="navy--text font-weight-bold">{{ block.title }}</div>

            <div class="d-flex justify-space-between">
              <j-gradient-text
                v-if="block.leadInstructor"
                :gradient="['pink', 'yellow']"
                class="text-uppercase sm-text"
              >
                {{ withCopy }}
              </j-gradient-text>
              <div v-if="block.startDate >= $moment().format('YYYY-MM-DD')" class="sm-text">
                <v-icon class="sm-text" small>mdi-calendar-clock</v-icon>
                {{ $moment(block.startDate).format("D MMM YY") }}
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <div v-if="block.avgRating > 0" class="d-flex align-baseline justify-start mt-n1">
        <v-rating
          full-icon="mdi-star"
          background-color="yellow lighten-1"
          color="yellow"
          length="1"
          readonly
          size="25"
          :value="3"
        ></v-rating>
        <p class="p-text">{{ block.avgRating }} ({{ block.totalReviews }})</p>
      </div>
    </j-card>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/user/UserAvatar";
import { decodeId } from "@/lib/string";
import BlockMixin from "@/mixins/BlockMixin";
import PremiumBlockMixin from "@/mixins/PremiumBlockMixin";

export default {
  name: "BlockListingCard",
  mixins: [BlockMixin, PremiumBlockMixin],
  components: {
    UserAvatar,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    showNewChip() {
      return this.block?.startDate >= this.$moment().format("YYYY-MM-DD");
    },
    link() {
      if (this.type == "NUTRITION") {
        return `/nutrition/${decodeId(this.block.id)}`;
      }
      return `/program/${decodeId(this.block.id)}`;
    },
  },
  methods: {
    decodeId(val) {
      return decodeId(val);
    },
  },
};
</script>

<style lang="scss">
.new-chip {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white !important;
  font-weight: bold;
}

.star-rating-review .v-icon.v-icon.v-icon--link {
  padding-left: 0px !important;
}
</style>
