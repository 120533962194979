<template>
  <j-banner-container
    header="Stats"
    :backLink="`/p/${userId}`"
    md="6"
    slot-text-align="left"
  >
    <activity-overview :userId="decodedId" />
  </j-banner-container>
</template>

<script>
import ActivityOverview from "@/components/tracker/ActivityOverview";
import { decodeId } from "@/lib/string";

export default {
  name: "UserStats",
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    decodedId() {
      return decodeId(this.userId);
    },
  },
  components: {
    ActivityOverview,
  },
};
</script>
