<template>
  <v-textarea :color="color" outlined v-bind="$props" ref="vtextarea" v-on="$listeners" class="j-textarea">
    <template v-slot:append>
      <slot name="append" />
    </template>
  </v-textarea>
</template>

<script>
import { VTextarea } from "vuetify/lib";
import Tribute from "tributejs";

export default {
  name: "JTextarea",
  extends: VTextarea,
  data() {
    return {
      mentionOptions: {
        itemClass: "v-list-item v-list-item--link theme--light",
        selectClass: "mention--highlighted",
        containerClass: "v-list v-list--dense v-list--flat v-card theme--light displayTop",
        values: null,
        requireLeadingSpace: false,
        allowSpaces: false,
        loadingItemTemplate: "<span>Loading Results</span>",
        searchOpts: {
          pre: "",
          post: "",
          skip: true, // true will skip local search, useful if doing server-side search
        },
        noMatchTemplate: function() {
          return '<div class="mention-no-results">No results found.</div>';
        },
        menuItemTemplate: function(item) {
          let image = item.original.image ? item.original.image : require("@/assets/images/grey.png");
          return `<img class="mention-avatar" src="${image}"><span style='font-family: Robot, Arial;'">${item.string}</span>`;
        },
      },
    };
  },
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    includeMentions: {
      type: Boolean,
      default: false,
    },
    mentionValuesLookup: {
      type: Function,
      default: null,
    },
  },
  mounted() {
    if (this.includeMentions) {
      if (this.mentionValuesLookup) this.mentionOptions.values = this.mentionValuesLookup;

      this.tribute = new Tribute(this.mentionOptions);
      this.tribute.attach(this.$refs.vtextarea.$el.getElementsByTagName("textarea")[0]);
    }
  },
  beforeDestroy() {
    if (this.tribute) this.tribute.detach(this.$refs.vtextarea.$el.getElementsByTagName("textarea")[0]);
  },
  methods: {
    focus() {
      this.$refs.vtextarea.focus();
    },
    select(start = 0, end = null) {
      this.$refs.vtextarea.$refs.input.selectionStart = start;
      if (end) {
        this.$refs.vtextarea.$refs.input.selectionEnd = end;
      }
      this.focus();
    },
  },
};
</script>

<style>
.mention--highlighted {
  background-color: #e0e0e0;
}
.displayTop {
  z-index: 999 !important;
  max-height: 210px;
  overflow: auto;
  width: auto;
  min-width: 225px;
  margin-top: 8px;
  padding:5px;
  background-color: #eeeeee !important;
}
.displayTop::-webkit-scrollbar {
  width: 15px;
}

.displayTop::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.displayTop::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.displayTop::-webkit-scrollbar-thumb:hover {
  background: black;
}
.displayTop > ul > .v-list-item {
  font-size: 13px !important;
}
.mention-avatar {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 30px;
}
.mention-no-results {
  font-size: 12px;
  font-weight: bold;
  font-family: Robot, Arial;
}
</style>
