<template>
  <div>
    <j-workout-card
      v-if="unitActivity.type === 'WORKOUT' && unitActivity.activity"
      :color="blockColor"
      :imageSrc="imageSrc"
      :chip-text="chipText"
      :instructor-profile-pic="instructorProfilePic"
      :title="'Day ' + unitActivity.currentActivity + ': ' + unitActivity.activity.title"
      :instructor-name="instructor.firstName + ' ' + instructor.lastName"
      :duration="unitActivity.activity.durationMinutes ? `${unitActivity.activity.durationMinutes} mins` : ''"
      :show-arrows="showArrows"
      :progress="progress"
      :left-arrow="unitActivity.currentActivity > 1"
      :right-arrow="unitActivity.currentActivity < totalActivities"
      :overlay-type="overlayType"
      @navigate="navigate"
      @arrow-clicked="updateBlock($event)"
      :button-text="`Start ${$vuetify.breakpoint.mdAndUp ? 'Workout' : ''}`"
    />

    <j-workout-card
      v-else-if="(unitActivity.type === 'MODULE' || unitActivity.type === 'ED') && unitActivity.activity.embedUrl"
      :color="blockColor"
      :imageSrc="imageSrc"
      :instructor-profile-pic="instructorProfilePic"
      :title="'Module ' + unitActivity.currentActivity + ': ' + unitActivity.activity.title"
      :instructor-name="'with ' + instructor.firstName"
      :duration="unitActivity.activity.durationMinutes ? `${unitActivity.activity.durationMinutes} mins` : ''"
      :show-arrows="showArrows"
      :progress="progress"
      :left-arrow="unitActivity.currentActivity > 1"
      :right-arrow="unitActivity.currentActivity < totalActivities"
      :overlay-type="overlayType"
      @navigate="navigate"
      @arrow-clicked="updateBlock($event)"
      :button-text="`Start ${$vuetify.breakpoint.mdAndUp ? 'Module' : ''}`"
    />

    <!-- no embed url, put content inline. Let's target Ed and Module only for now. Can always expand later.  -->
    <j-card
      v-else-if="(unitActivity.type === 'MODULE' || unitActivity.type === 'ED') && !unitActivity.activity.embedUrl"
      class="j-elevated-2 py-3 background-grey"
    >
      <v-responsive :aspect-ratio="16 / 9" content-class="d-flex flex-column flex-fill justify-center">
        <h3
          class="text-center grey-navy--text mb-sm-3 mb-lg-6"
          :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4'"
        >
          Today's {{ unitActivity.type | capitalize }}
        </h3>
        <div
          v-if="activityDescription"
          class="text-center text-body-1 grey-navy--text px-10 px-lg-16 mt-4"
          v-html="activityDescription"
        />
        <div class="mb-6 mt-2 d-flex justify-center">
          <j-btn secondary @click="navigate">View {{ unitActivity.type | capitalize }}</j-btn>
        </div>

        <v-overlay v-if="completed" absolute opacity="0" class="j-workout-overlay mb-5 pointer" @click="navigate">
          <v-img :src="require('@/assets/images/tickred.png')" class="overlay-image mx-auto" :aspect-ratio="1 / 1" />
        </v-overlay>
      </v-responsive>

      <arrow-row
        v-if="showArrows"
        :left-arrow="unitActivity.currentActivity > 1"
        :right-arrow="unitActivity.currentActivity < totalActivities"
        @arrow-clicked="updateBlock($event)"
        class="mt-n6"
        :color="blockColor"
      />
      <arrow-row-content
        class="mt-3"
        :instructor-profile-pic="subscriptionBlock.block.leadInstructor.userProfile.profilePicture"
        :title="'Day ' + unitActivity.currentActivity + ': ' + unitActivity.activity.title"
        :instructor-name="`${subscriptionBlock.block.leadInstructor.firstName} ${subscriptionBlock.block.leadInstructor.lastName}`"
      />
    </j-card>

    <j-card v-else-if="unitActivity.type === 'LIVE_EVENT'" class="j-elevated-2 py-3">
      <v-responsive :aspect-ratio="16 / 9" content-class="d-flex flex-column flex-fill justify-center">
        <h3 class="text-center text-h4 grey-navy--text mb-sm-3 mb-lg-6">Freestyle Day</h3>
        <div
          class="text-center text-body-1 grey-navy--text px-10 px-lg-16"
          :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }"
        >
          Move in a way that suits you. Whether its a home workout, gym session, walk or run. We don’t mind as long as
          you move!
        </div>

        <div class="d-flex justify-center mt-6">
          <j-btn small secondary @click="logDot('CUSTOM')" class="mr-4">Log A Workout</j-btn>
          <rest-day-card :unit-activity="unitActivity" :subscription-block="subscriptionBlock" small
            >Log rest</rest-day-card
          >
        </div>
        <div class="mt-6 text-center" :class="{ 'mb-8': $vuetify.breakpoint.smAndDown }">
          <router-link to="/live" class="text-decoration-none font-weight-bold"
            ><v-icon class="mr-1">mdi-magnify</v-icon>FIND FRESH WORKOUTS</router-link
          >
        </div>

        <v-overlay v-if="completed" absolute opacity="0" class="j-workout-overlay mb-5">
          <v-img :src="require('@/assets/images/tickred.png')" class="overlay-image mx-auto" :aspect-ratio="1 / 1" />
        </v-overlay>
      </v-responsive>

      <arrow-row v-if="showArrows" @arrow-clicked="updateBlock($event)" class="mt-n6" />
      <arrow-row-content
        class="mt-3"
        :instructor-profile-pic="subscriptionBlock.block.leadInstructor.userProfile.profilePicture"
        :title="'Day ' + unitActivity.currentActivity + ': Freestyle Day'"
        :instructor-name="`${subscriptionBlock.block.leadInstructor.firstName} ${subscriptionBlock.block.leadInstructor.lastName}`"
      />
    </j-card>

    <j-card
      v-else-if="unitActivity.type === 'REST_DAY'"
      class="j-elevated-2 py-3"
      :class="{ 'background-grey': unitActivity.type === 'REST_DAY' }"
    >
      <v-responsive :aspect-ratio="16 / 9" content-class="d-flex flex-column flex-fill justify-center">
        <h3 class="text-center text-h4 grey-navy--text mb-sm-3 mb-lg-6">Rest day</h3>
        <div class="text-center text-body-1 grey-navy--text px-10 px-lg-16">
          Log your rest or you can stretch and walk if you want to keep active
        </div>

        <rest-day-card
          :unit-activity="unitActivity"
          :subscription-block="subscriptionBlock"
          large
          class="text-center mt-3 mt-md-5 mt-lg-7"
          >Log rest</rest-day-card
        >

        <div class="pointer mt-4 text-center p-text" @click="logDot('CUSTOM')">
          or <span class="text-decoration-underline navy--text font-weight-bold">Log a Workout</span>
        </div>

        <v-overlay v-if="completed" absolute opacity="0" class="j-workout-overlay mb-5">
          <v-img :src="require('@/assets/images/tickred.png')" class="overlay-image mx-auto" :aspect-ratio="1 / 1" />
        </v-overlay>
      </v-responsive>

      <arrow-row v-if="showArrows" @arrow-clicked="updateBlock($event)" class="mt-n6" />
      <arrow-row-content
        class="mt-3"
        :instructor-profile-pic="subscriptionBlock.block.leadInstructor.userProfile.profilePicture"
        :title="'Day ' + unitActivity.currentActivity + ': Rest Day'"
        :instructor-name="`${subscriptionBlock.block.leadInstructor.firstName} ${subscriptionBlock.block.leadInstructor.lastName}`"
      />
    </j-card>

    <!-- Enhance Dot/Workout Log dialog -->
    <v-dialog v-model="logDotDialog" width="600" transition="dialog-bottom-transition" class="limited-height">
      <enhance-workout-log
        :activity-id="workoutLogId"
        :subscription-block-id="subscriptionBlock.id"
        :unit-activity="unitActivity"
        @closed="logDotDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { FRESH_CLASSES } from "@/graphql/queries/home";
import { decodeId, truncate } from "@/lib/string";
import { mapActions } from "vuex";
import JWorkoutCard from "@/layouts/cards/JWorkoutCard";
import ArrowRow from "@/components/workoutblocks/ArrowRow";
import RestDayCard from "@/components/tracker/RestDayCard";
import { UPSERT_ACTIVITY_MUTATION } from "@/graphql/mutations/tracker";
import EnhanceWorkoutLog from "@/components/workoutblocks/dialogs/EnhanceWorkoutLog";
import ArrowRowContent from "@/components/workoutblocks/ArrowRowContent";
import { capitalize } from "@/lib/string";

export default {
  name: "TodayActivityBlock",
  components: {
    JWorkoutCard,
    ArrowRow,
    RestDayCard,
    EnhanceWorkoutLog,
    ArrowRowContent,
  },
  data() {
    return {
      logDotDialog: false,
      workoutLogId: null,
    };
  },
  props: {
    unitActivity: {
      type: Object,
      required: true,
    },
    subscriptionBlock: {
      type: Object,
      required: true,
    },
    anchorDate: {
      type: String,
      required: true,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
    skipFreshCount: {
      type: Number,
      default: 0,
    },
  },
  filters: {
    capitalize(val) {
      return capitalize(val);
    },
  },
  computed: {
    activityDescription() {
      let text = this.unitActivity?.activity?.description;
      if (this.unitActivity?.activity?.shortDescription) {
        text = this.unitActivity.activity.shortDescription;
      }
      return truncate(text, 200);
    },
    instructor() {
      return this.unitActivity?.activity?.leadInstructor ?? this.freshClass?.instructor;
    },
    instructorProfilePic() {
      return this.instructor?.userProfile?.profilePicture;
    },
    imageSrc() {
      switch (this.unitActivity.type) {
        case "WORKOUT":
        case "ED":
        case "MODULE":
          return this.workoutImageSrc;
        case "LIVE_EVENT":
          return this.liveImageSrc;
      }

      return "";
    },
    workoutImageSrc() {
      if (this.unitActivity.activity?.image) {
        return this.unitActivity.activity?.image;
      } else if (this.unitActivity.activity?.embedUrl && this.unitActivity.activity?.embedUrl.includes("youtube")) {
        const youtubeId = this.unitActivity.activity?.embedUrl.split("watch?v=")[1];
        const overlayImage = this.completed ? "l_tickred_ct2whg" : "l_playnavy_mtlav7";
        return `https://res.cloudinary.com/jeff-fitness/image/youtube/${overlayImage}/w_250/fl_layer_apply/v1/${youtubeId}.jpg`;
      }

      return "";
    },
    liveImageSrc() {
      const overlayImage = this.completed ? "l_tickred_ct2whg" : "l_playnavy_mtlav7";

      if (this.freshClass) {
        if (this.freshClass.embedUrl && this.freshClass.embedUrl.includes("youtube")) {
          const youtubeId = this.freshClass.embedUrl.split("watch?v=")[1];
          return `https://res.cloudinary.com/jeff-fitness/image/youtube/${overlayImage}/w_250/fl_layer_apply/v1/${youtubeId}.jpg`;
        } else if (this.freshClass.youtubeId) {
          return `https://res.cloudinary.com/jeff-fitness/image/youtube/${overlayImage}/w_250/fl_layer_apply/v1/${this.freshClass.youtubeId}.jpg`;
        } else if (this.freshClass.thumbnail) {
          return this.freshClass.thumbnail;
        }
      }

      return "";
    },
    manualOverlay() {
      return !this.imageSrc?.includes("res.cloudinary.com/jeff-fitness");
    },
    workoutLink() {
      return { name: "Activity", params: { unitActivityId: decodeId(this.unitActivity.id) } };
    },
    isAudio() {
      return !!this.unitActivity?.activity?.activityattachmentSet?.find((el) => el.type === "AUDIO");
    },
    chipText() {
      if (this.isAudio) {
        return "Audio";
      }
      return null;
    },
    totalActivities() {
      return (
        (this.subscriptionBlock?.block?.totalWorkouts ?? 0) +
        (this.subscriptionBlock?.block?.totalModules ?? 0) +
        (this.subscriptionBlock?.block?.totalEds ?? 0) +
        (this.subscriptionBlock?.block?.totalLiveEventsRestDays ?? 0)
      );
    },
    progress() {
      return this.subscriptionBlock.isSelfPaced
        ? (this.unitActivity.currentActivity / this.totalActivities) * 100
        : (this.unitActivity.currentDay / this.subscriptionBlock.block?.duration) * 100;
    },
    blockColor() {
      if (this.subscriptionBlock.block?.type === "COURSE") {
        return "yellow";
      }
      if (this.subscriptionBlock.block?.type === "NUTRITION") {
        return "nutrition";
      }
      return "exercise";
    },
    overlayType() {
      if (!this.manualOverlay) {
        return null;
      }
      if (this.completed) return "DONE";
      if (this.unitActivity?.activity?.embedUrl) return "PLAY";
      if (this.unitActivity?.activity?.activityattachmentSet?.some((element) => element.type === "AUDIO"))
        return "LISTEN";
      if (this.unitActivity?.activity?.activityattachmentSet?.some((element) => element.type === "PDF")) return "READ";
      return "READ";
    },
  },
  methods: {
    ...mapActions(["setSelectedDate"]),
    async logDot(type = "LIVE_EVENT") {
      let input = {
        subscriptionBlockId: decodeId(this.subscriptionBlock.id),
        unitActivityId: decodeId(this.unitActivity.id),
        startDate: this.$moment().format("YYYY-MM-DD"),
      };
      if (type === "CUSTOM") {
        input["isCustom"] = true;
        input["activityTypeName"] = "Workout";
      }
      let result = await this.$apollo.mutate({
        mutation: UPSERT_ACTIVITY_MUTATION,
        variables: {
          data: input,
        },
        refetchQueries: ["assistantQuery", "accountQuery", "workoutBlockWorkoutLogs"],
      });

      const workoutLogId = result.data.upsertUserActivity.upsertedUserActivity.userActivity.id;
      if (workoutLogId) {
        this.workoutLogId = decodeId(workoutLogId);
        this.logDotDialog = true;
      }
    },
    updateBlock(direction) {
      let anchorDate = this.$store.getters.selectedDate;

      if (this.subscriptionBlock.isSelfPaced) {
        this.$emit("change-activity", direction);
      } else {
        anchorDate = this.$moment(anchorDate).add(direction, "days");

        this.setSelectedDate(anchorDate);
      }
    },
    navigate() {
      switch (this.unitActivity.type) {
        case "WORKOUT":
        case "ED":
        case "MODULE":
          this.$router.push(
            `/unit-activity/${this.unitActivity.id}?&sbid=${decodeId(this.subscriptionBlock.id)}&d=${this.anchorDate}`
          );
          break;
        case "LIVE_EVENT":
          if (this.freshClass) {
            this.$router.push(`/event/${decodeId(this.freshClass.id)}`);
          }
          break;
        case "REST_DAY":
          this.$emit("openRestDay");
          break;
      }
    },
  },
  apollo: {
    freshClass: {
      query: FRESH_CLASSES,
      variables() {
        return {
          first: 1,
          skip: this.skipFreshCount,
        };
      },
      update(data) {
        return data.paginatedEvents?.edges && data.paginatedEvents.edges[0].node;
      },
      skip() {
        let skip = this.unitActivity?.type != "LIVE_EVENT";
        this.$emit("fresh-block", !skip);
        return skip;
      },
    },
  },
};
</script>
