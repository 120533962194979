<template>
  <j-main-banner-container md="5">
    <div class="px-4 pb-8 pt-8">
      <h2 class="navy--text">This feature requires our native app</h2>
      <p class="std-text navy--text mt-4">
        This functionality is only available on our native app. Download it using one of the links below.
      </p>

      <v-row>
        <v-col cols="12" md="4"
          ><a href="https://play.google.com/store/apps/details?id=fitness.jeff.jeff" target="_blank"
            ><v-img :src="require('@/assets/images/download_apple.png')" /></a
        ></v-col>
        <v-col cols="12" md="4"
          ><a href="https://play.google.com/store/apps/details?id=fitness.jeff.jeff" target="_blank"
            ><v-img :src="require('@/assets/images/download_play.png')" /></a
        ></v-col>
        <v-col cols="12" md="4"
          ><a href="https://appgallery.huawei.com/#/app/C108915741" target="_blank"
            ><v-img :src="require('@/assets/images/download_huawei.png')" /></a
        ></v-col>
      </v-row>
    </div>
  </j-main-banner-container>
</template>

<script>
export default {
  name: "RequiresApp",
};
</script>
