<template>
  <j-banner-container
    header="Info &amp; Preferences"
    md="5"
    class="my-2"
  >
    <v-row>
      <j-card class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
        <v-list-item-group class="px-6">
          <v-list-item class="px-0" to="/checkin/assessments">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text"> mdi-clipboard-check-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">Check-ins</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <template v-if="coaching">
            <v-divider />
            <v-list-item class="px-0" to="/checkin/coaching/goals">
              <v-list-item-icon class="mr-2">
                <v-icon class="navy--text">mdi-bullseye-arrow</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="navy--text text-uppercase font-weight-bold">1-on-1 goal</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon class="navy--text">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-divider />
          <v-list-item class="px-0" to="/social/your-posts">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-post-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">
                Your group posts
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/manage-groups">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-account-group-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">Your Groups</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </j-card>
      <j-card class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
        <v-list-item-group class="px-6">
          <v-list-item class="px-0" to="/account/preferences">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-image-auto-adjust</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">Your preferences</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </j-card>
      <j-card class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
        <v-list-item-group class="px-6">
          <v-list-item class="px-0" to="/account/general">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-card-account-details-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">
                Personal &amp; account details
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/account/billing">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-credit-card-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">Billing details</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/account/discovery">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-account-arrow-right-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">
                Discovery Vitality
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </j-card>
      <j-card class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
        <v-list-item-group class="px-6">
          <v-list-item class="px-0" to="/today/to-do">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-check-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">
                Tasks for you to do
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/today/inbox">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-email-newsletter</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">Inbox Messages</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/rewards/points">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">
                Earn Rewards Points
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" to="/rewards/points">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-account-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold"
                >Invite your friends</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </j-card>
      <j-card class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
        <v-list-item-group class="px-6">
          <v-list-item class="px-0" target="_blank" href="https://www.jeff.fitness/">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">mdi-cursor-default-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold"
                >{{ $appName }} Website</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider />
          <v-list-item class="px-0" @click="logout()">
            <v-list-item-icon class="mr-2">
              <v-icon class="navy--text">exit_to_app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navy--text text-uppercase font-weight-bold">Logout</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon class="navy--text">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </j-card>
    </v-row>
  </j-banner-container>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "Account",
  mixins: [UserMixin],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    coaching() {
      return this.hasCoaching;
    },
  },
  methods: {
    logout() {
      sessionStorage.removeItem("contentRedirectPath");
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
