<template>
  <div class="mx-2">
    <template v-if="userConnections">
      <j-subsection-row
        title="Friends"
        :see-all="{
          text: `${allUserConnections.length} Friend${allUserConnections.length != 1 ? 's' : ''}`,
          link: null,
          action: openfriendsDialog,
          args: [],
        }"
      >
        <v-card class="mx-auto" align="left" style="box-shadow: none">
          <v-list three-line>
            <template v-for="(item, index) in userConnections">
              <v-list-item :key="item.node.title">
                <v-list-item-avatar
                  size="70"
                  class="pointer"
                  @click="navigate(`/p/${getUserId(getFriend(item.node))}`)"
                >
                  <v-img
                    v-if="getFriend(item.node).userProfile.profilePicture"
                    :src="getFriend(item.node).userProfile.profilePicture"
                  ></v-img>

                  <v-avatar v-else size="130" color="navy" class="rounded-xl j-elevated-1">
                    <span class="white--text heading-4">
                      {{ getFriend(item.node).firstName ? getFriend(item.node).firstName.charAt(0).toUpperCase() : ""
                      }}{{
                        getFriend(item.node).lastName ? getFriend(item.node).lastName.charAt(0).toUpperCase() : ""
                      }}</span
                    >
                  </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="d-inline-grid pointer"
                    @click="navigate(`/p/${getUserId(getFriend(item.node))}`)"
                  >
                    <span class="heading-4 font-weight-bold navy--text"
                      >{{ getFriend(item.node).firstName }} {{ getFriend(item.node).lastName }}</span
                    >
                    <span class="sm-text"
                      >{{
                        item.node.requester.userProfile.friendship
                          ? item.node.requester.userProfile.friendship.mutualFriendsCount
                          : 0
                      }}
                      mutual friends</span
                    >
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon class="align-center">
                  <v-progress-circular
                    v-if="chatLoading"
                    indeterminate
                    :width="3"
                    :size="17"
                    color="navy"
                  ></v-progress-circular>
                  <v-icon v-else class="pointer navy--text" :size="24" @click="goToChat(getFriend(item.node).id)"
                    >mdi-chat</v-icon
                  >
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="navy--text pointer" size="24" v-bind="attrs" @click.prevent="on.click"
                        >mdi-dots-horizontal-circle</v-icon
                      >
                    </template>
                    <v-list>
                      <v-list-item class="p-text pointer" @click="confirmRemoveFriend(item.node)">
                        <v-icon class="pr-2 mt-n1">mdi-trash-can</v-icon> Remove Friend
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-icon>
              </v-list-item>

              <v-divider :key="index" inset />
            </template>
          </v-list>
        </v-card>

        <v-row>
          <v-col cols="12" align="center">
            <j-btn :rounded="false" narrow to="/friend-requests" small class="mx-2 my-1 text-left">
              <span
                >{{ pendingUserConnections.length }} Friend request{{ pendingUserConnections.length != 1 ? "s" : "" }}
              </span>
            </j-btn>
            <j-btn :rounded="false" tertiary small narrow @click="modal.searchDialog = true" class="mx-2 my-1">
              <span>FIND MORE FRIENDS</span>
            </j-btn>
          </v-col>
        </v-row>
      </j-subsection-row>
    </template>
    <v-dialog v-model="modal.openfriendsDialog" max-width="600" transition="dialog-bottom-transition">
      <j-card class="pa-2 card-radius">
        <v-toolbar flat>
          <v-toolbar-title class="secondary--text pl-1">
            <span class="heading-2 ml-n1">Friends</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="modal.openfriendsDialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="overflow-auto mh">
          <friends-listing :userId="userId" status="APPROVED" />
        </v-card-text>
      </j-card>
    </v-dialog>
    <v-dialog v-model="modal.searchDialog" max-width="600" transition="dialog-bottom-transition">
      <j-card class="pa-2 card-radius">
        <v-toolbar flat>
          <v-toolbar-title class="secondary--text pl-1">
            <span class="heading-2 ml-n1">Search for friend</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="modal.searchDialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="overflow-auto" style="">
          <user-search />
        </v-card-text>
      </j-card>
    </v-dialog>
    <v-dialog v-model="modal.deleteDialog" width="500">
      <j-card rounded="lg">
        <v-card-title class="std-text"> Are you sure you want to remove this friend? </v-card-title>
        <v-card-text></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="secondary" @click="modal.deleteDialog = false"> Cancel </v-btn>
          <v-spacer />
          <v-btn color="background-grey" class="secondary--text pa-2" @click="removeFriend"> Confirm </v-btn>
        </v-card-actions>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import { USER_CONNECTIONS_QUERY } from "@/graphql/queries/friends";
import { DELETE_FRIEND_REQUEST } from "@/graphql/mutations/friends";
import { decodeId } from "@/lib/string";
import FriendsListing from "@/components/friends/FriendsListing";
import UserSearch from "@/components/account/UserSearch";
import FriendMixin from "@/mixins/FriendMixin";

export default {
  name: "FriendsBlock",
  mixins: [FriendMixin],
  components: {
    FriendsListing,
    UserSearch,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userConnections: null,
      allUserConnections: [],
      loading: true,
      pendingUserConnections: [],
      modal: {
        openfriendsDialog: false,
        searchDialog: false,
        deleteDialog: false,
      },
      friendId: null,
    };
  },
  computed: {
    leftButton() {
      return this.group?.isMember ? "Leave" : "Join";
    },
    leftButtonIcon() {
      return this.group?.isMember ? "mdi-logout" : "mdi-account-multiple-plus";
    },
  },
  methods: {
    confirmRemoveFriend(friend) {
      this.friendId = null;
      this.modal.deleteDialog = true;
      this.friendId = friend.id;
    },
    async removeFriend() {
      if (this.friendId) {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: DELETE_FRIEND_REQUEST,
          variables: {
            id: decodeId(this.friendId),
          },
        });
        this.loading = false;
      }

      this.$toasted.success("Success!", {
        icon: "info",
        duration: 2000,
        position: "bottom-center",
      });

      this.modal.deleteDialog = false;
      this.$apollo.queries.userConnections.refetch();
      this.$apollo.queries.allUserConnections.refetch();
    },
    navigate(val) {
      this.$router.push(val);
    },
    getFriend(userConnection) {
      let userId = decodeId(this.userId);
      if (userConnection.user.id != userId) {
        return userConnection.user;
      }
      return userConnection.requester;
    },
    openfriendsDialog() {
      this.modal.openfriendsDialog = true;
    },
    getUserId(user) {
      return window.btoa(`UserNode: ${user.id}`);
    },
  },
  apollo: {
    userConnections: {
      query: USER_CONNECTIONS_QUERY,
      variables() {
        let userId = decodeId(this.userId);

        return { status: "APPROVED", forUserId: userId, first: 4 };
      },
      // network-only, otherwise doesn't update the listing card nicely on update
      fetchPolicy: "network-only",
      update: (data) => data.userConnections.edges,
    },
    allUserConnections: {
      query: USER_CONNECTIONS_QUERY,
      variables() {
        let userId = decodeId(this.userId);

        return { status: "APPROVED", forUserId: userId };
      },
      // network-only, otherwise doesn't update the listing card nicely on update
      fetchPolicy: "network-only",
      update: (data) => data.userConnections.edges,
    },
    pendingUserConnections: {
      query: USER_CONNECTIONS_QUERY,
      variables() {
        let userId = this.userId;

        if (parseInt(userId)) {
          userId = btoa(`UserNode: ${this.userId}`);
        }
        return { status: "PENDING", userId: userId };
      },
      // network-only, otherwise doesn't update the listing card nicely on update
      fetchPolicy: "network-only",
      update: (data) => data.userConnections.edges,
    },
  },
};
</script>

<style scoped>
.mh {
  max-height: 500px;
}

.d-inline-grid {
  display: inline-grid;
}
</style>
