<template>
  <v-container>
    <div v-if="$apollo.loading && !nutritionBlock">
      <v-row justify="center">
        <v-col cols="12" md="6"><v-skeleton-loader type="card-avatar" /> </v-col>
      </v-row>
    </div>
    <j-card-banner-container
      v-if="nutritionBlock"
      :header="isCurrent ? 'My Menu' : 'Nutrition Menu'"
      :image="isCurrent ? null : nutritionBlock.image"
      :icon="nutritionBlock.leadInstructor.userProfile.profilePicture"
      :title="nutritionBlock.title"
      :subtitle="'with ' + nutritionBlock.leadInstructor.firstName"
      :textGradient="['yellow', 'green']"
      show-share-link
      use-header-title-in-link
    >
      <template v-slot:card-content>
        <block-header
          :nutrition-block="nutritionBlock"
          :nutrition-subscription-block="currentNutritionSubscriptionBlock"
          :is-current="isCurrent"
          :is-mine="isMine"
          :loading="loading"
          @manage="openManageBlockDialog = true"
          @doProgram="doProgram"
          @restartProgram="restartProgram"
        />
      </template>

      <div v-if="isMine">
        <div class="pa-md-4 pb-8 pr-0">
          <j-btn narrow :to="'/nutrition/' + blockId + '/overview'" small class="mx-2 my-2">
            <span>Week's Menu </span>
            <v-icon small class="ml-2">mdi-calendar-month</v-icon>
          </j-btn>
          <j-btn tertiary narrow :to="'/nutrition/shopping-list/' + blockId" small class="mx-2 my-2">
            <span>Shopping List</span>
            <v-icon small class="ml-2">mdi-cart-outline</v-icon>
          </j-btn>
          <block-calendar-dots
            class="ml-2"
            :activityLogs="nutritionBlockLogs"
            accentColor="green"
            @dateUpdated="calendarUpdated($event)"
          />
          <recipe-day-listing
            v-if="recipesForWeek && recipesForWeek.length > 0"
            :blockId="blockId"
            :date="formattedAnchorDate"
            :breakfast="breakfast(formattedAnchorDate)"
            :lunch="lunch(formattedAnchorDate)"
            :dinner="dinner(formattedAnchorDate)"
            :primarySnack="primarySnack(formattedAnchorDate)"
            :md-cols="calculateMdCols(formattedAnchorDate)"
          />
          <j-alert v-if="!$apollo.loading && (!recipesForWeek || (recipesForWeek && recipesForWeek.length == 0))">
            No recipes for this week
          </j-alert>
        </div>

        <div v-if="primaryGroupId && isCurrent" class="pa-md-4 pt-0">
          <post-container
            ref="postContainer"
            :groupId="primaryGroupId"
            :featured-user-ids="[nutritionBlock.leadInstructor.id]"
            highlight-colour="#3ADEB4"
          />
        </div>
      </div>
    </j-card-banner-container>

    <!-- Confirm program dialog -->
    <v-dialog
      v-if="nutritionBlock && !isCurrent"
      v-model="confirmProgramDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <confirm-nutrition-program
        :nutrition-block="nutritionBlock"
        :loading="loading"
        @closed="confirmProgramDialog = false"
        @saved="confirmProgram"
      />
    </v-dialog>

    <!-- manage block dialog -->
    <v-dialog
      v-if="currentNutritionSubscriptionBlock"
      v-model="openManageBlockDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <manage-block-dialog
        @leave="leaveBlock"
        @saved="updateNutritionSubscriptionBlock"
        :loading="loading"
        :nutrition-subscription-block="currentNutritionSubscriptionBlock"
        @closed="openManageBlockDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { SUBSCRIPTION_NUTRITION_BLOCK_MUTATION } from "@/graphql/mutations/nutritionblock";
import { decodeId } from "@/lib/string";
import BlockHeader from "@/components/nutritionblocks/BlockHeader";
import ConfirmNutritionProgram from "@/components/nutritionblocks/dialogs/ConfirmNutritionProgram";
import RecipeListMixin from "@/mixins/nutrition/RecipeListMixin";
import NutritionBlockMixin from "@/mixins/nutrition/NutritionBlockMixin";
import PostContainer from "@/components/groups/posts/PostContainer";
import ManageBlockDialog from "@/components/nutritionblocks/dialogs/ManageBlockDialog";
import RecipeDayListing from "@/components/nutritionblocks/RecipeDayListing";

export default {
  name: "NutritionBlock",
  mixins: [RecipeListMixin, NutritionBlockMixin],
  components: {
    BlockHeader,
    ConfirmNutritionProgram,
    PostContainer,
    ManageBlockDialog,
    RecipeDayListing,
  },
  props: {
    blockId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      confirmProgramDialog: false,
      chooseGroupDialog: false,
      createGroupDialog: false,
      findGroupDialog: false,
      startDate: null,
      openShareDialog: false,
      openManageBlockDialog: false,
    };
  },
  computed: {
    primaryGroupId() {
      if (!this.activeNutritionBlock) {
        return false;
      }
      if (this.activeNutritionBlock.groupSet?.edges.length > 0) {
        // should only be one.
        return decodeId(this.activeNutritionBlock.groupSet.edges[0].node.id);
      }
      return null;
    },
    shareObject() {
      return {
        url: window.location.href,
        title: this.nutritionBlock.title,
        description: this.nutritionBlock.description,
        quote: `Check out this nutrition program on ${this.$appName}`,
        hashtags: "jeffclub",
        media: this.nutritionBlock.image,
      };
    },
  },
  created() {
    // we are not handling auth in router/index.js so that we can redirect to jeff club checkout and not login
    if (!this.$store.getters.user) {
      return this.$router.push("/checkout/jeff-club");
    }
  },
  methods: {
    calculateMdCols(date) {
      const count = [];
      count.push(this.breakfast(date));
      count.push(this.lunch(date));
      count.push(this.dinner(date));
      count.push(this.primarySnack(date));

      const filtered = count.filter((element) => element != null);
      if (filtered.length == 3) {
        return 4;
      }
      return 3;
    },
    doProgram() {
      const role = this.$store.getters.role;
      if ((role === "ONE_ON_ONE" || role === "CLUB") && !this.isCurrent) {
        return (this.confirmProgramDialog = true);
      }

      // user needs to buy club.
      // set the block Id for the purposes of redirecting.
      sessionStorage.setItem("nutritionBlockId", decodeId(this.blockId));
      return this.$router.push(`/checkout/jeff-club`);
    },
    async confirmProgram(data) {
      this.loading = true;
      // first leave any existing nutrition blocks.
      if (this.currentNutritionSubscriptionBlock > 0) {
        await this.leaveNutritionBlocks(this.currentNutritionSubscriptionBlock);
      }

      // now join this new nutrition block
      this.startDate = data.startDate;

      const input = {
        startDate: this.startDate,
        nutritionBlockId: decodeId(this.blockId),
      };

      await this.$apollo.mutate({
        mutation: SUBSCRIPTION_NUTRITION_BLOCK_MUTATION,
        variables: {
          data: input,
        },
        refetchQueries: ["accountQuery", "nutritionSubscriptionBlocks"],
      });
      this.confirmProgramDialog = false;
      this.loading = false;
    },
    async restartProgram() {
      this.loading = true;
      const data = {
        nutritionSubscriptionBlock: { id: this.currentNutritionSubscriptionBlock.id },
        startDate: this.$moment()
          .startOf("isoweek")
          .format("YYYY-MM-DD"),
      };
      await this.updateNutritionSubscriptionBlock(data);
      this.loading = false;
    },
    async leaveBlock() {
      this.loading = true;
      await this.leaveNutritionBlock();
      this.openManageBlockDialog = false;
      this.loading = false;
    },
    reloadGroup() {
      const postContainer = this.$refs.postContainer;
      postContainer.reloadThread();
    },
  },
};
</script>
