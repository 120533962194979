<template>
  <v-row class="j-subsection-row pb-2 overflow-hidden">
    <v-col v-if="(title || subtitle || showSeeAll)" cols="12" :class="[{ 'pb-2': compact && !subtitle }, { 'pb-0 mb-0': subtitle }]">
      <div class="d-inline-flex full-width">
        <h3 class="heading-3 text-left" :class="color + '--text'">{{ title }}</h3>
        <v-spacer />
        <v-btn v-if="showSeeAll" small text @click="seeAllButton" class="grey-navy--text">
          <strong>{{ seeAll.text }}</strong>
        </v-btn>
        <slot name="custom-see-all"></slot>
      </div>
    </v-col>
    <v-col v-if="subtitle" cols="12" class="mt-0 pt-0" :class="{ 'pb-2 mt-n1': compact }">
      <div class="d-inline-flex full-width">
        <div class="grey-navy--text heading-7">{{ subtitle }}</div>
      </div>
    </v-col>
    <v-col cols="12" class="py-0">
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "JSubsectionRow",
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "navy"
    },
    subtitle: {
      type: String,
      required: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    showSeeAll: {
      type: Boolean,
      default: true,
    },
    seeAll: {
      type: Object,
      default: () => {
        return {
          text: "See all",
          link: "/",
          action: null,
          args: [],
        };
      },
    },
  },
  methods: {
    seeAllButton() {
      if (this.seeAll.link) {
        if (this.seeAll.link.startsWith("http")) {
          return window.open(this.seeAll.link, "_blank");
        }
        return this.$router.push(this.seeAll.link);
      } else if (this.seeAll.action) {
        this.seeAll.action(...this.seeAll.args);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}
</style>
