import gql from "graphql-tag";

export const USER_METRIC_QUERY = gql`
  query metric($metricDate: Date!) {
    metric(metricDate: $metricDate) {
      id
      metricNumber
      metricLabel
      sevenDayAverageNumber
      sevenDayAverageLabel
    }
  }
`;

export const USER_DOTS_QUERY = gql`
  query userDotsQuery($startDate: DateTime!, $endDate: DateTime) {
    userDots(startDate: $startDate, endDate: $endDate) {
      exercise
      mindset
      nutrition
    }
  }
`;

export const USER_QUERY = gql`
  query profileQuery($userId: ID!) {
    user(id: $userId) {
      id
      username
      firstName
      lastName
      email
      dateJoined
      isStaff
      useractivitySet(hasImage: true, orderBy: "-created_at") {
        edges {
          node {
            id
            image
            startDate
          }
        }
      }
      userProfile {
        id
        phone
        biography
        user {
          id
        }
        userType
        nextPaymentDate
        dateOfBirth
        idNumber
        passportNumber
        isDiscoveryMember
        claimDiscount
        profilePicture
        gender
        dietaryRequirement
        suburb
        friendsCount
        earnBadgeCount
        coverImage
        friendship {
          id
          status
          mutualFriendsCount
        }
        activeSubscription {
          id
          product {
            id
            type
          }
        }
        country {
          name
          iocCode
          code
        }
        extrovertScale
        desiredWeight
      }
      userSettings {
        id
        value
        key
      }
    }
    workoutBlockWorkoutLogs(subscriptionBlock_UserId: $userId, hasImage: true, orderBy: "-startDate") {
      edges {
        node {
          id
          image
          startDate
        }
      }
    }
  }
`;

export const USER_NAV_QUERY = gql`
  query profileQuery($userId: ID!) {
    user(id: $userId) {
      id
      userProfile {
        id
        friendsCount
        earnBadgeCount
        dotsCount
        checkInCount
        activeSubscription {
          id
          product {
            id
            name
            type
          }
        }
      }
    }
  }
`;
