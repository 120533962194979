<template>
  <j-banner-container
    header="Recipes"
    search
    :search-val="search"
    @searchValueChanged="updateSearch"
    show-share-link
    use-header-in-link
  >
    <div class="px-2">
      <recipe-filter-sort :incoming-params="incomingParams" @triggerFilters="triggerFilters" showBookmark />

      <div v-if="$apollo.loading && recipeList.length == 0">
        <v-row>
          <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col class="hidden-sm-and-down" cols="4"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col class="hidden-sm-and-down" cols="4"><v-skeleton-loader type="card-avatar" /> </v-col>
        </v-row>
      </div>

      <v-row v-if="recipeList.length > 0">
        <v-col cols="6" md="4" v-for="recipe in recipeList" :key="recipe.id">
          <recipe-listing-card @showLockedBanner="lockedToClubDialog = true" :recipe="recipe.node" />
        </v-col>
      </v-row>
      <div v-if="!$apollo.loading && recipeList.length == 0">
        <j-alert class="ma-4">No recipes found</j-alert>
      </div>
    </div>
    <scroll-loader :loader-method="loadmore" :loader-disable="!hasNextPage"> </scroll-loader>
    <v-dialog v-model="lockedToClubDialog" width="500">
      <j-card>
        <v-card-title class="headline grey lighten-2">
          <v-icon>mdi-diamond-stone</v-icon> Join JEFF Club to access
        </v-card-title>
        <v-card-text> Get full access to all the {{ $appName }} recipes when you're on JEFF Club! </v-card-text>
        <v-divider />
        <v-card-actions>
          <j-btn color="secondary" href="https://www.jeff.fitness/pages/jeff-club" target="_blank">
            Find Out More
          </j-btn>
        </v-card-actions>
      </j-card>
    </v-dialog>
  </j-banner-container>
</template>

<script>
import { RECIPES_QUERY } from "@/graphql/queries/nutrition";
import { debounce } from "@/lib/helpers";
import RecipeListingCard from "@/components/nutritionblocks/cards/RecipeListingCard";
import RecipeFilterSort from "@/components/nutritionblocks/blocks/RecipeFilterSort";
import RecipeMixin from "@/mixins/nutrition/RecipeMixin";

export default {
  name: "RecipeList",
  mixins: [RecipeMixin],
  components: {
    RecipeListingCard,
    RecipeFilterSort,
  },
  data() {
    return {
      recipeList: [],
      search: "",
      after: "",
      endCursor: "",
      hasNextPage: true,
      scrollY: 0,
      lockedToClubDialog: false,
      filterVariables: {
        isAddedByMe: false,
        orderBy: "-createdAt",
      },
      incomingParams: {
        bookmarkedFilter: false,
        addedByMeFilter: false,
        orderBy: null,
        recipeTypeFilter: [],
        preferencesFilter: [],
      },
    };
  },
  methods: {
    updateSearch(value) {
      this.search = value;

      this.$route.query.t = null;
      this.$route.query.f = null;
      this.doSearch();
    },
    triggerFilters(filterVariables) {
      this.filterVariables = filterVariables;

      let searchParams = this.buildSearchParams();
      if (searchParams.length > 0) {
        history.pushState({}, null, this.$route.path + "?" + searchParams.join("&"));
      } else {
        history.pushState({}, null, this.$route.path);
      }
      this.doSearch();
    },
    doSearch() {
      this.recipeList = [];
      this.after = "";
      this.endCursor = "";
      this.hasNextPage = true;

      // when we update search, start from scratch with regards to url params
      const searchParams = this.buildSearchParams();
      if (searchParams.length > 0) {
        history.pushState({}, null, this.$route.path + "?" + searchParams.join("&"));
      } else {
        history.pushState({}, null, this.$route.path);
      }
    },
    loadmore() {
      if (!this.loading) {
        this.after = this.endCursor;
      }
    },
    handleScroll() {
      if (this.$route.name == "Recipes") {
        history.pushState({}, null, this.$route.path + "?" + this.buildScrollParams().join("&"));
      }
    },
    buildSearchParams() {
      let paramList = [];
      if (this.search) {
        paramList.push("s=" + encodeURIComponent(this.search));
      }

      if (this.filterVariables) {
        if (this.filterVariables.isBookMarked) {
          // bookmarked (b)
          paramList.push("b=1");
        }
        if (this.filterVariables.isAddedByMe) {
          paramList.push("abm=1");
        }
        if (this.filterVariables.orderBy) {
          // orderBy (ob)
          paramList.push("ob=" + JSON.stringify(this.filterVariables.orderBy));
        }

        if (this.filterVariables.mealIn?.length > 0) {
          // recipeTypes (rt)
          paramList.push("rt=" + JSON.stringify(this.filterVariables.mealIn));
        }
        if (this.filterVariables.preferencesIn?.length > 0) {
          // preferences (p)
          paramList.push("p=" + JSON.stringify(this.filterVariables.preferencesIn));
        }
      }

      return paramList;
    },
    buildScrollParams() {
      // start with the search params and then add
      let paramList = this.buildSearchParams();

      if (this.scrollY) {
        paramList.push("t=" + encodeURIComponent(this.scrollY));
      }
      if (this.recipeList.length) {
        paramList.push("f=" + encodeURIComponent(this.recipeList.length));
      }
      return paramList;
    },
  },
  apollo: {
    recipes: {
      query: RECIPES_QUERY,
      fetchPolicy: "network-only",
      variables() {
        return {
          search: this.search,
          after: this.after,
          first: this.$route.query.f ?? 12,
          ...this.filterVariables,
        };
      },
      result(response) {
        if (response.data) {
          this.loading = false;
          this.endCursor = response.data.recipes.pageInfo.endCursor;
          this.hasNextPage = response.data.recipes.pageInfo.hasNextPage;
          this.recipeList = [...this.recipeList, ...response.data.recipes.edges];

          // if we have a t (top) query string param when loading, then scroll to that point.
          if (this.$route.query.t > 0) {
            setTimeout(() => {
              window.scrollTo({
                top: this.$route.query.t,
                left: 0,
                behavior: "auto",
              });
            }, 200);
          }
        }
      },
    },
  },
  created() {
    if (this.$route.query.s) {
      this.search = this.$route.query.s;
    }

    // listen to scroll event and store in variable. Every x ms, we add it to the querystring.
    window.addEventListener("scroll", () => {
      this.scrollY = scrollY;
      debounce(this.handleScroll, 400);
    });

    if (this.$route.query.rt) {
      this.incomingParams.recipeTypeFilter = JSON.parse(this.$route.query.rt);
    }

    if (this.$route.query.p) {
      this.incomingParams.preferencesFilter = JSON.parse(this.$route.query.p);
    }

    if (this.$route.query.ob) {
      this.incomingParams.orderBy = JSON.parse(this.$route.query.ob);
    }

    if (this.$route.query.b) {
      this.incomingParams.bookmarkedFilter = true;
    }
    this.incomingParams.addedByMeFilter = !!this.$route.query.abm;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.recipe-switch {
  .v-label {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
