<template>
  <j-banner-container
    header="On Demand Classes"
    show-share-link
    use-header-in-link
  >
    <v-tabs
      v-model="tab"
      centered
      background-color="tabs"
      color="secondary"
      active-class="selected-tab-active"
      slider-size="0"
      class="selected-tabs-active mt-6"
    >
      <v-tab>Video</v-tab>
      <v-tab>Audio</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <video-classes />
      </v-tab-item>
      <v-tab-item>
        <audio-classes />
      </v-tab-item>
    </v-tabs-items>
  </j-banner-container>
</template>

<script>
import VideoClasses from "@/components/ondemand/VideoClasses";
import AudioClasses from "@/components/ondemand/AudioClasses";

export default {
  name: "OnDemand",
  components: {
    VideoClasses,
    AudioClasses,
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
