<template>
  <v-container class="container-gradient" fluid style="height: 100%">
    <v-row justify="center" align="center">
      <v-col cols="12" md="5" xl="4">
        <div class="d-flex justify-center mb-4">
          <img :alt="$appName + ' Logo'" src="@/assets/images/logo.png" width="80" />
        </div>
        <login-form class="text-center mt-2" />

        <div class="mt-8">
          <div class="d-flex justify-center">
            <v-icon class="white--text mr-2">mdi-lock-reset</v-icon>
            <router-link to="/account/forgot-password" class="white--text text-decoration-none"
              >Forgot password</router-link
            >
          </div>
          <div class="d-flex justify-center mt-4">
            <v-icon class="white--text mr-2">mdi-email</v-icon>
            <router-link to="/contact-us" class="white--text text-decoration-none">Contact support</router-link>
          </div>
        </div>

        <div class="d-flex justify-center align-center flex-column mt-8">
          <div class="p-text white--text mb-2">or</div>
          <div>
            <j-btn :gradient-degrees="90" large width="200px" :gradient="['light-yellow', 'orange']" to="/checkout/join"
              >Activate Play</j-btn
            >
          </div>
        </div>
        <div class="d-flex justify-center mt-10 pt-3">
          <v-img :alt="$appName + ' Logo'" src="@/assets/images/pf-main-logo.png" contain max-width="200" />
          <div class="vl mt-2 ml-4"></div>
          <v-img src="@/assets/images/JEFFLogoWhite.png" max-width="90" contain style="margin-top: -5px;" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from "./blocks/LoginForm";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
};
</script>

<style lang="scss" scoped>
.container-gradient {
  background-image: linear-gradient(180deg, var(--v-navy-darken2), var(--v-navy-lighten3));
}
.vl {
  border-left: 1px solid white;
  height: 30px;
}
</style>
