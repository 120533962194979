import Cookies from "js-cookie";

export default {
  data() {
    return {
      showPWAInstallPrompt: false,
    };
  },
  mounted() {
    if (this.shouldPromptUser()) this.promptUser();
  },
  methods: {
    shouldPromptUser() {
      if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return false;

      if (window.matchMedia("(display-mode: standalone)").matches) return false; //app is already installed and running as a PWA

      if (this.$route.fullPath === "/install") return false; // we are on the install page already

      return Cookies.get("install_pwa_prompt") === undefined;
    },
    promptUser() {
      this.showPWAInstallPrompt = true;
      Cookies.set("install_pwa_prompt", "true", { expires: 7 });
    },
    goToInstallPage() {
      this.showPWAInstallPrompt = false;
      this.$router.push("/install");
    },
  },
};
