<template>
  <div>
    <div v-if="youtubeId">
      <div class="mt-4">
        <video-block
          :eventProperties="eventProperties"
          :youtubeId="youtubeId"
          @ended="ended()"
          @playing="playing()"
          @paused="paused()"
        >
        </video-block>
      </div>

      <div align="center" class="my-6" v-if="activity.facebookUrl">
        <j-btn tertiary :href="activity.facebookUrl" target="_blank">
          WATCH + COMMENT
          <v-icon>facebook</v-icon>
        </j-btn>
      </div>
    </div>

    <div v-if="this.activity.description">
      <j-card class="pa-4 article-content p-text mt-6 pb-1" v-html="stripSpans(activity.description)" />
    </div>

    <activity-exercises
      v-if="activity.activitysectionSet && activity.activitysectionSet.length > 0"
      class="mt-4"
      :unit-activity="unitActivity"
      :activity-sections="activity.activitysectionSet"
      :subscription-block-id="subscriptionBlockId"
    />

    <div v-if="pdfs" class="text-center my-2">
      <div v-for="pdf in pdfs" :key="pdf.id">
        <j-btn tertiary narrow :href="pdf.file" target="_blank" class="mb-4">
          <span>{{ pdf.title }}</span>
          <v-icon class="ml-2">mdi-file-link</v-icon>
        </j-btn>
      </div>
    </div>

    <div v-if="audioFiles">
      <v-card-text v-for="audioFile in audioFiles" :key="audioFile.id" class="pa-3" align="center" justify="center">
        <div class="text-left mt-2 std-text font-weight-bold">{{ audioFile.title }}</div>
        <vue-plyr
          :emit="['play', 'ended', 'pause']"
          :options="{ controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay'] }"
        >
          <audio :src="audioFile.file" type="audio/mpeg"></audio>
        </vue-plyr>
        <v-btn
          color="secondary"
          class="my-2"
          large
          rounded
          :loading="downloading"
          @ended="audioEnded"
          @play="audioPlaying"
          @pause="audioPaused"
          min-width="200px"
          @click="download(audioFile.file, `${activity.title} - ${audioFile.title}`)"
        >
          <strong>Download</strong>
          <template v-slot:loader>
            <strong class="mr-4">Downloading </strong>
            <v-progress-circular :size="15" indeterminate></v-progress-circular>
          </template>
        </v-btn>
        <div v-if="downloading" class="mt-4">
          <v-progress-linear color="navy" height="15" rounded :value="percentCompleted"></v-progress-linear>
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import { getYouTubeId, stripSpans } from "@/lib/string";
import VideoBlock from "@/components/events/VideoBlock";
import ActivityExercises from "@/components/workoutblocks/cards/ActivityExercises";
import ActivityTrackingMixin from "@/mixins/ActivityTrackingMixin";
import FileDownloadMixin from "@/mixins/FileDownloadMixin";
import {DiscoveryVideoTracking} from "@/lib/discovery_video_tracking";

export default {
  name: "ActivityContent",
  mixins: [ActivityTrackingMixin, FileDownloadMixin],
  components: {
    VideoBlock,
    ActivityExercises,
  },
  props: {
    activity: {
      required: true,
    },
    unitActivity: {
      required: true,
    },
    subscriptionBlockId: {
      required: true,
    },
  },
  mounted() {
    this.videoTracking = new DiscoveryVideoTracking(this.$apollo, this.unitActivity.id, "unitActivity");
  },
  computed: {
    youtubeId() {
      if (this.activity && this.activity.embedUrl) {
        return getYouTubeId(this.activity.embedUrl);
      }
      return null;
    },
    eventProperties() {
      return {
        name: this.activity.title,
        instructor_id: this.activity.leadInstructor.id,
        event_id: this.activity.id,
        video_type: "past",
        workout_type: "workout_block_activity",
      };
    },
    pdfs() {
      return this.activity?.activityattachmentSet?.filter((el) => el.type === "PDF");
    },
    audioFiles() {
      return this.activity?.activityattachmentSet?.filter((el) => el.type === "AUDIO");
    },
  },
  methods: {
    stripSpans(text) {
      return stripSpans(text);
    },
  },
};
</script>
