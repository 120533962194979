import gql from "graphql-tag";

export const EVENT_TYPES_QUERY = gql`
  query eventTypesQuery($category: String) {
    eventTypes(category: $category) {
      id
      name
      category
    }
  }
`;

export const SCHEDULED_EVENTS_QUERY = gql`
  query eventQuery(
    $scheduledDate: String
    $scheduledStartDate: String
    $scheduledEndDate: String
    $eventType: Int
    $eventTypeName: String
    $orderBy: String
    $featuredEvent: Boolean
    $excludeCategories: [String]
    $featureForWeek: Boolean
  ) {
    events(
      scheduledDate: $scheduledDate
      scheduledStartDate: $scheduledStartDate
      scheduledEndDate: $scheduledEndDate
      eventType: $eventType
      eventTypeName: $eventTypeName
      orderBy: $orderBy
      featuredEvent: $featuredEvent
      excludeCategories: $excludeCategories
      featureForWeek: $featureForWeek
    ) {
      id
      name
      userBookmark {
        id
      }
      eventType {
        id
        name
        category
      }
      thumbnail
      url
      embedUrl
      scheduledDate
      startTime
      endTime
      duration
      durationMinutes
      description
      createdAt
      vimeoVideoId
      location
      openAccess
      featuredEvent
      featureForWeek
      session {
        name
        vimeoLiveId
      }
      instructor {
        id
        firstName
      }
      useractivitySet {
        edges {
          node {
            id
            seconds
            status
          }
        }
      }
    }
  }
`;

export const SCHEDULED_EVENT_QUERY = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      name
      pdf
      userBookmark {
        id
      }
      eventType {
        id
        name
        category
      }
      thumbnail
      instructor {
        id
      }
      url
      scheduledDate
      duration
      durationMinutes
      timeRequired
      description
      embedUrl
      startTime
      endTime
      vimeoVideoId
      youtubeId
      videoStartTime
      facebookPermalinkId
      registrationLink
      openAccess
      createdAt
      location
      audioFile
      content {
        name
        file
      }
      session {
        name
        vimeoLiveId
      }
      instructor {
        id
        firstName
        userProfile {
          id
          profilePicture
        }
      }
      useractivitySet {
        edges {
          node {
            id
            status
          }
        }
      }
      activitysectionSet {
        id
        title
        description
        activitysectionexerciseSet {
          id
          order
          description
          longDescription
          isAutoStart
          activitysectionexercisetimerSet {
            id
            title
            order
            seconds
            isRest
          }
          exercise {
            id
            title
            howTo
            animatedImage
            quickHowTo
            staticImage
            coach {
              id
              userProfile {
                id
                profilePicture
              }
            }
          }
        }
      }
    }
  }
`;

export const PAGINATED_EVENT_LIST_QUERY = gql`
  query paginatedEvents(
    $pageSize: Int
    $after: String
    $eventTypeName: String
    $instructorIdIn: [String]
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: String
    $nameContains: String
    $hidden: Boolean
    $featuredEvent: Boolean
  ) {
    paginatedEvents(
      first: $pageSize
      instructorIdIn: $instructorIdIn
      after: $after
      eventType_Name: $eventTypeName
      scheduledDate_Lt: $endDate
      scheduledDate_Gt: $startDate
      orderBy: $orderBy
      name_Icontains: $nameContains
      hidden: $hidden
      featuredEvent: $featuredEvent
    ) {
      totalCount
      edgeCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          userBookmark {
            id
          }
          eventType {
            id
            name
            category
          }
          thumbnail
          url
          scheduledDate
          duration
          durationMinutes
          description
          embedUrl
          startTime
          endTime
          vimeoVideoId
          facebookPermalinkId
          openAccess
          createdAt
          content {
            file
          }
          session {
            name
            vimeoLiveId
          }
          instructor {
            id
            firstName
            userProfile {
              id
              profilePicture
            }
          }
          useractivitySet {
            edges {
              node {
                id
                status
              }
            }
          }
        }
      }
    }
  }
`;

export const OPEN_EVENTS_QUERY = gql`
  query paginatedEventsQuery($first: Int) {
    paginatedEvents(openAccess: true, first: $first) {
      edges {
        node {
          id
          name
          thumbnail
          url
          duration
          durationMinutes
          embedUrl
          vimeoVideoId
          facebookPermalinkId
          instructor {
            id
            firstName
            userProfile {
              id
              profilePicture
            }
          }
        }
      }
    }
  }
`;
