<template>
  <v-row justify="center" class="mb-4 mx-0">
    <v-col cols="12" :md="6" :xl="5" align="center" justify="center" class="py-0 mt-1 px-3">
      
      <!-- only show this if not one on one client -->
      <j-card v-if="$store.getters.role != 'ONE_ON_ONE'" class="mb-4 pa-4 text-left mt-4">
        <h2 class="planet-grey--text mb-2">Have you been to a Planet Fitness?</h2>
        <p>With 48 clubs to choose from around South Africa, our teams are excited and ready to welcome you. Simply sign up for a guest pass and try before you join.</p>
        <j-btn href="http://bit.ly/1wpfplay" target="_blank" narrow class="mr-2">Sign up for Guest Access</j-btn>
      </j-card>
      <v-alert
        v-if="oneOnOneWithoutCoach"
        @click="chooseCoachDialog = true"
        text
        prominent
        type="warning"
        icon="mdi-alert"
        class="text-left"
      >
        To continue your one-on-one journey, please <b>select your coach</b>.
      </v-alert>

      <todo-onboarding-summary v-if="!oneOnOneWithoutCoach && showOnboardingSummary" id="v-step-5" />
      <check-in-alert class="mt-4" />

      <div class="d-flex justify-center align-center" v-if="$route.query.purchase">
        <j-alert type="success"> Success! Thank you. </j-alert>
      </div>
      <div class="d-flex justify-center align-center mb-4 mt-2" v-if="$route.query.survey">
        <div class="successful-sign-up">
          <v-icon color="white" size="70" class="pa-3">mdi-check-bold</v-icon>
        </div>
        <div class="ml-4 std-text">Thank you for your feedback!</div>
      </div>

      <!-- block countdown timer -->
      <block-countdown-timer v-if="countdownTimer" :block-countdown-timer="countdownTimer" />
      <tribe-summary-card />
      
      <!-- unread chats -->
      <div v-if="chats.length > 0" class="mb-4">
        <div v-for="chat in chats" :key="chat.id">
          <chat-listing-card :chat="chat" hide-user-list use-last-message-sender />
        </div>
      </div>

      <!-- gym branch -->
      <!-- CAREFUL. v-if="false" for now -->
      <group-ranking-card
        v-if="false && gymBranch && gymBranch.group"
        :group="gymBranch.group"
        :position="gymBranch.position"
        class="mb-4 mt-4"
      />

      <j-card class="j-elevated-1 pb-1">
        <div v-if="!$apollo.loading" class="d-flex justify-space-between pa-5 pb-3">
          <div class="d-flex">
            <h2 class="planet-grey--text">My Week</h2>
            <v-chip v-if="weeklyGoalAchieved" color="mindset" class="ml-4 white--text font-weight-bold"
              >Achieved <v-icon small class="ml-2">mdi-trophy</v-icon></v-chip
            >
          </div>

          <j-btn
            tertiary
            :icon="!!hasTargets"
            small
            narrow
            elevation="4"
            class="background-grey"
            to="/today/goal"
            id="v-step-15"
          >
            <span v-if="!hasTargets" class="planet-grey--text">Set my goal</span>
            <v-icon color="planet-grey" size="14" :class="{ 'ml-1 mt-n1': !hasTargets }">mdi-pencil</v-icon>
          </j-btn>
        </div>
        <div v-else class="pa-4">
          <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </div>
        <v-card-text v-if="hasTargets" class="pt-0 ml-1 text-left">
          <span v-if="weeklyGoalAchieved">I achieved my goal to </span>
          <span v-else>My goal is to</span>
          <span class="exercise--text font-weight-bold">
            exercise for {{ weeklyGoal.targets.weekly_exercise }} day<span
              v-if="weeklyGoal.targets.weekly_exercise != 1"
              >s</span
            >
          </span>

          <span v-if="weeklyGoal.targets.weekly_nutrition > 0">
            and
            <span class="green--text font-weight-bold">
              <span v-if="weeklyGoal.targets.focus && weeklyGoal.targets.focus.title">{{
                weeklyGoal.targets.focus.title.toLowerCase()
              }}</span>
              <span v-else>eat well</span> for {{ weeklyGoal.targets.weekly_nutrition }} day<span
                v-if="weeklyGoal.targets.weekly_nutrition != 1"
                >s</span
              >
            </span>
          </span>

          this week
          <span v-if="weeklyGoalAchieved">🎉</span>
        </v-card-text>
        <streak-calendar-dots ref="streakCalendarDotsRef" id="v-step-20" />
      </j-card>

      <div class="d-flex mt-5 justify-center">
        <j-card class="px-3 py-2 j-elevated-1 mr-5" to="/today/exercise" id="v-step-30">
          <v-icon size="34" color="light-blue">mdi-run</v-icon>
          <div class="light-blue--text font-weight-bold pt-2">
            Exercise
            <v-icon size="30" color="light-blue" class="right-arrow">keyboard_backspace</v-icon>
          </div>
        </j-card>
        <j-card class="px-3 py-2 j-elevated-1 mr-5" to="/today/nutrition" id="v-step-40">
          <v-icon size="34" color="green">mdi-silverware-fork-knife</v-icon>
          <div class="green--text font-weight-bold pt-2">
            Nutrition
            <v-icon size="30" color="green" class="right-arrow">keyboard_backspace</v-icon>
          </div>
        </j-card>
        <j-card class="px-3 py-2 j-elevated-1" to="/today/mindset" id="v-step-50">
          <v-icon size="34" color="yellow">mdi-lightbulb-on</v-icon>
          <div class="yellow--text font-weight-bold pt-2">
            Mindset
            <v-icon size="30" color="yellow" class="right-arrow">keyboard_backspace</v-icon>
          </div>
        </j-card>
      </div>
      <div v-if="upcomingPrograms.length > 0" class="mt-4">
        <h3 class="pink--text text-left mb-1">Upcoming Program{{ upcomingPrograms.length != 1 ? "s" : "" }}</h3>
        <upcoming-subscription-block
          v-for="workoutBlock in upcomingPrograms"
          :key="'upcoming-block' + workoutBlock.id"
          :subscription-block="workoutBlock"
        />
      </div>
      <div v-if="upcomingNutritionPrograms.length > 0" class="mt-4">
        <h3 class="green--text text-left mb-1">
          Upcoming Nutrition Program{{ upcomingNutritionPrograms.length != 1 ? "s" : "" }}
        </h3>
        <upcoming-subscription-block
          v-for="workoutBlock in upcomingNutritionPrograms"
          :key="'upcoming-block' + workoutBlock.id"
          :subscription-block="workoutBlock"
        />
      </div>

      <!-- Exercise -->
      <div id="v-step-60">
        <today-workout-block
          v-if="firstActiveBlock"
          :subscription-block="firstActiveBlock"
          :anchor-date="selectedDate"
          @fresh-block="freshSectionSkipBlock = $event"
        />
        <div v-else-if="!firstActiveBlock && upcomingPrograms.length == 0 && !$apollo.loading" class="mt-6">
          <j-dotted-btn wide color="light-blue" to="/programs">Choose Exercise Program</j-dotted-btn>
        </div>
      </div>

      <!-- Nutrition -->
      <today-workout-block
        v-if="firstActiveNutritionBlock"
        see-all-text="View Program"
        :subscription-block="firstActiveNutritionBlock"
        :anchor-date="selectedDate"
        @fresh-block="freshSectionSkipBlock = $event"
        id="v-step-70"
      />

      <!-- Mindset -->
      <today-workout-block
        v-if="firstActiveCourse"
        :subscription-block="firstActiveCourse"
        :anchor-date="selectedDate"
        @fresh-block="freshSectionSkipBlock = $event"
      />

      <today-fresh-section id="v-step-65" :skip-fresh-block="freshSectionSkipBlock" />

      <today-workout-block
        v-for="(activeBlock, index) in activeWorkoutBlocks.slice(1)"
        :key="activeBlock.id"
        :subscription-block="activeBlock"
        :anchor-date="selectedDate"
        :skip-fresh-count="index + freshSectionSkipBlock + 4"
      />

      <post-container
        v-if="groupList && groupList.length > 0"
        :userId="$store.getters.user.id"
        show-all-posts-toggle
        dedupe-user
        hide-post-link
        class="pt-6 mt-4"
        heading="Latest from the Community"
      />
      <v-dialog v-model="showToDo" persistent max-width="600px">
        <j-card class="pa-8">
          <todo-block @skip="todosSkipped()" />
        </j-card>
      </v-dialog>

      <v-tour name="myTour" :steps="steps" :callbacks="tourCallbacks"></v-tour>
    </v-col>

    <!-- note v-if false. Hiding for challenge -->
    <j-btn
      v-if="false"
      icon
      color="exercise"
      elevation="12"
      @click="logCustomWorkoutDialog = true"
      class="floating-btn rounded-pill pa-7 px-7"
      :class="{ 'md-up-floating-btn': $vuetify.breakpoint.mdAndUp }"
    >
      <div class="d-flex flex-column justify-center">
        <v-icon size="35" class="mx-auto mt-3">mdi-plus-thick</v-icon>
        <span class="mb-2 font-14">LOG</span>
      </div>
    </j-btn>
    <v-dialog v-model="logCustomWorkoutDialog" max-width="600px">
      <j-card v-if="logCustomWorkoutDialog" rounded="lg">
        <v-toolbar class="mb-6">
          <v-toolbar-title>
            <span>Log My Own Activity</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="logCustomWorkoutDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-2">
          <custom-activity-form :fullForm="true" @close="logCustomWorkoutDialog = false" class="pb-3" />
        </v-card-text>
      </j-card>
    </v-dialog>
    <!-- choose coach dialog -->
    <v-dialog v-model="chooseCoachDialog" width="600" transition="dialog-bottom-transition" class="limited-height">
      <choose-coach-dialog @closed="chooseCoachDialog = false" />
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from "vue";
import { WEEKLY_GOAL_QUERY } from "@/graphql/queries/goal";
import { HOME_QUERY } from "@/graphql/queries/home";
import { CHATS_FOR_USER } from "@/graphql/queries/chat";
import { GROUPS_QUERY } from "@/graphql/queries/group";
import { mapActions } from "vuex";
import { getMonday } from "@/lib/calendarWeek";
import { getColor } from "@/lib/vuetifyColorHelper";
import { dedupeNodes, extractNodes } from "@/lib/array";
import { UPSERT_MY_SETTINGS_MUTATION } from "@/graphql/mutations/account";
import TodayWorkoutBlock from "@/components/workoutblocks/cards/TodayWorkoutBlock";
import TodayFreshSection from "@/components/home/FreshSection";
import StreakCalendarDots from "@/components/shared/blocks/StreakCalendarDots";
import OnboardingMixin from "@/mixins/OnboardingMixin";
import UserMixin from "@/mixins/UserMixin";
import TodoBlock from "@/components/todo/TodoBlock";
import ChatListingCard from "@/components/chat/ChatListingCard";
import TodoOnboardingSummary from "@/components/todo/TodoOnboardingSummary";
import CheckInAlert from "@/components/checkin/CheckInAlert";
import Cookies from "js-cookie";
import PostContainer from "@/components/groups/posts/PostContainer";
import GroupRankingCard from "@/components/groups/cards/GroupRankingCard";
import CustomActivityForm from "@/components/tracker/activity-form/CustomActivityForm";
import UpcomingSubscriptionBlock from "@/components/workoutblocks/cards/UpcomingSubscriptionBlock";
import ChooseCoachDialog from "@/components/account/dialogs/ChooseCoachDialog";
import BlockCountdownTimer from "@/components/workoutblocks/cards/BlockCountdownTimer";
import TribeSummaryCard from "@/components/tribe/TribeSummaryCard";

export default {
  name: "Home",
  mixins: [UserMixin, OnboardingMixin],
  components: {
    UpcomingSubscriptionBlock,
    TodayWorkoutBlock,
    TodayFreshSection,
    StreakCalendarDots,
    TodoBlock,
    CheckInAlert,
    ChatListingCard,
    TodoOnboardingSummary,
    PostContainer,
    GroupRankingCard,
    CustomActivityForm,
    ChooseCoachDialog,
    BlockCountdownTimer,
    TribeSummaryCard,
  },
  data() {
    return {
      loading: true,
      activeWorkoutBlocks: [],
      activeCourseBlocks: [],
      activeNutritionBlocks: [],
      chats: [],
      freshSectionSkipBlock: false,
      groupList: [],
      showAllPosts: true,
      logCustomWorkoutDialog: false,
      upcomingPrograms: [],
      upcomingNutritionPrograms: [],
      tourCallbacks: {
        onFinish: this.tourOnFinish,
        onSkip: this.tourOnFinish,
      },
      chooseCoachDialog: false,
      countdownTimer: null,
    };
  },
  created() {
    const startOfWeek = this.$moment().startOf("week").format("YYYY-MM-DD");
    const cookie = Cookies.get("weekly_goal_achieved_notification");
    if (this.weeklyGoalAchieved && cookie != startOfWeek) {
      // wait a bit before we throw confetti
      setTimeout(() => {
        this.celebrateWeeklyGoal(startOfWeek);
      }, 1000);
    }
  },
  computed: {
    oneOnOneWithoutCoach() {
      return this.$store.getters.role == "ONE_ON_ONE" && !this.$store.getters.user?.userProfile?.coach;
    },
    steps() {
      const action = this.$vuetify.breakpoint.smAndDown ? "Tap" : "Click";
      let exerciseStep;
      let exerciseTitle = `Your Daily Exercise`;
      if (this.firstActiveBlock) {
        exerciseStep = `${action} the above to access your workout for the day.`;
      } else if (!this.firstActiveBlock && this.upcomingPrograms?.length > 0) {
        exerciseStep = `Your upcoming programs can be found here.`;
        exerciseTitle = `Upcoming Programs`;
      } else {
        exerciseStep = `Get started by choosing your first <strong>exercise program</strong>. Once done, you will be able to access your daily workout here.`;
      }

      const steps = [
        {
          target: "#v-step-5",
          header: {
            title: "<strong>Start Here</strong>",
          },
          content: `These are our suggestions for how to get started.`,
        },
        {
          target: "#v-step-10",
          header: {
            title: "<strong>App Features</strong>",
          },
          content: `${action} your profile to explore all available app features.`,
          offset: -100,
        },
        {
          target: "#v-step-15",
          header: {
            title: "<strong>Set Your Goal</strong>",
          },
          content: `Set your exercise and nutrion goals for the week.`,
        },
        {
          target: "#v-step-20",
          header: {
            title: "<strong>Your Dots</strong>",
          },
          content: `Your <strong>dots</strong> will appear here. You get a dot when you log an activity or meal!`,
        },
        {
          target: "#v-step-30",
          header: {
            title: "<strong>Exercise</strong>",
          },
          content: `Access your <strong>exercise</strong> section.`,
        },
        {
          target: "#v-step-40",
          header: {
            title: "<strong>Nutrition</strong>",
          },
          content: `Access your <strong>nutrition</strong> section.`,
        },
        {
          target: "#v-step-50",
          header: {
            title: "<strong>Mindset</strong>",
          },
          content: `Access your <strong>mindset</strong> section.`,
        },
        {
          target: "#v-step-60",
          header: {
            title: `<strong>${exerciseTitle}</strong>`,
          },
          content: exerciseStep,
        },
        {
          target: "#v-step-65",
          header: {
            title: "<strong>Fresh Classes</strong>",
          },
          content: `Try out one of our fresh classes`,
        },
      ];

      if (this.firstActiveNutritionBlock) {
        steps.push({
          target: "#v-step-70",
          header: {
            title: "<strong>Your Nutrition Program</strong>",
          },
          content: `${action} the above to access your <strong>nutrition program's</strong> daily lesson and ${action.toLowerCase()} <em>Full Program</em> to view your <strong>menu</strong> and more.`,
        });
      }
      return steps;
    },
    hasTargets() {
      if (this.weeklyGoal?.targets?.weekly_exercise === 0 && this.weeklyGoal?.targets?.weekly_nutrition === 0) {
        return false;
      }
      if (this.weeklyGoal?.targets?.weekly_exercise === null && this.weeklyGoal?.targets?.weekly_nutrition === null) {
        return false;
      }
      return this.weeklyGoal?.targets;
    },
    gymBranch() {
      return this.$store.getters.user?.userProfile?.gymBranch;
    },
    weeklyGoalAchieved() {
      return this.$store.getters.user?.userProfile?.weeklyGoalAchieved;
    },
    firstActiveBlock() {
      if(this.activeWorkoutBlocks?.length > 0) {
        return this.activeWorkoutBlocks[0];
      }
      return null;
    },
    firstActiveCourse() {
      if(this.activeCourseBlocks?.length > 0) {
        return this.activeCourseBlocks[0];
      }
      return null;
    },
    firstActiveNutritionBlock() {
      return this.activeNutritionBlocks?.length > 0 && this.activeNutritionBlocks[0];
    },
    showToDo() {
      return !this.$store.getters.skipTasks.skipped && this.$store.getters.tasks > 0;
    },
    selectedDate() {
      return this.$store.getters.selectedDate?.format("YYYY-MM-DD") || this.$moment().format("YYYY-MM-DD");
    },
    monday() {
      return this.$moment(getMonday(this.$moment().format("YYYY-MM-DD"))).format("YYYY-MM-DD");
    },
  },
  methods: {
    ...mapActions(["setSelectedDate"]),
    startTour() {
      // if we don't have the onboarding card, the tour breaks. Also, if the onboarding card missing, then the user has probably onboarded themselves already
      if (!this.showOnboardingSummary) {
        return null;
      }
      const tourHomeSeen = this.$store.getters.user?.userSettings?.find((obj) => obj.key == "tour-home-seen");
      if (!tourHomeSeen) {
        setTimeout(() => {
          this.$tours["myTour"].start();
        }, 1000);
      }
    },
    tourOnFinish() {
      this.$apollo.mutate({
        mutation: UPSERT_MY_SETTINGS_MUTATION,
        variables: {
          settingName: "tour-home-seen",
          value: true,
        },
        refetchQueries: ["accountQuery"],
      });
    },
    todosSkipped() {
      this.startTour();
    },
    celebrateWeeklyGoal(startOfWeek) {
      // also expire it, to be "safe"
      Cookies.set("weekly_goal_achieved_notification", startOfWeek, { expires: 8 });
      Vue.toasted.success(`Yay! You have achieved your goal for the week!`, {
        icon: {
          name: "party-popper",
        },
      });
      this.$confetti.start({
        particlesPerFrame: 1.5,
        particles: [
          {
            type: "heart",
          },
          {
            type: "circle",
          },
          {
            type: "rect",
          },
          {
            type: "image",
            url: "https://storage.googleapis.com/master_site/settings/jeff_50_50.png",
          },
        ],
        defaultColors: [
          getColor("exercise"),
          getColor("nutrition"),
          getColor("mindset"),
          getColor("sleep"),
          getColor("hydration"),
          getColor("gold"),
        ],
      });
      setTimeout(() => {
        this.$confetti.stop();
      }, 3000);
    },
    setCalendarDate(date) {
      if (!date) {
        return;
      }
      const momentDate = this.$moment(date);
      if (momentDate) {
        this.setSelectedDate(momentDate);
        this.$refs.streakCalendarDotsRef?.changeDate(momentDate.isoWeekday() - 1);
      }
    },
  },
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt();
  },
  apollo: {
    home: {
      query: HOME_QUERY,
      variables() {
        return {
          withGroups: true,
          withGroupUsers: false,
          withUsers: false,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          let data = response.data.home;
          if (data.subscriptionBlocks) {
            // set the bool that we do have programs or challenges, even if they have ended.
            this.hasPrograms = data.subscriptionBlocks.some(
              (element) => element.block.type === "PROGRAM" || element.block.type === "CHALLENGE"
            );

            const activeBlocks =
              data.subscriptionBlocks.filter((element) => {
                return element.endDate == null || this.$moment(element.endDate).isAfter(this.selectedDate);
              }) ?? [];

            this.activeCourseBlocks =
              activeBlocks.filter((element) => {
                return element.block.type === "COURSE" && element.progressPercent < 100;
              }) ?? null;

            this.activeWorkoutBlocks =
              activeBlocks.filter((element) => {
                return (element.block.type === "PROGRAM" || element.block.type === "CHALLENGE") && element.progressPercent < 100;
              }) ?? null;

            this.activeNutritionBlocks =
              activeBlocks.filter((element) => {
                return element.block.type === "NUTRITION";
              }) ?? null;
          }
          if (data.upcomingSubscriptionBlocks) {
            this.upcomingPrograms =
              data.upcomingSubscriptionBlocks.filter(
                (element) => element.block.type === "PROGRAM" || element.block.type === "CHALLENGE"
              ) ?? [];
            this.upcomingNutritionPrograms =
              data.upcomingSubscriptionBlocks.filter((element) => element.block.type === "NUTRITION") ?? [];
          }

          this.countdownTimer = data.countdownTimer;

          // we can't show the tour if the todo modal is open
          if (!this.showToDo) {
            this.startTour();
          }
        }
      },
    },
    weeklyGoal: {
      query: WEEKLY_GOAL_QUERY,
      variables() {
        return {
          weekStart: this.monday,
        };
      },
      update(data) {
        return data.weeklyGoals ?? null;
      },
    },
    chatsForUser: {
      query: CHATS_FOR_USER,
      variables() {
        return {
          onlyUnread: true,
          first: 5,
        };
      },
      fetchPolicy: "network-only",
      // make this the same as NOTIFICATION_QUERY in CentralStorageMixin.js
      pollInterval: 3 * 60 * 1000,
      result(response) {
        if (response.data && !response.loading) {
          const chats = extractNodes(response.data.chatsForUser.edges);

          if (this.$store.getters.role == "JEFF_MEMBER" || this.$store.getters.role == "INACTIVE") {
            const supportChats = chats?.filter((chat) => chat.type === "SUPPORT");
            if (supportChats?.length > 0) {
              return (this.chats = supportChats);
            }
            return;
          }

          const supportChats = chats?.filter((chat) => chat.type === "SUPPORT");
          if (supportChats?.length > 0) {
            return (this.chats = supportChats);
          }

          const coachChats = chats?.filter((chat) => chat.type === "COACH");
          if (coachChats?.length > 0) {
            return (this.chats = coachChats);
          }

          const accountabilityChats = chats?.filter((chat) => chat.type === "ACCOUNTABILITY");
          if (accountabilityChats?.length > 0) {
            return (this.chats = [accountabilityChats[0]]);
          }
          this.chats = chats?.length > 0 ? [chats[0]] : [];
        }
      },
    },
    groups: {
      query: GROUPS_QUERY,
      // so that the unread count works nicely without any hard refresh business.
      fetchPolicy: "no-cache",
      variables() {
        let biggerScreen = this.$vuetify.breakpoint.smAndUp;
        return {
          mine: true,
          first: biggerScreen ? 4 : 2,
          withUsers: false,
        };
      },
      result(response) {
        if (response.data) {
          let groupList = [...this.groupList, ...response.data.groups.edges];
          this.groupList = dedupeNodes(groupList);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.right-arrow {
  transform: rotate(180deg);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.floating-btn {
  position: fixed;
  bottom: 75px;
  right: 25px;
  background-color: var(--v-navy-base);
  z-index: 1;

  &.md-up-floating-btn {
    right: 75px;
  }

  .font-14 {
    font-size: 14px;
    padding-top: 2px;
    padding-bottom: 6px;
  }
}
</style>
