<template>
  <div>
    <v-alert class="my-4" v-if="errorMessage" color="alert-yellow" text>
      <span class="white--text"> {{ errorMessage }} </span>
    </v-alert>

    <ValidationObserver ref="observer">
      <v-form @submit.prevent="submit">
        <ValidationProvider id="username" v-slot="{ errors }" name="email" rules="required|email">
          <v-text-field
            class="login-input"
            v-model.trim="username"
            label="Email"
            :error-messages="errors"
            @keydown.space.prevent
            color="white"
            persistent-hint
            persistent-placeholder
            outlined
          />
        </ValidationProvider>

        <ValidationProvider id="password" v-slot="{ errors }" name="password" rules="required">
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            :error-messages="errors"
            @click:append="showPassword = !showPassword"
            color="white"
            class="login-input"
            persistent-hint
            persistent-placeholder
            outlined
          />
        </ValidationProvider>

        <j-btn
          large
          type="submit"
          :gradient="['grey', 'planet-grey']"
          :gradient-degrees="90"
          width="200px"
          :loading="buttonLoading"
        >
          log in
        </j-btn>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import LoginMixin from "@/mixins/LoginMixin";

export default {
  name: "LoginForm",
  mixins: [LoginMixin],
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      buttonLoading: false,
      errorMessage: "",
    };
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.buttonLoading = true;
          this.errorMessage = "";
          this.login();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-input ::v-deep {
  input {
    color: white !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
  }
  label {
    color: white !important;
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: white;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: white;
    opacity: 1;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: white;
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
  }

  ::placeholder {
    /* Most modern browsers support this now. */
    color: white;
  }
}
</style>
