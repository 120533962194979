<template>
  <div>
    <j-banner-container header="Add Own Recipe" lg="8" xl="9">
      <div v-if="$vuetify.breakpoint.lgAndUp" class="d-flex">
        <div class="pa-4 half-width">
          <validation-observer ref="obs">
            <edit-recipe
              ref="recipeForm"
              :recipe="recipe"
              :loading-button="loadingButton"
              :show-errors="showErrorAlert"
              :template-items="templateItems"
              :errors="errors"
              @submit="submit"
            />
          </validation-observer>
        </div>
        <div class="half-width">
          <div v-if="!$apollo.loading && recipe.meal">
            <j-card-banner-container
              header="Recipe"
              :image="recipe.image.dataUrl"
              :title="recipe.title"
              :subtitle="recipe.meal.replace('PRIMARY_', '')"
              :show-back-link="false"
              md="12"
              lg="12"
              xl="12"
            >
              <template v-slot:card-content>
                <view-recipe :recipe="recipe" client-made portion="Medium" preview />
              </template>
            </j-card-banner-container>
          </div>
        </div>
      </div>
      <div v-else>
        <v-tabs v-model="tab">
          <v-tab key="edit">Edit</v-tab>
          <v-tab key="preview">Preview</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="edit" class="pa-4">
            <validation-observer ref="obs">
              <edit-recipe
                ref="recipeForm"
                :recipe="recipe"
                :loading-button="loadingButton"
                :show-errors="showErrorAlert"
                :template-items="templateItems"
                :errors="errors"
                @submit="submit"
              />
            </validation-observer>
          </v-tab-item>
          <v-tab-item key="preview">
            <div v-if="!$apollo.loading && recipe.meal">
              <j-card-banner-container
                header="Recipe"
                :image="recipe.image.dataUrl"
                :title="recipe.title"
                :subtitle="recipe.meal.replace('PRIMARY_', '')"
                :show-back-link="false"
                md="12"
                lg="12"
                xl="12"
              >
                <template v-slot:card-content>
                  <view-recipe :recipe="recipe" client-made portion-size="Medium" preview />
                </template>
              </j-card-banner-container>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </j-banner-container>
  </div>
</template>

<script>
import { RECIPE_QUERY } from "@/graphql/queries/nutritionblock";
import { UPSERT_RECIPE_MUTATION } from "@/graphql/mutations/nutrition";
import { decodeId } from "@/lib/string";
import ViewRecipe from "@/components/nutritionblocks/blocks/ViewRecipe";
import EditRecipe from "@/components/nutritionblocks/blocks/RecipeForm";
import RecipeMixin from "@/mixins/nutrition/RecipeMixin";

export default {
  name: "UpsertRecipe",
  mixins: [RecipeMixin],
  components: {
    EditRecipe,
    ViewRecipe,
  },
  data() {
    return {
      tab: null,
      showErrorAlert: false,
      loadingButton: false,
      templateItems: ["place / person", "X", "X people"],
      recipe: {
        id: null,
        meal: "BREAKFAST",
        title: null,
        description:
          "This recipe comes from {{ place / person }}. \nWe use it {{ X }} times a week. \nIt serves {{ X people }}.",
        directions: "",
        createdAt: this.$moment(),
        updatedAt: this.$moment(),
        image: {
          file: null,
          dataUrl: null,
        },
        recipeingredientSet: [],
      },
      errors: [],
    };
  },
  methods: {
    validate() {
      this.errors = [];
      if (!this.recipe.image?.file && !this.recipe.image?.dataUrl) {
        this.errors.push("Missing recipe image");
      }

      this.$refs.recipeForm?.validate().forEach((err) => this.errors.push(err));

      if (!this.recipe.recipeingredientSet || this.recipe.recipeingredientSet?.length == 0) {
        this.errors.push("No ingredients added");
      }

      return !this.errors.length > 0;
    },
    submit() {
      let validRecipe = this.validate();

      this.$refs.obs.validate().then((valid) => {
        if (!validRecipe || !valid) {
          this.showErrorAlert = true;
        } else {
          this.showErrorAlert = false;
          this.loadingButton = true;
          let ingredients = this.recipe.recipeingredientSet.map((ing) => {
            return {
              id: decodeId(ing.id),
              ingredientId: decodeId(ing.ingredient?.id),
              preparationId: decodeId(ing.preparation?.id),
              quantity: ing.quantity,
              measurementUnitId: decodeId(ing.measurementUnit?.id),
            };
          });

          this.$apollo
            .mutate({
              mutation: UPSERT_RECIPE_MUTATION,
              variables: {
                data: {
                  id: decodeId(this.recipe.id),
                  title: this.recipe.title,
                  description: this.recipe.description,
                  image: this.recipe.image.file,
                  directions: this.recipe.directions,
                  meal: this.recipe.meal,
                  privacy: "PRIVATE",
                  ingredients: ingredients,
                },
              },
            })
            .then((response) => {
              if (response.data.upsertRecipe?.success) {
                this.$router.push(`/recipes/${response.data.upsertRecipe.recipeId}`);
              }
            });
        }
      });
    },
  },
  apollo: {
    recipe: {
      query: RECIPE_QUERY,
      variables() {
        return {
          id: this.$attrs.id,
        };
      },
      update(data) {
        let ingredients = data.recipe?.recipeingredientSet?.map((recIng) => {
          return {
            id: recIng.id,
            ingredient: recIng.ingredient,
            preparation: recIng.preparation,
            quantity: recIng.quantityLarge,
            measurementUnit: recIng.measurementUnit,
            index: recIng.sortOrder,
          };
        });

        let recipe = {
          id: data.recipe?.id,
          meal: data.recipe?.meal,
          title: data.recipe?.title,
          description: data.recipe?.description,
          directions: data.recipe?.directions,
          createdAt: data.recipe?.createdAt,
          updatedAt: data.recipe?.updatedAt,
          owner: data.recipe?.owner,
          image: {
            file: null,
            dataUrl: data.recipe?.image,
          },
          recipeingredientSet: ingredients,
        };

        return recipe;
      },
      skip() {
        return !this.$attrs.id;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.half-width {
  width: 50%;
}
</style>
