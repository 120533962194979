<template>
  <div class="j-dotted-btn rounded-lg pa-3 px-0">
    <j-btn v-bind="$props" v-on="$listeners">
      <span><slot /></span>
    </j-btn>
  </div>
</template>

<script>
import { VBtn } from "vuetify/lib";
import { getColor } from "@/lib/vuetifyColorHelper";

export default {
  name: "JDottedBtn",
  extends: VBtn,
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    primary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getColor() {
      if (this.primary) {
        return "pink";
      }
      if (this.secondary) {
        return "secondary";
      }
      return this.color;
    },
    colorBorder() {
      return `border: 2px ${getColor(this.getColor)} dotted`;
    },
  },
};
</script>
