<template>
  <div
    class="relative"
    v-if="coachDetail && coachDetail.userProfile.biography && coachDetail.userProfile.profilePicture"
  >
    <v-avatar color="blue-background" class="pointer position-initial" @click="openCoachDialog = true" size="44">
      <v-icon v-if="isOutOfOffice" small class="m-0 p-0 out-office-calendar" color="gray">mdi-calendar</v-icon>
      <img v-if="coachDetail" :src="coachDetail.userProfile.profilePicture" />
    </v-avatar>
    <div class="sm-text white--text">COACH</div>
    <v-dialog v-model="openCoachDialog" max-width="600" transition="dialog-bottom-transition" class="limited-height">
      <j-card rounded="lg">
        <v-toolbar class="mb-6">
          <v-toolbar-title class="navy--text headline"> Coach {{ coachDetail.firstName }} </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="openCoachDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="d-flex">
            <div>
              <v-avatar color="blue-background" size="80">
                <img v-if="coachDetail" :src="coachDetail.userProfile.profilePicture" />
              </v-avatar>
            </div>
            <div class="mt-1 ml-5">
              <h3 class="navy--text">{{ coachDetail.firstName }}</h3>
              <p>{{ coachDetail.userProfile.biography }}</p>
            </div>
          </div>
          <div class="d-flex justify-end mr-6 mt-n4 pb-4" v-if="$store.getters.role == 'CLUB'">
            <v-switch
              v-model="coachSupportOff"
              label="I don’t want to be contacted by my Coach"
              color="nutrition"
              hide-details
              @click="upsertSetting"
            />
          </div>
          <v-row class="my-2">
            <div v-if="!isStaffActive" class="w-100">
              <j-alert type="warning" icon="mdi-lightbulb-on-outline" class="mx-4">
                <span>I'm no longer working at {{ $appName }}. Please reach out on your support chat!</span>
              </j-alert>
            </div>
            <div v-else-if="isOutOfOffice" class="w-100">
              <j-alert type="warning" icon="mdi-lightbulb-on-outline" class="mx-4">
                <span v-html="outOfOfficeMessage"></span>
              </j-alert>
            </div>

            <v-col cols="12" sm="6" align="center">
              <j-btn
                primary
                v-if="coachingChatId"
                :to="`/chats/${coachingChatId}`"
                @click="openCoachDialog = false"
                :disabled="!isStaffActive"
              >
                Chat to me
                <v-icon>mdi-chat</v-icon>
              </j-btn>
            </v-col>
            <v-col v-if="checkInLink" cols="12" sm="6" align="center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <j-btn :href="checkInLink" target="_blank" color="blue-grey" :disabled="!canBook">
                      Book <span v-if="$store.getters.role === 'ONE_ON_ONE'">check-in</span><span v-else>consult</span>
                      <v-icon class="ml-1">mdi-calendar-clock</v-icon>
                    </j-btn>
                  </span>
                </template>
                <span v-if="!canBook">You can only book a consult with your coach once a month</span>
                <span v-else>Book a consult with coach {{ coachDetail.firstName }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <video-block
            v-if="coachDetail.userProfile.youtubeIntroVideo"
            :youtube-id="getYouTubeId(coachDetail.userProfile.youtubeIntroVideo)"
            class="my-2"
            :show-video-cast-message="false"
          />
        </v-card-text>

        <v-card-text
          v-if="coachDetail.userProfile.coachIntroduction"
          v-html="coachDetail.userProfile.coachIntroduction"
        />
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import VideoBlock from "@/components/events/VideoBlock";
import { getYouTubeId } from "@/lib/string";
import { nl2br } from "@/lib/string";
import { UPSERT_MY_SETTINGS_MUTATION } from "@/graphql/mutations/account";

export default {
  name: "MyCoach",
  components: {
    VideoBlock,
  },
  data() {
    return {
      coachSupportOff: false,
      canBook: true, // let's for now, open it up so that can book these things at any time.
      openCoachDialog: false,
    };
  },
  computed: {
    isStaffActive() {
      return this.$store.getters.user?.userProfile?.coach?.userProfile?.user?.isActive;
    },
    isOutOfOffice() {
      if (!this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod) return;

      const startDate = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod?.startDate;
      const endDate = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod?.endDate;

      return (
        this.$moment(startDate).isSameOrBefore(this.$moment()) && this.$moment(endDate).isSameOrAfter(this.$moment())
      );
    },
    outOfOfficeMessage() {
      const coach = this.$store.getters.user?.userProfile?.coach?.firstName;
      const startDate = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod?.startDate;
      const endDate = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod?.endDate;
      const outOfOfficeMessage = this.$store.getters.user?.userProfile?.coach?.currentCoachSecondaryPeriod.outOfOffice;
      const defaultMessage = `${coach} is on leave from ${this.$moment(startDate).format("D MMM")} to ${this.$moment(
        endDate
      ).format("D MMM")}`;

      return nl2br(outOfOfficeMessage) || defaultMessage;
    },
    coachingChatId() {
      if (this.$store.getters.user?.userProfile?.coachingChat?.id) {
        return this.$store.getters.user.userProfile.coachingChat.id;
      }
      return null;
    },
    coachDetail() {
      return this.$store.getters.user?.userProfile?.coach;
    },
    checkInLink() {
      if (this.$store.getters.role === "ONE_ON_ONE" && this.$store.getters.checkinBookingLink) {
        return this.$store.getters.checkinBookingLink;
      }
      return this.coachDetail.userProfile.consultBookingLink;
    },
  },
  mounted() {
    const setting = this.$store.getters.user?.userSettings?.find((obj) => obj.key == "coach-support-off");
    this.coachSupportOff = !!setting?.value;
  },
  methods: {
    getYouTubeId(url) {
      return getYouTubeId(url);
    },
    async upsertSetting() {
      this.$apollo.mutate({
        mutation: UPSERT_MY_SETTINGS_MUTATION,
        variables: {
          settingName: "coach-support-off",
          value: this.coachSupportOff,
        },
        refetchQueries: ["accountQuery"],
      });
    },
  },
};
</script>
<style scoped>
.out-office-calendar {
  position: absolute;
  top: 0px;
  left: 25px;
  width: 20px;
  height: 20px;
  background: #fff3ce;
  border-radius: 50%;
}
</style>
