<template>
  <j-banner-container
    back-link="/celebrations"
    header="Celebration Creator"
  >
    <v-stepper v-model="stepper">
      <v-stepper-header>
        <v-stepper-step
          :complete="stepper > 1"
          step="1"
          @click="celebrationData ? gotoStep(null, 1) : null"
          :class="celebrationData ? 'pointer' : ''"
        >
          Photos
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="stepper > 2"
          step="2"
          @click="celebrationData ? gotoStep(null, 2) : null"
          :class="celebrationData ? 'pointer' : ''"
        >
          Layout
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="3"
          @click="celebrationData ? gotoStep(null, 3) : null"
          :class="celebrationData ? 'pointer' : ''"
        >
          Download
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <celebration-step-one @saved="gotoStep($event, 2)" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <celebration-step-two
            v-if="celebrationData"
            :celebration-data="celebrationData"
            @back="stepper = 1"
            @saved="gotoStep($event, 3)"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <celebration-step-three
            v-if="celebrationData"
            :celebration-data="celebrationData"
            @back="gotoStep(null, 2)"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </j-banner-container>
</template>

<script>
import CelebrationStepOne from "@/components/celebration/steps/CelebrationStepOne";
import CelebrationStepTwo from "@/components/celebration/steps/CelebrationStepTwo";
import CelebrationStepThree from "@/components/celebration/steps/CelebrationStepThree";
import { CELEBRATION_QUERY } from "@/graphql/queries/celebration";

export default {
  name: "CreateCelebration",
  components: {
    CelebrationStepOne,
    CelebrationStepTwo,
    CelebrationStepThree,
  },
  data() {
    return {
      stepper: 1,
      celebrationData: {},
    };
  },
  created() {
    let data;
    if (this.$route.query.id) {
      data = { id: this.$route.query.id };
    }

    let step = 1;
    if (this.$route.query.s) {
      step = parseInt(this.$route.query.s);
    }
    this.gotoStep(data, step);
  },
  methods: {
    gotoStep(data = null, step) {
      if (data) {
        this.celebrationData = data;
      }
      this.stepper = step;
      this.updateQueryString(step);
    },
    updateQueryString(step) {
      let paramList = [];
      if (step) {
        paramList.push("s=" + encodeURIComponent(step));
      }
      if (this.celebrationData.id) {
        paramList.push("id=" + encodeURIComponent(this.celebrationData.id));
      }
      history.pushState({}, null, this.$route.path + "?" + paramList.join("&"));
    },
  },
  apollo: {
    celebration: {
      query: CELEBRATION_QUERY,
      variables() {
        return {
          id: this.celebrationData.id,
        };
      },
      skip() {
        return !this.celebrationData.id;
      },
      async result(response) {
        if (response.data && !response.loading) {
          this.celebrationData = response.data.celebration;
        }
      },
    },
  },
};
</script>
