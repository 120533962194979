<template>
  <v-container class="fill-height pt-0">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" md="6" class="background-grey-3 fill-height">
        <div class="text-center py-3">
          <h1 class="navy--text heading-2">Unlock your free content</h1>
          <div class="grey-navy--text">Fill in these last few details to get your content</div>
        </div>
        <j-card>
          <v-card-text>
            <div class="pt-2 px-3">
              <registration-form :submit-button="{ text: 'Continue', color: 'pink' }" :include-password="false">
                <template v-slot:terms>
                  By entering your mobile number and email address you consent to being contacted for the purpose of
                  providing further information about {{ $appName }} fitness programs. You can unsubscribe at any time.
                </template>
              </registration-form>
            </div>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RegistrationForm from "@/components/authentication/blocks/RegistrationForm";

export default {
  name: "Register",
  components: {
    RegistrationForm,
  },
  created() {
    if (!sessionStorage.getItem("redirectPath")) {
      sessionStorage.setItem("redirectPath", "/gate");
    }
  },
};
</script>
