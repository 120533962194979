import gql from "graphql-tag";

export const FRESH_CLASSES = gql`
  query freshClasses(
    $first: Int!
    $skip: Int
    $featureForWeek: Boolean
    $scheduledDate_Gte: DateTime
    $scheduledDate_Lte: DateTime
  ) {
    paginatedEvents(
      first: $first
      orderBy: "-scheduled_date"
      offset: $skip
      featureForWeek: $featureForWeek
      scheduledDate_Gte: $scheduledDate_Gte
      scheduledDate_Lte: $scheduledDate_Lte
    ) {
      edges {
        node {
          id
          name
          featureForWeek
          userBookmark {
            id
          }
          instructor {
            id
            firstName
            userProfile {
              id
              profilePicture
            }
          }
          eventType {
            id
            name
            category
          }
          thumbnail
          embedUrl
          youtubeId
          scheduledDate
          duration
          durationMinutes
          useractivitySet {
            edges {
              node {
                id
                seconds
                status
              }
            }
          }
        }
      }
    }
  }
`;

export const HOME_QUERY = gql`
  query home($withGroups: Boolean!, $withGroupUsers: Boolean!, $withUsers: Boolean!) {
    home {
      countdownTimer {
        id
        image
        description
        start
        end
        milisecondsToEnd
      }
      subscriptionBlocks {
        id
        startDate
        endDate
        isSelfPaced
        currentUnit {
          id
          title
        }
        block {
          id
          title
          type
          image
          duration
          totalWorkouts
          totalModules
          totalEds
          totalLiveEventsRestDays
          relatedNutritionBlock {
            id
            title
          }
          blockattachmentSet {
            id
            code
            attachment
          }
          userList @include(if: $withUsers) {
            id
            firstName
            lastName
            profilePicture
          }
          leadInstructor {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
          secondaryInstructor {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
        }
        progressPercent
        currentDay
        currentDayOfWeek
      }
      upcomingSubscriptionBlocks {
        id
        startDate
        endDate
        currentUnit {
          id
          title
        }
        block {
          id
          title
          duration
          type
          activityPlanner
          nutritionPlan
          relatedNutritionBlock {
            id
          }
          userList {
            id
            firstName
            lastName
            profilePicture
          }
          leadInstructor {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
          secondaryInstructor {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
        }
        progressPercent
        currentDay
        currentDayOfWeek
      }
      groups @include(if: $withGroups) {
        id
        title
        banner
        memberCount
        postCount
        users @include(if: $withGroupUsers) {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
          }
        }
      }
    }
  }
`;

export const LAST_CHECKIN_QUERY = gql`
  query lastCheckinQuery {
    userCheckIns(last: 1, orderBy: "assessmentDate") {
      edges {
        node {
          id
          assessmentDate
        }
      }
    }
  }
`;
