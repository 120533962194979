<template>
  <ValidationObserver ref="observer">
    <v-form @submit.prevent="submit">
      <j-text-field v-model="totallyRealFields.name" aria-hidden="true" class="totally_real_input"></j-text-field>

      <j-text-field v-model="totallyRealFields.surname" aria-hidden="true" class="totally_real_input"></j-text-field>

      <v-row>
        <v-col v-if="requiresEmployeeCode" cols="12" class="pb-0 mb-n4">
          <ValidationProvider id="employeeCode" v-slot="{ errors }" name="Employer Code" rules="required">
            <j-text-field
              v-model="employeeCode"
              outlined
              label="Please enter the code provided by your employer"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="6" class="pb-0">
          <ValidationProvider id="firstName" v-slot="{ errors }" name="First name" rules="required">
            <j-text-field v-model="firstName" outlined label="First name" :error-messages="errors" />
          </ValidationProvider>
        </v-col>
        <v-col cols="6" class="pb-0">
          <ValidationProvider id="lastName" v-slot="{ errors }" name="Last name" rules="required">
            <j-text-field v-model="lastName" outlined label="Last name" :error-messages="errors" />
          </ValidationProvider>
        </v-col>
      </v-row>
      <ValidationProvider id="username" v-slot="{ errors }" name="Email" rules="required|email">
        <j-text-field v-model.trim="username" label="Email" outlined :error-messages="errors" @keydown.space.prevent />
      </ValidationProvider>

      <ValidationProvider
        v-if="includePassword"
        id="password"
        v-slot="{ errors }"
        name="Password"
        rules="required|verify_password"
      >
        <j-text-field
          v-model="password"
          outlined
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          :error-messages="errors"
          @click:append="showPassword = !showPassword"
        />
      </ValidationProvider>
      <div class="pb-7">
        <ValidationProvider id="phone" v-slot="{ errors }" name="phone" rules="required">
          <vue-tel-input mode="international" v-model="phone" styleClasses="input-padding"></vue-tel-input>
          <span v-if="errors.length > 0" class="error--text sm-text">The Phone field is required</span>
        </ValidationProvider>
      </div>

      <j-radio-group v-model="idNumberRadio" row class="mt-n2">
        <j-radio label="SA ID Number" :value="true" />
        <j-radio label="Passport Number" :value="false" />
      </j-radio-group>

      <div v-if="idNumberRadio">
        <ValidationProvider mode="lazy" name="ID Number" :rules="idRules" v-slot="{ errors }">
          <j-text-field
            v-model="idNumber"
            :error-messages="errors"
            placeholder="Your 13 digit SA ID number"
            class="pt-0 mt-0"
          ></j-text-field>
        </ValidationProvider>
      </div>
      <div v-else>
        <ValidationProvider mode="lazy" name="Passport Number" :rules="passportRules" v-slot="{ errors }">
          <j-text-field
            v-model="passportNumber"
            :error-messages="errors"
            placeholder="Your Passport number"
            class="pt-0 mt-0"
          ></j-text-field>
        </ValidationProvider>
      </div>
      <j-autocomplete
        v-model="gymBranch"
        :items="branches"
        filled
        :label="`Your Club`"
        item-text="title"
        item-value="id"
        background-color="white"
      />

      <ValidationProvider id="terms" v-slot="{ errors }" name="Terms" :rules="{ required: { allowFalse: false } }">
        <j-checkbox v-model="terms" class="mt-n2" :error-messages="errors">
          <div slot="label">
            <span class="p-text">
              I am 15+ and I have read and agree to the
              <a @click.stop href="https://www.planetfitness.co.za/privacy-policy/" target="_blank">Privacy Policy</a>
              &amp;
              <span class="pointer font-weight-bold navy--text text-decoration-underline" @click="termsDialog = true"
                >Terms</span
              >
            </span>
          </div>
        </j-checkbox>
      </ValidationProvider>
      <div v-if="!!$slots.terms" class="mb-8 p-text">
        <slot name="terms" />
      </div>

      <v-row v-if="errors != null" align="center" justify="center">
        <j-alert>
          <span v-for="(value, key, index) in errors" :key="key + index">
            <span v-for="(error, indexError) in value" :key="indexError" v-html="displayError(error['message'])">
            </span>
          </span>
        </j-alert>
      </v-row>

      <v-row align="center" justify="center">
        <j-btn large wide type="submit" :color="submitButton.color" :loading="buttonLoading" class="mb-6 mt-2">
          <div :class="submitButton.textColor + '--text'">
            {{ submitButton.text }}
          </div>
        </j-btn>
      </v-row>

      <v-dialog v-model="termsDialog" max-width="500" transition="dialog-bottom-transition" class="limited-height">
        <j-card rounded="lg">
          <v-card-text>
            <div class="d-flex justify-end">
              <v-btn icon @click="termsDialog = false" class="mr-n6">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <h1 class="mt-n4 mb-4">Terms</h1>

            <h4>LIMITED LIABILITIES</h4>

            <p>
              To be clear: we will not be liable for any loss arising from your use of the Program or any reliance on
              the information presented on the Website or Program or provided by Planet Fitness and JEFF; we will not be
              liable to you for any loss caused by using our Program or your liability to any third party arising from
              those subjects. This includes but is not limited to bodily injuries, damage to property or any other forms
              of damage and/or loss suffered by you or any third party as a result of your use of the Program; the
              Website or Program may include inaccuracies – in such instances we can’t be held liable or be forced to
              comply with offers that are genuinely (and/or negligently) erroneous; we are not responsible for the
              proper and/or complete transmission of information contained in any electronic communication or of the
              electronic communication itself nor for any delay in its delivery or receipt. Security measures have been
              implemented to ensure the safety and integrity of our systems. However, despite this, information that is
              transmitted over the internet may be susceptible to unlawful access and monitoring; and finally, our
              limited liability applies to all and any kind of loss which we can possibly contract out of under law,
              including direct, indirect, consequential, special or other kinds of losses or claims which you may
              suffer. If any matter results in a valid claim against Planet Fitness and JEFF, the liability of Planet
              Fitness and JEFF will be limited to the Fees paid by the Client in respect of the Program in terms of
              which the claim arose.
            </p>
            <h4>INDEMNITY</h4>
            <p>
              You hereby indemnify and hold us (including our shareholders, directors and employees) harmless against
              any claim by any person for any costs, damages (including, without limitation, indirect, extrinsic,
              special, penal, punitive, exemplary or consequential loss or damage of any kind), penalties, actions,
              judgements, suits, expenses, disbursements, fines or other amounts arising, whether directly or
              indirectly, from a breach of this Agreement by you.
            </p>
          </v-card-text>
        </j-card>
      </v-dialog>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { GYM_BRANCHES } from "@/graphql/queries/gym";
import RegistrationMixin from "@/mixins/RegistrationMixin";

export default {
  name: "RegistrationForm",
  mixins: [RegistrationMixin],
  data() {
    return {
      termsDialog: false,
      idNumberRadio: true,
    };
  },
  computed: {
    passportRules() {
      return !this.idNumber ? "required" : "";
    },
    idRules() {
      return !this.passportNumber ? "required|idNumber" : "";
    },
  },
  apollo: {
    branches: {
      query: GYM_BRANCHES,
      update(data) {
        return [{ id: null, title: "- Not a member -" }, ...data.gymBranches];
      },
    },
  },
};
</script>

<style scoped>
.input-padding {
  padding: 10px;
}
</style>
