<template>
  <div class="d-flex flex-column">
    <j-card class="pa-2 flex-fill j-elevated-3" >
      <div class="d-flex align-center text-left">
        <div class="d-flex flex-column">
          <v-avatar color="grey" size="44">
            <img v-if="group.banner" :src="group.banner" />
            <v-icon v-else>mdi-account-group</v-icon>
          </v-avatar>
        </div>
        <div class="d-flex flex-column flex-fill ml-2 truncate-text">
          <h4 class="planet-grey--text font-weight-medium truncate-text">
            {{ group.title }}
          </h4>
          <div class="p-text">
            Your Club
          </div>
        </div>
        <div class="d-flex align-end">
          <div v-if="position" class="d-flex align-center mr-2">
            <v-btn @click="$router.push('/leaderboard/gym')" fab small class="pl-4">
              <v-icon :color="positionColor" size="36" :class="[{ 'pl-2': position > 9 }]">mdi-decagram</v-icon>
              <span class="badge-position font-weight-bold large-text" :class="[{ 'medium-text': position > 9 }]">{{
                position
              }}</span>
            </v-btn>
          </div>

          <v-btn @click="$router.push(`/group/${decodeId(group.id)}`)" color="primary" fab small class="ml-1 mr-2">
            <v-icon size="28">mdi-account-group</v-icon>
          </v-btn>
        </div>
      </div>
    </j-card>
  </div>
</template>

<script>
import { decodeId } from "@/lib/string";

export default {
  name: "GroupRankingCard",
  props: {
    group: {
      type: Object,
      required: true,
    },
    position: {
      type: [Number, String],
      required: false,
    },
  },
  methods: {
    decodeId(val) {
      return decodeId(val);
    },
  },
  computed: {
    positionColor() {
      switch (this.position) {
        case 1:
          return "yellow-icon";
        case 2:
          return "grey darken-2";
        case 3:
          return "brown lighten-2";
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-position {
  position: relative;
  color: white;
  right: 20px;
}
.medium-text {
  font-size: 18px !important;
}
.large-text {
  font-size: 24px;
}
</style>
