<template>
  <j-card>
    <v-toolbar class="mb-6">
      <v-toolbar-title class="font-weight-medium navy--text mx-3">
        Update goal details
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pb-0">
      <j-alert v-if="missingDetailsForCheckin" type="warning">
        Please complete your goal details to proceed with your check-in.
      </j-alert>
      <p v-if="showJourneyText" class="std-text mb-6">
        Any adjustments you make may change your journey to
        <span class="pink--text font-weight-medium">better</span> help you reach your
        <span class="pink--text font-weight-medium">goals</span>
      </p>
      <ValidationObserver ref="journeyObs">
        <v-form>
          <ValidationProvider name="Main Goal" rules="required" v-slot="{ errors }">
            <j-select
              v-model="form.primaryGoal"
              required
              :items="tags"
              :item-value="(tag) => tag.value"
              :item-text="(tag) => tag.text"
              multiple
              :error-messages="errors"
              chips
              chip-color="exercise" 
              label="Your main goal"
            />
          </ValidationProvider>
          <ValidationProvider name="Fitness Level" rules="required" v-slot="{ errors }">
            <j-select
              v-model="form.fitnessLevel"
              :items="fitnessOptions"
              chips
              chip-color="primary"
              label="Your fitness level"
              :error-messages="errors"
            />
          </ValidationProvider>
          <div class="d-flex flex-row ">
            <ValidationProvider
              name="Current weight"
              rules="required|validDecimal"
              v-slot="{ errors }"
              class="mr-3 half-width"
            >
              <j-text-field v-model="form.weight" type="number" :error-messages="errors" label="Your current weight" />
            </ValidationProvider>
            <ValidationProvider name="Goal weight" rules="required|validDecimal" v-slot="{ errors }" class="half-width">
              <j-text-field v-model="form.goalWeight" type="number" :error-messages="errors" label="Your goal weight" />
            </ValidationProvider>
          </div>
        </v-form>
      </ValidationObserver>
    </v-card-text>
    <v-card-actions class="d-flex justify-end pb-6 pr-6">
      <j-btn tertiary @click="$emit('close')">
        Cancel
      </j-btn>
      <j-btn primary :loading="loading" @click="updateDetails()">
        Update
      </j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { TAGS_QUERY } from "@/graphql/queries/tag";
import { USER_TAGS_MUTATION } from "@/graphql/mutations/tag";
import { UPDATE_PROFILE_MUTATION } from "@/graphql/mutations/account";
import { fitnessOptions } from "@/lib/user";
import JourneyDetailsMixin from "@/mixins/JourneyDetailsMixin";

export default {
  name: "JourneyDetails",
  mixins: [JourneyDetailsMixin],
  props: {
    showJourneyText: {
      type: Boolean,
      default: false,
    },
    currentWeight: {
      type: [Number, String],
      required: false,
    },
    missingDetailsForCheckin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      fitnessOptions: fitnessOptions,
      form: {
        primaryGoal: [],
        fitnessLevel: null,
        weight: null,
        goalWeight: null,
      },
      category: "PRIMARY",
    };
  },
  watch: {
    primaryGoals: function(goal) {
      this.form.primaryGoal = goal;
    },
  },
  created() {
    this.form.fitnessLevel = this.fitnessLevel;
    this.form.weight = this.currentWeight ?? this.weight;
    this.form.goalWeight = this.goalWeight;
  },
  computed: {
    userProfileChanged() {
      return (
        this.form.fitnessLevel != this.fitnessLevel ||
        this.form.weight != this.weight ||
        this.form.goalWeight != this.goalWeight
      );
    },
  },
  methods: {
    async updateDetails() {
      this.$refs.journeyObs.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          if (this.userProfileChanged) {
            this.$apollo.mutate({
              mutation: UPDATE_PROFILE_MUTATION,
              variables: {
                userProfileInput: {
                  fitnessLevel: this.form.fitnessLevel,
                  weight: this.form.weight,
                  desiredWeight: this.form.goalWeight,
                },
              },
              refetchQueries: ["accountQuery"],
            });
          }

          this.$apollo.mutate({
            mutation: USER_TAGS_MUTATION,
            variables: {
              tags: this.form.primaryGoal,
              category: this.category,
            },
            refetchQueries: ["userTags", "recommendedWorkoutJourney"],
          });

          this.loading = false;
          this.$emit("update", this.form.weight);
        }
      });
    },
  },
  apollo: {
    tags: {
      query: TAGS_QUERY,
      variables() {
        return {
          category: this.category,
          showToUser: true,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.tags.edges.map((tag) => {
          return {
            text: tag.node.title,
            value: tag.node.code,
          };
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.half-width {
  width: 50%;
}
</style>
