<template>
  <j-banner-container header="To Do">
    <v-row>
      <v-col v-if="$store.getters.tasks > 0" cols="12">
        <j-card class="j-elevated-1">
          <v-card-actions class="d-flex">
            <todo-items class="pt-4" />
          </v-card-actions>
        </j-card>
      </v-col>
      <v-col v-else>
        <j-alert class="ma-5 mb-0">
          <span class="font-weight-bold std-text blue--text">You're all done for now!</span>
          <p class="std-text blue--text">Look at the To Do button at the top. You'll see a number when you have more to do.</p>
        </j-alert>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import TodoItems from "@/components/todo/TodoItems";

export default {
  name: "Assistant",
  components: {
    TodoItems
  },
};
</script>
