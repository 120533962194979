import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        grey: "#DFE2E5",
        "planet-grey": "#74736e",
        "grey-navy": "#656077",
        "mid-grey": "#858585",
        "background-grey": "#F7F7F9",
        "background-grey-2": "#F5F5F5",
        "background-grey-3": "#F0EFF3",
        "font-color": "#2c3e50",
        
        //vuetify defaults
        primary: "#005A9E", // primary and secondary the same
        secondary: "#005A9E",
        error: "#FC3A64",
        info: "#4E4278",
        success: "#07A693",
        warning: "#C29400",
        "grey-info": "#747678",

        //primary colors
        white: {
          base: "#FFFFFF",
          lighten2: "#FFFFFF",
          darken2: "#ebe9ef"
        },
        black: "#222",
        "light-grey": "#DFE2E5",
        navy: {
          base: "#005A9E",
          lighten2: "#5294c4",
          darken2: "#044f87",
          lighten3: "#75bef3",
        },
        blue: "#005A9E",
        'light-blue': "#008fd5",
        pink: {
          base: "#FC3A64",
          lighten2: "#ff7c98",
          darken2: "#f51344",
        },
        red: "#FC3A64",
        purple: "#99759B",
        orange: "#EB8948",
        'light-yellow': "#FDDE5B",
        yellow: {
          base: "#FFC50B",
          lighten2: "#ffdf76",
          darken2: "#ffb305",
        },
        green: {
          base: "#06A77D",
          lighten2: "#54cdbe",
          darken2: "#079d89",
        },
        gold: {
          base: "#D4AF37"
        },
        // grey: "#656077",
        "darker-grey": "#666666",
        "whatsapp-green": "#25D366",
        "facebook-blue": "#1877F2",
        
        //plans
        "jeff-life": "#2EB8A7",
        challenges: "#FFC50B",
        "one-on-one": "#F73A64",

        //dots
        exercise: "#008fd5",
        nutrition: "#06A77D",
        mindset: "#FFD13C",
        sleep: "#968abd",
        hydration: "#60c8e8",
        rest: "#acaab2",

        //backgrounds
        "blue-background": "#bfd0d9",
        "grey-background": "#f4f4f4",
        "pink-background": "#FED7E0",
        "red-background": "#FED7E0",
        "yellow-background": "#FFF3CE",
        "green-background": "#D0F6F2",

        //icons
        "blue-icon": "#4E4278",
        "grey-icon": "#929496",
        "pink-icon": "#FC3A64",
        "red-icon": "#FC3A64",
        "yellow-icon": "#FFC509",
        "green-icon": "#16D3BC",
      },
    },
  },
});
