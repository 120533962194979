<template>
  <j-banner-container header="Classes" md="6" use-header-in-link>
    <v-card v-if="user.userProfile.gymBranch" class="d-flex text-left mx-2 mb-5 pa-3" elevation="4" rounded>
      <v-img
        v-if="user.userProfile.gymBranch.image"
        :src="user.userProfile.gymBranch.image"
        max-width="150"
        class="rounded-lg flex-shrink-1"
      />
      <div class="ml-5 flex-shrink-0" style="width: 60%">
        <div class="gym-title d-flex align-center">
          <v-autocomplete
            v-if="editGymbranch"
            v-model="selectedBranch"
            :items="branches"
            rounded
            filled
            placeholder="Gym"
            @change="changeGym"
          />
          <div v-else>
            <strong :class="{ 'gym-title-large': $vuetify.breakpoint.mdAndUp }">{{
              user.userProfile.gymBranch.title
            }}</strong>
          </div>
          <j-btn
            tertiary
            x-small
            fab
            narrow
            elevation="4"
            class="background-grey ml-2"
            @click="editGymbranch = !editGymbranch"
            :class="{ 'mt-n8': editGymbranch }"
          >
            <v-progress-circular
              v-if="gymBranchLoading"
              indeterminate
              color="primary"
              size="12"
              width="2"
            ></v-progress-circular>
            <v-icon v-else size="14" color="navy">mdi-pencil</v-icon>
          </j-btn>
        </div>
        <div v-if="user.userProfile.gymBranch.phone" class="mt-1 p-text font-weight-bold">
          <a class="text-decoration-none" :href="'tel:' + user.userProfile.gymBranch.phone"
            >{{ user.userProfile.gymBranch.phone | southAfricanNumberFormat }}
          </a>
        </div>
        <div v-if="user.userProfile.gymBranch.address" class="mt-2 sm-text">
          <a
            :href="'https://maps.google.com/?q=Planet Fitness ' + user.userProfile.gymBranch.address"
            target="_blank"
            >{{ user.userProfile.gymBranch.address }}</a
          >
        </div>
      </div>
    </v-card>
    <!-- no gym branch, let the user select here as well -->
    <v-card v-else class="text-left mx-2 mb-5 pa-5" elevation="4" rounded>
      <p class="font-weight-bold">Select a Planet Fitness branch to view the timetable</p>
      <j-autocomplete
        v-if="branches"
        v-model="selectedBranch"
        :items="branches"
        placeholder="- Search Branches -"
        :loading="gymBranchLoading"
        @change="changeGym"
      />
    </v-card>
    <v-expansion-panels
      v-if="gymTimetable && Object.keys(gymTimetable).length > 0"
      v-model="selectedDayIndex"
      focusable
    >
      <v-expansion-panel v-for="(day, i) in daysOfWeek" :key="'panel' + i">
        <v-expansion-panel-header class="p-text font-weight-bold">{{ day | titleize }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <thead>
              <tr class="p-text">
                <th>Time</th>
                <th>Class</th>
                <th>With</th>
                <th>Studio</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in gymTimetable[day]" :key="event.id">
                <td>
                  <span class="sm-text">{{ event.StartTime.substr(0, 5) }}</span>
                </td>
                <td>
                  <span class="sm-text">{{ event.ClassName }}</span>
                </td>
                <td>
                  <span class="sm-text">{{ event.TrainerName }}</span>
                </td>
                <td>
                  <span class="sm-text">{{ event.StudioName }}</span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- FRESH events -->
    <div v-if="!$apollo.loading && freshClasses && freshClasses.length > 0" class="text-left mt-4">
      <v-row>
        <v-col>
          <div class="text-center">
            <h2 class="navy--text mb-2">This week's fresh classes</h2>
          </div>
          <v-row>
            <v-col v-for="(item, index) in freshClasses" :key="index" cols="12">
              <event-card
                :event="item"
                :activityLogged="item.useractivitySet.edges && item.useractivitySet.edges.length < 1"
                :show-time="false"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- View On Demand Library -->

    <div class="d-flex justify-center">
      <j-btn tertiary narrow to="/on-demand" small class="mx-2 my-2">
        <span>On Demand Library</span>
        <v-icon small class="ml-1 mb-1">mdi-animation-play</v-icon>
      </j-btn>
    </div>
  </j-banner-container>
</template>

<script>
import { mapGetters } from "vuex";
import { GYM_TIMETABLE, GYM_BRANCHES } from "@/graphql/queries/gym";
import { FRESH_CLASSES } from "@/graphql/queries/home";
import { UPDATE_PROFILE_MUTATION } from "@/graphql/mutations/account";
import EventCard from "@/components/events/EventCard";

export default {
  name: "Classes",
  components: {
    EventCard,
  },
  data() {
    return {
      branches: [],
      editGymbranch: false,
      selectedBranch: null,
      freshClasses: [],
      selectedDayIndex: 0,
      daysOfWeek: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"],
      gymTimetable: {},
      gymBranchLoading: false,
    };
  },
  mounted() {
    this.selectedDayIndex = this.$moment().day() - 1;
    if (this.selectedDayIndex === -1) this.selectedDayIndex = 0;
    this.selectedBranch = this.user.userProfile.gymBranch?.id;
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async changeGym() {
      this.editGymbranch = false;
      this.gymBranchLoading = true;
      let data = {
        gymBranch: this.selectedBranch,
      };
      await this.$apollo.mutate({
        mutation: UPDATE_PROFILE_MUTATION,
        variables: {
          userProfileInput: data,
        },
        refetchQueries: ["accountQuery"],
      });
      // nasty hack to keep spinning while we refetch acccountQuery. Newer versions of apollo has awaitRefetchQueries
      setTimeout(() => (this.gymBranchLoading = false), 3000);
    },
  },
  apollo: {
    branches: {
      query: GYM_BRANCHES,
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.gymBranches.map((element) => {
          return {
            text: element.title,
            value: element.id,
          };
        });
      },
    },
    gymTimetable: {
      query: GYM_TIMETABLE,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          gymId: this.user.userProfile.gymBranch.id,
        };
      },
      skip() {
        return !this.user.userProfile.gymBranch?.id;
      },
    },
    freshClasses: {
      query: FRESH_CLASSES,
      fetchPolicy: "network-only",
      variables() {
        return {
          scheduledDate_Lte: this.$moment().endOf("isoweek").subtract(1, "days"),
          scheduledDate_Gte: this.$moment().startOf("isoweek").subtract(1, "days"),
          first: 12,
          featureForWeek: true,
        };
      },
      update(data) {
        return data.paginatedEvents.edges.map((edge) => edge.node);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.gym-title,
.gym-subtitle {
  color: var(--v-navy-base);
}
.v-expansion-panel-header--active {
  color: var(--v-navy-base);
  font-size: 20px;
  font-weight: bold;
}
.gym-title {
  font-size: 20px;
  line-height: 110%;
}
.gym-title-large {
  font-size: 30px;
  line-height: 110%;
}
.gym-subtitle {
  font-size: 20px;
}
</style>
