import Vue from "vue";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import VueApollo from "vue-apollo";
import { store } from "../store";
import { createUploadLink } from "apollo-upload-client";
import axios from "axios";
import { buildAxiosFetch } from "@lifeomic/axios-fetch";

const httpLink = new createUploadLink({
  // URL to graphql server, you should use an absolute URL here
  uri: process.env.VUE_APP_API_ENDPOINT + "/graphql/",
  fetch: buildAxiosFetch(axios, (config, input, init) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
  })),
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const accessToken = localStorage.getItem("accessToken") || store.getters.accessToken;
  operation.setContext({
    headers: {
      Authorization: accessToken ? `JWT ${accessToken}` : null,
      "frontend-context": "Client",
    },
  });

  return forward(operation);
});

// function refreshAccessToken(refreshToken, userId) {
//   return apolloClient.mutate({
//     mutation: REFRESH_ACCESS_TOKEN_MUTATION,
//     variables: {
//       refreshToken,
//       userId
//     }
//   })
// }

const link = authMiddleware.concat(httpLink);

// create the apollo client
const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

// install the vue plugin
Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
