<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="8" align="center" justify="center" class="pb-0">
        <div class="d-inline-block">
          <div class="successful-sign-up">
            <v-icon color="white" size="70" class="pa-3">mdi-check-bold </v-icon>
          </div>
          <v-card-title class="text-heading font-weight-bold secondary--text pa-2 white-space-pre">
            <span v-if="product && product.isRecurring">Successfully signed up for {{ product.title }} </span>
            <span v-if="product && !product.isRecurring">{{ product.title }} successfully purchased</span>
          </v-card-title>
        </div>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="8">
        <v-card class="card-radius mt-0">
          <v-row class="mt-0 pt-4">
            <v-col class="py-0">
              <ValidationObserver ref="observer">
                <v-form @submit.prevent="submit">
                  <v-card-text class="py-0">
                    <div class="d-flex pb-2">
                      <v-chip color="green-background" dense text-color="alert-green" class="font-16 mx-auto">
                        <strong>Step 3 of 3</strong>
                      </v-chip>
                    </div>

                    <v-card-text class="py-0 px-0">
                      <h2 class="secondary--text mb-4">Confirm your address</h2>
                      <billing-details
                        @billingSaved="
                          billingSaved = $event;
                          showError = false;
                        "
                      />
                    </v-card-text>

                    <h2 class="secondary--text pt-2">Choose your password</h2>
                    <ValidationProvider
                      id="password"
                      v-slot="{ errors }"
                      name="password"
                      rules="required|verify_password"
                    >
                      <v-text-field
                        v-model="password"
                        outlined
                        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                        :type="showPassword ? 'text' : 'password'"
                        label="Password"
                        hint="Please include an uppercase letter, lowercase letter, number and special character"
                        :error-messages="errors"
                        @click:append="showPassword = !showPassword"
                        class="pt-4"
                      />
                    </ValidationProvider>
                  </v-card-text>

                  <v-row v-if="errors != null" align="center" justify="center" class="mx-6">
                    <v-alert color="#FFF7DB">
                      <span v-for="(value, key, index) in errors" :key="key + index">
                        <span v-for="(error, indexError) in value" :key="indexError">
                          <span v-if="error.message">{{ error.message }}</span>
                          <span v-else>{{ error }}</span>
                        </span>
                      </span>
                    </v-alert>
                  </v-row>

                  <v-alert v-if="showError" type="warning" outlined class="mx-4">
                    Please enter your address before continuing or
                    <strong
                      >make sure that you have clicked on the Google address, so that the address lines beneath are
                      populated.</strong
                    >
                    <br /><br />
                    If you need assistance,
                    <router-link to="/contact" class="warning--text font-weight-bold"
                      >contact our support team</router-link
                    >.
                  </v-alert>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      large
                      rounded
                      class="font-weight-bold px-10"
                      type="submit"
                      color="primary"
                      :loading="buttonLoading"
                    >
                      <strong class="white--text">Let's go!</strong>
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-form>
              </ValidationObserver>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BILLING_PRODUCTS_QUERY, PASSWORD_TOKEN_QUERY } from "@/graphql/queries/store";
import { RESET_PASSWORD_MUTATION } from "@/graphql/mutations/account";
import BillingDetails from "@/components/store/blocks/BillingDetails";

export default {
  name: "ColdCheckoutPassword",
  components: {
    BillingDetails,
  },
  data() {
    return {
      password: "",
      showPassword: false,
      buttonLoading: false,
      errors: null,
      billingSaved: false,
      showError: false,
    };
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          if (!this.billingSaved) {
            return (this.showError = true);
          }
          this.buttonLoading = true;
          this.handlePasswordUpdate();
        }
      });
    },
    handlePasswordUpdate() {
      this.$apollo
        .mutate({
          mutation: RESET_PASSWORD_MUTATION,
          variables: {
            newPassword: this.password,
          },
        })
        .then((result) => {
          this.errors = null;
          if (result.data.resetPassword.success === true) {
            sessionStorage.removeItem("redirectPath");
            this.$store.dispatch("resetCheckout");

            // let's think. is there any scenario where a cold checkout password shouldbn't redirect you to "onboarding"?
            // i don't think so. hard code it
            //window.location.href = path
            sessionStorage.setItem("registerCompleted", 1);
            window.location.href = "/checkin/goals";
          } else {
            this.errors = [result.data.resetPassword.validationErrors];
            this.buttonLoading = false;
          }
        });
    },
  },
  apollo: {
    product: {
      query: BILLING_PRODUCTS_QUERY,
      variables() {
        return {
          code: this.$route.query.code || this.$route.params.code,
          withPackages: false,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.billingProducts.edges.length > 0) {
          let product = data.billingProducts.edges[0].node;
          return product;
        }
        return null;
      },
    },
    passwordResetToken: {
      query: PASSWORD_TOKEN_QUERY,
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>

<style scoped>
.white-space-pre {
  white-space: pre;
}
</style>
