<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <j-alert v-if="isDiscoveryGated" type="info">
          Please fill out your Discovery Vitality information below. Then you'll be able to continue to the rest of the
          {{ $appName }} Web App via the menu navigation.
        </j-alert>
        <j-alert v-if="passedDiscoveryGate" type="info">
          Continue to the rest of the {{ $appName }} Web App via the menu navigation.
        </j-alert>
      </v-col>
    </v-row>
    <j-banner-container
      header="Discovery Vitality"
      md="5"
      class="my-2"
    >
      <v-row v-if="me.userProfile && !inActive && !edit" class="text-left">
        <v-col cols="12">
          <j-card :disabled="edit" class="pb-4">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <j-alert type="info">
                    Earn Vitality points by doing {{ $appName }} workouts -
                    <a href="https://www.jeff.fitness/pages/discovery-vitality" target="_blank">Find out more</a>
                  </j-alert>
                </v-col>
              </v-row>
              <ValidationObserver ref="obs" v-slot="{ invalid }">
                <v-form ref="form" v-model="isValid">
                  <v-row>
                    <v-col cols="12" class="mt-3 py-0">
                      <strong>Are you a Discovery Vitality member?</strong>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <v-radio-group v-model="data.is_discovery_member" row>
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="saved">
                    <v-col cols="12" class="my-0 py-0">
                      <j-alert type="success">
                        Your information has been successfully saved and your
                        <router-link to="/account/billing" class="success--text text-decoration-underline"
                          >next payment will be discounted</router-link
                        >.</j-alert
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="data.is_discovery_member">
                    <v-col cols="12" class="my-0 py-0 mb-6"
                      >Please provide your Discovery Vitality identification information to activate your
                      benefits and pay less on your subscription.</v-col
                    >
                  </v-row>
                  <v-row v-if="data.is_discovery_member">
                    <v-col cols="12" class="my-0 py-0">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider mode="lazy" name="DOB" rules="required" v-slot="{ errors }">
                            <j-text-field
                              v-model="data.date_of_birth"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              :error-messages="errors"
                              label="Date of Birth"
                            ></j-text-field>
                          </ValidationProvider>
                        </template>

                        <v-date-picker
                          ref="picker"
                          v-model="data.date_of_birth"
                          :max="$moment().subtract(15, 'years').format('YYYY-MM-DD')"
                          min="1920-01-01"
                          @change="save_date"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.is_discovery_member">
                    <v-col cols="12" class="my-0 py-0">
                      <ValidationProvider mode="lazy" name="ID Number" :rules="id_rules" v-slot="{ errors }">
                        <j-text-field
                          v-model="data.id_number"
                          :error-messages="errors"
                          label="Your 13 digit ID number"
                          class="pt-0 mt-0"
                        ></j-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.is_discovery_member">
                    <v-col cols="12" class="my-0 py-0">
                      <ValidationProvider name="Passport Number" :rules="passport_rules" v-slot="{ errors }">
                        <j-text-field
                          ref="passport"
                          :error-messages="errors"
                          v-model="data.passport_number"
                          placeholder=""
                          class="pt-0 mt-0"
                          label="or Passport Number"
                        ></j-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="data.is_discovery_member" cols="12" class="my-0 py-0">
                      <ValidationProvider
                        name="Errors Checked"
                        :rules="data.is_discovery_member ? 'required' : ''"
                        v-slot="{ errors }"
                      >
                        <j-checkbox
                          class="mt-0"
                          v-model="data.errorChecked"
                          :error-messages="errors"
                          @change="updateErrorsChecked"
                          label="I have checked the Discovery Vitality identification information I have provided to make sure it is correct"
                        ></j-checkbox>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.is_discovery_member">
                    <v-col cols="12" class="my-0 py-0">
                      <ValidationProvider
                        name="Consent"
                        :rules="data.is_discovery_member ? 'required' : ''"
                        v-slot="{ errors }"
                      >
                        <j-checkbox
                          class="mt-0"
                          v-model="data.consent"
                          :error-messages="errors"
                          @change="updateConsent"
                          label="I consent to my information being shared with Discovery to verify my Vitality membership"
                        ></j-checkbox>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row v-if="error">
                    <v-col cols="12" class="my-0 py-0">
                      <v-alert type="warning">
                        {{ errorMessage }}
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row v-if="data.is_discovery_member">
                    <v-col cols="12" class="my-0 py-0">
                      <div class="d-flex justify-center">
                        <j-btn
                          :tertiary="invalid"
                          :secondary="!invalid"
                          class="mt-1"
                          large
                          rounded
                          wide
                          :loading="saving"
                          :disabled="invalid"
                          @click="save()"
                        >
                          Save Vitality Info
                        </j-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </ValidationObserver>
            </v-card-text>
          </j-card>
        </v-col>
      </v-row>
    </j-banner-container>
  </div>
</template>

<script>
import { UPDATE_PROFILE_MUTATION } from "@/graphql/mutations/account";

import "vue-advanced-cropper/dist/style.css";
import { userData } from "@/lib/user";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "Discovery",
  mixins: [UserMixin],
  data() {
    return {
      isValid: false,
      error: false,
      errorMessage: "",
      loading: true,
      menu: false,
      errors: null,
      saving: false,
      passedDiscoveryGate: false,
      edit: false,
      saved: false,
      subscription: null,
      data: { ...userData },
    };
  },
  created() {
    const userProfile = this.$store.getters?.user?.userProfile;
    this.data.is_discovery_member = userProfile?.isDiscoveryMember ?? false;
    this.data.date_of_birth = userProfile?.dateOfBirth;
    this.data.id_number = userProfile?.idNumber;
    this.data.passport_number = userProfile?.passportNumber;
    this.data.claim_discount = userProfile?.claimDiscount ?? false;
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    isDiscoveryGated() {
      return this.$store.getters.isDiscoveryMember && !this.$store.getters.providedIdentification;
    },
    passport_rules() {
      return !this.data.id_number && this.data.is_discovery_member ? "required" : "";
    },
    id_rules() {
      return !this.data.passport_number && this.data.is_discovery_member ? "required|idNumber" : "idNumber";
    },
    isMember() {
      return this.isLifer;
    },
    inActive() {
      return this.$store.getters.role == "INACTIVE" || this.$store.getters.inActive;
    },
  },
  methods: {
    save_date(date) {
      this.$refs.menu.save(date);
    },
    clearData() {
      this.data.date_of_birth = null;
      this.data.id_number = "";
      this.data.passport_number = "";
      this.data.errorChecked = null;
      this.data.consent = null;
      this.data.claim_discount = false;
    },
    save() {
      this.saving = true;
      let userData = {
        isDiscoveryMember: this.data.is_discovery_member,
        dateOfBirth: this.data.date_of_birth,
        idNumber: this.data.id_number,
        passportNumber: this.data.passport_number,
        claimDiscount: this.data.claim_discount,
      };

      this.$apollo
        .mutate({
          mutation: UPDATE_PROFILE_MUTATION,
          variables: {
            userProfileInput: userData,
          },
          refetchQueries: ["accountQuery"],
        })
        .then((response) => {
          if (response.data.updateProfile.ok) {
            this.saving = false;
            this.saved = true;

            if (this.isDiscoveryGated) {
              this.passedDiscoveryGate = true;
              this.$toasted.success("Thank you! Use the navigation to get started.", {
                duration: 4000,
                position: "bottom-center",
              });
            }
          }
        });
    },
    updateConsent(val) {
      if (!val) this.data.consent = null;
    },
    updateErrorsChecked(val) {
      if (!val) this.data.errorChecked = null;
    },
  },
};
</script>
