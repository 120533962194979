<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" md="6" xl="5" class="text-center mx-5 px-0 navy--text background-grey-3 fill-height mb-10">
        <div class="text-center pt-4">
          <div class="text-h5 navy--text font-weight-bold mb-4">Choose your Coach</div>
          <div class="p-text text font-weight-bold mb-4 px-4">
            You are signing up for One on One Coaching. Please choose your coach below.
          </div>
          <v-row class="pa-5">
            <v-col cols="12" v-for="coach in coaches" :key="coach.user.id" class="px-5 py-0">
              <choose-coach-listing-card
                @choose="chooseCoach"
                :coach-profile="coach"
                :is-selected="isSelected(coach.user.id)"
                class="mb-4"
              />
            </v-col>
          </v-row>

          <j-btn @click="goNext" :disabled="!selectedCoachId" primary
            ><span v-if="!selectedCoachId">Select a coach</span><span v-else>Continue with {{ coachName }}</span></j-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { decodeId } from "@/lib/string";
import StaffMixin from "@/mixins/StaffMixin";
import ChooseCoachListingCard from "@/components/coach/ChooseCoachListingCard";

export default {
  name: "Coaches",
  mixins: [StaffMixin],
  components: { ChooseCoachListingCard },
  data() {
    return {
      userType: "Coach",
      selectedCoachId: null,
    };
  },
  methods: {
    decodeId(id) {
      return decodeId(id);
    },
    isSelected(userId) {
      return decodeId(this.selectedCoachId) == decodeId(userId);
    },
    chooseCoach(userId) {
      this.selectedCoachId = decodeId(userId);
      window.scrollTo({
        top: document.body.scrollHeight || document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    },
    goNext() {
      sessionStorage.setItem(
        "selectedCoach",
        JSON.stringify({ userId: decodeId(this.selectedCoachId), coachName: this.coachName })
      );

      return this.$router.push(this.$route.query.redirect);
    },
  },
};
</script>

<style lang="scss" scoped>
div.logo-images {
  max-width: 100%;

  div.v-image {
    margin: 12px;
    max-width: 30%;
    flex: unset;
  }
}
</style>
