<template>
  <v-card :style="backgroundGradientStyle" v-bind="$props" v-on="$listeners" class="j-card">
    <v-img v-if="headerImageUrl" :lazy-src="headerImageUrl" :src="headerImageUrl" />
    <slot />
  </v-card>
</template>

<script>
import { gradientStyle } from "@/lib/gradientStyle";
import { VCard } from "vuetify/lib";

export default {
  name: "JCard",
  extends: VCard,
  props: {
    headerImageUrl: {
      type: String,
    },
    gradient: {
      type: Array,
      required: false,
    },
  },
  computed: {
    backgroundGradientStyle() {
      return gradientStyle(this.gradient, 90);
    },
  },
};
</script>
