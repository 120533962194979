<template>
  <div>
    <div v-if="$apollo.loading">
      <v-row justify="center">
        <v-col cols="12" md="6"><v-skeleton-loader type="card-avatar" /> </v-col>
      </v-row>
    </div>
    <j-banner-container v-else header="Notifications">
      <div class="pa-4" v-if="notifications.edges.length == 0">
        <j-alert>No notifications</j-alert>
      </div>

      <div class="text-left pa-4" v-else>
        <div v-for="notification in notifications.edges" :key="notification.node.id">
          <notification-card :notification="notification.node" class="mb-4" />
        </div>
      </div>
    </j-banner-container>
  </div>
</template>

<script>
import { NOTIFICATIONS_QUERY } from "@/graphql/queries/notifications";
import NotificationCard from "@/components/notifications/NotificationCard";

export default {
  name: "Notifications",
  components: {
    NotificationCard,
  },
  apollo: {
    notifications: {
      query: NOTIFICATIONS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          // an abitrary hard limit
          first: 50,
        };
      },
    },
  },
};
</script>
