<template>
    <j-banner-container
      header="Program History"
      :backLink="`/p/${userId}`"
      md="6"
      slot-text-align="left"
    >
      <div v-if="$apollo.loading">
        <v-skeleton-loader type="list-item-avatar-two-line" class="py-3"/>
        <v-skeleton-loader type="divider" />
        <v-skeleton-loader type="list-item-avatar-two-line" class="py-3" />
      </div>
      <v-row v-else justify="center" align="center">
        <v-col cols="12">
          <div v-if="activeNutritionBlock">
            <j-subsection-row v-if="activeNutritionBlock" title="Currently active" class="mb-3" :showSeeAll="false">
              <j-block-header-card
                class="pointer"
                :title="activeNutritionBlock.title"
                :avatar-picture="activeNutritionBlock.leadInstructor.userProfile.profilePicture"
                :sub-title="activeNutritionBlock.leadInstructor.firstName"
                :user-profile-list="activeNutritionBlock.userList"
                accent-color="green"
                :start-date="activeNutritionBlock.startDate"
                @click.native="navigateNutritionBlock(activeNutritionBlock.id)"
              />
              <hr class="mt-4" />
            </j-subsection-row>
          </div>
          <div v-if="workoutBlocks.length > 0">
            <j-subsection-row
              v-for="workoutBlock in workoutBlocks"
              :key="workoutBlock.id"
              :title="format_date(workoutBlock)"
              class="mb-3"
              :showSeeAll="false"
            >
              <workout-block-overview
                class="pointer"
                :workout-block="workoutBlock.block"
                :subscription-block="workoutBlock"
                :show-unit="false"
                :start-date="workoutBlock.startDate"
                type="PROGRAM"
                @click.native="navigateBlock(workoutBlock.block.id)"
              />
              <hr class="mt-4" />
            </j-subsection-row>
          </div>
        </v-col>
      </v-row>
    </j-banner-container>
</template>

<script>
import { mapGetters } from "vuex";
import { WORKOUT_SUBSCRIPTION_BLOCKS_QUERY } from "@/graphql/queries/workoutblock";
import { fancy_date } from "@/lib/fancy_date";
import WorkoutBlockOverview from "@/components/workoutblocks/cards/WorkoutBlockOverview";
import JBlockHeaderCard from "@/layouts/cards/JBlockHeaderCard";
import BlockMixin from "@/mixins/BlockMixin";
import NutritionBlockMixin from "@/mixins/nutrition/NutritionBlockMixin";
import { decodeId } from "@/lib/string";

export default {
  name: "UserProgram",
  mixins: [BlockMixin, NutritionBlockMixin],
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    WorkoutBlockOverview,
    JBlockHeaderCard,
  },
  data() {
    return {
      nutritionSubscriptionBlocks: [],
      workoutSubscriptionBlock: null,
      workoutBlocks: [],
      openManageBlockDialog: false,
      openManageNutritionBlockDialog: false,
      nutritionSubscriptionBlock: null,
    };
  },
  computed: {
    ...mapGetters(["activeNutritionBlock", "upcomingNutritionBlock"]),
  },
  methods: {
    navigateBlock(workoutBlockId) {
      this.$router.push(`/program/${decodeId(workoutBlockId)}`);
    },
    navigateNutritionBlock(nutritionBlockId) {
      this.$router.push(`/nutrition/${decodeId(nutritionBlockId)}`);
    },
    format_date(workoutBlock) {
      if (this.isActive(workoutBlock.startDate, workoutBlock.endDate)) {
        return "Currently active";
      }

      if (workoutBlock.endDate <= this.$moment().format("YYYY-MM-DD")) {
        const endDateString = fancy_date(workoutBlock.endDate, "D MMM", false);
        return "Ended on " + endDateString;
      }

      const datestring = fancy_date(workoutBlock.startDate, "D MMM", false);
      return "Starts on " + datestring;
    },
    isActive(startDate, endDate) {
      const s = this.$moment(startDate, "YYYY-MM-DD");
      const e = this.$moment(endDate, "YYYY-MM-DD");
      const now = this.$moment();
      return now.isBetween(s, e, "days", "[)");
    },
  },
  apollo: {
    workoutSubscriptionBlocks: {
      query: WORKOUT_SUBSCRIPTION_BLOCKS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          orderBy: "-start_date",
          userId: this.userId,
        };
      },
      result(response) {
        this.workoutBlocks = [];
        if (response.data && !response.loading) {
          const edges = response.data.workoutSubscriptionBlocks.edges;
          edges.forEach((element) => {
            this.workoutBlocks.push(element.node);
          });
        }
      },
    },
  },
};
</script>

<style scoped></style>
