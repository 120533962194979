<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" md="6" class="background-grey-3 fill-height">
        <h1 class="mt-4 text-center text-h5 navy--text font-weight-medium">Choose Package</h1>
        <div class="mt-1 mb-2 text-center p-text grey-navy--text font-weight-medium">
          To get your plan, please sign up for a subscription
        </div>

        <div class="d-flex justify-center align-baseline">
          <div class="font-weight-bold" :class="!threeMonthBilling ? 'pink--text' : 'grey-navy--text'">
            1-month billing
          </div>
          <v-switch
            v-model="threeMonthBilling"
            color="exercise" 
            :ripple="false"
            class="ml-4 mr-3"
            @change="$store.dispatch('updatePreferredVariant', variantCode)"
          />
          <div class="text-center" :class="threeMonthBilling ? 'pink--text' : 'grey-navy--text'">
            <div class="font-weight-bold">3-month billing</div>
            <div class="heading-7">(up to 14% off)</div>
          </div>
        </div>

        <div class="d-flex flex-column justify-center mt-xl-5">
          <product-card code="jeff-club" :threeMonths="threeMonthBilling" color="yellow" class="mx-2 mt-6 mx-xl-10" />

          <product-card code="1-on-1-grad" :threeMonths="threeMonthBilling" color="blue" class="mx-2 mt-6 mx-xl-10" />

          <product-card code="1-on-1" :threeMonths="threeMonthBilling" color="pink" class="mx-2 mt-6 mx-xl-10" />
        </div>

        <need-more-time />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductCard from "@/components/start/ProductCard";
import NeedMoreTime from "@/components/shared/sections/NeedMoreTime";
import Cookies from "js-cookie";

export default {
  name: "Pricing",
  components: {
    ProductCard,
    NeedMoreTime,
  },
  data() {
    return {
      threeMonthBilling: false,
    };
  },
  created() {
    const redirectProductCode = Cookies.get("redirectProductCode");
    if (redirectProductCode) {
      Cookies.remove("redirectProductCode");
      return this.$router.push(`/checkout/join?code=${redirectProductCode}`);
    }
  },
  computed: {
    variantCode() {
      return this.threeMonthBilling ? "quarterly" : "standard";
    },
  },
};
</script>

<style lang="scss" scoped>
.right-arrow {
  transform: rotate(180deg);
}
</style>
