<template>
  <j-main-banner-container header="Support">
    <v-row>
      <v-col>
        <j-btn tertiary small narrow to="/help" class="mx-1 mb-2">
          Contact
          <v-icon size="17" color="blue">mdi-email</v-icon>
        </j-btn>
        <j-btn tertiary small narrow to="/today/to-do/tips" class="mx-1 mb-2">
          Tips
          <v-icon size="17" color="blue">mdi-strategy</v-icon>
        </j-btn>
        <j-btn tertiary small narrow to="/account" class="mx-1 mb-2">
          settings
          <v-icon size="17" color="blue">mdi-cog</v-icon>
        </j-btn>
      </v-col>
    </v-row>

    <j-card class="j-elevated-1 my-2 mx-auto" width="90%" align="left">
      <v-expansion-panels accordion class="rounded-xl">
        <v-expansion-panel v-if="$store.getters.role != 'ONE_ON_ONE'">
          <v-expansion-panel-header class="blue--text">
            <div class="d-flex">
              <v-icon size="22" color="blue">mdi-account-star</v-icon>
              <h2 class="heading-6 ml-2">COACH</h2>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-row">
              <div class="flex-fill text-left">
                <p>Add a world-class personal coach on top of everything within JEFF Club!</p>
              </div>
            </div>
            <div>
              <j-btn
                :to="
                  $store.getters.subscription && $store.getters.subscription.billingOrder
                    ? '/account/billing?coaching=1'
                    : '/checkout/1-on-1'
                "
              >
                Add 1-on-1 coach
              </j-btn>
            </div>
            <div class="mt-2">
              <j-btn tertiary href="https://www.jeff.fitness/pages/1-on-1-personal-coaching-program" target="_blank">
                Learn More
              </j-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-else-if="coach && coach.userProfile">
          <v-expansion-panel-header class="blue--text">
            <div class="d-flex">
              <v-icon size="22" color="blue">mdi-account-star</v-icon>
              <h2 class="heading-6 ml-2">COACH</h2>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-row">
              <v-avatar class="mr-4" size="133" v-if="coach.userProfile.profilePicture">
                <v-img :lazy-src="coach.userProfile.profilePicture" :src="coach.userProfile.profilePicture" />
              </v-avatar>
              <div class="flex-fill text-left">
                <div class="d-flex flex-row">
                  <h4>{{ coach.firstName }} {{ coach.lastName }}</h4>
                </div>
                <div v-if="coach.userProfile && coach.userProfile.phone">
                  <p class="p-text mb-1">WhatsApp number</p>
                  <p class="p-text">{{ coach.userProfile.phone }}</p>
                </div>
              </div>
            </div>
            <j-btn
              v-if="coach.userProfile && coach.userProfile.phone"
              tertiary
              class="mt-5"
              target="_blank"
              :href="`https://api.whatsapp.com/send?phone=${coach.userProfile.phone}`"
            >
              <strong>message on whatsapp</strong>
              <v-icon color="whatsapp-green" class="ml-1">mdi-whatsapp</v-icon>
            </j-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="blue--text">
            <div class="d-flex">
              <v-icon size="22" color="blue">mdi-whistle</v-icon>
              <h2 class="heading-6 ml-2">EXPERTS</h2>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              <span v-if="$store.getters.role != 'ONE_ON_ONE'">When you have a 1-on-1 coach, you</span>
              <span v-else>You</span>
              have access to all our team members through your coach.
            </p>
            <p>Each team member has various specialisations.</p>
            <p>Ask your coach if you'd like deeper insights on injuries, nutrition, and certain conditions.</p>
            <j-btn tertiary href="https://www.jeff.fitness/pages/meet-the-team">
              <strong>Read about the team</strong>
            </j-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </j-card>

    <j-card class="j-elevated-1 my-2 mx-auto my-5" width="90%" align="left">
      <v-expansion-panels accordion class="rounded-xl">
        <v-expansion-panel>
          <v-expansion-panel-header class="blue--text">
            <div class="d-flex">
              <v-icon size="22" color="blue">mdi-facebook</v-icon>
              <h2 class="heading-6 ml-2">FACEBOOK</h2>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <join-facebook-content />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="blue--text">
            <div class="d-flex">
              <v-icon size="22" color="blue">mdi-whatsapp</v-icon>
              <h2 class="heading-6 ml-2">WHATSAPP</h2>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="text-left">Join your nearest community or subscribe to our event notifications</p>
            <notifications />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </j-card>
  </j-main-banner-container>
</template>

<script>
import { mapGetters } from "vuex";
import Notifications from "@/components/support/Notifications";
import JoinFacebookContent from "@/components/shared/blocks/JoinFacebookContent";

export default {
  name: "Support",
  components: {
    Notifications,
    JoinFacebookContent,
  },
  computed: {
    ...mapGetters(["coach"]),
  }
};
</script>

<style lang="scss" scoped>
.top-space {
  margin-top: 1px;
}
</style>
