<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" md="12">
        <j-banner-container
          header="Card Updated"
          md="5"
          class="my-2"
          back-link="/account/billing"
        >
          <div v-if="!result">
            <v-progress-linear :indeterminate="true" />
          </div>
          <div v-else class="px-4">
            <j-alert class="mt-5" type="success">Your card details have been successfully updated.</j-alert>
            <j-alert type="info" v-if="result.transitioningFromPayfast" class="mt-6"
              >Your card is active and added, nice! <br /><br />
              You might receive an automated email from PayFast, our old subscription provider. Their email will say
              your subscription is cancelled with them. You can ignore that email.
            </j-alert>
            <j-alert type="info" v-if="result.message" class="mt-6">{{ result.message }} </j-alert>
          </div>
        </j-banner-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { FINALIZE_CARD_TOKENIZATION_MUTATION } from "@/graphql/mutations/store";

export default {
  name: "TokenizeCardResult",
  data() {
    return {
      result: null,
    };
  },
  async mounted() {
    let cardToken = this.$route.query.id;
    let result = await this.$apollo.mutate({
      mutation: FINALIZE_CARD_TOKENIZATION_MUTATION,
      variables: {
        tokenId: cardToken,
      },
    });
    this.result = result.data.finalizeCardTokenizationMutation;
  },
};
</script>
