<template>
  <div>
    <div v-if="showAllPostsToggle" class="d-flex justify-space-between">
      <h4 class="planet-grey--text">{{ heading }}</h4>
      <v-spacer />
      <div class="d-flex">
        <div v-if="selectedGroupId" class="pointer mr-2">
          <v-icon @click="showPostCreateForm = !showPostCreateForm"> mdi-plus-circle-outline </v-icon>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" @click.prevent="on.click"> mdi-filter-variant </v-icon>
          </template>

          <j-radio-group
            v-model="showAllPosts"
            @change="toggleAllPosts()"
            class="py-0 my-0 pt-2 post-container-toggle-radio px-2"
          >
            <j-radio label="Only groups you're in" :value="false" />
            <j-radio label="Show all posts" :value="true" />
          </j-radio-group>
        </v-menu>
      </div>
    </div>

    <v-row v-if="showPostCreateForm" class="text-left mt-2">
      <v-col cols="12">
        <!-- i was unable to make a j-autocomplete due to a error in the vuetfy v-autocomplete component -->
        <v-autocomplete
          outlined
          rounded
          append-icon="mdi-chevron-down"
          v-model="selectedGroupId"
          :items="userGroupOptions"
          label="Your Groups"
          hide-details
          class="mb-2"
        />
        <post-form key="group-container-post-form" :group-id="selectedGroupId" @saved="reloadThread()" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row v-if="groupId || activityId || eventId || recipeId">
          <v-col cols="12">
            <post-form
              key="container-post-form"
              :group-id="groupId"
              :activity-id="activityId"
              :event-id="eventId"
              :recipe-id="recipeId"
              @saved="reloadThread()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="$apollo.loading && initialLoading" cols="12">
            <v-skeleton-loader
              v-for="count in 3"
              :key="count"
              type="list-item-avatar-two-line, image"
              height="170"
              class="mt-4"
            />
          </v-col>
          <v-col v-else cols="12">
            <j-card v-if="group && group.pinnedPost" class="j-elevated-5">
              <v-card-title class="py-0 mb-n2 pt-2 secondary--text lighten-2"> Featured </v-card-title>
              <v-card-text class="pt-0 mt-0 pb-1">
                <post-block
                  :post="group.pinnedPost"
                  :highlight-colour="highlightColour"
                  :featured-user-ids="featuredUserIds"
                  :hide-group="hidePostGroupName"
                  :hide-post-link="hidePostLink"
                  pinned-post
                  :groupId="groupId"
                  @reload="reloadThread()"
                />
              </v-card-text>
            </j-card>
            <post-block
              v-for="post in postList"
              :key="post.node.id"
              :post="post.node"
              :groupId="groupId"
              :highlight-colour="highlightColour"
              :featured-user-ids="featuredUserIds"
              :hide-group="hidePostGroupName"
              :hide-post-link="hidePostLink"
              allow-pinned
              @deleted="reloadThread($event)"
              @reload="reloadThread()"
            />
          </v-col>

          <v-col v-if="!isClub" cols="12">
            <j-alert
              >You need to join JEFF Club to see all the posts and get full access to Groups!
              <br />
              <j-btn secondary to="/checkout/jeff-club" class="mt-2">Join JEFF Club</j-btn>
            </j-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <scroll-loader :loader-method="loadmore" :loader-disable="!hasNextPage"><div /></scroll-loader>
  </div>
</template>

<script>
import { POSTS_QUERY } from "@/graphql/queries/post";
import { GROUPS_QUERY, GROUP_QUERY } from "@/graphql/queries/group";
import { UPSERT_MY_SETTINGS_MUTATION } from "@/graphql/mutations/account";
import { decodeId } from "@/lib/string";
import { dedupeNodes, extractNodes } from "@/lib/array";
import PostForm from "@/components/groups/forms/PostForm";
import PostBlock from "@/components/groups/posts/PostBlock";

export default {
  name: "PostContainer",
  components: {
    PostForm,
    PostBlock,
  },
  props: {
    heading: {
      type: String,
      default: "Posts",
    },
    groupId: {
      type: [String, Number],
      required: false,
    },
    activityId: {
      type: [String, Number],
      required: false,
    },
    eventId: {
      type: [String, Number],
      required: false,
    },
    recipeId: {
      type: [String, Number],
      required: false,
    },
    userId: {
      type: [String, Number],
      required: false,
    },
    userPostsOnly: {
      type: Boolean,
      default: false,
    },
    showAllPostsToggle: {
      type: Boolean,
      default: false,
    },
    // we can pass an array of Ids. For each of these IDs, the post container will show
    // a bit differently. Border colour etc. i.e we want to highlight any posts the instructor makes different to normal users
    featuredUserIds: {
      type: Array,
      required: false,
    },
    highlightColour: {
      type: String,
      default: "#211256",
    },
    hidePostGroupName: {
      type: Boolean,
      default: false,
    },
    hidePostLink: {
      type: Boolean,
      default: false,
    },
    onlyShowPublic: {
      type: Boolean,
      default: false,
    },
    dedupeUser: {
      type: Boolean,
      default: false,
    },
    showPinned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      after: "",
      endCursor: "",
      hasNextPage: true,
      posts: [],
      postList: [],
      loading: true,
      initialLoading: true,
      showAllPosts: false,
      groupsImInSwitch: false,
      showPostCreateForm: false,
      selectedGroupId: null,
      userGroupOptions: [],
    };
  },
  created() {
    if (this.showAllPostsToggle) {
      const groupSetting = this.$store.getters.user?.userSettings?.find((obj) => obj.key == "only-groups-in");
      if (groupSetting) {
        this.showAllPosts = !groupSetting.value;
        return;
      }
      this.showAllPosts = true;
    }
  },
  computed: {
    isClub() {
      return ["ONE_ON_ONE", "CLUB"].includes(this.$store.getters.role);
    },
  },
  methods: {
    toggleAllPosts() {
      // skeleton load again!
      this.initialLoading = true;
      this.posts = [];
      this.postList = [];
      this.endCursor = "";
      this.upsertSetting(!this.showAllPosts);
    },
    loadmore() {
      if (!this.loading) {
        this.after = this.endCursor;
      }
    },
    getId(idString) {
      return decodeId(idString);
    },
    async reloadThread(deletedPostId = null) {
      this.loading = true;
      if (deletedPostId) {
        this.postList = this.postList.filter((post) => decodeId(post.node.id) !== decodeId(deletedPostId));
      }
      // No, we are not resetting the entire postList. If we do so, the UX is terrible and the page jumps as all posts are destroyed and rebuilt.
      // See below apollo query for how we a) dedupe b) sort by date
      //this.postList = [];
      this.post = [];
      this.endCursor = "";
      this.after = "";
      this.hasNextPage = true;

      await Object.values(this.$apollo.queries).forEach((query) => query.refetch());
    },
    upsertSetting(value) {
      this.$apollo.mutate({
        mutation: UPSERT_MY_SETTINGS_MUTATION,
        variables: {
          settingName: "only-groups-in",
          value: value,
        },
        refetchQueries: ["accountQuery"],
      });
    },
  },
  apollo: {
    posts: {
      query: POSTS_QUERY,
      variables() {
        let input = { after: this.after, first: 10 };
        if (this.groupId) {
          input["forGroupId"] = this.groupId;
        } else if (this.activityId) {
          input["forActivityId"] = this.activityId;
        } else if (this.eventId) {
          input["forEventId"] = this.eventId;
        } else if (this.recipeId) {
          input["forRecipeId"] = this.recipeId;
        }

        // if we are specifying to show all posts, then we can't limit to user_id
        if (this.userId && !this.showAllPosts) {
          input["forUserId"] = this.userId;
        }

        if (this.userPostsOnly) {
          input["forUserPostsId"] = this.userId;
        }

        // if showing all posts, can't show anything from private groups.
        if (this.showAllPosts) {
          input["showAll"] = true;
        }

        if (this.onlyShowPublic) {
          input["group_Permission"] = "PUBLIC";
        }

        input["dedupeUser"] = this.dedupeUser;

        return input;
      },
      fetchPolicy: "network-only",
      result(response) {
        if (response.data && !response.loading) {
          this.loading = false;
          // the skeleton loader should only kick in when we initially load the page. Otherwise, when infinite scrolling, it's very janky
          this.initialLoading = false;
          this.endCursor = response.data.posts.pageInfo.endCursor;
          this.hasNextPage = response.data.posts.pageInfo.hasNextPage;
          let posts = [...this.postList, ...response.data.posts.edges];

          // deduplicate, since we are not resettting posts. This stops the horrid page jump when you add a post
          let dedupedPosts = dedupeNodes(posts);

          // now sort by createdAt
          dedupedPosts.sort(function (a, b) {
            return new Date(b.node.createdAt) - new Date(a.node.createdAt);
          });
          this.postList = dedupedPosts;

          // only show first post if not club
          if (!this.isClub) {
            this.postList.splice(1);
          }
        }
      },
      update: (data) => data.posts.edges,
    },
    groups: {
      query: GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.showAllPostsToggle;
      },
      variables() {
        return {
          mine: true,
          withUsers: false,
          
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          const groups = extractNodes(response.data?.groups?.edges);

          if (!groups || groups?.length == 0) {
            return false;
          }

          const primaryMonthGroup = groups.find((group) =>
            group.tags.edges.some((tag) => tag.node.code === "primary-month-group")
          );

          if (primaryMonthGroup) {
            this.selectedGroupId = decodeId(primaryMonthGroup.id);
          } else {
            this.selectedGroupId = decodeId(groups[0]?.id);
          }

          this.userGroupOptions = groups.map((group) => {
            return { value: decodeId(group.id), text: group.title };
          });
        }
      },
    },
    group: {
      query: GROUP_QUERY,
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.groupId || !this.showPinned;
      },
      variables() {
        return {
          groupId: this.groupId,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.post-container-toggle-radio {
  background-color: white;
  .v-label {
    font-size: 13px;
    font-weight: 500;
  }
}
</style>
