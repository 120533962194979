<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title v-if="chat">Update {{ chat.title }}</v-toolbar-title>
      <v-toolbar-title v-else>Create a Chat</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <span v-if="!chat">
        <h3 class="mt-n2 mb-2">Create your own chat for extra accountability</h3>
        <p>Search for people or you can invite your friends to join {{ $appName }} after you create your chat.</p>

        <!-- BEWARE, v-if="false"-->
        <accountability-group-how-to v-if="false" class="mt-2 mb-6" />
      </span>

      <j-text-field placeholder="Name of chat" v-model="chatName" />
      <j-image-uploader label="Upload chat icon (optional)" :image="image" />
      
      <h4 class="mt-4 mb-2">Add people to your chat</h4>
      <j-text-field
        v-model="searchString"
        @keyup="searchUsers()"
        prepend-inner-icon="mdi-magnify"
        append-icon="mdi-close"
        placeholder="Search for people by name..."
        @click:append="searchString = null"
      />

      <div v-if="selectedUsersForDisplay && selectedUsersForDisplay.length > 0">
        <h4>Selected</h4>
        <j-avatar-card
          v-for="(user, index) in selectedUsersForDisplay"
          :key="user.id"
          :title="`${user.firstName} ${user.lastName}`"
          :avatar-url="user.profilePicture"
          :colour="colours[index]"
          class="mb-2"
        >
          <template v-slot:trailing>
            <div class="d-flex align-center justify-center">
              <j-checkbox v-model="adminUsers" :value="user.id" label="Admin" class="mr-2 ml-2 mr-md-6" />
              <v-icon @click="removeUser(user.id)">mdi-trash-can-outline </v-icon>
            </div>
          </template>
        </j-avatar-card>
        <v-divider />
      </div>

      <div v-if="users && searchString">
        <j-avatar-card
          v-for="(user, index) in users"
          :key="user.id"
          :title="`${user.firstName} ${user.lastName}`"
          :avatar-url="user.userProfile.profilePicture"
          :colour="colours[index]"
          class="mb-2"
          :title-class="getUserClass(user)"
        >
          <template v-slot:trailing>
            <div class="d-flex align-center justify-center">
              <p v-if="!canAdd(user)" class="font-italic mid-grey--text mt-4 mr-4">Requires JEFF Club</p>
              <j-checkbox v-else v-model="selectedUsers" :value="user.id" />
            </div>
          </template>
        </j-avatar-card>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center pb-6">
      <j-btn primary @click="confirm()">
        <strong class="white--text" v-if="chat">UPDATE</strong>
        <strong class="white--text" v-else>CREATE</strong>
      </j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import { CLIENTS_QUERY } from "@/graphql/queries/account";
import AccountabilityGroupHowTo from "@/components/chat/dialogs/AccountabilityGroupHowTo";

export default {
  name: "CreateEditChat",
  components: {
    AccountabilityGroupHowTo,
  },
  data() {
    return {
      chatName: null,
      image: {
        file: null,
        dataUrl: null,
      },
      keyUpSearchInterval: null,
      users: null,
      selectedUsers: [],
      adminUsers: [],
      selectedUsersForDisplay: [],
      searchString: null,
      // I can't set the random colour in Javatarcard, since on click of checkbox, it then changes the colour, which is weird.
      limit: 16,
      colours: ["pink", "green", "yellow", "navy", "blue", "red"],
    };
  },
  props: {
    workoutBlock: {
      type: Object,
      required: false,
    },
    // if editing
    chat: {
      type: Object,
      required: false,
    },
  },
  watch: {
    chat() {
      this.setData();
    },
    selectedUsers() {
      if (!this.selectedUsers || this.selectedUsers?.length == 0) {
        this.selectedUsersForDisplay = null;
      }
    },
  },
  methods: {
    canAdd(user) {
      if (user.isStaff && this.$store.getters.user.isStaff) {
        return true;
      }
      return ["CLUB", "ONE_ON_ONE"].includes(user.activeSubscription?.product?.type);
    },
    getUserClass(user) {
      if (this.canAdd(user)) {
        return "";
      }
      return "mid-grey--text";
    },
    searchUsers() {
      if (this.keyUpSearchInterval) {
        clearTimeout(this.keyUpSearchInterval);
        this.keyUpSearchInterval = null;
      }
      this.keyUpSearchInterval = setTimeout(() => {
        this.doSearch();
      }, 1000);
    },
    removeUser(userId) {
      this.selectedUsers = this.selectedUsers.filter(function(el) {
        return el != userId;
      });
    },
    doSearch() {
      if (!this.searchString) {
        if (!this.chat) {
          this.users = null;
        } else {
          this.users = this.chat.users;
        }
        return;
      }
      let data = {
        first: this.limit,
        search: this.searchString,
      };
      if (!this.$store.getters.user.isStaff) {
        data["userType"] = "User";
        data["userSubscriptionsActive"] = true;
        data["userIsStaff"] = false;
      }

      // no need to paginate. let's think about it...the user is trying to find and add people one at a time.
      // showing `$limit` is enough. Will JEFF have more than `$limit` people of the same name and surname? if so, we can deal with i then.
      this.$apollo
        .query({
          query: CLIENTS_QUERY,
          variables: data,
        })
        .then((response) => {
          let users = [];
          for (const userNode of response.data.clients.edges) {
            let user = {
              userProfile: {},
            };
            user.id = userNode.node.user.id;
            user.firstName = userNode.node.user.firstName;
            user.lastName = userNode.node.user.lastName;
            user.isStaff = userNode.node.user.isStaff;
            user.userProfile.profilePicture = userNode.node.profilePicture;
            user.activeSubscription = userNode.node.activeSubscription;
            users.push(user);
          }
          this.users = users;
        });
    },
    confirm() {
      let data = {
        id: decodeId(this.chat?.id),
        chatName: this.chatName,
        image: this.image.file,
        selectedUsers: this.selectedUsers,
        adminUsers: this.adminUsers,
      };
      if (this.chat) {
        data.id = decodeId(this.chat.id);
      }
      this.$emit("saved", data);
    },
    setData() {
      if (this.chat) {
        this.chatName = this.chat.title;
        this.image.dataUrl = this.chat.image;
        this.users = this.chat.users;
        this.selectedUsers = this.users.map((user) => {
          return user.id;
        });

        const adminUserChats = this.chat.userchatSet.filter((element) => element.isAdmin);
        this.adminUsers = adminUserChats.map((userChat) => {
          return userChat.user.id;
        });
      } else {
        this.chatName = null;
        this.image.file = null;
        this.image.dataUrl = null;
        this.users = null;
        this.selectedUsers = [];
        this.adminUsers = [];
        this.searchString = null;
      }
    },
  },
  created() {
    this.setData();
  },
  apollo: {
    clients: {
      query: CLIENTS_QUERY,
      variables() {
        return {
          userType: "User",
          userIdIn: this.selectedUsers,
        };
      },
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          this.selectedUsersForDisplay = response.data.clients.edges.map((userProfile) => {
            return {
              id: userProfile.node.user.id,
              firstName: userProfile.node.user.firstName,
              lastName: userProfile.node.user.lastName,
              profilePicture: userProfile.node.profilePicture,
            };
          });
        }
      },
      skip() {
        return !this.selectedUsers || this.selectedUsers?.length == 0;
      },
    },
  },
};
</script>
