import gql from "graphql-tag";

export const MASTER_SETTINGS = gql`
  query masterSettings($type: String) {
    masterSettings(type: $type) {
      edges {
        node {
          id
          type
          key
          value
        }
      }
    }
  }
`;
