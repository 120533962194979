<template>
  <j-banner-container
    header="Rewards"
    class="fill-height"
    show-share-link
    use-header-in-link
  >
    <v-container fill-height v-if="loading">
      <v-row justify="center" align="center">
        <v-col justify="center" align="center">
          <v-progress-circular :size="50" indeterminate> </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col>
          <j-card class="j-elevation-1" :gradient="['white', 'light-blue']">
            <v-row class="py-0 my-0">
              <v-col cols="7" class="pr-0">
                <v-card-title class="navy--text pb-0">
                  <h2 class="header">
                    <span class="larger-heading" :class="{ 'smaller-heading': $vuetify.breakpoint.mdAndDown }">{{
                      totalPoints
                    }}</span>
                    Points
                  </h2>
                </v-card-title>
                <transition name="fade">
                  <v-card-title v-if="totalPoints > 0 && doneSpinningAnimation" class="navy--text py-0">
                    <h3 class="heading-2">You can save R{{ pointsToRandValue(totalPoints) }}</h3>
                  </v-card-title>
                </transition>
              </v-col>
              <v-col cols="5" class="pl-0">
                <v-img :src="require('@/assets/images/rewards-thumbs-up.png')" width="100" height="100"></v-img>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex flex-wrap justify-center pb-3">
              <!-- note v-if false hiding get points for now. -->
              <j-btn v-if="false" secondary large to="/rewards/points/earn" class="mb-2">Get Points</j-btn>
              <j-btn v-if="totalPoints > 0" primary to="/rewards/points/use" large class="mb-2">
                Use Points
              </j-btn>
            </v-card-actions>
          </j-card>
        </v-col>
      </v-row>

      <!-- user refer friend discount code -->
      <!-- note, v-if false. hiding for now. once you remove v-if false, remove this j-alert as well -->
      <j-alert v-if="totalPoints <= 0" class="mx-2">Log your first workout selfie to earn points!</j-alert>
      <v-row v-if="false && inviteFriendDiscountCode">
        <v-col>
          <j-card class="j-elevation-1 pa-6" :gradient="['green lighten-2', 'white']">
            <h3 class="navy--text">Earn points every time a friend joins {{ $appName }} with your code 🎉</h3>

            <ul class="navy--text text-left my-4">
              <li v-if="getSetting('invite-friend-reward-points-club') > 0">
                <span class="font-weight-bold">{{ $defaultProductName }}</span
                ><v-icon class="navy--text">mdi-arrow-right-thin</v-icon>
                {{ getSetting("invite-friend-reward-points-club") }} points
              </li>
              <li v-if="getSetting('invite-friend-reward-points-weekly') > 0">
                <span class="font-weight-bold">Weekly Coaching</span
                ><v-icon class="navy--text">mdi-arrow-right-thin</v-icon>
                {{ getSetting("invite-friend-reward-points-weekly") }} points
              </li>
              <li v-if="getSetting('invite-friend-reward-points-one-on-one') > 0">
                <span class="font-weight-bold">1-1 Coaching</span
                ><v-icon class="navy--text">mdi-arrow-right-thin</v-icon>
                {{ getSetting("invite-friend-reward-points-one-on-one") }} points
              </li>
            </ul>

            <p
              v-if="rewardsDiscountActive"
              class="font-weight-bold larger-heading navy--text"
              :class="{ 'smaller-heading': $vuetify.breakpoint.mdAndDown }"
            >
              <j-gradient-text :gradient="['yellow', 'green']" class="text-uppercase">
                {{ inviteFriendDiscountCode }}</j-gradient-text
              >
            </p>

            <div v-if="rewardsDiscountActive">
              <p class="success--text std-text font-weight-bold">Your friends will get a discount 🙌</p>
              <ul class="success--text text-left my-2">
                <li>
                  <span class="font-weight-bold">{{ $defaultProductName }}</span
                  ><v-icon class="success--text">mdi-arrow-right-thin</v-icon> 1<sup>st</sup> month they get R{{
                    getSetting("invite-friend-reward-discount-amount-club")
                  }} off
                </li>
                <li>
                  <span class="font-weight-bold">Weekly Coaching</span
                  ><v-icon class="success--text">mdi-arrow-right-thin</v-icon> 1<sup>st</sup> month they get
                  {{ getSetting("invite-friend-reward-discount-percent-weekly") }}% off
                </li>
                <li>
                  <span class="font-weight-bold">1-1 Coaching</span
                  ><v-icon class="success--text">mdi-arrow-right-thin</v-icon> 1<sup>st</sup> month they get
                  {{ getSetting("invite-friend-reward-discount-percent-one-on-one") }}% off
                </li>
              </ul>
            </div>

            <v-card-actions class="mt-4 d-flex justify-center">
              <j-btn v-if="rewardsDiscountActive" tertiary narrow small @click="copyCode">
                <span v-if="copiedCode">Copied</span><span v-else>Copy My Code</span>
                <v-icon small class="ml-2 mt-n1">mdi-content-copy</v-icon>
              </j-btn>
              <j-btn tertiary narrow small @click="copyLink">
                <span v-if="copiedLink">Copied</span><span v-else>Copy My Link</span>
                <v-icon small class="ml-2 mt-n1">mdi-link-variant-plus</v-icon>
              </j-btn>
            </v-card-actions>
            <v-card-actions class="mb-3">
              <v-spacer />
              <j-btn wide color="whatsapp-green" target="_blank" :href="whatsappShareUrl">
                <strong>Invite via whatsapp</strong>
                <v-icon class="ml-2">mdi-whatsapp</v-icon>
              </j-btn>
              <v-spacer />
            </v-card-actions>
          </j-card>
        </v-col>
      </v-row>

      <v-row v-if="rewardEvents.length > 0">
        <v-col class="pb-0">
          <h2 class="text-left darker-grey--text font-weight-regular">Transactions</h2>
        </v-col>
      </v-row>
      <v-row v-if="rewardEvents.length > 0">
        <v-col class="py-0">
          <reward-event-card v-for="(event, index) in rewardEvents" :event="event" :key="index" />
        </v-col>
      </v-row>
    </v-container>
  </j-banner-container>
</template>

<script>
import { REWARDS_QUERY } from "@/graphql/queries/rewards";
import RewardEventCard from "@/components/rewards/blocks/RewardEventCard";
import RewardsMixin from "@/mixins/RewardsMixin";
import { get_register_share_link } from "@/lib/whatsapp";

export default {
  name: "Points",
  mixins: [RewardsMixin],
  components: {
    RewardEventCard,
  },
  data() {
    return {
      loading: true,
      totalPoints: 0,
      doneSpinningAnimation: false,
      rewardEvents: [],
      copiedCode: false,
      copiedLink: false,
    };
  },
  computed: {
    rewardsDiscountActive() {
      return (
        this.getSetting("invite-friend-reward-discount-amount-club") > 0 ||
        this.getSetting("invite-friend-reward-discount-percent-one-on-one") > 0
      );
    },
    inviteFriendDiscountCode() {
      return this.$store.getters.user?.userProfile?.inviteFriendDiscountCode;
    },
    whatsappShareUrl() {
      return get_register_share_link(this.inviteFriendDiscountCode, this.$appName, this.$store.getters.settings.get("REWARDS")?.get('invite-friend-url'));
    },
  },
  methods: {
    getSetting(key) {
      return this.$store.getters.settings.get("REWARDS")?.get(key);
    },
    copyLink() {
      this.copiedLink = true;
      const inviteFriendUrl = this.$store.getters.settings.get("REWARDS")?.get('invite-friend-url');
      navigator.clipboard.writeText(
        `https://${window.location.hostname}${inviteFriendUrl}?referral_code=${this.inviteFriendDiscountCode}`
      );
    },
    copyCode() {
      this.copiedCode = true;
      navigator.clipboard.writeText(this.inviteFriendDiscountCode);
    },
    animateValue(start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        this.totalPoints = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        } else {
          this.doneSpinningAnimation = true;
        }
      };
      window.requestAnimationFrame(step);
    },
  },
  apollo: {
    rewardEvents: {
      query: REWARDS_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data && !response.loading) {
          this.loading = false;
          let totalPoints = 0;
          response.data.rewardEvents
            .filter((item) => item.approvalStatus === "APPROVED")
            .forEach((item) => {
              totalPoints += item.points;
            });
          this.animateValue(0, totalPoints, 0.5 * 1000);

          // mark all the notifications as read
          this.$store.dispatch("updateUnreadRewardNotificationCount", 0);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.larger-heading {
  font-size: 45px;
}
.smaller-heading {
  font-size: 30px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
