<template>
  <j-card rounded="lg" :style="backgroundGradientStyle" class="py-3">
    <v-skeleton-loader v-if="$apollo.loading" type="card" />
    <div v-else-if="product && variant && price">
      <div class="white rounded-0 pb-4 px-4 d-flex flex-column">
        <div class="d-flex align-center justify-center">
          <div class="d-flex align-center">
            <v-img
              :src="product.image"
              :lazy-src="product.image"
              height="120"
              width="120"
              :aspect-ratio="1 / 1"
              contain
            />
            <div class="d-flex flex-column py-5 pl-4 navy--text font-weight-bold heading-2">
              <div>{{ product.title }}</div>
              <div>
                R{{ parseInt(price) }}
                <span class="grey-navy--text heading-3 font-weight-regular">
                  <span v-if="!threeMonths"> / month</span>
                  <span v-else> / 3 months</span>
                </span>
              </div>
              <div v-if="variant.code == 'standard-jeff-club'" class="d-flex align-center mt-1 p-text">
                R {{ parseInt(price * ((100 - discoveryDiscountPercent) / 100)) }} / month
                <img class="ml-2" width="50" :src="require('@/assets/images/vitality_grey.png')" />
              </div>
            </div>
          </div>
        </div>
        <j-btn class="text-center mx-auto mt-4 pl-16 pr-3" :color="color" :to="'/checkout/join?code=' + this.code">
          Join now
          <v-icon class="right-arrow ml-6">keyboard_backspace</v-icon>
        </j-btn>
        <div v-if="product.shortDescription" class="d-flex justify-center p-text font-italic mt-4 text-center">
          "{{ product.shortDescription }}"
        </div>
      </div>

      <div class="text-center">
        <div v-if="variant.firstMonthDiscountedAmount">
          <div class="heading-3 mt-2 font-weight-bold pink--text">
            SAVE R{{ parseInt(price) - variant.firstMonthDiscountedAmount }}
          </div>
          <div class="grey-navy--text">
            <span v-if="!threeMonths">Your first month only</span>
            <span v-else>Your first 3 months only</span>
          </div>
          <v-chip class="px-4 my-2 navy--text font-weight-bold price">R{{ variant.firstMonthDiscountedAmount }}</v-chip>
        </div>

        <div class="navy--text font-weight-medium">
          <span v-if="code == '1-on-1' || code == '1-on-1-grad'">+ in-depth coach consultation</span>
        </div>
        <div class="grey--text my-3 text--darken-2 p-text">Promotion codes can be applied later</div>
        <v-btn small text class="font-weight-medium grey-navy--text align-center" @click="seeMore = !seeMore">
          See all features
          <v-icon v-if="seeMore">mdi-minus</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
        <v-card-text v-if="seeMore" class="px-10">
          <div class="d-flex justify-center checkbox-list">
            <div v-html="product.description" class="p-text text-left"></div>
          </div>
        </v-card-text>
      </div>
    </div>
  </j-card>
</template>

<script>
import { START_PRODUCTS_QUERY } from "@/graphql/queries/start";
import { gradientStyle } from "@/lib/gradientStyle";

export default {
  name: "ProductCard",
  props: {
    code: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "exercise",
    },
    threeMonths: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      seeMore: false,
      // watch out. These hard coded here and in Checkout and must match up what is set in the DB under RecurringDiscounts
      discoveryDiscountPercent: 25,
    };
  },
  computed: {
    backgroundGradientStyle() {
      return gradientStyle([this.color, `${this.color}-background`, "white"], 180);
    },
    variant() {
      return this.product?.productvariantSet?.edges
        .map((variant) => variant.node)
        ?.filter((variant) => variant.code == `${this.variantCode}-${this.code}`)[0];
    },
    price() {
      return this.variant?.productvariantpriceSet?.filter((set) => set.currency == "ZAR")[0].price;
    },
    variantCode() {
      return this.threeMonths ? "quarterly" : "standard";
    },
  },
  apollo: {
    product: {
      query: START_PRODUCTS_QUERY,
      variables() {
        return {
          code: this.code,
        };
      },
      update(data) {
        return data.billingProducts?.edges?.map((product) => product.node)[0];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.right-arrow {
  transform: rotate(180deg);
}
.price {
  font-size: 20px;
}

.checkbox-list ::v-deep {
  ul {
    list-style-type: "\2713";
    line-height: 180%;
  }
  li {
    padding-left: 10px;
  }
}
</style>
