import { getColor } from "@/lib/vuetifyColorHelper";

export const gradientStyle = (colors, degrees = 180) => {
  if (colors && colors.length > 0) {
    let value = `background-image: linear-gradient(${degrees}deg, `;
    let gradients = colors.map((grad) => getColor(grad));
    value += gradients.join(", ") + ");";
    return value;
  }

  return "";
}