<template>
  <div>
    <j-banner-container header="Habit">
      <div v-if="$apollo.loading">
        <v-skeleton-loader type="card" />
      </div>
      <v-row v-else-if="habit" justify="center">
        <v-col>
          <j-card class="j-elevated-1 pa-5 mx-3">
            <div class="d-flex flex-row justify-space-between">
              <div class="d-flex flex-column text-left">
                <h2 v-if="habit.title" class="navy--text">{{ habit.title }}</h2>
                <h2 v-else-if="habit.habitOption && habit.habitOption.title" class="navy--text">
                  {{ habit.habitOption.title }}
                </h2>
                <span class="p-text pt-2">Started {{ habit.startDate | moment("D MMM YYYY") }}</span>
              </div>
              <j-btn secondary narrow @click="setEditHabit">Edit</j-btn>
            </div>
            <habit-dots :completed="habit.completed" :missed="habit.missed" class="mt-6" />
          </j-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <j-card class="j-elevated-1 py-5 mx-3">
            <habit-calendar v-if="habit" />
            <j-btn tertiary @click="showNewHabit = true">Set new habit</j-btn>
          </j-card>
        </v-col>
      </v-row>
      <j-btn v-if="pastHabits" tertiary small narrow to="/accountability/habits/past">View past habits</j-btn>
    </j-banner-container>

    <v-dialog v-if="showNewHabit" v-model="showNewHabit" width="600" transition="dialog-bottom-transition">
      <habit-dialog :habit="newHabit" :edit="false" :previousHabit="habit != null" @close="showNewHabit = false" />
    </v-dialog>

    <v-dialog v-model="showEditHabit" width="600" transition="dialog-bottom-transition">
      <habit-dialog :habit="editHabit" :edit="true" @close="showEditHabit = false" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import HabitDots from "@/components/tracker/habit/HabitDots";
import HabitCalendar from "@/components/tracker/habit/HabitCalendar";
import HabitDialog from "@/components/tracker/habit/HabitDialog";
import HabitMixin from "@/mixins/HabitMixin";

export default {
  name: "Habits",
  mixins: [HabitMixin],
  components: {
    HabitDots,
    HabitCalendar,
    HabitDialog,
  },
  data() {
    return {
      showNewHabit: false,
      showNewHabitAlert: false,
      newHabit: {
        id: 0,
        title: "",
        color: "blue",
        habitOption: null,
      },
      showEditHabit: false,
      editHabit: null,
    };
  },
  created() {
    this.setSelectedDate(this.$moment());
  },
  methods: {
    ...mapActions(["setSelectedDate"]),
    setEditHabit() {
      this.showEditHabit = true;
      this.editHabit = { ...this.habit };
    },
  },
};
</script>
