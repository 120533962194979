<template>
  <v-text-field :color="color" outlined v-bind="[$props, $attrs]" :pattern="pattern" :maxlength="maxlength"  v-on="$listeners" class="j-text-field" />
</template>

<script>
import { VTextField } from "vuetify/lib";

export default {
  name: "JTextField",
  extends: VTextField,
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    pattern: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
  },
};
</script>
