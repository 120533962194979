import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { store } from "./store";
import { apolloProvider } from "./graphql/apollo";
import "./plugins/vee-validate";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import Toasted from "vue-toasted";
import OneSignalVue from "onesignal-vue";
import VueYouTubeEmbed from "vue-youtube-embed";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import filters from "./filters";
import VueCardFormat from "vue-credit-card-validation";
import VueAnalytics from "vue-analytics";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import * as GmapVue from "gmap-vue";
import JBannerContainer from "@/layouts/sections/banners/JBannerContainer";
import JMainBannerContainer from "@/layouts/sections/banners/JMainBannerContainer";
import JCardBannerContainer from "/src/layouts/sections/banners/JCardBannerContainer.vue";
import JSubsectionRow from "@/layouts/sections/JSubsectionRow";
import JDottedBtn from "@/layouts/buttons/JDottedBtn";
import JBtn from "@/layouts/buttons/JBtn";
import JCard from "@/layouts/cards/JCard";
import JAvatarCard from "@/layouts/cards/JAvatarCard";
import JAvatarList from "@/layouts/sections/JAvatarList";
import JCheckbox from "@/layouts/inputs/JCheckbox";
import JTreeview from "@/layouts/inputs/JTreeview";
import JRadioGroup from "@/layouts/inputs/JRadioGroup";
import JRadio from "@/layouts/inputs/JRadio";
import JTextField from "@/layouts/inputs/JTextField";
import JTextarea from "@/layouts/inputs/JTextarea";
import JSelect from "@/layouts/inputs/JSelect";
import JAutocomplete from "@/layouts/inputs/JAutocomplete";
import JGradientText from "@/layouts/JGradientText";
import JAlert from "@/layouts/JAlert";
import JBlockCard from "@/layouts/cards/JBlockCard";
import JEmojiTextarea from "@/layouts/inputs/JEmojiTextarea";
import JBlockHeaderCard from "@/layouts/cards/JBlockHeaderCard";
import JListCard from "@/layouts/cards/JListCard";
import JInputMask from "@/layouts/inputs/JInputMask";
import JImageUploader from "@/layouts/inputs/JImageUploader";
import BackLink from "@/layouts/BackLink";
import ScrollLoader from "vue-scroll-loader";
import VueUploadComponent from "vue-upload-component";
import VueSocialSharing from "vue-social-sharing";
import QuestionBlock from "@/components/survey/blocks/QuestionBlock";
import HighchartsVue from "highcharts-vue";
import Cloudinary, { CldImage, CldVideo, CldTransformation, CldContext } from "cloudinary-vue";
import VueConfetti from "vue-confetti";
//import * as Sentry from "@sentry/vue";
//import { BrowserTracing } from "@sentry/tracing";
import VueTour from 'vue-tour'
import VueCountdown from '@chenfengyuan/vue-countdown';

import "./registerServiceWorker";

require('vue-tour/dist/vue-tour.css')

/*
if (process.env.VUE_APP_ENVIRONMENT == "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENVIRONMENT,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["club.jeff.fitness", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  Sentry.configureScope(function(scope) {
    scope.setUser(JSON.parse(localStorage.getItem("user")) || store.getters.user);
  });
}
*/

Vue.prototype.$appName = "PlanetFitnessPlay";
Vue.prototype.$defaultProductName = "PlanetFitnessPlay";
// SET TO null, unless you want to give users access to the default product without paying!!
Vue.prototype.$defaultProductId = 1;
Vue.config.productionTip = false;

Vue.use(VueTour)

Vue.use(Cloudinary, {
  configuration: { cloudName: "jeff-fitness" },
  components: {
    CldContext,
    CldImage,
    CldVideo,
    CldTransformation,
  },
});

Vue.use(VueMoment, {
  moment,
});

Vue.use(VueYouTubeEmbed);
Vue.use(VueTelInput);
Vue.use(VueCardFormat);
Vue.use(ScrollLoader);
Vue.use(HighchartsVue);
Vue.use(VueConfetti);

Vue.use(Toasted, {
  position: "top-right",
  duration: 6000,
  keepOnHover: true,
  iconPack: "mdi",
  router,
});

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  router,
});
Vue.use(VueFacebookPixel);
Vue.use(VueSocialSharing);
Vue.analytics.fbq.init("485216198745484");

Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: true },
  },
  emit: ["ended"],
});

Vue.use(GmapVue, {
  load: {
    key: "AIzaSyAf4MduSjYF8Gl1115UOWGf1gBKYBoW5PQ",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
  installComponents: true,
});

Vue.use(OneSignalVue);

Vue.component("google-map", GmapVue.Map);
Vue.component("google-marker", GmapVue.Marker);

// Our own components
Vue.component("j-banner-container", JBannerContainer);
Vue.component("j-main-banner-container", JMainBannerContainer);
Vue.component("j-card-banner-container", JCardBannerContainer);
Vue.component("j-subsection-row", JSubsectionRow);
Vue.component("j-dotted-btn", JDottedBtn);
Vue.component("j-btn", JBtn);
Vue.component("j-card", JCard);
Vue.component("j-avatar-card", JAvatarCard);
Vue.component("j-checkbox", JCheckbox);
Vue.component("j-treeview", JTreeview);
Vue.component("j-radio-group", JRadioGroup);
Vue.component("j-radio", JRadio);
Vue.component("j-text-field", JTextField);
Vue.component("j-textarea", JTextarea);
Vue.component("j-select", JSelect);
Vue.component("j-autocomplete", JAutocomplete);
Vue.component("j-gradient-text", JGradientText);
Vue.component("j-avatar-list", JAvatarList);
Vue.component("j-alert", JAlert);
Vue.component("j-block-card", JBlockCard);
Vue.component("j-emoji-textarea", JEmojiTextarea);
Vue.component("j-block-header-card", JBlockHeaderCard);
Vue.component("j-list-card", JListCard);
Vue.component("j-input-mask", JInputMask);
Vue.component("j-image-uploader", JImageUploader);
Vue.component("back-link", BackLink);
Vue.component("file-upload", VueUploadComponent);

// importing QuestionBlock globally to prevent recursion issues, where this component is used in parent/children
Vue.component("QuestionBlock", QuestionBlock);

Vue.component(VueCountdown.name, VueCountdown);
/**
 * Bind filters to the main vue instance
 */
for (var key in filters) {
  Vue.filter(key, filters[key]);
}

/**
 * Create an event bus so we can pass events through chained components more easily.
 * Dont abuse this tool, use it only when you need to pass an event through multiple nested components.
 */

export const bus = new Vue();
Vue.prototype.$bus = bus;


new Vue({
  apolloProvider,
  router,
  store,
  vuetify,
  render: (h) => h(App),
  beforeMount() {
    if (process.env.VUE_APP_ONE_SIGNAL_APP_ID) {
      this.$OneSignal.init({
        appId: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerParam: {
          scope: "/push/onesignal/",
        },
      });
    }
  },
}).$mount("#app");
