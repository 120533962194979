<template>
  <div class="text-center mb-5">
    <div class="heading-2 navy--text font-weight-bold py-3 mt-4">Need more time to decide?</div>
    <div class="mb-2 text-center heading-4 grey-navy--text font-weight-medium">
      Answer 3 quick questions to try some FREE workouts and recipes
    </div>
    <j-btn color="navy" class="px-12" to="/start/obstacle">
      Continue
    </j-btn>
  </div>
</template>

<script>
export default {
  name: "NeedMoreTime",
};
</script>
