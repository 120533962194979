<template>
  <v-container>
    <v-row justify="center" class="mb-4 mx-0">
      <v-col cols="12" :md="6" :xl="5" align="center" justify="center" class="pt-0 mt-0 text-left">
        <v-img :src="require('@/assets/images/always_on.png')" />
        <j-card class="pa-4">
          <p class="font-weight-bold">Aaah! It looks like your device is offline!</p>
          <p>
            Did you know that all Planet Fitness clubs are #ALWAYSON? Our clubs are equipped with generators to ensure
            uninterrupted training for our members.
          </p>
          <p>
            Head on over to your nearest Planet Fitness club, or re-check your internet connection to continue using the
            Planet Fitness web app.
          </p>

          <j-btn href="/today"> Try again </j-btn>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Offline",
};
</script>
