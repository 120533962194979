<template>
  <j-banner-container header="Shopping List">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-tabs v-model="tab" slider-size="4" height="40" class="selected-tabs-active">
          <v-tabs-slider :style="activeStyle()"></v-tabs-slider>
          <v-tab key="this-week">
            <span :style="activeStyle(0)">This Week</span>
          </v-tab>
          <v-tab key="next-week">
            <span :style="activeStyle(1)">Next Week</span>
          </v-tab>
          <v-tabs-items v-model="tab">
            <template v-if="requiresRefresh">
              <j-alert type="warning" class="mt-3">
                You've recently changed your menu. Would you like to refresh your shopping list?<br />
                <j-btn class="mt-2" tertiary narrow @click="refreshShoppingList">Refresh</j-btn>
              </j-alert>
            </template>
            <v-tab-item key="this-week" class="mt-5">
              <v-skeleton-loader
                v-if="state.loading"
                type="list-item-avatar,list-item-avatar,list-item-avatar"
              ></v-skeleton-loader>
              <div v-else>
                <shopping-list-view
                  v-if="!state.error && shoppingList"
                  :shoppingList="shoppingList"
                  :shoppingListId="parseInt(shoppingListId)"
                  :start-date="startDate"
                ></shopping-list-view>
                <j-alert
                  v-if="
                    state.error &&
                      (!upcomingNutritionBlock ||
                        (upcomingNutritionBlock && decodeId(upcomingNutritionBlock.id) == blockId))
                  "
                  type="info"
                >
                  {{ humanErrorMessage }}
                </j-alert>

                <j-dotted-btn
                  v-if="state.error && upcomingNutritionBlock && decodeId(upcomingNutritionBlock.id) != blockId"
                  wide
                  color="green"
                  @click="navigateNutritionBlock(upcomingNutritionBlock.id)"
                  >View next program</j-dotted-btn
                >
              </div>
            </v-tab-item>
            <v-tab-item key="next-week" class="mt-5">
              <v-skeleton-loader
                v-if="state.loading"
                type="list-item-avatar,list-item-avatar,list-item-avatar"
              ></v-skeleton-loader>

              <div v-else>
                <shopping-list-view
                  v-if="!state.error && shoppingList"
                  :shoppingList="shoppingList"
                  :shoppingListId="parseInt(shoppingListId)"
                  :start-date="startDate"
                ></shopping-list-view>
                <j-alert
                  v-if="
                    state.error &&
                      (!upcomingNutritionBlock ||
                        (upcomingNutritionBlock && decodeId(upcomingNutritionBlock.id) == blockId))
                  "
                  type="info"
                >
                  {{ humanErrorMessage }}
                </j-alert>
                <j-dotted-btn
                  v-if="state.error && upcomingNutritionBlock && decodeId(upcomingNutritionBlock.id) != blockId"
                  wide
                  color="green"
                  @click="navigateNutritionBlock(upcomingNutritionBlock.id)"
                  >View next program</j-dotted-btn
                >
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { getNextMonday, getThisMonday } from "@/lib/fancy_date";
import { SHOPPING_LIST_QUERY } from "@/graphql/queries/shoppingList";
import { GENERATE_SHOPPING_LIST_MUTATION } from "@/graphql/mutations/shoppingList";
import ShoppingListView from "@/components/nutritionblocks/ShoppingList";
import { gradientStyle } from "@/lib/gradientStyle";
import { mapGetters } from "vuex";
import { decodeId } from "@/lib/string";
import RecipeListMixin from "@/mixins/nutrition/RecipeListMixin";
import ShoppingListMixin from "@/mixins/ShoppingListMixin";

export default {
  name: "ShoppingList",
  mixins: [ShoppingListMixin, RecipeListMixin],
  components: {
    ShoppingListView,
  },
  data() {
    return {
      state: {
        loading: true,
        error: false,
        errorMsg: "",
      },
      shoppingListId: null,
      shoppingList: null,
      requiresRefresh: false,
      startDate: null,
      week: "current",
      tab: 0,
      newItem: "",
    };
  },
  watch: {
    tab(val) {
      this.shoppingList = null;
      this.resetState();
      this.startDate = val === 0 ? getThisMonday() : getNextMonday();
    },
  },
  created() {
    if (this.$route.query.tab) {
      this.tab = parseInt(this.$route.query.tab);
    }
  },
  mounted() {
    this.startDate = getThisMonday();
  },
  computed: {
    ...mapGetters(["upcomingNutritionBlock"]),
    blockId() {
      return decodeId(this.$route.params.nutritionBlockId);
    },
    textGradientStyle() {
      return gradientStyle(["yellow", "green"], 90);
    },
    humanErrorMessage() {
      switch (this.state.errorMsg) {
        case "NO_NUTRITION_BLOCK":
          return "You do not currently have a nutrition program setup, please select a nutrition program and try again.";
        case "NO_RECIPES":
          return "No recipes for this week";
        default:
          return "";
      }
    },
  },
  methods: {
    decodeId(id) {
      return decodeId(id);
    },
    resetState() {
      this.state.loading = true;
      this.state.errorMsg = "";
      this.state.error = false;
    },
    activeStyle(btnIndex = this.tab) {
      return this.tab === btnIndex ? this.textGradientStyle : "";
    },
    async refreshShoppingList() {
      this.state.loading = true;
      this.requiresRefresh = false;
      await this.generateShoppingList();
    },
    async generateShoppingList() {
      let response = await this.$apollo.mutate({
        mutation: GENERATE_SHOPPING_LIST_MUTATION,
        variables: {
          data: {
            startDate: this.startDate,
            nutritionBlockId: this.blockId,
            subscriptionNutritionBlockId: this.getSubscriptionBlockIdForBlock(this.blockId),
          },
        },
      });
      if (response.data.generateShoppingListMutation.success) {
        this.shoppingList = this.mutateShoppingList(response.data.generateShoppingListMutation.shoppingList);
      } else {
        this.state.error = true;
        this.state.errorMsg = response.data.generateShoppingListMutation.error;
      }
      this.state.loading = false;
    },
    navigateNutritionBlock(id) {
      this.$router.push(`/nutrition/${decodeId(id)}`);
    },
  },
  apollo: {
    shoppingList: {
      query: SHOPPING_LIST_QUERY,
      fetchPolicy: "no-cache",
      variables() {
        return {
          startDate: this.startDate,
        };
      },
      skip() {
        return this.startDate == null;
      },
      update(data) {
        if (data.shoppingList !== null) {
          this.state.loading = false;
          this.requiresRefresh = data.shoppingList.requiresRefresh;
          return this.mutateShoppingList(data.shoppingList);
        } else {
          this.generateShoppingList();
        }
        return null;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.v-tab--active span,
.v-tab--active .v-icon {
  @extend .gradient-text;
  font-weight: bold;
}
</style>
