<template>
  <div>
    <j-card-content
      :icon="workoutBlock.leadInstructor.userProfile.profilePicture"
      :instructor="instructorCopy"
      :secondary-instructor="workoutBlock.secondaryInstructor"
      :info="info"
      :summaryInfo="summaryInfo"
      :leftButton="leftButtonText"
      leftButtonIcon="mdi-format-list-bulleted"
      @leftButtonClicked="clickLeftButton()"
      :topButton="showAccountabilityGroupButton ? 'Join Accountability Group' : null"
      topButtonIcon="mdi-account-multiple"
      @topButtonClicked="$emit('joinAccountabilityGroup')"
      :primaryButton="primaryButtonText"
      :primaryButtonLoading="loading"
      :primary-button-disabled="salesClosed"
      @primaryButtonClicked="$emit('doProgram')"
      :accent-color="accentColor"
      :show-cta="showCta"
      class="py-1"
      :facebook-url="workoutBlock.facebookUrl"
      :whatsapp-url="workoutBlock.whatsappUrl"
      @ratingButtonClicked="$emit('openRatingDialog')"
      @allRatingsButtonClicked="$emit('showRatings')"
      :avg-rating="avgRating"
      :total-reviews="totalReviews"
      has-review-rating
      :user-can-rate="workoutBlock.userCanRate"
    />
    <div v-if="workoutSubscriptionBlock && isMine && !isCurrent && !hasEnded" class="ma-4 mt-6">
      <j-alert>
        Your {{ workoutBlock.type == "COURSE" ? "course" : "program" }} starts on
        {{ workoutSubscriptionBlock.startDate | moment("ddd, D MMM") }}.
        <router-link :to="navigateOverview()" class="text-decoration-underline font-weight-bold">View plan</router-link>
      </j-alert>
    </div>
    <div v-if="workoutSubscriptionBlock && isMine && hasEnded" class="ma-4 mt-6">
      
      <j-alert>
        Your {{ workoutBlock.type == "COURSE" ? "course" : "program" }} ended <span v-if="workoutSubscriptionBlock.endDate">on
        {{ workoutSubscriptionBlock.endDate | moment("ddd, D MMM 'YY") }}.</span>
        <router-link :to="navigateOverview()" class="text-decoration-underline font-weight-bold">View plan</router-link>
        <j-btn secondary narrow @click="restartProgram()" class="d-block mt-2"> Restart Program </j-btn>
      </j-alert>
    </div>

    <v-card-text v-if="showCta">
      <div class="mt-6" v-if="!isMine && (workoutBlock.relatedBlock || workoutBlock.relatedNutritionBlock)">
        <p class="navy--text">
          With this {{ workoutBlock.type == "COURSE" ? "course" : "program" }}, you also get access to:
        </p>

        <j-avatar-card
          v-if="workoutBlock.relatedBlock"
          :navigate-to="`/program/${workoutBlock.relatedBlock.id}`"
          :title="workoutBlock.relatedBlock.title"
          :subtitle="workoutBlock.relatedBlock.leadInstructor.firstName"
          :avatar-url="workoutBlock.relatedBlock.leadInstructor.userProfile.profilePicture"
        >
          <template v-slot:trailing>
            <div v-if="workoutBlock.relatedBlock.userList.length > 0">
              <j-avatar-list :user-list="workoutBlock.relatedBlock.userList" />
            </div>
          </template>
        </j-avatar-card>

        <j-avatar-card
          class="mt-4"
          :navigate-to="
            workoutBlock.relatedNutritionBlock.showInSearches
              ? `/nutrition/${decodeId(workoutBlock.relatedNutritionBlock.id)}`
              : null
          "
          v-if="workoutBlock.relatedNutritionBlock"
          :title="workoutBlock.relatedNutritionBlock.title"
          :subtitle="`with ${workoutBlock.relatedNutritionBlock.leadInstructor.firstName}`"
          :avatar-url="workoutBlock.relatedNutritionBlock.leadInstructor.userProfile.profilePicture"
        >
          <template v-slot:trailing>
            <div v-if="workoutBlock.relatedNutritionBlock.userList.length > 0">
              <j-avatar-list :user-list="workoutBlock.relatedNutritionBlock.userList" />
            </div>
          </template>
        </j-avatar-card>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import BlockMixin from "@/mixins/BlockMixin";
import JCardContent from "@/layouts/sections/JCardContent";

export default {
  name: "BlockHeader",
  mixins: [BlockMixin],
  props: {
    workoutBlock: {
      type: Object,
      required: true,
    },
    workoutSubscriptionBlock: {
      type: Object,
      required: false,
    },
    isMine: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    hasEnded: {
      type: Boolean,
      default: false,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    avgRating: {
      type: [Number, String],
      required: false,
    },
    totalReviews: {
      type: Number,
      default: 0,
    },
  },
  components: {
    JCardContent,
  },
  data() {
    return {
      leftButton: "",
      rightButton: "",
    };
  },
  computed: {
    info() {
      let priceDescription = `${this.getPrice()}`;
      if (this.workoutBlock?.priceDescription) {
        priceDescription = this.workoutBlock.priceDescription;
      }
      return [
        {
          icon: "mdi-calendar-month",
          text: this.getStartingText(),
        },
        {
          icon: "mdi-tag-outline",
          text: priceDescription,
        },
        {
          icon: "mdi-clock-outline",
          text: `${this.workoutBlock.duration} days`,
        },
      ];
    },
    summaryInfo() {
      let value = "Exercise";
      if (this.workoutBlock.type === "COURSE") {
        value = "Course";
      } else if (this.workoutBlock.type === "NUTRITION") {
        value = "Nutrition";
      }
      return [
        {
          title: "Program",
          value: value,
        },
      ];
    },
    salesClosed() {
      return this.workoutBlock.salesCloseDate && this.$moment().isAfter(this.workoutBlock.salesCloseDate);
    },
    instructorCopy() {
      let copy = `with ${this.workoutBlock.leadInstructor.firstName}`;
      if (this.workoutBlock.secondaryInstructor?.firstName) {
        copy = copy + ` & ${this.workoutBlock.secondaryInstructor?.firstName}`;
      }
      return copy;
    },
    leftButtonText() {
      if (!this.$store.getters.user?.id) {
        return null;
      }
      if (this.isMine && !this.hasEnded) {
        return "Manage";
      }
      return "Plan";
    },
    primaryButtonText() {
      // if mine and hasEnded, then user can do block again and we should show the CTA
      if (this.isMine && !this.hasEnded) return null;

      if (this.salesClosed) {
        return "Sales have Closed";
      }
      return this.workoutBlock.type == "COURSE" ? "Do this course" : "Do this program";
    },
    showAccountabilityGroupButton() {
      if (this.workoutBlock.showAccountabilityGroupRegistration) {
        return this.isMine && !this.workoutBlock?.hasChatRegistration;
      }
      if (this.workoutBlock.showAccountabilityChats) {
        return this.isMine && !this.workoutBlock.hasAccountabilityChat;
      }
      return false;
    },
  },
  methods: {
    getStartingText() {
      if (this.isCurrent) {
        return `Started ${this.$moment(this.workoutSubscriptionBlock.startDate).format("ddd, D MMM 'YY")}`;
      }

      if (this.workoutBlock.startDate && this.$moment(this.workoutBlock.startDate).isBefore(this.$moment())) {
        return `Started ${this.$moment(this.workoutBlock.startDate).format("ddd, D MMM 'YY")}`;
      }

      return `Starting ${
        this.$moment(this.workoutBlock.startDate).isSameOrAfter(this.$moment())
          ? this.$moment(this.workoutBlock.startDate).format("ddd, D MMM 'YY")
          : "every Monday"
      }`;
    },
    getPrice() {
      return this.workoutBlock.price > 0 ? "R" + this.workoutBlock.price : "FREE";
    },
    clickLeftButton() {
      if (this.isMine && !this.hasEnded) {
        this.$emit("manage");
      } else {
        this.$router.push(`/program/${this.workoutBlock.id}/overview`);
      }
    },
    restartProgram() {
      this.$emit("restartProgram");
    },
  },
  created() {
    if (this.workoutBlock.activeUsers > 0) {
      this.summaryInfo.push({ title: "Active", value: this.workoutBlock.activeUsers });
    }

    if (this.workoutBlock.completedUsers > 0) {
      this.summaryInfo.push({ title: "Completed", value: this.workoutBlock.completedUsers });
    }
  },
};
</script>
