<template>

  <div v-if="macros && Object.keys(macros).length > 0">
    <div v-if="macros.KCAL" class="p-text navy--text">~{{ macros.KCAL }} kcal per serving</div>
    <!-- <div class="d-flex mt-2">
      <div class="mr-5">
        <div class="p-text navy--text text-center">Units</div>
        <v-chip color="blue lighten-5">
          <span class="navy--text font-weight-bold">{{ macros.UNITS }}</span>
        </v-chip>
      </div>
      <div class="mr-5">
        <div class="p-text pink--text text-center">Protein</div>
        <div class="text-center">
          <v-chip color="pink lighten-5">
            <span class="pink--text font-weight-bold">{{ macros.PROTEIN }}</span>
          </v-chip>
        </div>
      </div>
      <div class="mr-5">
        <div class="p-text nutrition--text text-center">Carbs</div>
        <div class="text-center">
          <v-chip color="nutrition lighten-5">
            <span class="nutrition--text font-weight-bold">{{ macros.CARBS }}</span>
          </v-chip>
        </div>
      </div>
      <div>
        <div class="p-text yellow--text text-center">Fat</div>
        <div class="text-center">
          <v-chip color="yellow lighten-5">
            <span class="yellow--text font-weight-bold">{{ macros.FAT }}</span>
          </v-chip>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "MacroCard",
  props: {
    macros: {
      type: Object,
      required: false,
    },
  },
};
</script>
